import { PropsGroupBase, type Bim, type SceneInstanceShapeMigration, PropsGroupsRegistry, PropsFieldOneOf} from "../..";
import { BimProperty } from "../../bimDescriptions/BimProperty";
import type { PropertiesGroupFormatters } from "../../bimDescriptions/PropertiesGroupFormatter";
import { PropertiesGroupFormatter } from "../../bimDescriptions/PropertiesGroupFormatter";
import { BimPropertiesFormatter, sceneInstanceHierarchyPropsRegistry } from "../../catalog/SceneInstanceHierarchyPropsRegistry";
import type { AssetBasedCatalogItemCreators } from "../../catalog/CatalogItemCollection";
import { create_expandCommonEquipmentPropsWithCostTableLinks, registerEquipmentCommonAssetToCatalogItem } from "../EquipmentCommon";
import { PropsFieldFlags } from "../../properties/PropsGroupComplexDefaults";
import { TransformerSLDViewerProperty, registerTransformerSLDRuntime } from "./sld";
import { IterUtils } from "engine-utils-ts";
import type { PropertiesPatch } from "../../bimDescriptions/PropertiesCollection";

export const TransformerIdent = 'transformer';

export const TransformerKeyProps = {
    model: BimProperty.NewShared({
        path: ['commercial', 'model'],
        value: 'unknown_model',
    }),
    power: BimProperty.NewShared({
        path: ['output', 'power'],
        value: 0,
        unit: 'W',
    }),
    mv_voltage: BimProperty.NewShared({
        path: ['output', 'mv_voltage'],
        value: 0,
        unit: 'V',
    }),
};

export function registerTransformerKeyPropsGroupFormatter(group: PropertiesGroupFormatters) {
    group.register(
        TransformerIdent,
        new PropertiesGroupFormatter(
            TransformerKeyProps,
            (props, unitsMapper) => {
                return [
                    props.model.asText(),
                    [
                        props.power,
                        props.mv_voltage
                    ].map(x => x.valueUnitUiString(unitsMapper)),
                ].flat().join(' ');
            }
        )
    )
}

export function registerTransformerAssetToCatalogItem(group: AssetBasedCatalogItemCreators) {
    registerEquipmentCommonAssetToCatalogItem(TransformerIdent, group);
}


export function registerTransformer(bim: Bim) {
    bim.instances.archetypes.registerArchetype(
        {
            type_identifier: TransformerIdent,
            mandatoryProps: [
            {path: ['dimensions', 'width'], value: 3, unit: 'ft', numeric_range: [0.01, 50]},
                {path: ['dimensions', 'depth'], value: 3, unit: 'ft', numeric_range: [0.01, 50]},
                {path: ['dimensions', 'length'], value: 3, unit: 'ft', numeric_range: [0.01, 50]},
            ],
            propsShapeMigrations: migrations(),
            propsClass: TransformerProps,
        }
    );
    bim.reactiveRuntimes.registerRuntimeSolver(registerTransformerSLDRuntime())
}

function migrations():SceneInstanceShapeMigration[]{
    return [
        {
			toVersion: 1,
			validation: {
				updatedProps: [],
				deletedProps: [],
			},
			patch: (inst)=>{
                const mv_props = inst.properties.createPatchToRemovePropsStartingWith("circuit | mv_wiring");
                if(mv_props){
                    inst.properties.applyPatch(mv_props);
                }
			}
        },
        removeEnergyPropsMigration(2),
    ]
}

sceneInstanceHierarchyPropsRegistry.set(
    TransformerIdent,
    [
        new BimPropertiesFormatter(
            {
                model: BimProperty.NewShared({
                    path: ['commercial', 'model'],
                    value: '',
                }),
            },
            (props) => [props.model.asText() || 'model not specified'],
        )
    ]
);


export class TransformerProps extends PropsGroupBase {
    singleLineDiagramViewer: TransformerSLDViewerProperty | null;

    constructor(args: Partial<TransformerProps>) {
        super();
        this.singleLineDiagramViewer = args.singleLineDiagramViewer ?? null;
    }
}

PropsGroupsRegistry.register({
    class: TransformerProps,
    complexDefaults: {
        singleLineDiagramViewer: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization,
            null,
            TransformerSLDViewerProperty
        ),
    }
})

export function removeEnergyPropsMigration(version: number): SceneInstanceShapeMigration {
    const migration: SceneInstanceShapeMigration = {
        toVersion: version,
        validation: {
            updatedProps: [],
            deletedProps: [],
        },
        patch: (inst) => {
            const energy_props = inst.properties.createPatchToRemovePropsStartingWith("circuit | energy");
            const block_energy = inst.properties.createPatchToRemovePropsStartingWith("circuit | block_energy");
            const patch: PropertiesPatch = [];
            if (energy_props) {
                IterUtils.extendArray(patch, energy_props);
            }
            if (block_energy) {
                IterUtils.extendArray(patch, block_energy);
            }
            if (patch.length > 0) {
                inst.properties.applyPatch(patch);
            }
        }
    }

    return migration;
}

export function create_expandTransformerPropsWithCostTableLinks() {
    return create_expandCommonEquipmentPropsWithCostTableLinks(TransformerKeyProps);
}
