import { IrregularHeightmapGeometry, RegularHeightmapGeometry, TerrainElevation, TerrainTileId, WGSCoord } from "bim-ts";
import { ExecutionThreadPreference, JobExecutor, registerExecutor } from "engine-utils-ts";
import { Aabb2, Vector2 } from "math-ts";
import { Matrix4 } from "math-ts";
import { WGSConverter } from "../WGSConverter";


interface CreateGeoTiffExecutorArgs {
    yInterval: {min: number, max: number},
    bbox: Aabb2,
    dy: number,
    dx: number,
    width: number,
    datum: string | null,
    representations: {
        tilesIdsBbox: Aabb2,
        tilesSize: number,
        geoTiles: Map<TerrainTileId, RegularHeightmapGeometry> | Map<TerrainTileId, IrregularHeightmapGeometry>,
        worldMatrix: Matrix4,
    }[],
}


export class CreateGeoTiffExecutor extends JobExecutor<CreateGeoTiffExecutorArgs, Float32Array> {
	execute(args: CreateGeoTiffExecutorArgs): Float32Array {
        
        const pointsToSample = new Array<Vector2>((args.yInterval.max - args.yInterval.min + 1) * args.width);
        const image = new Float32Array((args.yInterval.max - args.yInterval.min + 1) * args.width);
        let index = 0;
        const wm = new Matrix4();

        let point: Vector2;
        for (let iy = args.yInterval.max; iy >= args.yInterval.min; iy--) {
            const y = args.bbox.min.y + iy * args.dy;
            
            for (let ix = 0; ix < args.width; ix++, index++) {
                const x = args.bbox.min.x + ix * args.dx;
    
                image[index] = NaN;
                if (args.datum) {
                    point = WGSConverter.projectWgsToFlatMap(new WGSCoord(y, x)!, args.datum);
                } else {
                    point = new Vector2(x, y);
                }
                pointsToSample[index] = point;
                
                for (const repr of args.representations) {
                    const localPoint = point.clone();
                    localPoint.applyMatrix4(new Matrix4().getInverse(repr.worldMatrix));

                    if (repr.tilesIdsBbox.containsPoint(localPoint) && 
                        repr.geoTiles.values().next().value instanceof RegularHeightmapGeometry
                    ) {
                        const regularTiles = repr.geoTiles as Map<TerrainTileId, RegularHeightmapGeometry>;
                        const tileSize = repr.tilesSize;
    
                        const tileId = TerrainTileId.newFromPoint(localPoint, tileSize);
                        const tile = regularTiles.get(tileId);
    
                        if (tile !== undefined) {
                            const tileOffset = tileId.localOffset(tileSize);
                            const elevation = tile.sampleInLocalSpaceSingular(localPoint.sub(tileOffset));
    
                            if (elevation.distToRealSample < 0.5 * tile.segmentSizeInMeters &&
                                (isNaN(image[index]) || image[index] < elevation.elevation!)) {
    
                                image[index] = elevation.elevation! + repr.worldMatrix.elements[14];
                            }
                        }
                    }
                }
            }
        }
    
        for (const repr of args.representations) {
            if (repr.geoTiles.values().next().value instanceof IrregularHeightmapGeometry) {
                const irregularTiles = repr.geoTiles as Map<TerrainTileId, IrregularHeightmapGeometry>;
                for (const tile of irregularTiles) {
                    const elevations = TerrainElevation.sampleFromIrregularGeometry(tile[1], wm, pointsToSample);
        
                    for (let i = 0; i < pointsToSample.length; ++i) {
                        if (elevations[i].distToRealSample < 0.5 &&
                            (isNaN(image[i]) || image[i] < elevations[i].elevation!)) {
        
                            image[i] = elevations[i].elevation!;
                        }
                    }
                }
            }
        }

        return image;
	}

	executionPreference(_: CreateGeoTiffExecutorArgs): ExecutionThreadPreference {
        return ExecutionThreadPreference.WorkerThread;
	}
}
registerExecutor(CreateGeoTiffExecutor);