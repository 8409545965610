import { AttrFlag, BlockTypeFlag, HorizontalJustification, VerticalJustification } from "./DxfEnums";
import { PropertyEntry } from "./DxfNode";


export type DxfPropertyMap = {
    [key: string]: PropertyEntry;
}

//Codes are taken from https://images.autodesk.com/adsk/files/autocad_2012_pdf_dxf-reference_enu.pdf

export const TABLE_PROPERTIES: DxfPropertyMap = {
    name: { code: 2, value: ""},
    handle: { code: 5, value: 0 },
    subclassMarker: { code: 100, value: "AcDbSymbolTable" },
    numberOfEntries: { code: 70, value: 0 },
};

export const LAYER_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    subclassMarker: { code: 100, value: "AcDbSymbolTableRecord" },
    classMarker: { code: 100, value: "AcDbLayerTableRecord" },
    name: { code: 2, value: "0" },
    layerFlag: { code: 70, value: 0 },
    colorNumber: { code: 62, value: 7 },
    lineTypeName: { code: 6, value: "CONTINUOUS" },
    plotStyleName: { code: 390, value: 0 }
};

export const DICTIONARY_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    ownerHandle: { code: 330, value: 0 },
    classMarker: { code: 100, value: "AcDbDictionary" },
    hardOwnerFlag: { code: 280, value: 1 },
};

export const DIM_STYLE_PROPERTIES: DxfPropertyMap = {
    handle: { code: 105, value: 0 },
    subclassMarker: { code: 100, value: "AcDbSymbolTableRecord" },
    classMarker: { code: 100, value: "AcDbDimStyleTableRecord" },
    styleName: { code: 2, value:""},
    standardFlag: { code: 70, value: 0 },
    dimpost: { code: 3, value: "" },
    dimapost: { code: 4, value: "" },
    dimblk: { code: 5, value: "" },
    dimblk1: { code: 6, value: "" },
    dimblk2: { code: 7, value: "" },
    dimscale: { code: 40, value: 1 },
    dimasz: { code: 41, value: 0.18 },
    dimexo: { code: 42, value: 0.0625 },
    dimdli: { code: 43, value: 0.4 },
    dimexe: { code: 44, value: 0.2 },
    dimrnd: { code: 45, value: 0.0 },
    dimdle: { code: 46, value: 0.0 },
    dimtp: { code: 47, value: 0.0 },
    dimtm: { code: 48, value: 0.0 },
    dimtxt: { code: 140, value: 0.25 },
    dimcen: { code: 141, value: 0.1 },
    dimtsz: { code: 142, value: 0.0 },
    dimaltf: { code: 143, value: 25 },
    dimlfac: { code: 144, value: 1 },
    dimtvp: { code: 145, value: 0.0 },
    dimtfac: { code: 146, value: 1.0 },
    dimgap: { code: 147, value: 0.1 },
    dimtol: { code: 71, value: 0 },
    dimlim: { code: 72, value: 0 },
    dimtih: { code: 73, value: 0 },
    dimtoh: { code: 74, value: 0 },
    dimse1: { code: 75, value: 0 },
    dimse2: { code: 76, value: 0 },
    dimtad: { code: 77, value: 1 },
    dimzin: { code: 78, value: 0 },
    dimalt: { code: 170, value: 0 },
    dimaltd: { code: 171, value: 2 },
    dimtofl: { code: 172, value: 0 },
    dimsah: { code: 173, value: 0 },
    dimtix: { code: 174, value: 0 },
    dimsoxd: { code: 175, value: 0 },
    dimclrd: { code: 176, value: 0 },
    dimclre: { code: 177, value: 0 },
    dimclrt: { code: 178, value: 0 },
    dimlunit: { code: 277, value: 5 },
};      

export const TEXT_STYLE_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    subclassMarker: { code: 100, value: "AcDbSymbolTableRecord" },
    classMarker: { code: 100, value: "AcDbTextStyleTableRecord" },
    name: {code: 2, value: "Arial" },
    flags: { code: 70, value: 0 },
    generationFlags: { code: 71, value: 0 },
    height: { code: 40, value: 0 },
    lastHeight: { code: 41, value: 1 },
    width: { code: 42, value: 1 },
    oblique: { code: 50, value: 0 },
    font: { code: 3, value: "Arial" }
};

export const LINE_TYPE_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    subclassMarker: { code: 100, value: "AcDbSymbolTableRecord" },
    classMarker: { code: 100, value: "AcDbLinetypeTableRecord" },
    linetypeName: { code: 2, value: "linetype" },
    standardFlag: { code: 70, value: 0 },
    description: { code: 3, value: "" },
    alignmentCode: { code: 72, value: 65 },
    numberOfElements: { code: 73, value: 0 },
    totalPatternLength: { code: 40, value: 0 },
};

export const APP_ID_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    softPointerId: { code: 330, value: 0 },
    subclassMarker: { code: 100, value: "AcDbSymbolTableRecord" },
    classMarker: { code: 100, value: "AcDbRegAppTableRecord" },
    name: { code: 2, value: "ACAD" },
    standardFlag: { code: 70, value: 0 }
};

export const BLOCK_RECORD_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    subclassMarker: { code: 100, value: "AcDbSymbolTableRecord" },
    classMarker: { code: 100, value: "AcDbBlockTableRecord" },
    name: { code: 2, value:""},
    layoutHandle: { code: 340, value:"0"},
    insertionUnits: { code: 70, value: 0 },
    blockExplodability: { code: 280, value: 1 },
    blockScalability: { code: 281, value: 0 },
    annotative: { code: 1071, value: 0 }
};

export const VPORT_PROPERTIES: DxfPropertyMap = {  
    handle: { code: 5, value: 0 },
    subclassMarker: { code: 100, value: "AcDbSymbolTableRecord" },
    classMarker: { code: 100, value: "AcDbViewportTableRecord" },
    viewportName: { code: 2, value: "Standard" },
    standardFlag: { code: 70, value: 0 },
    softPointerId: { code: 330, value: 2 },
    loewrLeftX: { code: 10, value: 0 },
    loewrLeftY: { code: 20, value: 0 },
    upperRightX: { code: 11, value: 1 },
    upperRightY: { code: 21, value: 1 },
    centerX: { code: 12, value: 0 },
    centerY: { code: 22, value: 0 },
    snapBaseX: { code: 13, value: 0 },
    snapBaseY: { code: 23, value: 0 },
    snapSpacingX: { code: 14, value: 1 },
    snapSpacingY: { code: 24, value: 1 },
    gridSpacingX: { code: 15, value: 10 },
    gridSpacingY: { code: 25, value: 10 },
    viewDirX: { code: 16, value: 0 },
    viewDirY: { code: 26, value: 0 },
    viewDirZ: { code: 36, value: 1 },
    viewTargetX: { code: 17, value: 0 },
    viewTargetY: { code: 27, value: 0 },
    viewTargetZ: { code: 37, value: 0 },
    viewHeight: { code: 40, value: 10000 },
    viewPortAspectRatio: { code: 41, value: 1 },
    lensLength: { code: 42, value: 50 },
    clippingPlanesFront: { code: 43, value: 0 },
    clippingPlanesBack: { code: 44, value: 0 },
    snapRotationAngle: { code: 50, value: 0 },
    viewTwistAngle: { code: 51, value: 0 },
    viewMode: { code: 71, value: 0 },
    circleZoomPercent: { code: 72, value: 0 },
    fastZoomSetting: { code: 73, value: 0 },
    ucsiconSetting: { code: 74, value: 0 },
    snapOnOff: { code: 75, value: 0 },
    gridOnOf: { code: 76, value: 0 },
    snapStyle: { code: 77, value: 0 },
    snapIsopair: { code: 78, value: 0 },
};

export const ATTRIBUTE_DEFINITION_PROPERTIES: DxfPropertyMap= {
    handle: { code: 5, value: 0 },
    subclassMarker: { code: 100, value: "AcDbEntity" },
    layerName: { code: 8, value: "0" },
    classMarker: { code: 100, value: "AcDbText" },
    xTextStartPoint: { code: 10, value: 0 },
    yTextStartPoint: { code: 20, value: 0 },
    zTextStartPoint: { code: 30, value: 0 },
    height: { code: 40, value: 1.5 },
    textRotationAngle: { code: 50, value: 0 },
    defaultValue: { code: 1, value: "Default" },
    textStyle: { code: 7, value: "STANDARD" },
    horisontalJustification: { code: 72, value: HorizontalJustification.Left }, 
    //xSecondAlignmentPoint: { code: 11, value: 0 },
    //ySecondAlignmentPoint: { code: 21, value: 0 },
    //zSecondAlignmentPoint: { code: 31, value: 0 },
    subclass: { code: 100, value: "AcDbAttributeDefinition" }, 
    versionNumber: { code: 280, value: 0 },
    prompt: { code: 3, value: "" },
    tag: { code: 2, value: "" },
    attrFlag: { code: 70, value: AttrFlag.Default },
    verticalJustification: { code: 74, value: VerticalJustification.Top },
    lockPosition: { code: 280, value: 1 }, 
};

export const ATTRIBUTE_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    ownerHandle: {code: 330, value: 0},

    subclassMarker: { code: 100, value: "AcDbEntity" },
    layerName: { code: 8, value: "0" },

    classMarker: { code: 100, value: "AcDbText" },
    x: { code: 10, value: 0 },
    y: { code: 20, value: 0 },
    z: { code: 30, value: 0 },
    height: { code: 40, value: 2 },
    rotation: { code: 50, value: 0 },
    text: { code: 1, value: "" },

    subclass: { code: 100, value: "AcDbAttribute" },
    horisontalJustification: { code: 72, value: HorizontalJustification.Center },
    verticalJustification: { code: 74, value: VerticalJustification.Middle },
    xSecondAlignmentPoint: { code: 11, value: 0 },
    ySecondAlignmentPoint: { code: 21, value: 0 },
    zSecondAlignmentPoint: { code: 31, value: 0 },


    versionNumber: { code: 280, value: 0 },
    tag: { code: 2, value: "" },
    attrFlag: { code: 70, value: AttrFlag.Default },
    lockPosition: { code: 280, value: 1 }
};

export const GEODATA_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    ownerHandle: { code: 330, value: "0" },
    classMarker: { code: 100, value: "AcDbGeoData" },
    objectVersion: { code: 90, value: 3 },
    disignCoordinates: { code: 70, value: 2 },
    objectId: { code: 330, value: 3 },
    deltaX: { code: 10, value: 0 },
    deltaY: { code: 20, value: 0 },
    deltaZ: { code: 30, value: 0 },
    localX: { code: 11, value: 0 },
    localY: { code: 21, value: 0 },
    localZ: { code: 31, value: 0 },
    horizontalUnitScaleX: { code: 40, value: 1 },
    horizontalUnitScaleY: { code: 91, value: 2 },
    verticalUnitScaleX: { code: 41, value: 1 },
    verticalUnitScaleY: { code: 92, value: 2 },
    upDirectionX: { code: 210, value: 0 },
    upDirectionY: { code: 220, value: 0 },
    upDirectionZ: { code: 230, value: 1 },
    northDirection2dX: { code: 12, value: 0 },
    northDirection2dY: { code: 22, value: 1 },
    scaleEtimationMethod: { code: 95, value: 1 },
    userSpecifiedScale: { code: 141, value: 1 },
    seaLevelCorrection: { code: 294, value: 0 },
    seaLevelElevation: { code: 142, value: 0 },
    radius: { code: 143, value: 6369334.668266807 },
    //xmlString: { code: 303, value: "" },
    //geopoint: { code: 302, value: "" },
    //geoMeshPoints: { code: 93, value: 0 },
    //geoMeshFaces: { code: 96, value: 0 }
};

export const BLOCK_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    subclassMarker: { code: 100, value: "AcDbEntity" },
    layerName: { code: 8, value: "0" },
    classMarker: { code: 100, value: "AcDbBlockBegin" },
    blockName: { code: 2, value: "" },
    blockTypeFlag: { code: 70, value: BlockTypeFlag.SimpleBlock },
    x: { code: 10, value: 0 },
    y: { code: 20, value: 0 },
    z: { code: 30, value: 0 },
    blockNameRepeated: { code: 3, value: "" },
    xrefPathName: { code: 1, value: "" },
    description: { code: 4, value: "" }
};

export const ENDBLOCK_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    subclassMarker: { code: 100, value: "AcDbEntity" },
    LayerName: { code: 8, value: "0" },
    classMarker: { code: 100, value: "AcDbBlockEnd" }
};

export const SEQEND_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    ownerHandle: {code: 330, value: 0},
    subclassMarker: { code: 100, value: "AcDbEntity" },
    layerName: { code: 8, value: "0" }
};

export const TEXT_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    ownerHandle: {code: 330, value: 0},
    subclassMarker: { code: 100, value: "AcDbEntity" },
    Space: { code: 67, value: 0 },
    layerName: { code: 8, value: "0" },
    classMarker: { code: 100, value: "AcDbText" },
    x: { code: 10, value: 0 },
    y: { code: 20, value: 0 },
    z: { code: 30, value: 0 },
    height: { code: 40, value: 1.5 },
    textValue: { code: 1, value: "" },
    textRotation: { code: 50, value: 0 },
    textStyle: { code: 7, value: "Standart" },
    horisontalJustification: { code: 72, value: HorizontalJustification.Center },
    alignmentX: { code: 11, value: 0 },
    alignmentY: { code: 21, value: 0 },
    alignmentZ: { code: 31, value: 0 },
    classMarker2: { code: 100, value: "AcDbText" },
    verticalJustification: { code: 73, value: VerticalJustification.Middle }
};

export const INSERT_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    OwnerHandle: { code: 330, value: 0 },
    subclassMarker: { code: 100, value: "AcDbEntity" },
    Space: { code: 67, value: 0 },
    layerName: { code: 8, value: "0" },
    Color: { code: 62, value: 0 },
    classMarker: { code: 100, value: "AcDbBlockReference" },
    attributesFlag: { code: 66, value: 0 },
    blockName: { code: 2, value: "" },
    x: { code: 10, value: 0 },
    y: { code: 20, value: 0 },
    z: { code: 30, value: 0 },
    rotation: { code: 50, value: 0 }
};

export const POLYLINE_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    OwnerHandle: { code: 330, value: 0 },
    subclassMarker: { code: 100, value: "AcDbEntity" },
    Space: { code: 67, value: 0 },
    layerName: { code: 8, value: "0" },
    classMarker: { code: 100, value: "AcDbPolyline" },
    verticesCount: { code: 90, value: 0 },
    isClosed: { code: 70, value: 0 },
    width: { code: 43, value: 0 }
};

export const CIRCLE_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    OwnerHandle: { code: 330, value: 0 },
    subclassMarker: { code: 100, value: "AcDbEntity" },
    Space: { code: 67, value: 0 },
    layerName: { code: 8, value: "0" },
    colorNumber: {code:62, value:7},
    classMarker: { code: 100, value: "AcDbCircle" },
    thickness: { code: 39, value: 0 },
    x: { code: 10, value: 0 },
    y: { code: 20, value: 0 },
    z: { code: 30, value: 0 },
    radius: { code: 40, value: 0 },
};

export const LINE_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    OwnerHandle: { code: 330, value: 0 },
    subclassMarker: { code: 100, value: "AcDbEntity" },
    Space: { code: 67, value: 0 },  //0 - model space, 1- paper space
    layerName: { code: 8, value: "0" },
    colorNumber: {code:62, value:7},
    classMarker: { code: 100, value: "AcDbLine" },
    thickness: { code: 39, value: 0 },
    startX: { code: 10, value: 0 },
    startY: { code: 20, value: 0 },
    startZ: { code: 30, value: 0 },
    EndX: { code: 11, value: 0 },
    EndY: { code: 21, value: 0 },
    EndZ: { code: 31, value: 0 },
    
};

export const ARC_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    subclassMarker: { code: 100, value: "AcDbEntity" },
    Space: { code: 67, value: 0 },  //0 - model space, 1- paper space
    layerName: { code: 8, value: "0" },
    colorNumber: {code:62, value:7},
    classMarker: { code: 100, value: "AcDbCircle" },
    thickness: { code: 39, value: 0 },
    x: { code: 10, value: 0 },
    y: { code: 20, value: 0 },
    z: { code: 30, value: 0 },
    radius: { code: 40, value: "" },
    subclassMarker2: { code: 100, value: "AcDbArc" },
    startAngle: { code: 50, value: 51 },
    endAngle: { code: 51, value: 0 },
};

export const FACE3D_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    ownerHandle: { code: 330, value: 2 },
    subclassMarker: { code: 100, value: "AcDbEntity" },
    layerName: { code: 8, value: "0" },
    colorNumber: {code:62, value:252},
    lineWeight: {code:370, value:-3},
    classMarker: { code: 100, value: "AcDbFace" },
    FirstCornerX: { code: 10, value: 0 },
    FirstCornerY: { code: 20, value: 0 },
    FirstCornerZ: { code: 30, value: 0 },
    SecondCornerX: { code: 11, value: 0 },
    SecondCornerY: { code: 21, value: 0 },
    SecondCornerZ: { code: 31, value: 0 },    
    ThirdCornerX: { code: 12, value: 0 },
    ThirdCornerY: { code: 22, value: 0 },
    ThirdCornerZ: { code: 32, value: 0 },    
    FourthCornerX: { code: 13, value: 0 },
    FourthCornerY: { code: 23, value: 0 },
    FourthCornerZ: { code: 33, value: 0 },
    
};

export const IMAGE_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    ownerHandle: { code: 330, value: 2 },
    subclassMarker: { code: 100, value: "AcDbEntity" },
    layerName: { code: 8, value: "0" },
    colorNumber: {code:6, value:"Continuous"},

    subclassMarker2: { code: 100, value: "AcDbRasterImage" },

    insertionPointX: { code: 10, value: 0 },
    insertionPointY: { code: 20, value: 0 },
    insertionPointZ: { code: 30, value: 0 },

    uVectorX: { code: 11, value: 0 },
    uVectorY: { code: 21, value: 0 },
    uVectorZ: { code: 31, value: 0 },
    
    vVectorX: { code: 12, value: 0 },
    vVectorY: { code: 22, value: 0 },
    vVectorZ: { code: 32, value: 0 },
    
    imageSizeU: { code: 13, value: 0 },
    imageSizeV: { code: 23, value: 0 },

    imageDefReference: { code: 340, value: 0 },

    imageDisplayProperties: { code: 70, value: 1 }, // 1 = Show image
    clippingState: { code: 280, value: 0 }, // 0 = Clipping off
    brightness: { code: 281, value: 50 }, // Default 50
    contrast: { code: 282, value: 50 }, // Default 50
    fade: { code: 283, value: 0 }, // Default 0
    
    imageDefReactorReference: { code: 360, value: 0 },

    clippingBoundaryType: { code: 71, value: 1 }, // 1 = Rectangular
    numberOfClipBoundaryVertices: { code: 91, value: 0 },

    clipBoundaryVertexX: { code: 14, value: 0 }, // Can have multiple entries
    clipBoundaryVertexY: { code: 24, value: 0 }, // Can have multiple entries

    clipMode: { code: 290, value: 0 }, // 0 = Outside Mode
};

export const IMAGEDEF_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    dictStart: {code:102, value:"{ACAD_REACTORS" },
    dictEnd: {code:102, value:"}" },

    ownerHandle: { code: 330, value: 2 },

    subclassMarker: { code: 100, value: "AcDbRasterImageDef" },
    classVersion: { code: 90, value: 0 }, // Class version, default 0

    fileName: { code: 1, value: "" }, // File name of the image
    
    imageSizeU: { code: 10, value: 0 }, // Image size in pixels (U value)
    imageSizeV: { code: 20, value: 0 }, // Image size in pixels (V value)
    
    defaultPixelSizeU: { code: 11, value: 0.1058425063505504 }, // Default size of one pixel in AutoCAD units (U value)
    defaultPixelSizeV: { code: 21, value: 0.1058425063505504 }, // Default size of one pixel in AutoCAD units (V value)
    
    isImageLoaded: { code: 280, value: 1 }, // 0 = Unloaded, 1 = Loaded
    resolutionUnits: { code: 281, value: 0 }, // 0 = No units, 2 = Centimeters, 5 = Inches
};

export const LAYOUT_PROPERTIES: DxfPropertyMap = {
    // LAYOUT object definition
    handle: { code: 5, value: 0 }, // Handle
    dictStart: { code: 102, value: "{ACAD_REACTORS" }, // Start of persistent reactors group
    //ownerDictionary: { code: 330, value: 0 }, // Soft-pointer ID/handle to owner dictionary
    dictEnd: { code: 102, value: "}" }, // End of persistent reactors group

    ownerObject: { code: 330, value: 0 }, // Soft-pointer ID/handle to owner object
    subclassPlotSettings: { code: 100, value: "AcDbPlotSettings" }, // Subclass marker (AcDbPlotSettings)

    subclassLayout: { code: 100, value: "AcDbLayout" }, // Subclass marker (AcDbLayout)

    layoutName: { code: 1, value: "" }, // Layout name
    flags: { code: 70, value: 1 }, // Flags (PSLTSCALE, LIMCHECK, Tab order)
    tabOrder: { code: 71, value: 0 }, // Tab order

    // Limits and extents
    minLimitX: { code: 10, value: 0 }, // Minimum limits X (LIMMIN)
    minLimitY: { code: 20, value: 0 }, // Minimum limits Y (LIMMIN)
    maxLimitX: { code: 11, value: 0 }, // Maximum limits X (LIMMAX)
    maxLimitY: { code: 21, value: 0 }, // Maximum limits Y (LIMMAX)

    // Insertion base point
    basePointX: { code: 12, value: 0 }, // Insertion base point X (INSBASE)
    basePointY: { code: 22, value: 0 }, // Insertion base point Y
    basePointZ: { code: 32, value: 0 }, // Insertion base point Z

    // Extents
    minExtentsX: { code: 14, value: 0 }, // Minimum extents X (EXTMIN)
    minExtentsY: { code: 24, value: 0 }, // Minimum extents Y
    minExtentsZ: { code: 34, value: 0 }, // Minimum extents Z
    maxExtentsX: { code: 15, value: 0 }, // Maximum extents X (EXTMAX)
    maxExtentsY: { code: 25, value: 0 }, // Maximum extents Y
    maxExtentsZ: { code: 35, value: 0 }, // Maximum extents Z

    // Elevation
    elevation: { code: 146, value: 0 }, // Elevation

    // UCS origin
    ucsOriginX: { code: 13, value: 0 }, // UCS origin X
    ucsOriginY: { code: 23, value: 0 }, // UCS origin Y
    ucsOriginZ: { code: 33, value: 0 }, // UCS origin Z

    // UCS X-axis
    ucsXAxisX: { code: 16, value: 1 }, // UCS X-axis X
    ucsXAxisY: { code: 26, value: 0 }, // UCS X-axis Y
    ucsXAxisZ: { code: 36, value: 0 }, // UCS X-axis Z

    // UCS Y-axis
    ucsYAxisX: { code: 17, value: 0 }, // UCS Y-axis X
    ucsYAxisY: { code: 27, value: 1 }, // UCS Y-axis Y
    ucsYAxisZ: { code: 37, value: 0 }, // UCS Y-axis Z

    // Orthographic UCS type
    ucsOrthographicType: { code: 76, value: 0 }, // 0 = Not orthographic, 1 = Top, 2 = Bottom, etc.

    // Handle references
    //paperSpaceBlockTableRecord: { code: 330, value: 2 }, // ID/handle to associated paper space block table record
    //lastActiveViewport: { code: 331, value: 0 }, // ID/handle to the last active viewport
    //ucsTableRecord: { code: 345, value: 0 }, // ID/handle of AcDbUCSTableRecord if UCS is named
    //baseUCSTableRecord: { code: 346, value: 0 }, // ID/handle of base UCS if UCS is orthographic
    //shadePlotID: { code: 333, value: 0 }, // Shade plot ID
};

export const VIEWPORT_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 }, // Handle
    dictStart: { code: 102, value: "{ACAD_XDICTIONARY" }, 
    //ownerDictionary: { code: 330, value: 0 }, // Soft-pointer ID/handle to owner dictionary
    dictEnd: { code: 102, value: "}" }, // End of persistent reactors group

    ownerHandle: { code: 330, value: 0 },
    subclassMarker: { code: 100, value: "AcDbEntity" },
    layerName: { code: 8, value: "0" },
    colorNumber: {code:6, value:"Continuous"},

    // Viewport object definition
    subclassMarkerViewport: { code: 100, value: "AcDbViewport" }, // Subclass marker (AcDbViewport)

    // Center point in WCS (World Coordinate System)
    centerX: { code: 10, value: 0 }, // Center point X (in WCS)
    centerY: { code: 20, value: 0 }, // Center point Y (in WCS)
    centerZ: { code: 30, value: 0 }, // Center point Z (in WCS)

    // Viewport dimensions in paper space units
    width: { code: 40, value: 0 }, // Width in paper space units
    height: { code: 41, value: 0 }, // Height in paper space units

    // Viewport status and ID
    status: { code: 68, value: 0 }, // Viewport status field
    viewportID: { code: 69, value: 0 }, // Viewport ID

    // View center point in DCS (Display Coordinate System)
    viewCenterX: { code: 12, value: 0 }, // View center point X (in DCS)
    viewCenterY: { code: 22, value: 0 }, // View center point Y (in DCS)

    // Snap base point
    snapBaseX: { code: 13, value: 0 }, // Snap base point X
    snapBaseY: { code: 23, value: 0 }, // Snap base point Y

    // Snap spacing
    snapSpacingX: { code: 14, value: 0 }, // Snap spacing X
    snapSpacingY: { code: 24, value: 0 }, // Snap spacing Y

    // Grid spacing
    gridSpacingX: { code: 15, value: 0 }, // Grid spacing X
    gridSpacingY: { code: 25, value: 0 }, // Grid spacing Y

    // View direction vector in WCS
    viewDirectionX: { code: 16, value: 0 }, // View direction vector X (in WCS)
    viewDirectionY: { code: 26, value: 0 }, // View direction vector Y (in WCS)
    viewDirectionZ: { code: 36, value: 1 }, // View direction vector Z (in WCS)

    // View target point in WCS
    viewTargetX: { code: 17, value: 0 }, // View target point X (in WCS)
    viewTargetY: { code: 27, value: 0 }, // View target point Y (in WCS)
    viewTargetZ: { code: 37, value: 0 }, // View target point Z (in WCS)

    // Perspective and clipping planes
    lensLength: { code: 42, value: 0 }, // Perspective lens length
    frontClipPlaneZ: { code: 43, value: 0 }, // Front clip plane Z value
    backClipPlaneZ: { code: 44, value: 0 }, // Back clip plane Z value
    viewHeight: { code: 45, value: 0 }, // View height (in model space units)

    // Viewport angles
    snapAngle: { code: 50, value: 0 }, // Snap angle
    viewTwistAngle: { code: 51, value: 0 }, // View twist angle

    // Circle zoom percent
    circleZoomPercent: { code: 72, value: 0 }, // Circle zoom percent

    // Viewport status flags (bit-coded)
    statusFlags: { code: 90, value: 0 }, // Bit-coded flags for perspective, clipping, UCS settings, etc.

    // Rendering mode
    renderMode: { code: 281, value: 0 }, // Render mode (0 = 2D optimized, 1 = Wireframe, etc.)

    // UCS (User Coordinate System) settings
    ucsPerViewportFlag: { code: 71, value: 0 }, // UCS per viewport flag (0 = global, 1 = per viewport)
    displayUCSIcon: { code: 74, value: 0 }, // Display UCS icon at UCS origin flag

    // UCS origin in 3D
    ucsOriginX: { code: 110, value: 0 }, // UCS origin X
    ucsOriginY: { code: 120, value: 0 }, // UCS origin Y
    ucsOriginZ: { code: 130, value: 0 }, // UCS origin Z

    // UCS X-axis direction in 3D
    ucsXAxisX: { code: 111, value: 1 }, // UCS X-axis X direction
    ucsXAxisY: { code: 121, value: 0 }, // UCS X-axis Y direction
    ucsXAxisZ: { code: 131, value: 0 }, // UCS X-axis Z direction

    // UCS Y-axis direction in 3D
    ucsYAxisX: { code: 112, value: 0 }, // UCS Y-axis X direction
    ucsYAxisY: { code: 122, value: 1 }, // UCS Y-axis Y direction
    ucsYAxisZ: { code: 132, value: 0 }, // UCS Y-axis Z direction

    // UCS Orthographic type and ID handles
    ucsOrthographicType: { code: 79, value: 0 }, // Orthographic type of UCS
    ucsID: { code: 345, value: 0 }, // Handle of UCS table record (named UCS)
    baseUCSID: { code: 346, value: 0 }, // Handle of base UCS table record (orthographic UCS)

    // Viewport shading and lighting
    shadePlotMode: { code: 170, value: 0 }, // ShadePlot mode (0 = As displayed, 1 = Wireframe, etc.)
    majorGridFrequency: { code: 61, value: 0 }, // Frequency of major grid lines compared to minor grid lines
    backgroundID: { code: 332, value: 0 }, // Background ID/handle (optional)
    shadePlotID: { code: 333, value: 0 }, // Shade plot ID/handle (optional)
    visualStyleID: { code: 348, value: 0 }, // Visual style ID/handle (optional)

    // Default lighting settings
    defaultLightingFlag: { code: 292, value: 0 }, // Default lighting flag (on if no user lights)
    defaultLightingType: { code: 282, value: 0 }, // Default lighting type (0 = One light, 1 = Two lights)

    // Viewport brightness and contrast
    viewBrightness: { code: 141, value: 0 }, // View brightness
    viewContrast: { code: 142, value: 0 }, // View contrast

    // Ambient light color (if not black)
    ambientLightColor: { code: 63, value: 0 }, // Ambient light color
};

export const SPLINE_PROPERTIES: DxfPropertyMap = {
    handle: { code: 5, value: 0 },
    OwnerHandle: { code: 330, value: 0 },
    subclassMarker: { code: 100, value: "AcDbEntity" },
    Space: { code: 67, value: 0 },  //0 - model space, 1- paper space
    layerName: { code: 8, value: "0" },

    subclassMarkerSpline: { code: 100, value: "AcDbSpline" },
    normalX: { code: 210, value: 0 },
    normalY: { code: 220, value: 0 },
    normalZ: { code: 230, value: 0 },
    splineFlag: { code: 70, value: 8 }, //Spline flag (bit coded): 1 = Closed spline, 2 = Periodic spline, 4 = Rational spline, 8 = Planar, 16 = Linear (planar bit is also set)
    splineDegree: { code: 71, value: 2 },
    numKnots: { code: 72, value: 0 },
    numControlPoints: { code: 73, value: 0 },
    numFitPoints: { code: 74, value: 0 },
    knotTolerance: { code: 42, value: 0.0000001 },
    controlPointTolerance: { code: 43, value: 0.0000001 },
    fitTolerance: { code: 44, value: 0.0000000001 },
    startTangentX: { code: 12, value: 0 },
    startTangentY: { code: 22, value: 0 },
    startTangentZ: { code: 32, value: 0 },
    endTangentX: { code: 13, value: 0 },
    endTangentY: { code: 23, value: 0 },
    endTangentZ: { code: 33, value: 0 }
};


export const PLOTSETTINGS_PROPERTIES: DxfPropertyMap = {
    pageSetupName: { code: 1, value: "" },
    printerName: { code: 2, value: "none_device" },
    paperSize: { code: 4, value: "ISO_A2_(594.00_x_420.00_MM)" },
    plotViewName: { code: 6, value: "" },
    leftMargin: { code: 40, value: 7.5 },
    bottomMargin: { code: 41, value: 20 },
    rightMargin: { code: 42, value: 7.5 },
    topMargin: { code: 43, value: 20 },
    paperWidth: { code: 44, value: 594 },
    paperHeight: { code: 45, value: 420 },
    plotOriginX: { code: 46, value: 0 },
    plotOriginY: { code: 47, value: 0 },
    windowLowerLeftX: { code: 48, value: 0 },
    windowLowerLeftY: { code: 140, value: 0 },
    windowUpperRightX: { code: 49, value: 0 },
    windowUpperRightY: { code: 141, value: 0 },
    customScaleNumerator: { code: 142, value: 1 },
    customScaleDenominator: { code: 143, value: 1 },
    plotLayoutFlags: { code: 70, value: 692 }, // Example: 128 for PrintLineweights
    paperUnits: { code: 72, value: 1 }, // 0 = inches, 1 = mm, 2 = pixels
    plotRotation: { code: 73, value: 0 }, // 0 = no rotation, 1 = 90° CCW, etc.
    plotType: { code: 74, value: 1 }, // 0 = last screen display, etc.
    currentStyleSheet: { code: 7, value: "" },
    standardScaleType: { code: 75, value: 0 }, // Example: 16 = 1:1 scale
    shadePlotMode: { code: 76, value: 0 }, // 0 = As displayed, 1 = Wireframe, etc.
    shadePlotResolution: { code: 77, value: 2 }, // 0 = Draft, 1 = Preview, etc.
    customDPI: { code: 78, value: 300 }, // Valid: 100–32767
    standardScaleFactor: { code: 147, value: 1 },
    paperImageOriginX: { code: 148, value: 0 },
    paperImageOriginY: { code: 149, value: 0 }
};

