import { Matrix3, Vector2, Vector3 } from "math-ts";
import { annotationLayer, BlockData, DataService, DxfEquipmentData, DxfPolylineData, sanitizeBlockName, Table, TrackerData, TrackerType } from "./DxfDataService";
import { DxfFileManager } from "./DxfFileManager";
import { Bim, generateSLDForSubstation, generateSLDForSubstationSVG, generateSLDForTransformer, TrackerPilesCollection } from "bim-ts";
import { DxfExporterSettings } from "./DxfFileExporter";
import { FileExporterContext } from "ui-bindings";
import { getDefaultDxfFile } from "./DxfFileProjectSettings";
import { MetersToFeet, Vector2ToFeet, Vector3ToFeet } from "./DxfUnitsConverters";
import { attributeInsertData, blockInsertData, DXFNodeFactory, dxfTextData } from "./DxfFileFactory";
import { AttrFlag, BlockTypeFlag, HorizontalJustification, VerticalJustification } from "./DxfEnums";
import { groupByNumber } from "../instanceUtils";
import { Object2D } from "vector-graphic";
import { getResultValueOr } from "engine-utils-ts";
import { SldDataService } from "./SldDataService";
import { DXFNode } from "./DxfNode";

type attributePromt = { promt: string; value: string | number }

const getTrackersCenter = (types: TrackerData[]) => {
    const { sumOfOrigins, count } = types.reduce(
        ({ sumOfOrigins, count }, data) => {
            const origin = data.graphicData.origin;
            sumOfOrigins.add(origin);
            count++;
            return { sumOfOrigins, count };
        },
        { sumOfOrigins: new Vector3(), count: 0 }
    );

    return sumOfOrigins.divideScalar(count);
};

export class DxfService {
    private dataService: DataService;
    private dxfManager: DxfFileManager;
    private blocksNames: Set<string>;

    constructor(
        bim: Bim,
        settings: DxfExporterSettings,
        context: FileExporterContext,
        pilesCollection: TrackerPilesCollection,
        fileName?: string
    ) {
        this.dataService = new DataService(
            bim,
            settings,
            context,
            pilesCollection
        );
        this.dxfManager = getDefaultDxfFile(bim, fileName);
        this.blocksNames = new Set<string>();
    }

    *generateDxfModel() {
        this.setViewPort();
        if (this.dataService.settings.images) {
            yield* this.createImageDxf();
        }

        if (this.dataService.settings.terrain) {
            yield* this.createSurfaceDxf();
        }

        if (this.dataService.settings.layout) {
            yield* this.createBoundaryDXF();
            yield* this.createRoadDXF();

            yield* this.createEquipmentDXF();
            yield* this.createTrackersDXF();
        }

        if (this.dataService.settings.wireing) {
            yield* this.createTrenchDXF();
            yield* this.createWiringDXF();
        }

        if (this.dataService.settings.pile_position_plan) {
            yield* this.createPilesDXF();
        }
        if (this.dataService.settings.singleLineDiagram) {
            yield* this.createSingleLineDiagram();
        }
    }

    private setViewPort(): void {
        const bounds = this.dataService.getTotalBounds();
        const origin =
            this.dataService.bim.instances.getSceneOrigin()
                ?.cartesianCoordsOrigin || new Vector3();

        const centerView = bounds.getCenter_t().clone().add(origin);
        this.scaleToImperial(centerView)

        let heigh = bounds.depth();
        let width = bounds.width();
  
        if (this.dataService.bim.unitsMapper.isImperial()) {
            heigh = MetersToFeet(heigh);
            width = MetersToFeet(width);
        }
        
        DXFNodeFactory.setView(this.dxfManager, centerView, width, heigh);
    }

    private *createSingleLineDiagram() {
        const scale = 0.001;
        const diagrams = getSLDDiagrams(this.dataService.bim);
        const scaleMatrix = new Matrix3().scale(scale, -scale);
        let i = 0;
        for (const diagram of diagrams) {
            if (!diagram) {
                continue;
            }
            const sheetSize = new Vector2(594,420);

            const layoutName = `${formatNumberToThreeDigits(i)} ${
                diagram.name ?? "SLD"
            }`;

            const limits = diagram.aabb.clone().applyMatrix3(scaleMatrix);
            const size = limits.getSize();
            const center = limits.getCenter();

            const layoutBlock = DXFNodeFactory.createPaperLayout(
                this.dxfManager,
                layoutName,
                sheetSize,
                limits,
                i + 1
            );



            DXFNodeFactory.createViewPort(
                this.dxfManager,
                layoutBlock,
                size,
                center
            );

            let layoutBlockName: string | undefined =
                layoutBlock.properties.get(2) as string;

            layoutBlockName =
                layoutBlockName !== "*Paper_Space"
                    ? layoutBlockName
                    : undefined;

            SldDataService.createSldDiagram(
                this.dxfManager,
                diagram,
                layoutBlockName,
                scale,
                this.dataService.context.logger
            );
            i++;
        }

        SldDataService.blockCache = new Map();
    }

    private *createImageDxf() {
        const imagesData = this.dataService.getImages();
        const handles: string[] = [];
        for (const imageData of imagesData) {
            const imageHandle = DXFNodeFactory.createImageInsert(
                this.dxfManager,
                imageData
            );
            handles.push(imageHandle);
        }
        return handles;
    }

    private *createSurfaceDxf(trianglesSize: number = 15) {
        const terrainFacesGenerator =
            this.dataService.getTerraineFaces(trianglesSize);
        for (const tinSurface of terrainFacesGenerator) {
            const name = tinSurface.Name;
            const layerName = `pvfarm_terrain_${name}`;
            for (let i = 0; i < tinSurface.Triangles.length; i++) {
                const triangle = tinSurface.Triangles[i];
                const pointsCoordinates: Vector3[] = [];
                for (const p of triangle) {
                    const coord = tinSurface.Points[p].clone();
                    if (coord) {
                        this.scaleToImperial(coord);
                        pointsCoordinates.push(coord);
                    }
                }
                if (pointsCoordinates.length === 3) {
                    DXFNodeFactory.create3dFace(
                        this.dxfManager,
                        layerName,
                        252,
                        pointsCoordinates
                    );
                }
            }
        }
    }

    private createPilesTableDXF(tableData: Table) {
        const limits = this.dataService.getTotalBounds();
        const insertPoint = limits.getMax().clone();
        const origin =
            this.dataService.bim.instances.getSceneOrigin()
                ?.cartesianCoordsOrigin;

        if (origin) {
            insertPoint.add(origin);
        }

        if (this.dataService.bim.unitsMapper.isImperial()) {
            Vector3ToFeet(insertPoint);
        }

        const tableName = "pvfarm_pile_table";

        DXFNodeFactory.createPileTable(
            this.dxfManager,
            tableName,
            annotationLayer,
            tableData
        );

        const tableInsertData: blockInsertData = {
            blockName: tableName,
            insertPoint,
            rotation: 0,
            layer: annotationLayer,
        };

        DXFNodeFactory.createBlockInsert(this.dxfManager, tableInsertData);
    }

    private *createPilesDXF() {
        yield* this.dataService.setBins();

        const pileTableData = this.dataService.getPilesTableData();
        if (!pileTableData) {
            return;
        }

        this.createPilesTableDXF(pileTableData);

        const pileData = this.dataService.getPilesData();
        if (!pileData) {
            return;
        }
        for (const pData of pileData) {
            this.scaleToImperial(pData.origin);
            const blockName = getPileBlockName(pData.type, pData.color);

            const pileInsertData: blockInsertData = {
                blockName,
                insertPoint: pData.origin,
                rotation: 0,
                layer: pData.layer,
            };

            const blockInsert = DXFNodeFactory.createBlockInsert(
                this.dxfManager,
                pileInsertData
            )!;

            const insertHandle = blockInsert.properties.get(5) as string;

            const codeAttr = DXFNodeFactory.AddAttribute(
                this.dxfManager,
                insertHandle,
                {
                    tag: "PILEROWCODE",
                    text: pData.rowCode,
                    position: pData.origin.clone().add(new Vector3(0, 2)),
                }
            );

            const indexAttr = DXFNodeFactory.AddAttribute(
                this.dxfManager,
                insertHandle,
                {
                tag: "PILEROWINDEX",
                text: pData.rowIndex,
                position: pData.origin.clone().add(new Vector3(0, -2))
                }
            );

            const parentIdAttr = DXFNodeFactory.AddAttribute(
                this.dxfManager,
                insertHandle,
                {
                tag: "PARENTID",
                text: pData.parentId,
                position: pData.origin.clone().add(new Vector3(0, -6))
                }
            );

            codeAttr.properties.set(70, AttrFlag.Default);
            codeAttr.properties.set(72, HorizontalJustification.Center);
            codeAttr.properties.set(74, VerticalJustification.Bottom);
            codeAttr.properties.set(11, pData.origin.clone().x);
            codeAttr.properties.set(21, pData.origin.clone().y + 1);
            codeAttr.properties.set(31, 0);

            indexAttr.properties.set(70, AttrFlag.Default);
            indexAttr.properties.set(72, HorizontalJustification.Center);
            indexAttr.properties.set(74, VerticalJustification.Bottom);
            indexAttr.properties.set(11, pData.origin.clone().x);
            indexAttr.properties.set(21, pData.origin.clone().y - 2);
            indexAttr.properties.set(31, 0);

            parentIdAttr.properties.set(70, AttrFlag.Invisible);
            parentIdAttr.properties.set(72, HorizontalJustification.Center);
            parentIdAttr.properties.set(74, VerticalJustification.Bottom);
            parentIdAttr.properties.set(11, pData.origin.clone().x);
            parentIdAttr.properties.set(21, pData.origin.clone().y - 5);
            parentIdAttr.properties.set(31, 0);

            DXFNodeFactory.AddSeqEnd(this.dxfManager, insertHandle);
        }
    }

    private *createRoadDXF() {
        const roadData = this.dataService.getRoadData();
        for (const rData of roadData) {
            this.scaleToImperial(rData.vertices);
            DXFNodeFactory.createPolyLine(this.dxfManager, rData);
        }
    }

    private *createTrenchDXF() {
        const trenchData = this.dataService.getTrenchData();
        if (!trenchData) {
            return;
        }
        for (const tData of trenchData) {
            this.scaleToImperial(tData.vertices);
            DXFNodeFactory.createPolyLine(this.dxfManager, tData);
        }
    }

    private *createWiringDXF() {
        const wiringData = this.dataService.getWiringData();
        for (const wData of wiringData) {
            this.scaleToImperial(wData.vertices);
            DXFNodeFactory.createPolyLine(this.dxfManager, wData);
        }
    }

    private *createBoundaryDXF(): Generator<never, void, unknown> {
        const boundaryData = this.dataService.getBoundaryData();
        for (const bData of boundaryData) {
            
            this.scaleToImperial(bData.vertices);
            
            DXFNodeFactory.createPolyLine(this.dxfManager, bData);
        }
    }
    
    private *createEquipmentDXF(): Generator<never, void, unknown> {
        const sceneOrigin = this.dataService.bim.instances.getSceneOrigin()?.cartesianCoordsOrigin ?? new Vector3();
        const equipmentData = this.dataService.getEquipmentData();
    
        for (const eData of equipmentData) {
            this.createBlock(
                eData
            )

            this.createInsert(
                eData,
                sceneOrigin,
            )
        }
    }

    private createBlock(
        data:DxfEquipmentData,
        flags: BlockTypeFlag = 0,
    ): boolean {
        if (this.blocksNames.has(data.name)) {
            return false;
        }
    
        this.blocksNames.add(data.name);
    
        // Create the block
        DXFNodeFactory.createBlock(this.dxfManager, data.name, data.layer, flags);
    
        // Add polylines if provided
        if (data.polylines) {
            for (const polyline of data.polylines) {
                if (polyline.color !== undefined) {
                    polyline.layerColour = 0;
                }
                this.scaleToImperial(polyline.vertices);
                DXFNodeFactory.createPolyLine(this.dxfManager, polyline, data.name);
            }
        }

        return true;
    }

    private addAttributesToBlock(
        blockName: string,
        attributes: Map<string, attributePromt>,
        startPosition: Vector2,
        textHeight: number = 0.8,
        rotation: number = 0,
    ): void {
        const rotationRadians = (rotation * Math.PI) / 180 - Math.PI/2;
        const direction = new Vector2(
            Math.cos(rotationRadians),
            Math.sin(rotationRadians)
        );

        // Scale direction vector by text height
        direction.multiplyScalar(textHeight+0.2);

        let currentPosition = startPosition.clone();
        
        for (const [tag, parameter] of attributes) {
            
            DXFNodeFactory.addAttributeDefinition(
                this.dxfManager,
                blockName,
                tag,
                parameter.promt,
                currentPosition,
                rotation,
                textHeight
            ); 
    
            currentPosition.add(direction);
        }
    }

    private createInsert(
        data:DxfEquipmentData,
        insertionOffset: Vector3 = new Vector3(),
        parentBlockName?: string
    ): string | undefined {
        
        data.origin.add(insertionOffset);
        this.scaleToImperial(data.origin);
    
        const insertData: blockInsertData = {
            blockName: data.name,
            insertPoint: data.origin,
            rotation: data.rotation,
            layer: data.layer,
            color: 0,
        };

        const insert = DXFNodeFactory.createBlockInsert(this.dxfManager, insertData, parentBlockName);
        
        return insert?.properties.get(5) as string | undefined;
    }

    private setAttributesValues(
        insertHandle: string,
        attributes: Map<string, attributePromt>,
        startPosition: Vector3,
        insertionOffset: Vector3 = new Vector3(),
        textHeight: number = 0.8,
        rotation: number = 0,
        attrFlag: AttrFlag = AttrFlag.Invisible,
        parentBlockName?:string
    ): void {
        const rotationRadians = (rotation * Math.PI) / 180 - Math.PI/2;
        const direction = new Vector3(
            Math.cos(rotationRadians),
            Math.sin(rotationRadians)
        );

        // Scale direction vector by text height
        direction.multiplyScalar(textHeight+0.2);

        const insert = startPosition.clone().add(insertionOffset);
        this.scaleToImperial(insert);

        let currentPosition = insert.clone();
        
        for (const [tag, parameter] of attributes) {
            
            const attrInsertData:attributeInsertData = {
                tag,
                text:parameter.value.toString(),
                position:currentPosition,
                rotation,
                attrFlag,
                heigh:textHeight
            }
    
            DXFNodeFactory.AddAttribute(
                this.dxfManager,
                insertHandle,
                attrInsertData,
                parentBlockName
            );
    
            currentPosition.add(direction);
        }
        DXFNodeFactory.AddSeqEnd(this.dxfManager,insertHandle, parentBlockName)
    }


    private *createTrackersDXF(): Generator<never, void, unknown> {

        let layoutTrackerData = this.dataService.getTrackersData();
        const sceneOrigin =
            this.dataService.bim.instances.getSceneOrigin()
                ?.cartesianCoordsOrigin ?? new Vector3();

        DXFNodeFactory.createLayer(this.dxfManager, "pvfarm_solar_plant");

        for (const group of layoutTrackerData.blocksData) {
            const groupOrigin = group.trackersData.length > 0 
            ? getTrackersCenter(group.trackersData) 
            : group.transformerData.origin;

            if (!this.blocksNames.has(group.blockName)) {
                this.blocksNames.add(group.blockName);
                DXFNodeFactory.createBlock(
                    this.dxfManager,
                    group.blockName,
                    group.layer,
                    BlockTypeFlag.BlockHasAttributes
                );
            }

            //Add transformer
            this.createBlock(group.transformerData);
            this.createInsert(group.transformerData, groupOrigin.clone().negate(), group.blockName)

            //Add trackers
            for (const trackerData of group.trackersData) {

                const newBlock = this.createBlock(
                    trackerData.graphicData,
                    BlockTypeFlag.BlockHasAttributes
                );

                if(newBlock){
                    this.addAttributesToBlock(
                    trackerData.graphicData.name,
                    trackerData.parameters,
                    new Vector2(),
                    0.8,
                    90
                    );
                }

                const insertHandle = this.createInsert(
                    trackerData.graphicData,
                    groupOrigin.clone().negate(),
                    group.blockName
                );

                if (insertHandle) {
                    this.setAttributesValues(
                        insertHandle,
                        trackerData.parameters,
                        trackerData.graphicData.origin,
                        groupOrigin.clone().negate(),
                        0.8,
                        90,
                        AttrFlag.Invisible,
                        group.blockName
                    );
                }
            }

            this.addAttributesToBlock(group.blockName, group.parameters, new Vector2(0, 0), 20);

            const groupInsertionPoint = groupOrigin.clone().add(sceneOrigin);
            this.scaleToImperial(groupInsertionPoint);
            
            const blockInsertData: blockInsertData = {
                blockName: group.blockName,
                insertPoint: groupInsertionPoint,
                layer: group.layer,
                color: group.color,
            };

            const blockInsert = DXFNodeFactory.createBlockInsert(
                this.dxfManager,
                blockInsertData
            );

            const insertHandle = blockInsert?.properties.get(5)! as string;
            
            const groupAttrInsertion = groupInsertionPoint.clone();
            const offset = new Vector3(0,-12);

            for (const [tag, value] of group.parameters) {

                const attrData: attributeInsertData = {
                    tag,
                    text:value.value.toString(),
                    position:groupAttrInsertion,
                    heigh:20,
                    attrFlag:AttrFlag.Invisible,
                }

                DXFNodeFactory.AddAttribute(
                    this.dxfManager,
                    insertHandle,
                    attrData,
                );

                groupAttrInsertion.add(offset);
            }

            DXFNodeFactory.AddSeqEnd(this.dxfManager,insertHandle )

            if (group.parameters.get("GROUPTRANSFORMERID")) {
                const offset = new Vector3(0, 5, 0);
                const annotationInsertPoint = groupInsertionPoint
                    .clone()
                    .add(offset);
                this.createAnnotationTag(group, annotationInsertPoint);
            }
        }

        for (const trackerData of layoutTrackerData.freeTrackers) {
            const newBlock = this.createBlock(
                trackerData.graphicData,
                BlockTypeFlag.BlockHasAttributes
            );
            if (newBlock) {
                this.addAttributesToBlock(
                    trackerData.graphicData.name,
                    trackerData.parameters,
                    new Vector2(),
                    0.8,
                    90
                );
            }

            trackerData.graphicData.origin.add(sceneOrigin);

            this.createInsert(
                trackerData.graphicData,
            );
        }
    }


    private createAnnotationTag(
        group: BlockData,
        textInsertPoint: Vector3 | Vector2
    ) {
        //Add annotation
        let posY = 0;
        //set tracker count and number of modules data to mark
        const trackers = group.trackersData;
        const annBlockName = `Annotation_${
            group.parameters.get("GROUPTRANSFORMERID")?.value
        }`;
        DXFNodeFactory.createLayer(this.dxfManager, annotationLayer);
        DXFNodeFactory.createBlock(
            this.dxfManager,
            annBlockName,
            annotationLayer,
            BlockTypeFlag.SimpleBlock,
            true
        );

        //Block number
        const num = group.parameters.get("GROUPNUMBER")!;
        //Modules max power
        const trackerModulePowers = group.trackersData.map(
            (x) => x.parameters.get("MODULE_POWER")?.value
        );
        const modulePowers = Array.from(new Set(trackerModulePowers));
        const powersTagData = `(${modulePowers.join("/")})`;
        //Wattage per block
        const wattage = group.parameters.get("GROUPPOWER");

        const groupNumTextData: dxfTextData = {
            text: convertGroupNum(num.value as number),
            position: new Vector2(0, posY),
            height: 50,
            layer: annotationLayer,
        };
        const powerTextData: dxfTextData = {
            text: powersTagData,
            position: new Vector2(0, (posY -= 65)),
            height: 40,
            layer: annotationLayer,
        };

        DXFNodeFactory.createText(
            this.dxfManager,
            groupNumTextData,
            annBlockName
        );
        DXFNodeFactory.createText(this.dxfManager, powerTextData, annBlockName);

        //Modules per tracker
        let groupedTrackers: [number, TrackerData[]][] = Array.from(
            groupByNumber(trackers, (tracker) => tracker.modulsNum)
        );
        groupedTrackers = groupedTrackers.sort((a, b) => {
            return a[0] - b[0];
        });

        for (const trks of groupedTrackers) {
            const trakerNumData = trks[1].length.toString();
            const moduleNum = trks[0];

            const textData: dxfTextData = {
                text: `${moduleNum}/${trakerNumData}`,
                position: new Vector2(0, (posY -= 55)),
                height: 40,
                layer: annotationLayer,
            };

            DXFNodeFactory.createText(this.dxfManager, textData, annBlockName);
        }

        const textData: dxfTextData = {
            text: wattage?.value.toString()!,
            position: new Vector2(0, (posY -= 55)),
            height: 40,
            layer: annotationLayer,
        };

        DXFNodeFactory.createText(this.dxfManager, textData, annBlockName);

        const tagInsertData: blockInsertData = {
            blockName: annBlockName,
            insertPoint: textInsertPoint,
            layer: annotationLayer,
        };

        DXFNodeFactory.createBlockInsert(this.dxfManager, tagInsertData);
    }


    private scaleToImperial(coords: Vector2 | Vector2[] | Vector3 | Vector3[] | undefined): void {
        if (!this.dataService.bim.unitsMapper.isImperial() || !coords) return;

        if (Array.isArray(coords)) {
        for (const vertex of coords) {
            if(vertex instanceof Vector3){
                Vector3ToFeet(vertex);
            }else{
                Vector2ToFeet(vertex);
            }
            
        }
    } else {
        if(coords instanceof Vector3){
            Vector3ToFeet(coords);
        }else{
            Vector2ToFeet(coords);
        }
    }
    }

        
    getDxfModel() {
        return this.dxfManager.root;
    }
}



function convertGroupNum(i: number): string {
    let num = i.toString();
    if (i < 10) {
        num = `0${i}`;
    }
    return num;
}

export function getPileBlockName(pileName: string, pileColor: number): string {
    return sanitizeBlockName(`${pileName}_${pileColor.toString()}`);
}

function formatNumberToThreeDigits(num: number) {
    // Convert the number to a string and pad with leading zeros if needed
    let formattedNumber = num.toString().padStart(3, '0');
    return formattedNumber;
}

function getSLDDiagrams(bim: Bim) {
    const transformers = bim.instances
    .peekByTypeIdent("transformer")
    ?.map((t) => t[0]);

    const totalDiagrams = transformers.length + 1; // +1 for the Substation
    let sldDiagrams: Object2D[] = new Array(totalDiagrams).fill(null);

    const substationId = bim.instances.peekByTypeIdent("substation")?.[0]?.[0];
    if (substationId) {
        const sldResult = generateSLDForSubstation(bim, substationId);
        const sldScheme = getResultValueOr(sldResult, undefined);

        let num = 0;

        if (sldScheme) {
            sldScheme.name = `Substation_${substationId} SLD`;
            sldDiagrams[0] = sldScheme;
        }



        for (const transformerId of transformers){

            const sldResult = generateSLDForTransformer(bim, transformerId);
            const sldScheme = getResultValueOr(sldResult, undefined);
            if (sldScheme) {
                num++;
                const transformerInstance = bim.instances.peekById(transformerId);
                const blockNumber =
                transformerInstance?.properties
                    .get("circuit | position | block_number")
                    ?.asNumber();

                sldScheme.name = `Block_${blockNumber ?? transformerId} SLD`
                if(blockNumber){
                    sldDiagrams[blockNumber] = sldScheme;
                }else{
                   sldDiagrams.push(sldScheme); 
                }            
            }
        }
    }

    return sldDiagrams;
}