import { DefaultMap, LegacyLogger, ObjectUtils } from "engine-utils-ts";
import { PropsProxy } from '../runtime/propsProxy/PropsProxy';
import { PropsFieldFlags } from './PropsGroupComplexDefaults';
import { PropsGroupsRegistry } from "./PropsGroupsRegistry";


export type TypedHint = {type: string};

export type TypeHintedPropsGroupArgs<T extends PropsGroupBase|PropertyBase> = {
    [K in keyof T as
        string extends K ? never :
        number extends K ? never :
        K
    ]?: T[K] extends (PropsGroupBase|PropertyBase) ? TypeHintedPropsGroupArgs<T[K]>|TypedHint : T[K]; 
};


export abstract class PropsGroupBase {
    [key: string]: PropsGroupField | Function;

equals(other: PropsGroupBase): boolean {
        for (const key in this) {
            const v1 = this[key];
            const v2 = other[key];
            if (v1 === v2) {
                continue;
            }
            if (typeof v1 !== typeof v2) {
                return false;
            }
            if (!v1 || !v2) {
                return false;
            }
            if (v1.constructor !== v2.constructor) {
                return false;
            }
            if (v1 instanceof PropsGroupBase || v1 instanceof PropertyBase) {
                return v1.equals(v2 as PropsGroupBase&PropertyBase);
            }
            if (Array.isArray(v1)) {
                if (v1.length !== (v2 as Array<any>).length) {
                    return false;
                }
                for (let i = 0; i < v1.length; i++) {
                    const it1 = v1[i];
                    const it2 = (v2 as Array<any>)[i];
                    if (!it1.equals(it2)) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    clone(): this {
        return this.cloneWithoutFlags(PropsFieldFlags.SkipClone);
    }

    cloneWithoutFlags(flags: PropsFieldFlags): this {
        return PropsGroupsRegistry.cloneWithoutFlags(this, flags);
    }

    freeze(): Readonly<this> {
        if (Object.isFrozen(this)) {
            return this;
        }
        for (const key in this) {
            const v = this[key];
            if (v instanceof PropsGroupBase) {
                v.freeze();
            }
        }
        Object.freeze(this);
        return this;
    }

    getAtPath(path: (string|number)[], inPathOffset: number = 0): PropsGroupField | undefined {
        const key = path[inPathOffset];
        const val = this[key];
        if (val === undefined || typeof val === 'function') {
            return undefined;
        }
        if (inPathOffset === path.length - 1) {
            return val;
        }
        if (val instanceof PropsGroupBase) {
            return val.getAtPath(path, inPathOffset + 1);
        }
        if (Array.isArray(val)) {
            const nextValue = val[path[inPathOffset + 1] as number];
            if (nextValue === undefined) {
                return undefined;
            }
            if (inPathOffset === path.length - 2) {
                return nextValue;
            }
            if (nextValue instanceof PropsGroupBase) {
                return nextValue.getAtPath(path, inPathOffset + 2);
            }
        }
        return undefined;
    }
}

export type PropsGroupField = null | PropertyBase | PropsGroupBase | PropertyBase[] | PropsGroupBase[];

export abstract class PropertyBase {

    // unique non collidable hash of property
    // for small primitive props should be value itself
    // for complex props that cant be hashed without collisions
    // should return unique object reference id see getPropertyReferenceId(...)
    abstract uniqueValueHash(): PropValueTotalHash;

    hash(): number | string {
        return ObjectUtils.objectHash(this);
    }

    abstract equals(other: PropsGroupField): boolean;
}
export type PropValueTotalHash = number | string;


export class PropsPatch {
    [key: string|number]: PropsGroupField | PropsPatch;

    constructor(args?: { [key: string|number]: PropsGroupField | PropsPatch}) {
        if (args) {
            Object.assign(this, args);
        }
    }
}

export function applyPatchToProps<P extends PropsGroupBase>(patch: PropsPatch, props: P): [P, PropsPatch] | null {
    let reversePatch: PropsPatch = new PropsPatch();
    let newArgs: {[key: string]: PropsGroupField} = {};
    perPatchKeyLoop:
    for (const key in patch) {
        const patchValue = patch[key];
        if (!(key in props)) {
            LegacyLogger.deferredWarn(`key ${key} not found in props group ${patch.constructor.name}`, patch);
            continue;
        }
        const currValue = props[key] as PropsGroupField; // function patching is not supported
        if (patchValue instanceof PropsPatch) {
            if (currValue instanceof PropsGroupBase) {
                const fieldPatched = applyPatchToProps(patchValue, currValue);
                if (fieldPatched) {
                    newArgs[key] = fieldPatched[0];
                    reversePatch[key] = currValue;
                }
            } else {
                LegacyLogger.deferredError(`attempt to apply patch to non propsgroup property ${key} in ${props.constructor.name}`, patch);
            }
            continue perPatchKeyLoop;
        }
        if (currValue === patchValue ) {
            continue perPatchKeyLoop;
        }
        if ((patchValue && currValue) && (patchValue.constructor === currValue.constructor)) {
            if (Array.isArray(currValue)) {
                if (currValue.length === (patchValue as PropertyBase[] | PropsGroupBase[]).length) {
                    continue perPatchKeyLoop;
                } else {
                    for (let i = 0; i < currValue.length; i++) {
                        if (!currValue[i].equals((patchValue as any)[i])) {
                            continue perPatchKeyLoop;
                        }
                    }
                }
            } else if ((currValue as any).equals(patchValue)) {
                continue perPatchKeyLoop;
            }
        }
        reversePatch[key] = currValue as PropsGroupField;
        newArgs[key] = patchValue;
    }
    if (ObjectUtils.isObjectEmpty(newArgs)) {
        return null;
    }
    const newProps = new (props.constructor as {new (args: any): P})(
        {...props, ...newArgs}
    );
    return [newProps, reversePatch!];
}




export function producePropsPatch<Props extends PropsGroupBase>(
    props: Props,
    patchFn: (mutableProps: Props) => void,
): PropsPatch | null {
    // const proxy: PropsProxy<Props> = _propsProxiesPerClass.getOrCreate(props.constructor as any) as PropsProxy<Props>;
    // proxy.reset();
    const proxy = new PropsProxy<Props>();
    proxy.setForProxying(props);
    patchFn(proxy.getProxy());
    const result = proxy.producePatch();
    return result;
}
const _propsProxiesPerClass = new DefaultMap<
    {new (args: any): PropsGroupBase},
    PropsProxy<PropsGroupBase>
>(
    _class => new PropsProxy()
);


export function producePatchedProps<Props extends PropsGroupBase>(
    props: Props,
    patchFn: (mutableProps: Props) => void,
): Props | null {
    // const proxy: PropsProxy<Props> =
    //     _propsProxiesPerClass.getOrCreate(props.constructor as any) as PropsProxy<Props>;
    // proxy.reset();
    const proxy = new PropsProxy<Props>();
    proxy.setForProxying(props);
    patchFn(proxy.getProxy());
    const result = proxy.produceResult();
    return result;
}

export function propsWithDifferentValueAt<Props extends PropsGroupBase>(
    sourceProps: Props, value: PropsGroupField, path: (string|number)[]
): Props | null {
    try {
        return _propsWithDifferentValue_r(sourceProps, value, path, 0);
    } catch (e) {
        console.error(e);
        return null;
    }
}

function _propsWithDifferentValue_r<Props extends PropsGroupBase>(
    sourceProps: Props, value: PropsGroupField, path: (string|number)[], inPathOffset: number
): Props {
    const key = path[inPathOffset];
    if (!(key in sourceProps)) {
        throw new Error(`key ${key} not found in props group ${sourceProps.constructor.name}`);
    }
    if (inPathOffset === path.length - 1) {
        const newArgs = {...sourceProps, [key]: value};
        return PropsGroupsRegistry.newGroupInstanceChecked<Props>(sourceProps.constructor as any, newArgs);
    }
    const currValue = sourceProps[key] as PropsGroupField | undefined;
    if (currValue === null) {
        throw new Error(`key ${key} is null in props group ${sourceProps.constructor.name}`);
    }
    if (currValue instanceof PropsGroupBase) {
        const newArgs = {...sourceProps, [key]: _propsWithDifferentValue_r(currValue, value, path, inPathOffset + 1)};
        return PropsGroupsRegistry.newGroupInstanceChecked<Props>(sourceProps.constructor as any, newArgs);
    } else if (Array.isArray(currValue)) {
        const nextKey = path[inPathOffset + 1]; // expect next key to be array index

        if (typeof nextKey !== 'number' || (!(nextKey >= 0 &&  nextKey < currValue.length))) {
            throw new Error(`key ${nextKey} is not valid array index in props group ${sourceProps.constructor.name}`);
        }
        const newArray = currValue.slice();
        if (inPathOffset === path.length - 2) {
            if (value instanceof PropertyBase || value instanceof PropsGroupBase) {
                newArray[nextKey] = value;
            } else {
                throw new Error(`value at ${key} should be property or properties group instance, got ${value}, type: ${typeof value}`);
            }
        } else {
            const groupExpected = newArray[nextKey];
            if (!(groupExpected instanceof PropsGroupBase)) {
                throw new Error(`expected props group at index ${nextKey}`);
            }
            newArray[nextKey] = _propsWithDifferentValue_r(groupExpected, value, path, inPathOffset + 2);
        }
        const newArgs = {...sourceProps, [key]: newArray};
        return PropsGroupsRegistry.newGroupInstanceChecked<Props>(sourceProps.constructor as any, newArgs);
    } else {
        throw new Error(`expected props group or array at ${key}, because it is not the last key in path`);
    }

}
