import type { IdBimGeo, IdBimMaterial, SceneInstance, StdSubmeshesLod, StdSubmeshRepresentation } from 'bim-ts';
import { StdGroupedMeshRepresentation, StdMeshRepresentation } from 'bim-ts';
import { IterUtils } from 'engine-utils-ts';

import type { EngineMaterialId } from '../pools/EngineMaterialId';
import {
	InObjFullId, InObjIdType, InObjLocalId,
} from '../scene/EngineSceneIds';
import type { ESOHandle } from '../scene/ESOsCollection';
import { newLodGroupLocalIdent } from '../scene/LodGroups';
import type { SubmeshAllocArgs} from '../scene/Submeshes2';
import {
	LodMask
} from '../scene/Submeshes2';
import { ESO } from './ESO';
import type { ESOsHandlerInputs } from './ESOsHandlerBase';
import { ESOsHandlerBase } from './ESOsHandlerBase';
import type { SubmeshesCreationOutput, SubmeshesCreationResources} from './ESSO';
import {
	ESSO
} from './ESSO';
import { SharedSelectHighlightJobUpdater } from './ESSO_HighlightUpdaters';
import { SharedStdRenderJobUpdater } from './SubmeshesStd';
import { ESSO_TextAnnotation } from './ESSO_TextAnnotation';
import { Quaternion, Transform } from 'math-ts';
import type { StdStaticAnnotationsCalculator } from '../annotations/StdStaticAnnotationsCalculator';
import { ESSO_TextAnnotationFrame } from './ESSO_TextAnnotationFrame';

export class ESO_StdStatic extends ESO {

	constructor(
		sceneInstanceRef: Readonly<SceneInstance>,
	) {
		super(sceneInstanceRef);
	}
}

export interface ESSO_StdStaticRepr {
	std: StdSubmeshRepresentation[],
	lod: StdSubmeshesLod | null,
}

export class ESSO_StdSelf extends ESSO<ESSO_StdStaticRepr> {

	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {
		
		// do we have lods
		const lodRepr = this.repr.lod;
		const lodGroupLocalIdent = newLodGroupLocalIdent(0, lodRepr?.enableAfterDetailSize ?? 0.001);
		const lodMask = lodRepr ? LodMask.Lod0 : LodMask.All;
		const instancingGroups = SubmeshesInstancingGroup.splitStdSubmeshesIntoGroups(this.repr.std);

		for(const instancingGroup of instancingGroups) {
			const engineGeoId = resoures.geometries.mapBimIdToEngineId(instancingGroup.geometryId);
			if (engineGeoId === undefined) {
				resoures.logger.batchedError('engine geo is absent', instancingGroup.geometryId);
				continue;
			}

			const materialId = resoures.materials.mapBimIdToEngineId(instancingGroup.materialId);
			if (materialId === undefined) {
				resoures.logger.batchedError('engine material is absent', instancingGroup.materialId);
				continue;
			}

			const allocArgs: SubmeshAllocArgs = {
				lodMask: lodMask,
				lodGroupLocalIdent,
				descr: {
					geoId: engineGeoId,
					materialId: materialId as unknown as EngineMaterialId,
					localTransforms: instancingGroup.transforms,
					mainRenderJobUpdater: SharedStdRenderJobUpdater,
					overlayRenderJobUpdater: SharedSelectHighlightJobUpdater,
				},
				id: this.id,
				subObjectRef: this,
			};
			output.submeshes.push(allocArgs);
		}
		instancingGroups.length = 0;

		if (lodRepr) {
			for (const submesh of lodRepr.submeshes) {
				const bimGeoId = submesh.geometryId;
				const bimMatId = submesh.materialId;
				const tr = submesh.transform;

				const engineGeoId = resoures.geometries.mapBimIdToEngineId(bimGeoId);
				if (engineGeoId === undefined) {
					resoures.logger.batchedError('engine geo is absent', bimGeoId);
					continue;
				}
				const materialId = resoures.materials.mapBimIdToEngineId(bimMatId);
                if (materialId === undefined) {
                    resoures.logger.batchedError('enigne material is absent', bimMatId);
                    continue;
                }
				const allocArgs: SubmeshAllocArgs = {
					lodMask: LodMask.Lod1 | LodMask.Lod2,
					lodGroupLocalIdent,
					descr: {
						geoId: engineGeoId,
						materialId: materialId as unknown as EngineMaterialId,
						localTransforms: [tr],
						mainRenderJobUpdater: SharedStdRenderJobUpdater,
						overlayRenderJobUpdater: SharedSelectHighlightJobUpdater
					},
					id: this.id,
					subObjectRef: this,
				}
				output.submeshes.push(allocArgs);
			}
		}
	}

	isRepresentationTheSame(repr: ESSO_StdStaticRepr): boolean {
		return this.repr.lod == repr.lod && this.repr.std == repr.std;
	}
}


export class ESO_StdStaticHandler extends ESOsHandlerBase<ESO_StdStatic> {
	constructor(
		identifier: string,
		args: ESOsHandlerInputs,
		annotationsCalculator: StdStaticAnnotationsCalculator
	) {
		super(identifier, args, annotationsCalculator);
	}

	tryCreateESO(instance: SceneInstance): ESO_StdStatic | undefined {
		if (
			instance.representation instanceof StdMeshRepresentation || 
			instance.representation instanceof StdGroupedMeshRepresentation
		) {
			return new ESO_StdStatic(instance);
        }
		return undefined;
	}

	esosTypesToHandle() {
		return [ESO_StdStatic];
	}

	createSubObjectsFor(objectsToRealloc: [ESOHandle, ESO_StdStatic][]): Iterable<[ESOHandle, ESSO<any>[]]> {
		return IterUtils.mapIter(
			objectsToRealloc,
			([handle, eso]) => {
				const repr = eso.bimRepresentation();
				if (!(repr instanceof StdMeshRepresentation)) {
					return [handle, []];
				}
				const subobjs: ESSO<any>[] = [new ESSO_StdSelf(
					eso,
					InObjFullId.new(handle, SelfId_0),
					{
						std: repr.submeshes,
						lod: repr.lod1,
					},
					null
				)];

				const annotationRepr = this.getAnnotation(handle, eso);
				if (annotationRepr) {
					const position = annotationRepr.position.clone().sub(eso.worldMatrix.extractPosition());
					const rotation = new Quaternion().setFromRotationMatrix(eso.worldMatrix).invert();
					position.applyQuaternion(rotation);
					
					const text = new ESSO_TextAnnotation(
						eso,
						InObjFullId.new(handle, SelfId_1),
						annotationRepr.textRepr,
						new Transform(position, rotation),
					);
					const frame = new ESSO_TextAnnotationFrame(
						eso,
						InObjFullId.new(handle, SelfId_2),
						annotationRepr.textRepr.blockOptions,
						new Transform(position, rotation)
					);
					
					subobjs.push(text, frame);
				}

				return [handle, subobjs]
			}
		);
	}

}

class SubmeshesInstancingGroup {
	constructor(
        readonly materialId: IdBimMaterial,
		readonly geometryId: IdBimGeo,
        readonly transforms: (Transform | null)[] = [],
    ) {
    }

    static splitStdSubmeshesIntoGroups(submeshes: StdSubmeshRepresentation[]): SubmeshesInstancingGroup[] {
        const groups: SubmeshesInstancingGroup[] = [];
        for (const submesh of submeshes) {
            let group: SubmeshesInstancingGroup | undefined = undefined;
            for (const existingGroup of groups) {
                if (existingGroup.materialId === submesh.materialId && existingGroup.geometryId == submesh.geometryId) {
                    group = existingGroup;
                }
            }
            if (group === undefined) {
                group = new SubmeshesInstancingGroup(submesh.materialId, submesh.geometryId);
                groups.push(group);
            }

            group.transforms.push(submesh.transform);
        }

        return groups;
    }
}

const SelfId_0 =  InObjLocalId.new(InObjIdType.ObjSelf, 0);
const SelfId_1=  InObjLocalId.new(InObjIdType.ObjSelf, 1);
const SelfId_2 =  InObjLocalId.new(InObjIdType.ObjSelf, 2);
