import type { BimGeometries} from 'bim-ts';
import {
	applyFlagsPatch, FlagsMask, SceneInstanceFlags,
} from 'bim-ts';
import type { ScopedLogger } from 'engine-utils-ts';
import { ObjectUtils } from 'engine-utils-ts';
import type { Aabb, Matrix4, Transform } from 'math-ts';

import type {
	AllEngineGeometries, IdEngineGeo,
} from '../geometries/AllEngineGeometries';
import type { EngineGeometry } from '../geometries/EngineGeometry';
import type { EngineImages } from '../materials/EngineImages';
import type { EngineStdMaterials } from '../materials/EngineStdMaterials';
import type { InObjFullId } from '../scene/EngineSceneIds';
import type { BimReprsBboxes } from '../scene/ESOsCollection';
import type { ESSOsCollection } from '../scene/ESSOsCollection';
import type { SubmeshAllocArgs, Submeshes2 } from '../scene/Submeshes2';
import type { ESO_Any } from './ESO';
import { ESSO_Diff } from './ESSO_Diff';

export const enum EngineSubObjectType {
	Static 			= 1,
	StaticHint 		= 2,
	Edit 			= 3,
	EditHint 		= 4,
}

export type SubObjectState = ESSO_Flags;

export enum ESSO_Flags {
	 // same as sceneInstanceFlags
	None 					= 0,
	// isHidden 				= 1,
	isSelected 				= 2,
	isHighlighted 			= 4,
}

export interface SubmeshesCreationResources {
	readonly logger: ScopedLogger,
	readonly geometries: AllEngineGeometries,
	readonly materials: EngineStdMaterials,
	readonly images: EngineImages,
	readonly bimGeometries: BimGeometries;
}

export class SubmeshesCreationOutput {
	readonly submeshes: SubmeshAllocArgs[] = [];
	readonly geometriesToAlloc: [IdEngineGeo, EngineGeometry][] = [];

	apply(submeshes: Submeshes2, resources: SubmeshesCreationResources) {
		if (this.geometriesToAlloc.length) {
			resources.geometries.allocate(this.geometriesToAlloc);
			this.geometriesToAlloc.length = 0
		}
		if (this.submeshes.length) {
			submeshes.addForAllocation(this.submeshes);
			this.submeshes.length = 0
		}
	}
}


export type ESSO_Any = ESSO<Object>;

export interface ESSO_Data<R> {
	localTransform: Transform | null,
	repr: R,
}

export interface ESSO_AabbCalcArgs {
	bimReprBboxes: Readonly<BimReprsBboxes>,
	bimGeometries: Readonly<BimGeometries>,
	engineGeometries: Readonly<AllEngineGeometries>,
}


export abstract class ESSO<R> implements ESSO_Data<R> { // Engine-Scene-Sub-Object

	public readonly rootRef: ESO_Any;
	public readonly id: InObjFullId;
	public repr: R;
	public localTransform: Transform | null;

	get isHidden() { return this.rootRef.bimFlags & SceneInstanceFlags.isHidden }
	get isSelected() { return this.rootRef.bimFlags & SceneInstanceFlags.isSelected }
	get isHighlighted() { return this.rootRef.bimFlags & SceneInstanceFlags.isHighlighted }

	get colorTint() { return this.rootRef.colorTint }

	get parentWorldMatrix(): Readonly<Matrix4> {
		return this.rootRef.worldMatrix; // triangulation is done in world space
	}

	constructor(
		rootRef: ESO_Any,
		id: InObjFullId,
		repr: R,
		localTransform: Transform | null,
	) {
		this.rootRef = rootRef;
		this.id = id;
		this.repr = repr;
		this.localTransform = localTransform;
	}
	
	selectionHighlightPower(): number {
		let highlightPower = 0;
		if (this.isHighlighted) {
			highlightPower += 0.3;
		}
		if (this.isSelected) {
			highlightPower += 0.7;
		}
		return highlightPower;
	}

	abstract createSubmeshes(
		resoures: SubmeshesCreationResources,
		output: SubmeshesCreationOutput,
	): void;

	calcWorldMatrix(result: Matrix4) {
		if (this.localTransform) {
			this.localTransform.toMatrix4(result);
			result.premultiply(this.parentWorldMatrix);
		} else {
			result.copy(this.parentWorldMatrix);
		}
	}

	
	applyPatch(patch: Partial<ESSO_Data<R>>, out: { revert: Partial<ESSO_Data<R>> | null }): ESSO_Diff {
		let dirtyFlags: ESSO_Diff = 0;

		if (patch.localTransform !== undefined && patch.localTransform != this.localTransform) {
			if (this.localTransform === null) {
				dirtyFlags |= ESSO_Diff.Position;
				this.localTransform = patch.localTransform!.clone();
			} else if (patch.localTransform === null) {
				dirtyFlags |= ESSO_Diff.Position;
				this.localTransform = null;
			} else if (!this.localTransform.equals(patch.localTransform)) {
				dirtyFlags |= ESSO_Diff.Position;
				this.localTransform.copy(patch.localTransform);
			}
		}
		if (patch.repr !== undefined && !this.isRepresentationTheSame(patch.repr)) {
			this.repr = patch.repr;
			dirtyFlags |= ESSO_Diff.RepresentationBreaking;
		}
		return dirtyFlags;
	}

	isRepresentationTheSame(repr: R) {
		return this.repr === repr;
	}

	// aabb(result: Aabb, args: ESSO_AabbCalcArgs): void {
	// 	const repr = this.rootRef.bimRepresentation();
	// 	if (repr) {
	// 		const reprBbox = args.bimReprBboxes.getOrCreate(repr);
	// 		result.copy(reprBbox);
	// 	} else {
	// 		result.setSizeOne();
	// 	}
	// 	result.applyMatrix4(this.parentWorldMatrix);
	// }
	// abstract lodDetailSize(): number;

}


export interface ESSO_Interactive_Data<R, C> extends ESSO_Data<R> {
	flags: ESSO_Flags,
	localTransform: Transform | null,
	repr: R,
	context: C
}

export type ESSO_Patch = Partial<ESSO_Interactive_Data<any, any>>;


export type ESSO_InteractiveAny = ESSO_Interactive<Object, Object | number>;


export const NoUndoFlags = (
	ESSO_Flags.isHighlighted
);
export const NoUndoFlagsPatch = NoUndoFlags << 12;

export abstract class ESSO_Interactive<R, C> extends ESSO<R> implements ESSO_Interactive_Data<R, C> {

	public flags: SubObjectState = ESSO_Flags.None;
	public context: C;

	get isSelected() { return this.flags & ESSO_Flags.isSelected }
	get isHighlighted() { return this.flags & ESSO_Flags.isHighlighted }
	
	constructor(
		rootRef: ESO_Any,
		id: InObjFullId,
		repr: R,
		localTransform: Transform | null,
		context: C,
	) {
		super(rootRef, id, repr, localTransform);
		this.context = context;
	}
	

	applyPatch(patch: Partial<ESSO_Interactive_Data<R, C>>, out: { revert: Partial<ESSO_Interactive_Data<R, C>> | null; }): ESSO_Diff {
		let dirtyFlags = super.applyPatch(patch, out);
		// if (dirtyFlags & ESSO_Diff.Position) {
		// 	this.lastPositionUpdateTime = performance.now();
		// }
		if (patch.flags !== undefined) {
			const currFlags = this.flags;
			const newFlags = applyFlagsPatch(currFlags, patch.flags);
			if (currFlags !== newFlags) {
				this.flags = newFlags;
				const currentFlagsChanged = (currFlags ^ newFlags) & FlagsMask;
				const flagsRevertMask = (currentFlagsChanged << 12)
				const flagsOld = currFlags & currentFlagsChanged;
				const revertFlags = flagsOld | flagsRevertMask;

				dirtyFlags |= ESSO_Diff.RepresentationOverlaySoft | ESSO_Diff.RepresentationSoft;
				if (currentFlagsChanged & (ESSO_Flags.isSelected)) {
					(out.revert || (out.revert = {})).flags = revertFlags & (~(NoUndoFlags | NoUndoFlagsPatch));
				}
			}
		}
		if (patch.context !== undefined && patch.context !== this.context) {
			this.context = patch.context;
		}
		return dirtyFlags;
	}

	
	isRepresentationTheSame(repr: R): boolean {
		return ObjectUtils.areObjectsEqual(this.repr, repr);
	}

	aabb(result: Aabb): void {
		throw new Error('not impl');
	}

}




const _reusedMap = new Map<InObjFullId, ESSO_Any>();

export class SubObjectsReconciler {

	toDelete: InObjFullId[] = [];
	toPatch: [InObjFullId, ESSO_Patch][] = [];
	toAlloc: ESSO_Any[] = [];

	reconcileSubobjectsAndRenderMeshes(
		prevSubObjs: ESSO_Any[],
		newSubObjects: ESSO_Any[],
	) {
		if (prevSubObjs.length === 0) {
			// fast path for new objects, nothing to reconcile
			for (const newObj of newSubObjects) {
				this.toAlloc.push(newObj);
			}
		} else {
			_reusedMap.clear();
			for (const obj of newSubObjects) {
				_reusedMap.set(obj.id, obj);
			}
			for (const prevObj of prevSubObjs) {
				const newObj = _reusedMap.get(prevObj.id);
				// if (newObj === undefined) {
				// 	this.toDelete.push(prevObj.id);
				// } else if (prevObj.canReuseRepresentation(newObj)) {
				// 	_reusedMap.delete(newObj.id);
				// } else {
				// 	this.toDelete.push(prevObj.id);
				// 	if (newObj instanceof ESSO_Interactive) {
				// 		newObj.flags = (prevObj as ESSO_Interactive<any>).flags | 0;
				// 	}
				// 	// dirty flags
				// }

				if (newObj === undefined || newObj.constructor !== prevObj.constructor) {
					this.toDelete.push(prevObj.id);
				} else {
					this.toPatch.push([newObj.id, {
						localTransform: newObj.localTransform,
						repr: newObj.repr,
						context: newObj instanceof ESSO_Interactive ? newObj.context : undefined,
					}]);
					_reusedMap.delete(newObj.id);
				}
			}
			// now only non mergable or new sub-objects left
			for (const newObj of _reusedMap.values()) {
				this.toAlloc.push(newObj);
			}
		}
	}

	apply(subobjects: ESSOsCollection) {
		try {
			subobjects.deleteByIds(this.toDelete);
			subobjects.applyPatchesFor(this.toPatch);
			subobjects.allocate(this.toAlloc.map(obj => [obj.id, obj]));
		} finally {
			this.toPatch.length = 0;
			this.toDelete.length = 0;
			this.toAlloc.length = 0;
		}
	}

}

