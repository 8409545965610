import type { TasksRunner} from "engine-utils-ts";
import { ScopedLogger, DefaultMapObjectKey, Yield, IterUtils, PollablePromise, Failure, ObjectUtils, Success, DefaultMap } from "engine-utils-ts";
import type { Aabb} from "math-ts";
import { Vector2, PolygonUtils, Aabb2, Matrix4, Vector3, Quaternion, PointsInPolygonChecker, Clipper, KrMath, Delaunator, Transform } from "math-ts";
import type { UiBindings} from "ui-bindings";
import { GroupedNotificationGenerator, NotificationDescription, NotificationType } from "ui-bindings";
import type { AnyTrackerProps } from "../../anyTracker/AnyTracker";
import { calculateTrackerFlatRepr } from "../../anyTracker/AnyTrackerMeshgen";
import { getPileUndulationType, PileUndulationType, getPileMotorType, PileMotorType } from "../../anyTracker/PilesFeatures";
import { DefaultPileLength } from "../../anyTracker/PilesProps";
import { FixedTiltTypeIdent } from "../../archetypes/fixed-tilt/FixedTilt";
import type { PropertiesPatch } from "../../bimDescriptions/PropertiesCollection";
import { BimPatch } from "../../BimPatch";
import type { IdInEntityLocal } from "../../collections/LocalIds";
import { GraphGeometry } from "../../geometries/GraphGeometries";
import { IrregularHeightmapGeometry } from "../../geometries/IrregularHeightmapGeometries";
import { PolylineGeometry } from "../../geometries/PolylineGeometries";
import { RegularHeightmapGeometry } from "../../geometries/RegularHeightmapGeometry";
import type { MathSolversApi } from "../../mathSolversApi/MathSolversApi";
import { notificationSource } from "../../Notifications";
import type { SceneInstanceSerializable } from "../../persistence/SceneInstancesSerializer";
import { PileBinsConfig } from "../../piles/PileBinsConfig";
import { PropsPatch, producePropsPatch } from "../../properties/Props";
import { SmallNumericArrayProperty } from "../../properties/SmallNumberArrayProperty";
import type { RepresentationBase} from "../../representation/Representations";
import { TerrainHeightMapRepresentation } from "../../representation/Representations";
import type { IdBimScene, SceneInstance } from "../../scene/SceneInstances";
import type { TrackerBuilderInfo} from "../../trackers/Tracker";
import { TrackerTypeIdent, segmentsStringFromSlopes, trackersPartsCache, TrackerPartType } from "../../trackers/Tracker";
import type { OnTerrainPlacementConfig} from "../OnTerrainPlacement";
import { OnTerrainPlacementType, placeInstancesOnTheGroundByType, patchLinerObjectsGeometry } from "../OnTerrainPlacement";
import { TerrainInstanceTypeIdent, TerrainVersion } from "../Terrain";
import { TerrainElevation } from "../TerrainElevation";
import type { TerrainTileId} from "../TerrainTile";
import { TerrainGeoVersionSelector, TerrainTile } from "../TerrainTile";
import type { TrackerSample, CutFillSolverJobArgs, TrackerType, PileSample } from "./CutFillSolverGenerator";
import { getAllHeightmaps, restoreNetBalance, applyCutFillHeightmap, refineHeightmap } from "./CutFillSolverGenerator";
import type { Bim } from "../../Bim";
import { BoundaryTypeIdent } from "../../archetypes/Boundary";



export enum CutFillContoursTypes {
    Trackers,
    Road,
    Platform,
}


export enum PlacementMethod {
    None,
    Basic,
    PilesOptimization,
    CutFill,
}


export interface CutFillServiceInput {
    placeMethod: PlacementMethod;
    contoursByType: Map<CutFillContoursTypes, { polygon: Vector2[], holes: Vector2[][] }[]>;
    equipment: IdBimScene[];
    
    gridSize: number;
    eastWestSlopeMaxPercent: number,
    northSlopeMaxPercent: number,
    southSlopeMaxPercent: number,
    axisSlopeMaxPercent: number,
    bayToBaySlopeMaxPercent: number,
    cumulativeSlopeMaxPercent: number,
    toleranceMeter: number;
    netBalanceMeter3?: number;
    timeLimit: number;

    maxPileRevealMeter: number;
    minPileRevealMeter: number;
    minPileEmbedmentMeter: number;
}


export async function placeEquipmentOnTerrain(args: {
    input: CutFillServiceInput;
    bim: Bim;
    mathSolversApi: MathSolversApi;
    tasksRunner: TasksRunner;
    uiBindings: UiBindings;
}): Promise<boolean> {
    const TIMEOUT = 900_000;
    const logger = new ScopedLogger('cut-fill service');
    const notificationGroup = new GroupedNotificationGenerator('Placing of equipment');
    if(!checkInput(args, notificationGroup)){
        return false;
    }
    try {
        const task = args.tasksRunner.newLongTask<any>({
            defaultGenerator: _placeEquipment({ ...args, logger, notificationGroup }),
            taskTimeoutMs: TIMEOUT
        });

        args.uiBindings.addNotification(
            notificationGroup.addRootNotification(
                NotificationDescription.newWithTask({
                    source: notificationSource,
                    key: 'placeEquipment',
                    taskDescription: { task },
                    type: NotificationType.Info,
                    addToNotificationsLog: true
                })
            )
        );
        await task.asPromise();
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}


function checkInput(
    args: {
        input: CutFillServiceInput;
        bim: Bim;
        tasksRunner: TasksRunner;
        uiBindings: UiBindings;
    }, 
    notificationGroup: GroupedNotificationGenerator
): boolean {
    function notify(key: keyof typeof notificationSource){
        args.uiBindings.addNotification(
            notificationGroup.addNotification(
                NotificationDescription.newBasic({
                    type: NotificationType.Error,
                    source: notificationSource,
                    key,
                    removeAfterMs: 5_000,
                    addToNotificationsLog: true
                })
            )
        ); 
    }
    const terrains = args.bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent);
    if (terrains.length === 0) {
        notify('heightmapNotFound');

        return false;
    }
    for (const [_, inst] of terrains) {
        const transform = Transform.fromMatrix(inst.worldMatrix);
        if (
            transform.rotation.x !== 0 ||
            transform.rotation.y !== 0 ||
            transform.rotation.z !== 0
        ) {
            notify('heightmapRotation');
            return false;
        }

        if (
            transform.scale.x !== 1 ||
            transform.scale.y !== 1 ||
            transform.scale.z !== 1
        ) {
            notify('heightmapScale');

            return false;
        }
    }

    return true;
}


const trackerTypes = ["any-tracker", TrackerTypeIdent, FixedTiltTypeIdent];


function* _placeEquipment(args: {
    input: CutFillServiceInput;
    bim: Bim;
    mathSolversApi: MathSolversApi;
    tasksRunner: TasksRunner;
    uiBindings: UiBindings;
    logger: ScopedLogger;
    notificationGroup: GroupedNotificationGenerator;
}) {
    const config: OnTerrainPlacementConfig = {
        terrainVersion: TerrainVersion.Latest,
        minEmbedment: args.input.minPileEmbedmentMeter,
    };
    if (args.input.placeMethod === PlacementMethod.CutFill) {
        yield* cutFillOptimization({
            ...args,
            onTerrainConfig: config
        });
    } else if (args.input.placeMethod === PlacementMethod.PilesOptimization) {
        yield* pilesOptimization({
            ...args,
            onTerrainConfig: config,
        });
    } else {
        args.uiBindings.addNotification(
            args.notificationGroup.addNotification(
            NotificationDescription.newBasic({
                type: NotificationType.Error,
                source: notificationSource,
                key: 'placeMethod',
                descriptionArg: args.input.placeMethod.toString(), 
                removeAfterMs: 5_000,
                addToNotificationsLog: true
            }))
        );
    }
}


export const percToTan = (perc:number) => 0.01 * perc;


interface TrackerSampleWithProps {
    commonProps: {
        typeIdentifier: string,
        pilesCount: number,
    } & TrackerBuilderInfo,
    sample: TrackerSample
}


function* cutFillOptimization(args: {
    input: CutFillServiceInput;
    bim: Bim;
    mathSolversApi: MathSolversApi;
    tasksRunner: TasksRunner;
    uiBindings: UiBindings;
    logger: ScopedLogger;
    onTerrainConfig: OnTerrainPlacementConfig;
    notificationGroup: GroupedNotificationGenerator;
}) {
    const allContours = args.input.contoursByType.get(CutFillContoursTypes.Trackers)!;

    const goemetriesAabbs = args.bim.allBimGeometries.aabbs.poll();
    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));

    const { trackersSamples, trackersPositions } = getTrackersPlacingArgs(args.input, args.bim, reprsBboxes, args.logger);

    const terrains = args.bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent)
        .filter(t => t[1].representation && t[1].representation instanceof TerrainHeightMapRepresentation)
        .map(t => {
            return { 
                id: t[0], 
                representation: t[1].representation! as TerrainHeightMapRepresentation, 
                worldMatrix: t[1].worldMatrix,
                aabb2: reprsBboxes.getOrCreate(t[1].representation!).xy() 
            }
        });
    const terrainsTiles = new Map<IdBimScene, Map<TerrainTileId, TerrainTile>>(
        terrains.map(t => [t.id, new Map<TerrainTileId, TerrainTile>(t.representation.tiles)]));

    const contoursWithAreas = allContours.map(c => { 
        return { 
            polygon: c.polygon, 
            holes: c.holes, 
            area: Math.abs(PolygonUtils.area(c.polygon)) - c.holes.reduce((sum, current) => sum + Math.abs(PolygonUtils.area(current)), 0)
        } 
    });
    const totalArea = contoursWithAreas.reduce((sum, current) => sum + current.area, 0);
    contoursWithAreas.sort((a, b) => b.area - a.area);

    const solverArgs: CutFillSolverJobArgs[] = [];
    for (const { polygon: inputPolygon, holes: inputHoles, area: area } of contoursWithAreas) {
        for (const terrain of terrains) {
            const inverseWorldMatrix = terrain.worldMatrix.clone().invert();

            const polygon: Vector2[] = [];
            for (let i = 0; i < inputPolygon.length; ++i) {
                polygon[i] = inputPolygon[i].clone();
                polygon[i].applyMatrix4(inverseWorldMatrix);
            }

            const holes: Vector2[][] = [];
            for (let i = 0; i < inputHoles.length; ++i) {
                holes[i] = [];
                for (let j = 0; j < inputHoles[i].length; ++j) {
                    holes[i][j] = inputHoles[i][j].clone();
                    holes[i][j].applyMatrix4(inverseWorldMatrix);
                }
            }
            
            const polyBbox = Aabb2.empty().setFromPoints(polygon);
    
            if (!terrain.aabb2.intersectsBox2(polyBbox)) {
                continue;
            }

            solverArgs.push({
                polygon: polygon,
                holes: holes,
                polyBbox: polyBbox,
                terrain: terrain,
                terrainTiles: terrainsTiles.get(terrain.id)!,
                samples: trackersSamples.map(ts => ts.sample),
                trackers: trackersPositions.map(t => t.trackerLine),
                timeRatio: area / totalArea,
                numThreads: Math.ceil(area / 500_000),
                logger: args.logger
            });
        }
    }

    yield Yield.Asap;

    const responses = yield* getAllHeightmaps(solverArgs, args.mathSolversApi, args.input, args.bim.regularHeightmapGeometries);
    
    const newCellSize = 2;

    const responsesPiles: Vector3[][] = [];
    for (const response of responses) {
        const piles: Vector3[] = [];
        const trackers = response.response[0].trackers;
        for (let i = 0; i < trackersPositions.length; ++i) {
            const sample = trackersSamples[trackersPositions[i].trackerLine.sample].sample;
            const relativePiles = sample.piles.filter(p => p.preserve_tolerance).map(p => p.vertex);
            trackers[i] = trackers[i].filter((_, j) => sample.piles[j].preserve_tolerance);
            
            if (trackers[i].length === relativePiles.length) {
                const trackerLine = trackersPositions[i].trackerLine;
                for (let j = 0; j < relativePiles.length; ++j) {
                    piles.push(new Vector3(
                        (1 - relativePiles[j]) * trackerLine.beg[0] + relativePiles[j] * trackerLine.end[0],
                        (1 - relativePiles[j]) * trackerLine.beg[1] + relativePiles[j] * trackerLine.end[1],
                        trackers[i][j]
                    ));
                }
            }
        }

        piles.sort((a, b) => (a.y === b.y) ? (a.x - b.x) : (a.y - b.y));

        responsesPiles.push(piles);
    }

    yield Yield.Asap;

    for (let i = 0; i < responses.length; ++i) {
        refineHeightmap(responses[i], newCellSize, responsesPiles[i], args.input, args.bim.regularHeightmapGeometries);
        yield Yield.Asap;
    }
    
    if (args.input.netBalanceMeter3 !== undefined) {
        yield* restoreNetBalance(responses, args.input.netBalanceMeter3, args.bim.regularHeightmapGeometries);
    }
    
    yield Yield.Asap;

    for (let i = 0; i < responses.length; ++i) {
        yield* applyCutFillHeightmap(
            responses[i].response[0].surface[0], 
            responses[i].argsForApply, 
            responsesPiles[i], 
            args.input.toleranceMeter,
            args.bim,
        );
    }

    const wmPatch = new Map<IdBimScene, Matrix4>();
    const patches = new Map<IdBimScene, PropertiesPatch | PropsPatch>();
    for (const response of responses) {
        const trackers = response.response[0].trackers;
        const pointsToSample: Vector2[] = [];
        for (let i = 0; i < trackersPositions.length; ++i) {
            const sample = trackersSamples[trackersPositions[i].trackerLine.sample].sample;
            const relativePiles = sample.piles.filter(p => p.preserve_tolerance).map(p => p.vertex);
            
            if (trackers[i].length === relativePiles.length) {
                const trackerLine = trackersPositions[i].trackerLine;
                for (const p of relativePiles) {
                    pointsToSample.push(new Vector2(
                        (1 - p) * trackerLine.beg[0] + p * trackerLine.end[0],
                        (1 - p) * trackerLine.beg[1] + p * trackerLine.end[1]
                    ));
                }
            }
        }

        const localTiles = new Map(IterUtils.mapIter(
            response.argsForApply.terrainTiles, 
            ([tileId, tile]) => {
                return [tileId, tile.selectGeoId(TerrainGeoVersionSelector.Latest)];
            }  
        ));

        const worldMatrix = response.argsForApply.terrain.worldMatrix.clone();
        worldMatrix.elements[14] = 0;
        const elevations = TerrainElevation.sampleFromTiles({
            logger: args.logger,
            tileSize: response.argsForApply.terrain.representation.tileSize,
            localTiles: localTiles,
            bim: args.bim,
            worldMatrix: worldMatrix,
            positionsToSampleAtWs: pointsToSample
        });

        yield Yield.Asap;

        let j = 0, k = 0;
        for (let i = 0; i < trackersPositions.length; ++i) {
            const sample = trackersSamples[trackersPositions[i].trackerLine.sample].sample;
            const relativePiles = sample.piles.filter(p => p.preserve_tolerance).map(p => p.vertex);
            
            if (trackers[i].length !== relativePiles.length) {
                continue;
            }

            let maxDiff = -Infinity, minDiff = 0, shouldPlace = false;
            const pilesReveals = new Array<number>(relativePiles.length);
            if (trackersPositions[i].typeIdentifier === "any-tracker") {
                for (j = 0; j < relativePiles.length; ++j, ++k) {
                    if (elevations[k].elevation !== null) {
                        shouldPlace = true;
                        pilesReveals[j] = trackers[i][j] - elevations[k].elevation!;
                        if (-pilesReveals[j] > maxDiff) {
                            maxDiff = -pilesReveals[j];
                        }
                    } else {
                        pilesReveals[j] = Infinity;
                    }
                }

                for (j = 0; j < relativePiles.length; ++j) {
                    pilesReveals[j] += maxDiff + args.input.minPileRevealMeter;
                    trackers[i][j] += maxDiff + args.input.minPileRevealMeter;
                }
            } else {
                for (j = 0; j < relativePiles.length; ++j, ++k) {
                    if (elevations[k].elevation !== null) {
                        shouldPlace = true;
                        if (elevations[k].elevation! - trackers[i][j] > maxDiff) {
                            maxDiff = elevations[k].elevation! - trackers[i][j];
                        }
                        if (elevations[k].elevation! - trackers[i][j] < minDiff) {
                            minDiff = elevations[k].elevation! - trackers[i][j];
                        }
                    }
                }
            }

            if (shouldPlace) {
                const {worldMatrix, patch} = placeTracker({
                    trackerPositions: trackersPositions[i],
                    relativePiles: relativePiles, 
                    pilesElevations: trackers[i], 
                    pilesReveals: pilesReveals,
                    baseTerrainElevation: response.argsForApply.terrain.worldMatrix.elements[14],
                    minPileEmbedmentMeter: args.input.minPileEmbedmentMeter,
                    maxRevealMeter: maxDiff - minDiff + args.input.minPileRevealMeter
                });
                
                if (patch !== null) {
                    patches.set(trackersPositions[i].id, patch);
                }

                wmPatch.set(trackersPositions[i].id, worldMatrix);
            } else if (trackersPositions[i].typeIdentifier !== "any-tracker") {
                const patch: PropertiesPatch = [];
                patchMinEmbedment(
                    patch, 
                    trackersPositions[i].typeIdentifier, 
                    0, 
                    args.input.minPileEmbedmentMeter + args.input.maxPileRevealMeter
                );
                patches.set(trackersPositions[i].id, patch);
            }
        }
    }

    const bimPatch = new BimPatch();
    for (const trackerPosition of trackersPositions) {
        let patch = patches.get(trackerPosition.id);
        if (patch !== undefined) {
            if (patch instanceof PropsPatch) {
                bimPatch.instances.toPatch.push([trackerPosition.id, { props: patch }]);
            } else {
                bimPatch.instances.toPatch.push([trackerPosition.id, { properties: patch }]);
            }
        } else {
            patch = [];
            patchMinEmbedment(
                patch, 
                trackerPosition.typeIdentifier, 
                0, 
                args.input.minPileEmbedmentMeter + args.input.maxPileRevealMeter
            );
            bimPatch.instances.toPatch.push([trackerPosition.id, { properties: patch }]);
        }
    }

    yield Yield.Asap;

    const anotherObjectsPatches = placeEquipmentAndLinerObjectsOnTheGround(
        args.logger,
        args.bim,
        args.input.equipment,
        allContours,
        args.onTerrainConfig
    );
    for (const patch of anotherObjectsPatches) {
        wmPatch.set(patch[0], patch[1]);
    }

    yield Yield.Asap;

    args.bim.instances.patchWorldMatrices(wmPatch);
    bimPatch.applyTo(args.bim);
}


function calculateCenterHeight(relativePiles: number[], pilesElevations: Float32Array | number[]) {
    for (let i = 0; i < relativePiles.length - 1; ++i) {
        if (relativePiles[i] <= 0.5 && relativePiles[i + 1] >= 0.5) {
            return pilesElevations[i] + (pilesElevations[i + 1] - pilesElevations[i]) * 
                (0.5 - relativePiles[i]) / (relativePiles[i + 1] - relativePiles[i]);
        }
    }
    return 0;
}


function placeTracker(args: {
    trackerPositions: TrackerPositions,
    relativePiles: number[],
    pilesElevations: Float32Array | number[],
    pilesReveals: number[],
    baseTerrainElevation: number,
    minPileEmbedmentMeter: number,
    maxRevealMeter: number,
}): { worldMatrix: Matrix4, patch: PropertiesPatch | PropsPatch | null } {
    const segmentsSlopes: number[] = new Array(args.pilesElevations.length - 1);

    const beg = new Vector2(args.trackerPositions.trackerLine.beg[0], args.trackerPositions.trackerLine.beg[1]);
    const end = new Vector2(args.trackerPositions.trackerLine.end[0], args.trackerPositions.trackerLine.end[1]);
    const trackerFlatVector = end.clone().sub(beg);
    const trackerFlatLength = trackerFlatVector.length();
    trackerFlatVector.normalize();
    const trackerVector = Vector3.fromVec2(trackerFlatVector);
    
    let pilesProjectionLength = 0;
    for (let i = 0; i < args.pilesElevations.length - 1; ++i) {
        const segmentLength = (args.relativePiles[i + 1] - args.relativePiles[i]) * trackerFlatLength;

        // sin, NOT tan or angle
        segmentsSlopes[i] = (args.pilesElevations[i + 1] - args.pilesElevations[i]) / segmentLength;

        pilesProjectionLength += Math.sqrt(1 - segmentsSlopes[i] * segmentsSlopes[i]) * segmentLength;
    }
    trackerVector.multiplyScalar(pilesProjectionLength);
    trackerVector.z = args.pilesElevations[args.pilesElevations.length - 1] - args.pilesElevations[0];
    const trackerAngle = Math.atan(trackerVector.z / pilesProjectionLength);

    for (let i = 0; i < args.pilesElevations.length - 1; ++i) {
        segmentsSlopes[i] = Math.asin(segmentsSlopes[i]) - trackerAngle;
    }

    let properties: PropertiesPatch | PropsPatch | null;
    if (args.trackerPositions.typeIdentifier === "any-tracker") {
        const pilesLengths = new Array<number>(args.pilesReveals.length);
        for (let i = 0; i < args.pilesReveals.length; ++i) {
            if (args.pilesReveals[i] === Infinity) {
                pilesLengths[i] = DefaultPileLength;
            } else {
                pilesLengths[i] = args.pilesReveals[i] + args.minPileEmbedmentMeter;
            }
        }

        properties = producePropsPatch(args.trackerPositions.props!, (props) => {
            if (segmentsSlopes.every(s => Math.abs(s) < 1e-4)) {
                props.piles._segments_slopes = null;
            } else {
                props.piles._segments_slopes = new SmallNumericArrayProperty({
                    values: segmentsSlopes,
                    unit: "rad",
                });
            }

            props.piles._pile_tops_distance_to_ground = new SmallNumericArrayProperty({
                values: args.pilesReveals,
                unit: "m",
            });
            props.piles.lengths = new SmallNumericArrayProperty({
                values: pilesLengths,
                unit: "m",
            });
        });
    } else {
        properties = [];

        if (args.trackerPositions.typeIdentifier === TrackerTypeIdent) {
            const newValue = segmentsStringFromSlopes(segmentsSlopes);

            properties.push([
                'position | _segments-slopes',
                {
                    path: ['position', '_segments-slopes'],
                    value: newValue,
                }
            ]);
        }

        patchMinEmbedment(
            properties, 
            args.trackerPositions.typeIdentifier, 
            args.minPileEmbedmentMeter, 
            args.maxRevealMeter
        );
    }

    const centerHeight = calculateCenterHeight(args.relativePiles, args.pilesElevations);

    const position = new Vector3();
    const scale = new Vector3();
    const rotation = new Quaternion();
    args.trackerPositions.worldMatrix.decompose(position, rotation, scale);
    rotation.x = 0;
    rotation.y = 0;
    rotation.premultiply(Quaternion.fromUnitVectors(
        Vector3.fromVec2(trackerFlatVector),
        trackerVector.clone().normalize()
    ));
    position.z = centerHeight + args.baseTerrainElevation;
    const newMatrix = new Matrix4().compose(position, rotation, scale);

    return {
        worldMatrix: newMatrix,
        patch: properties
    }
}


interface TrackerPositions {
    id: IdBimScene;
    props: AnyTrackerProps | undefined;
    typeIdentifier: string;
    worldMatrix: Matrix4;
    trackerLine: TrackerType;
}


function createTrackerSample(
    props: { typeIdentifier: string, pilesCount: number } & TrackerBuilderInfo,
    instance: SceneInstance,
    gridSize: number,
): { sample: TrackerSample, trackerLine: [Vector3, Vector3] } {
    const pilesSamples: PileSample[] = [];
    let motorIndex = 0;
    const trackerLine: [Vector3, Vector3] = [new Vector3(), new Vector3()];

    if (props.typeIdentifier === "fixed-tilt") {
        const length = instance.properties.get("dimensions | length")?.as('m')!;

        pilesSamples.push({
            vertex: 0,
            preserve_tolerance: true,
            regular: true
        });
        for (let i = 1; i < props.pilesCount; ++i) {
            const virtualPilesCount = Math.round(length / (gridSize * (props.pilesCount + 1)));
            for (let j = 1; j <= virtualPilesCount; ++j) {
                pilesSamples.push({
                    vertex: (i - 1 + (j / (virtualPilesCount + 1))) / (props.pilesCount - 1),
                    preserve_tolerance: false,
                    regular: true
                });
            }
            pilesSamples.push({
                vertex: i / (props.pilesCount - 1),
                preserve_tolerance: true,
                regular: true
            });
        }

        trackerLine[0].x = -length / 2;
        trackerLine[1].x = length / 2;
    } else if (props.typeIdentifier === "tracker") {
        const parts = trackersPartsCache.acquire(props).parts;

        let pileIndex = -1;
        const beg = parts[0].centerOffset, length = parts[parts.length - 1].centerOffset - beg;
        for (const part of parts) {
            if (part.ty & TrackerPartType.Pile) {
                const pilePos = (part.centerOffset - beg) / length;
                if (pilesSamples.length > 0) {
                    const prevPile = pilesSamples.at(-1)!.vertex;
                    const virtualPilesCount = Math.round((pilePos - prevPile) * length / gridSize);
                    for (let i = 1; i <= virtualPilesCount; ++i) {
                        pilesSamples.push({
                            vertex: (i / (virtualPilesCount + 1)) * pilePos 
                                + ((virtualPilesCount + 1 - i) / (virtualPilesCount + 1)) * prevPile,
                            preserve_tolerance: false,
                            regular: true
                        });
                        pileIndex++;
                    }
                }
                
                pilesSamples.push({
                    vertex: pilePos,
                    preserve_tolerance: true,
                    regular: false
                });
                pileIndex++;
            }

            if (part.ty & TrackerPartType.Motor) {
                motorIndex = pileIndex;
            }
        }
        trackerLine[0].y = -length / 2;
        trackerLine[1].y = length / 2;
    } else {
        const props = instance.props as AnyTrackerProps;
        const flatRepr = calculateTrackerFlatRepr(props, false);

        for (let i = 0; i < flatRepr.piles_offsets.length; ++i) {
            const pilePos = flatRepr.piles_offsets[i] / flatRepr.torque_tube_length;
            if (pilesSamples.length > 0) {
                const prevPile = pilesSamples.at(-1)!.vertex;
                const virtualPilesCount = Math.round((pilePos - prevPile) * flatRepr.torque_tube_length / gridSize);
                for (let i = 1; i <= virtualPilesCount; ++i) {
                    pilesSamples.push({
                        vertex: (i / (virtualPilesCount + 1)) * pilePos 
                            + ((virtualPilesCount + 1 - i) / (virtualPilesCount + 1)) * prevPile,
                        preserve_tolerance: false,
                        regular: true
                    });
                }
            }
            
            pilesSamples.push({
                vertex: pilePos,
                preserve_tolerance: true,
                regular: getPileUndulationType(flatRepr.piles_descriptions.features[i]) === PileUndulationType.Rigid
            });
        }

        motorIndex = flatRepr.piles_descriptions.features.findIndex(f => getPileMotorType(f) === PileMotorType.Motor);

        trackerLine[0].y = flatRepr.torque_tube_length / 2;
        trackerLine[1].y = -flatRepr.torque_tube_length / 2;
    }

    return { 
        sample:{
            piles: pilesSamples,
            motor: motorIndex,
        },
        trackerLine: trackerLine
    };
}


interface TrackersPiles {
    points: Vector3[];
    motor_node: number;
    regular: boolean[];
}

interface PilesOptimizationRequest {
    trackers: TrackersPiles[],
    min_reveal: number,
    max_slope: number,
    max_deviation: number,
    max_accumulation: number,
}

interface PilesOptimizationResponse {
    heights: number[][];
}


function* pilesOptimization(args: {
    input: CutFillServiceInput;
    bim: Bim;
    mathSolversApi: MathSolversApi;
    tasksRunner: TasksRunner;
    uiBindings: UiBindings;
    logger: ScopedLogger;
    onTerrainConfig: OnTerrainPlacementConfig;
    notificationGroup: GroupedNotificationGenerator;
}) {
    const allContours = Array.from(IterUtils.iterMap(args.input.contoursByType, c => c[1])).flat();

    const goemetriesAabbs = args.bim.allBimGeometries.aabbs.poll();
    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));

    const { trackersSamples, trackersPositions } = 
        getTrackersPlacingArgs(args.input, args.bim, reprsBboxes, args.logger);

    const pointsToSample: Vector2[] = [];
    for (const trackerPosition of trackersPositions) {
        const trackerLine = trackerPosition.trackerLine;
        const relativePiles = trackersSamples[trackerLine.sample].sample.piles;
        for (const pile of relativePiles) {
            pointsToSample.push(new Vector2(
                (1 - pile.vertex) * trackerLine.beg[0] + pile.vertex * trackerLine.end[0],
                (1 - pile.vertex) * trackerLine.beg[1] + pile.vertex * trackerLine.end[1]
            ));
        }
    }

    const elevations = TerrainElevation.sampleFromBim(
        args.logger,
        args.bim,
        pointsToSample,
        TerrainVersion.Latest
    );

    const trackersForPilesOptimization: TrackersPiles[] = [];
    for (let i = 0, k = 0; i < trackersPositions.length; ++i) {
        const trackerLine = trackersPositions[i].trackerLine;
        const relativePiles = trackersSamples[trackerLine.sample].sample.piles;
        const pilesPoints: Vector3[] = [];
        const regularFlags: boolean[] = [];
        let shouldPlace = false;
        for (let j = 0; j < relativePiles.length; ++j, ++k) {
            const pile = relativePiles[j];
            if (elevations[k].elevation !== null) {
                pilesPoints.push(new Vector3(
                    (1 - pile.vertex) * trackerLine.beg[0] + pile.vertex * trackerLine.end[0],
                    (1 - pile.vertex) * trackerLine.beg[1] + pile.vertex * trackerLine.end[1],
                    elevations[k].elevation!
                ));
                regularFlags.push(relativePiles[j].regular);
                shouldPlace = true;
            } else {
                pilesPoints.push(new Vector3(
                    (1 - pile.vertex) * trackerLine.beg[0] + pile.vertex * trackerLine.end[0],
                    (1 - pile.vertex) * trackerLine.beg[1] + pile.vertex * trackerLine.end[1],
                    NaN
                ));
                regularFlags.push(relativePiles[j].regular);
            }
        }
        if (shouldPlace) {
            trackersForPilesOptimization.push({
                points: pilesPoints,
                motor_node: trackersSamples[trackerLine.sample].sample.motor,
                regular: regularFlags
            });
        } else {
            trackersPositions.splice(i, 1);
            --i;
        }
    }

    const request: PilesOptimizationRequest = {
        trackers: trackersForPilesOptimization,
        min_reveal: args.input.minPileRevealMeter,
        max_slope: percToTan(args.input.axisSlopeMaxPercent),
        max_deviation: percToTan(args.input.bayToBaySlopeMaxPercent),
        max_accumulation: percToTan(args.input.cumulativeSlopeMaxPercent),
    };

    const url = "undulated_pile_optimisation";
    const responsePromise = args.mathSolversApi
        .callSolver<PilesOptimizationRequest, PilesOptimizationResponse>({
            solverName: url,
            solverType: "single",
            request: request,
        });
    
    const result = yield* PollablePromise.generatorWaitFor(responsePromise);
    if(result instanceof Failure){
        throw new Error(result.errorMsg());
    }
    const response = result.value;

    const pileBinsConfig = args.bim.configs.peekSingleton(PileBinsConfig.name);

    const bimPatch = new BimPatch();
    const wmPatch = new Map<IdBimScene, Matrix4>();
    for (let i = 0; i < trackersPositions.length; ++i) {
        const sample = trackersSamples[trackersPositions[i].trackerLine.sample].sample;
        const relativePiles = sample.piles.filter(p => p.preserve_tolerance).map(p => p.vertex);
        
        response.heights[i] = response.heights[i].filter((_, j) => sample.piles[j].preserve_tolerance);
        const pointsElevations = trackersForPilesOptimization[i].points.filter((_, j) => sample.piles[j].preserve_tolerance);

        let maxReveal = args.input.minPileRevealMeter;
        const pilesReveals = new Array<number>(pointsElevations.length);
        for (let j = 0; j < response.heights[i].length; ++j) {
            if (!Number.isNaN(pointsElevations[j].z)) {
                pilesReveals[j] = response.heights[i][j] - pointsElevations[j].z;
                if (pilesReveals[j] > maxReveal) {
                    maxReveal = pilesReveals[j];
                }
            } else {
                pilesReveals[j] = Infinity;
            }
        }
        if (trackersPositions[i].typeIdentifier !== "any-tracker") {
            for (let j = 0; j < response.heights[i].length; ++j) {
                response.heights[i][j] -= maxReveal;
            }
        }

        const {worldMatrix, patch} = placeTracker({
            trackerPositions: trackersPositions[i],
            relativePiles: relativePiles, 
            pilesElevations: response.heights[i], 
            pilesReveals: pilesReveals,
            baseTerrainElevation: 0,
            minPileEmbedmentMeter: args.input.minPileEmbedmentMeter,
            maxRevealMeter: maxReveal,
        });
        
        if (patch !== null) {
            if (!(patch instanceof PropsPatch)) {
                bimPatch.instances.toPatch.push([trackersPositions[i].id, { properties: patch }]);
            } else {
                bimPatch.instances.toPatch.push([trackersPositions[i].id, { props: patch }]);
            }
        }

        wmPatch.set(trackersPositions[i].id, worldMatrix);
    }

    const anotherObjectsPatches = placeEquipmentAndLinerObjectsOnTheGround(
        args.logger,
        args.bim,
        args.input.equipment,
        allContours,
        args.onTerrainConfig
    );
    for (const patch of anotherObjectsPatches) {
        wmPatch.set(patch[0], patch[1]);
    }

    yield Yield.Asap;

    args.bim.instances.patchWorldMatrices(wmPatch);
    bimPatch.applyTo(args.bim);
}


function patchMinEmbedment(patch: PropertiesPatch, typeIdentifier: string, minEmbedment: number, maxReveal: number) {
    function roundToCm(value: number) {
        return Math.round(value * 1e2) / 1e2;
    }
    if (typeIdentifier === FixedTiltTypeIdent) {
        patch.push([
            "piles | min_embedment",
            {
                path: ["piles", "min_embedment"],
                value: roundToCm(minEmbedment),
                unit: "m",
            },
        ], [
            "piles | max_reveal",
            {
                path: ["piles", "max_reveal"],
                value: roundToCm(maxReveal),
                unit: "m",
            },
        ]);
    } else if (typeIdentifier === TrackerTypeIdent) {
        patch.push([
            "tracker-frame | piles | min_embedment",
            {
                path: ["tracker-frame", "piles", "min_embedment"],
                value: roundToCm(minEmbedment),
                unit: "m",
            },
        ], [
            "tracker-frame | piles | max_reveal",
            {
                path: ["tracker-frame", "piles", "max_reveal"],
                value: roundToCm(maxReveal),
                unit: "m",
            },
        ]);
    }
}


function placeEquipmentAndLinerObjectsOnTheGround(
    logger: ScopedLogger,
    bim: Bim,
    ids: IdBimScene[],
    contours: { polygon: Vector2[], holes: Vector2[][] }[],
    config: OnTerrainPlacementConfig,
) {
    const excludePolygons: Vector2[][] = [];
    for (const contour of contours) {
        IterUtils.extendArray(excludePolygons, contour.holes);
    }
    const checker = new PointsInPolygonChecker(
        Clipper.offsetPolygons2D(contours.map(c => c.polygon), 100), 
        excludePolygons.length > 0 ? Clipper.offsetPolygons2D(excludePolygons, -100) : []
    );

    const equipmentTypeIdents = new Map<string, OnTerrainPlacementType>([
        ["substation", OnTerrainPlacementType.Basic1Point],
        ["transformer", OnTerrainPlacementType.Basic1Point],
        ["sectionalizing-cabinet", OnTerrainPlacementType.Basic1Point],
        ["combiner-box", OnTerrainPlacementType.Basic1Point],
        ["inverter", OnTerrainPlacementType.Basic1Point],
    ]);
    const patch = new Map<IdBimScene, Matrix4>();
    const intancesPatches = placeInstancesOnTheGroundByType(
        logger,
        bim,
        ids,
        equipmentTypeIdents,
        config
    );
    for (const [id, mtx] of intancesPatches) {
        patch.set(id, mtx);
    }

    const linerTypeIdents = new Map<string, OnTerrainPlacementType>([
        ["wire", OnTerrainPlacementType.LinerGeometry],
        ["lv-wire", OnTerrainPlacementType.LinerGeometry],
        ["trench", OnTerrainPlacementType.LinerGeometry],
        ["road", OnTerrainPlacementType.LinerGeometry],
    ]);

    const linerObjectsMtxPatch = patchLinerObjectsGeometry(
        logger,
        bim,
        ids,
        checker,
        linerTypeIdents,
        config
    );
    for (const [id, mtx] of linerObjectsMtxPatch) {
        patch.set(id, mtx);
    }

    return patch;
}


function getTrackersPlacingArgs(
    input: CutFillServiceInput,
    bim: Bim,
    reprsBboxes: DefaultMap<RepresentationBase, Aabb>,
    logger: ScopedLogger,
) {
    const trackersSamples: TrackerSampleWithProps[] = [];
    const trackersPositions: TrackerPositions[] = [];
    const trackersLines: [Vector3, Vector3][] = [];
    
    const instances = bim.instances.peekByIds(input.equipment);

    for (const [id, inst] of instances) {
        if (!trackerTypes.includes(inst.type_identifier)) {
            continue;
        }
        if (!inst.representation) {
            continue;
        }
        const reprAabb = reprsBboxes.getOrCreate(inst.representation);
        if (reprAabb.isEmpty()) {
            continue;
        }
        
        const props = getSolarTrackerProps(inst);

        const sampleIndex = trackersSamples.findIndex(t => {
            return ObjectUtils.areObjectsEqual(props, t.commonProps);
        });
        if (sampleIndex > -1) {
            const trackerLine = trackersLines[sampleIndex].map(l => l.clone().applyMatrix4(inst.worldMatrix));
            
            trackersPositions.push({
                id,
                props: inst.type_identifier === "any-tracker" ? inst.props as AnyTrackerProps : undefined,
                worldMatrix: inst.worldMatrix,
                typeIdentifier: inst.type_identifier,
                trackerLine: {
                    beg: [KrMath.roundTo(trackerLine[0].x, 0.0001), KrMath.roundTo(trackerLine[0].y, 0.0001)],
                    end: [KrMath.roundTo(trackerLine[1].x, 0.0001), KrMath.roundTo(trackerLine[1].y, 0.0001)],
                    sample: sampleIndex
                }
            });
        } else {
            const trackerSample = createTrackerSample(props, inst, input.gridSize);
            trackersSamples.push({
                commonProps: props,
                sample: trackerSample.sample,
            });

            trackersLines.push(trackerSample.trackerLine);
            const trackerLine = trackerSample.trackerLine.map(l => l.clone().applyMatrix4(inst.worldMatrix));
            
            trackersPositions.push({
                id,
                props: inst.type_identifier === "any-tracker" ? inst.props as AnyTrackerProps : undefined,
                worldMatrix: inst.worldMatrix,
                typeIdentifier: inst.type_identifier,
                trackerLine: {
                    beg: [KrMath.roundTo(trackerLine[0].x, 0.0001), KrMath.roundTo(trackerLine[0].y, 0.0001)],
                    end: [KrMath.roundTo(trackerLine[1].x, 0.0001), KrMath.roundTo(trackerLine[1].y, 0.0001)],
                    sample: trackersSamples.length - 1
                }
            });
        }
    }

    return {
        trackersSamples,
        trackersPositions
    }
}


export function getSolarTrackerProps(
    tracker: Readonly<SceneInstance> | Readonly<SceneInstanceSerializable>
): { typeIdentifier: string, pilesCount: number } & TrackerBuilderInfo {
    if (tracker.type_identifier === "any-tracker") {
        const props = tracker.props as AnyTrackerProps;
        return {
            typeIdentifier: tracker.type_identifier,
            pilesCount: props.piles.piles_count.value,
            moduleSize: props.module.width.as('m'),
            modulesPerStringCountHorizontal: props.tracker_frame.string.modules_count_x.value,
            stringsPerTrackerCount: props.tracker_frame.dimensions.strings_count.value,
            moduleBayCount: -1,
            pileGap: props.tracker_frame.dimensions.pile_bearings_gap.as('m'),
            stringGap: -1,
            modulesGap: props.tracker_frame.dimensions.modules_gap_y.as('m'),
            motorPlacementCoefficient: -1,
            motorGap: props.tracker_frame.dimensions.motor_gap.as('m'),
            modulesRow: "",
            useModulesRow: false,
        };
    } else {
        return {
            typeIdentifier: tracker.type_identifier,
            pilesCount: tracker.properties.get("piles | count")?.asNumber()!,
            moduleSize: tracker.properties.get("module | width")?.as("m")!,
            modulesPerStringCountHorizontal: tracker.properties
                .get("tracker-frame | string | modules_count_x")
                ?.asNumber()!,
            stringsPerTrackerCount: tracker.properties
                .get("tracker-frame | dimensions | strings_count")
                ?.asNumber()!,
            moduleBayCount: tracker.properties
                .get("tracker-frame | dimensions | module_bay_size")
                ?.asNumber()!,
            pileGap: tracker.properties
                .get("tracker-frame | dimensions | pile_bearings_gap")
                ?.as("m")!,
            stringGap: tracker.properties
                .get("tracker-frame | dimensions | strings_gap")
                ?.as("m")!,
            modulesGap: tracker.properties
                .get("tracker-frame | dimensions | modules_gap")
                ?.as("m")!,
            motorPlacementCoefficient: tracker.properties
                .get("tracker-frame | dimensions | motor_placement")
                ?.asNumber()!,
            motorGap: tracker.properties
                .get("tracker-frame | dimensions | motor_gap")
                ?.as("m")!,
            modulesRow: tracker.properties
                .get("tracker-frame | dimensions | modules_row")
                ?.asText()!,
            useModulesRow: tracker.properties
                .get("tracker-frame | dimensions | use_modules_row")
                ?.asBoolean()!,
        };
    }
}


export interface ResetTerrainInput {
    resetByBoundaries: boolean;
    resetTerrain: boolean;
    resetEquipment: boolean;
    terrainIds: IdBimScene[];
    boundaries: { polygon: Vector2[], holes: Vector2[][] }[];
    ids: IdBimScene[];
}


export async function resetTerrain(args: {
    bim: Bim;
    tasksRunner: TasksRunner;
    uiBindings: UiBindings;
    input: ResetTerrainInput;
}) {
    const TIMEOUT = 300_000;
    const logger = new ScopedLogger('cut&fill service');

    function* resetFn() {
        if (args.input.resetByBoundaries) {
            yield* resetTerrainByBoundaries({ ...args, logger });
        } else {
            yield* resetAllTerrainsToInitial({ ...args, logger });
        }
    }

    try {
        const task = args.tasksRunner.newLongTask<any>({
            defaultGenerator: resetFn(),
            taskTimeoutMs: TIMEOUT,
        });

        args.uiBindings.addNotification(
            NotificationDescription.newWithTask({
                source: notificationSource,
                key: 'resetTerrain',
                taskDescription: { task },
                type: NotificationType.Info,
                addToNotificationsLog: true
            })
        );

    await task.asPromise();
    } catch (e) {
        console.error(e);
    }

}


function* resetAllTerrainsToInitial(args: {
    bim: Bim;
    tasksRunner: TasksRunner;
    uiBindings: UiBindings;
    logger: ScopedLogger;
    input: ResetTerrainInput;
}){
    const bimPatch = new BimPatch();
    const wmPatch = new Map<IdBimScene, Matrix4>();
    if(args.input.resetEquipment){
        resetTrackers(args.bim, args.input.ids, bimPatch, wmPatch);
        yield Yield.Asap;
        resetSceneInstances(args.bim, args.input.ids, bimPatch, wmPatch);
        yield Yield.Asap;
    }

    if(args.input.resetTerrain){
        const allTerrains = args.bim.instances.peekByIds(args.input.terrainIds);
        for (const [id, instance] of allTerrains) {
            if (!(instance.representation instanceof TerrainHeightMapRepresentation)) {
                args.logger.error(`unexpected terrain representation ty`, instance.representation);
                continue;
            }
            const newTiles = new Map<TerrainTileId, TerrainTile>(instance.representation.tiles);
            for (const [tileId, tile] of instance.representation.tiles) {
                newTiles.set(tileId, new TerrainTile(tile.initialGeo, 0));
            }
            const newRepr = new TerrainHeightMapRepresentation(
                instance.representation.tileSize,
                newTiles,
            );
            bimPatch.instances.toPatch.push([id, { representation: newRepr}]);
    
            yield Yield.Asap;
        }
    }
    bimPatch.applyTo(args.bim);
    args.bim.instances.patchWorldMatrices(wmPatch);
}


export function resetTrackers(bim: Bim, selectedSceneInstances: IdBimScene[], bimPatch: BimPatch, wmPatch: Map<IdBimScene, Matrix4>) {

    const goemetriesAabbs = bim.allBimGeometries.aabbs.poll();
    const reprsBboxes = new DefaultMapObjectKey<RepresentationBase, Aabb>({
        valuesFactory: (r) => r.aabb(goemetriesAabbs),
        unique_hash: (r) => r.asString(),
    });
    const allIdsSet = new Set(selectedSceneInstances);
    const allTrackers = bim.instances.peekByTypeIdents(['any-tracker', TrackerTypeIdent, FixedTiltTypeIdent]);
    for (const [id, instance] of allTrackers) {
        if(!allIdsSet.has(id)){
            continue;
        }
        if (!instance.representation) {
            continue;
        }

        const reprAabb = reprsBboxes.getOrCreate(instance.representation);
        if (reprAabb.isEmpty()) {
            continue;
        }

        const position = new Vector3();
        const scale = new Vector3();
        const rotation = new Quaternion();
        instance.worldMatrix.decompose(position, rotation, scale);
        rotation.x = 0;
        rotation.y = 0;
        position.z = 0;
        const newMatrix = new Matrix4().compose(position, rotation, scale);
        wmPatch.set(id, newMatrix);

        if (instance.type_identifier !== 'any-tracker') {
            const segmentsSlopes = instance.properties.get('position | _segments-slopes');
            if (segmentsSlopes) {
                const properties: PropertiesPatch = [];

                const slopes = segmentsSlopes.asText().split(",");
                const newValue = '';
                for (let i = 0; i < slopes.length; ++i) {
                    newValue.concat("0%N");
                    if (i < slopes.length - 1) {
                        newValue.concat(", ");
                    }
                }
                properties.push([
                    'position | _segments-slopes',
                    {
                        path: ['position', '_segments-slopes'],
                        value: newValue,
                    }
                ]);

                bimPatch.instances.toPatch.push([id, { properties }]);
            }
        } else {
            const props = instance.props as AnyTrackerProps;
            const segmentsSlopes = props.piles._segments_slopes;
            if (segmentsSlopes) {
                const patch = producePropsPatch(props, (props) => {
                    props.piles._segments_slopes = null;
                });

                bimPatch.instances.toPatch.push([id, { props: patch! }]);
            }
        }
    }
}

function* resetTerrainByBoundaries(args: {
    bim: Bim;
    logger: ScopedLogger;
    input: ResetTerrainInput;
}) {
    const bimPatch = new BimPatch();
    const wmPatch = new Map<IdBimScene, Matrix4>();
    if (args.input.resetEquipment){
        resetTrackers(args.bim, args.input.ids, bimPatch, wmPatch);
        resetSceneInstances(args.bim, args.input.ids, bimPatch, wmPatch);
    }

    if (args.input.resetTerrain){      
        const boundaries = IterUtils.filterMap(
            args.input.boundaries,
            (contour) => {
                return {
                    contour: contour,
                    aabb2: Aabb2.empty().setFromPoints(contour.polygon),
                };
            }
        );
    
        if(boundaries.length == 0){
            args.logger.warn('boundaries not found with ids: ', Array.from(args.input.boundaries));
            return;
        }
    
        const terrainInstances = args.bim.instances.peekByIds(args.input.terrainIds);
        if (!terrainInstances.size) {
            args.logger.warn('no terrain heightmaps found');
            return;
        }
    
        const geosAabbs = args.bim.allBimGeometries.aabbs.poll();
    
        const point3d = new Vector3();
        const point2d = new Vector2();
        for (const [instanceId, instance] of terrainInstances) {
            if (!(instance.representation instanceof TerrainHeightMapRepresentation)) {
                args.logger.error(`unexpected terrain representation ty`, instance.representation);
                continue;
            }
    
            const terrainAabb = instance.representation.aabb(geosAabbs);
            terrainAabb.applyMatrix4(instance.worldMatrix);
    
            const terrainAabb2 = terrainAabb.xy();
    
            const boundariesForTerrainInstance = boundaries.filter(b => b.aabb2.intersectsBox2(terrainAabb2));
    
            if (boundariesForTerrainInstance.length === 0) {
                continue;
            }
            const newTiles = new Map<TerrainTileId, TerrainTile>(instance.representation.tiles);
    
            for (const [tileId, tile] of instance.representation.tiles) {
                const geometries = args.bim.allBimGeometries.getCollectinoOfId(tile.initialGeo);
                const updatedTileGeo = geometries.peekById(tile.updatedGeo);
                if(!updatedTileGeo){
                    continue;
                }
    
                const initTileGeo = geometries.peekById(tile.initialGeo);
                if (!initTileGeo) {
                    args.logger.error('tile geometry absent', tile);
                    continue;
                }
    
                const tileAabb = geosAabbs.get(tile.initialGeo)!.clone();
                if (initTileGeo instanceof RegularHeightmapGeometry) {
                    tileId.offsetAabb(tileAabb, instance.representation.tileSize);
                }
                tileAabb.applyMatrix4(instance.worldMatrix);
    
                const tileAabb2 = tileAabb.xy();
    
                const includePolygons: Vector2[][] = [];
                //const excludePolygons: Vector2[][] = [];
                boundariesForTerrainInstance.filter(
                    b => b.aabb2.intersectsBox2(tileAabb2)
                ).map(
                    b => { 
                        includePolygons.push(b.contour.polygon);
                        //IterUtils.extendArray(excludePolygons, b.contour.holes);
                    }
                );
    
                if (includePolygons.length === 0) {
                    continue;
                }

                const checker = new PointsInPolygonChecker(Clipper.offsetPolygons2D(includePolygons, 18), []);
    
                let updatedGeometry: IrregularHeightmapGeometry | RegularHeightmapGeometry;
                if (initTileGeo instanceof IrregularHeightmapGeometry && updatedTileGeo instanceof IrregularHeightmapGeometry) {
    
                    const initGeoPoints = Vector3.arrayFromFlatArray(initTileGeo.points3d);
                    const updatedGeoPoints = Vector3.arrayFromFlatArray(updatedTileGeo.points3d);
    
                    const newTerrainPoints: Vector3[] = [];
    
                    for (const p of updatedGeoPoints) {
                        point3d.copy(p).applyMatrix4(instance.worldMatrix);
                        if(!checker.isPointInside(point2d.set(point3d.x, point3d.y))){
                            newTerrainPoints.push(p);
                        }
                    }
                    for (const p of initGeoPoints) {
                        point3d.copy(p).applyMatrix4(instance.worldMatrix);
                        if(checker.isPointInside(point2d.set(point3d.x, point3d.y))){
                            newTerrainPoints.push(p);
                        }
                    }
                    newTerrainPoints.sort((p1, p2) =>
                        KrMath.zOrder(p1.x, p1.y, tileAabb2)
                        - KrMath.zOrder(p2.x, p2.y, tileAabb2)
                    );
                    const points2dFlat = new Float64Array(newTerrainPoints.length * 2);
                    for (let i = 0; i < newTerrainPoints.length; ++i) {
                        const p = newTerrainPoints[i];
                        points2dFlat[i * 2 + 0] = p.x;
                        points2dFlat[i * 2 + 1] = p.y;
                    }
    
                    const del = new Delaunator(points2dFlat);
    
                    updatedGeometry = new IrregularHeightmapGeometry(
                        new Float64Array(Vector3.arrToArr(newTerrainPoints)),
                        new Uint32Array(del.triangles),
                    );
                } else if (initTileGeo instanceof RegularHeightmapGeometry && updatedTileGeo instanceof RegularHeightmapGeometry) {
                    if( initTileGeo.xSegmentsCount !== updatedTileGeo.xSegmentsCount
                        || initTileGeo.ySegmentsCount !== updatedTileGeo.ySegmentsCount
                        || initTileGeo.segmentSizeInMeters !== updatedTileGeo.segmentSizeInMeters){
                        console.error("initial geometry not match with updated geometry", [initTileGeo, updatedTileGeo]);
                        throw new Error("initial geometry not match with updated geometry");
                    }
    
                    const tileOffset = tileId.localOffset(instance.representation.tileSize);
    
                    const updatedHeights = updatedTileGeo.elevationsAsFloats();
                    const xCount = initTileGeo.xSegmentsCount + 1;
                    for (let iy = 0; iy <= initTileGeo.ySegmentsCount; ++iy) {
                        for (let ix = 0; ix <= initTileGeo.xSegmentsCount; ++ix) {
                            const elevation = initTileGeo.readElevationAtInds(ix, iy);
                            if (Number.isNaN(elevation)) {
                                continue;
                            }
                            
                            point3d.set(
                                ix * initTileGeo.segmentSizeInMeters + tileOffset.x,
                                iy * initTileGeo.segmentSizeInMeters  + tileOffset.y,
                                elevation
                            ).applyMatrix4(instance.worldMatrix);
                            
                            if(checker.isPointInside(point2d.set(point3d.x, point3d.y))){
                                updatedHeights[ix + iy * xCount] = elevation;
                            }
                        }
                    }
    
                    const updatedGeometryRes = RegularHeightmapGeometry.newFromMetersAndNaNs(
                        initTileGeo.xSegmentsCount,
                        initTileGeo.ySegmentsCount,
                        initTileGeo.segmentSizeInMeters,
                        updatedHeights
                    );
                    if (!(updatedGeometryRes instanceof Success)) {
                        args.logger.error(`could not create geometry`, updatedGeometryRes.errorMsg());
                        continue;
                    }
                    updatedGeometry = updatedGeometryRes.value;
                } else {
                    args.logger.error(`unexpected geometry type`, [initTileGeo, updatedTileGeo]);
                    continue;
                }
    
                const newGeoId = geometries.reserveNewId();
                bimPatch.geometries.toAlloc.push([newGeoId, updatedGeometry]);
                newTiles.set(tileId, new TerrainTile(tile.initialGeo, newGeoId));
    
                yield Yield.Asap;
            }
    
    
            const newRepr = new TerrainHeightMapRepresentation(
                instance.representation.tileSize,
                newTiles,
            );
            bimPatch.instances.toPatch.push([instanceId, {representation: newRepr}]);
        }
    }

    bimPatch.applyTo(args.bim);
    args.bim.instances.patchWorldMatrices(wmPatch);
}

function resetSceneInstances(bim: Bim, ids: IdBimScene[], bimPatch: BimPatch, wmPatch: Map<IdBimScene, Matrix4>) {
    const instances = bim.instances.peekByIds(ids);

    for (const [id, inst] of instances) {
        if(trackerTypes.includes(inst.type_identifier) || inst.type_identifier === BoundaryTypeIdent){
            continue;
        }
        const position = new Vector3();
        const scale = new Vector3();
        const rotation = new Quaternion();
        inst.worldMatrix.decompose(position, rotation, scale);
        position.z = 0;
        const newMatrix = new Matrix4().compose(position, rotation, scale);
        wmPatch.set(id, newMatrix);
        if(!inst.representationAnalytical){
            continue;
        }
        const geomId = inst.representationAnalytical?.geometryId ?? 0;
        const geom = bim.allBimGeometries.peekById(geomId);
        if(!geom){
            continue;
        }
        if(geom instanceof PolylineGeometry && geom.points3d.length > 0){
            const points = Vector3.arrayFromFlatArray(geom.points3d);
            points.forEach(p => p.z = 0);
            const newGeom = PolylineGeometry.newWithAutoIds(points, geom.radius);
            bimPatch.geometries.toPatch.push([geomId, newGeom]);
        } else if(geom instanceof GraphGeometry && geom.points.size > 0){
            const points = new Map<IdInEntityLocal, Vector3>();
            for (const [idLocal, point] of geom.points) {
                const newPoint = point.clone();
                newPoint.z = 0;
                points.set(idLocal, newPoint);
            }
            
            const newGeom = new GraphGeometry(points, geom.edges);
            bimPatch.geometries.toPatch.push([geomId, newGeom]);
        }       
    }
}
