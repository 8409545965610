import { Immer, IterUtils, RGBA, RGBAHex, ScopedLogger, Success, ValueAndUnit, Yield } from "engine-utils-ts";
import jsPDF, { GState } from "jspdf";
import { Matrix4, Vector2 } from "math-ts";
import { PageFrameOffsets, PdfElement, PdfReportContext } from "../PdfReportBuilder";
import { convertPxToMm } from "../PdfCommon";
import { AreaTypeEnum, Bim, ColorProperty, CutFillConfig, CutFillConfigType, IdBimScene, MetricsGroup, NumberProperty, NumberRangeProperty, ProjectMetricsType, TerrainAnalysisConfig, TerrainAnalysisTypeIdent, TerrainGeoVersionSelector, TerrainInstanceTypeIdent, TerrainMetrics, TerrainMetricsRanges, TerrainMetricsType, TerrainPaletteSlice, UnitsMapper } from "bim-ts";
import { TerrainDisplayMode } from "engine-ts";
import { setTextStyle, TextBuilder } from "../TextBuilder";
import { drawLine, printTableHeader } from "./BlocksSchedulePage";

    
const cutFillPalette = [
    RGBA.parseFromHexString("#CD0405"),
    RGBA.parseFromHexString("#D42A29"),
    RGBA.parseFromHexString("#D42B2B"),
    RGBA.parseFromHexString("#E37676"),
    RGBA.newRGB(255, 255, 255),
    RGBA.parseFromHexString("#7D7DF6"),
    RGBA.parseFromHexString("#5050ED"),
    RGBA.parseFromHexString("#2B2BE9"),
    RGBA.parseFromHexString("#0606E5"),
];

interface CutFillTableHeader {
    description: string | string[];
    widthPx: number,
    key: keyof CutFillRangeProps;
    unit?: string;
}

interface CutFillRangeProps {
    index: NumberProperty;
    color: ColorProperty;
    min: NumberProperty | null;
    max: NumberProperty | null;
    area?: NumberProperty;
    areaShare?: NumberProperty;
    volume?: NumberProperty;
    volumeShare?: NumberProperty;
}

export class CutFillScreenshot extends PdfElement {
    constructor(
        readonly bim: Bim, 
        readonly logger: ScopedLogger
    ){
        super();
    }

    *draw({page, screenshotMaker, pageFrameOffsets}: PdfReportContext): Generator<Yield, void, unknown> {
        const {slices, metrics} = yield* createCutFillPallette(this.bim, this.logger);
        this.logger.info("Cut fill palette created", {slices, metrics});
        const imageResult = yield* screenshotMaker.makeScreenshotOf({
            ids: this.bim.instances.peekByTypeIdent('terrain-heightmap').map(x => x[0]),
            showAnnotations: false,
            showGizmo: false,
            showClickbox: false,
            terrainDisplaySettings: {
                terrainVersion: TerrainGeoVersionSelector.Latest,
                mode: TerrainDisplayMode.CutFill,
            },
            colorize: (bim, ids, event) => {
                const worldMatrixPatches = new Map<IdBimScene, Matrix4>();
                const instances = bim.instances.peekByIds(ids);
                for (const [id, inst] of instances) {
                    const mtrx = new Matrix4().copy(inst.worldMatrix);
                    const pos = mtrx.extractPosition();
                    mtrx.setPosition(pos.x, pos.y, Math.abs(metrics.fullRange.min) + Math.abs(metrics.fullRange.max) + 1);
                    worldMatrixPatches.set(id, mtrx);
                }
                bim.instances.patchWorldMatrices(worldMatrixPatches, event);

                const config = bim.configs.peekSingleton(TerrainAnalysisTypeIdent)?.get<TerrainAnalysisConfig>();
                if(!config){
                    return;
                }
                const updatedConfig = Immer.produce(config, draft => {
                    draft.cut_fill_palette.slices = slices;
                });
                bim.configs.applyPatchToSingleton(TerrainAnalysisTypeIdent, {properties: updatedConfig}, event);
            }
        });
        yield Yield.Asap;
        if(imageResult instanceof Success){ 
            addImageOnPageByTemplate1({
                header: "Cut fill map",
                page,
                image: new Uint8Array(imageResult.value), 
                offsets: pageFrameOffsets,
            });
        } else {
            this.logger.error("Failed to make screenshot of cut fill");
        }
    }
}

function addImageOnPageByTemplate1({header, page, image, offsets}: {
    header?: string,
    page:jsPDF, 
    image: Uint8Array, 
    offsets: PageFrameOffsets
}){
    const fontSize = 15;
    const headerOffsetPx = header ? fontSize : 0;
    const imageInfo = page.getImageProperties(image);
    const imageWidth = imageInfo.width;
    const imageHeight = imageInfo.height;
    const pageWidth = page.internal.pageSize.getWidth();
    const pageHeight = page.internal.pageSize.getHeight();
    const downAdditionOffsetPx = 30 + headerOffsetPx;
    const rightPartWidthRation = 1 - offsets.leftPartContentWidthRatio;

    const maxHeight = pageHeight 
        - convertPxToMm(offsets.upOffsetPx) 
        - convertPxToMm(offsets.downOffsetPx)
        - convertPxToMm(downAdditionOffsetPx);
    const maxWidth = pageWidth * rightPartWidthRation 
        - convertPxToMm(offsets.leftRightOffsetPx);
    
    const imagedToPageScale = Math.min(
        maxWidth / imageWidth,
        maxHeight / imageHeight
    );


    const imageDrawSize = new Vector2(
        imageWidth,
        imageHeight
    ).multiplyScalar(imagedToPageScale);
    const drawingCenter = new Vector2(
        pageWidth * offsets.leftPartContentWidthRatio + maxWidth * 0.5,
        convertPxToMm(offsets.upOffsetPx) + convertPxToMm(offsets.topContentOffsetPx) + convertPxToMm(headerOffsetPx) + imageDrawSize.y * 0.5
    );

    const pos = new Vector2(
        drawingCenter.x - imageDrawSize.x * 0.5,
        drawingCenter.y - imageDrawSize.y * 0.5,
    );
    page.addImage(image, "PNG", pos.x, pos.y, imageDrawSize.x, imageDrawSize.y);

    if(header){
        const headerOffsetPx = 15;
        const upOffsetPx = offsets.upOffsetPx + fontSize + headerOffsetPx;
        setTextStyle(page, "bold", fontSize);
        page.text(header, pos.x, convertPxToMm(upOffsetPx));
    }
}

export class CutFillMetrics extends PdfElement {
    constructor(
        readonly bim: Bim, 
        readonly metrics: MetricsGroup<Partial<ProjectMetricsType>>[], 
        readonly logger: ScopedLogger
    ){
        super();
    }

    *draw({page, pageFrameOffsets}: Readonly<PdfReportContext>): Generator<Yield, void, unknown> {
        const totalMetrics = this.metrics.find((x) => x.type === AreaTypeEnum.Total);
        if (!totalMetrics) {
            return;
        }

        const textBuilder = new TextBuilder(
            this.bim.unitsMapper,
            page, 
            pageFrameOffsets.upOffsetPx, 
            pageFrameOffsets.leftRightOffsetPx,
            totalMetrics.metrics,
            this.logger,
            convertPxToMm
        );

        const config = this.bim.configs.peekSingleton(CutFillConfigType)?.get<CutFillConfig>();
        if(!config){
            this.logger.error("Terrain analysis config not found");
            return;
        }
        textBuilder.addRow({text: "Targets", headerLevel: 1, values: [], style: 'bold'});
        textBuilder.addFromNumberProp("North terrain slope", config.cut_fill_limits.north_slope);
        textBuilder.addFromNumberProp("South terrain slope", config.cut_fill_limits.south_slope);
        textBuilder.addFromNumberProp("E-W terrain slope", config.cut_fill_limits.east_west_slope);
        if(config.cut_fill_limits.net_balance.selectedOption === "auto"){
            textBuilder.addRow({text: "Net volume balance target", values: [{value: "Auto"}]});
        } else {
            textBuilder.addFromNumberProp("Net volume balance target", config.cut_fill_limits.net_balance);
        }
        const gradientWindow = NumberRangeProperty.new({
            value: [
                config.cut_fill_limits.tolerance.value,
                config.cut_fill_limits.tolerance.value * 2,
            ],
            unit: config.cut_fill_limits.tolerance.unit,
        });
        textBuilder.addFromNumberRangeProp("Gradient window", gradientWindow);
        textBuilder.addOffset();
        textBuilder.addOffset();
        textBuilder.addOffset();
        function formatValuePercent(value: number, postfix: string){
            return {value: `${value.toFixed(2)}`, unit: `%, ${postfix}`};
        }
        const postFix = "max";
        textBuilder.addRow({text: "Solar arrays slope along axis", values: [formatValuePercent(config.solar_arrays_limits.slope_along_axis.value, postFix)]});
        textBuilder.addRow({text: "Slope change, Bay to bay", values: [formatValuePercent(config.solar_arrays_limits.slope_change_bay_to_bay.value, postFix)]});
        textBuilder.addRow({text: "Slope change, Cumulative", values: [formatValuePercent(config.solar_arrays_limits.cumulative.value, postFix)]});


        textBuilder.addOffset();
        textBuilder.addOffset();
        textBuilder.addOffset();

        this.drawCutFillResultDiagram(page, pageFrameOffsets, textBuilder);
        textBuilder.addCustomOffset(150);

        textBuilder.addRow({text: "Results breakdown by depth", headerLevel: 1, style: 'bold', values: []});
        yield* this.drawCutFillTable(page, textBuilder);
    }

    private *drawCutFillTable(page: jsPDF, textBuilder: TextBuilder){
        const headers: CutFillTableHeader[] = [
            {description: "", widthPx: 40, key: 'color'},
            {description: "", widthPx: 40, key: 'index'},
            {description: "Min", widthPx: 55, key: 'min', unit: this.bim.unitsMapper.mapToConfigured({value: 1, unit: 'm'}).unit},
            {description: "Max", widthPx: 55, key: 'max', unit: this.bim.unitsMapper.mapToConfigured({value: 1, unit: 'm'}).unit},
            {description: "Area", widthPx: 80, key: 'area', unit: this.bim.unitsMapper.mapToConfigured({value: 1, unit: 'm2'}).unit},
            {description: "", widthPx: 55, key: 'areaShare', unit: "%"},
            {description: "Volume", widthPx: 80, key: 'volume', unit: this.bim.unitsMapper.mapToConfigured({value: 1, unit: 'm3'}).unit},
            {description: "", widthPx: 55, key: 'volumeShare', unit: "%"},
        ];

        const fontSize = 10;
        const upOffsetPx = 10;
        const contentRightOffsetPx = printTableHeader(textBuilder.leftOffsetPx, textBuilder.offsetYPx  + upOffsetPx, fontSize, headers,page);
        const { tableRows } = yield* createCutFillPallette(this.bim, this.logger);
        const headerHeightPx = 33;
        let offsetYPx = textBuilder.offsetYPx + headerHeightPx + upOffsetPx;
        function drawRowText(row: CutFillRangeProps, rowHeightPx: number, isTotal?: boolean){
            const fontSize = 10;
            offsetYPx += rowHeightPx;
            const style = isTotal ? 'bold' : 'normal';
            setTextStyle(page, style, fontSize);
            const textHeight = page.getTextDimensions("1").h;
            const localOffsetY = convertPxToMm(offsetYPx) - (convertPxToMm(rowHeightPx) - textHeight) * 0.5
            let offsetXPx = textBuilder.leftOffsetPx;
            for (const header of headers) {
                const prop = row[header.key];
                offsetXPx += header.widthPx;
                let valueStr = '';
                if(prop instanceof NumberProperty){
                    const digits = header.unit ? 2 : 0;
                    const configured = header.unit ? prop.as(header.unit) : prop.value;
                    valueStr = configured.toFixed(digits);
                } else if(prop instanceof ColorProperty){
                    const [r, g, b] = RGBA.toRgbArray(prop.value);
                    page.setFillColor(r, g, b);
                    const upBotOffsetPx = 5;
                    const height = convertPxToMm(rowHeightPx - upBotOffsetPx*2);
                    const width = convertPxToMm(header.widthPx-8);
                    const round = convertPxToMm(1);
                    const compensationY = (height - textHeight) * 0.5;
                    page.roundedRect(
                        convertPxToMm(offsetXPx - header.widthPx), 
                        localOffsetY - (height) + compensationY, 
                        width, 
                        height,
                        round,
                        round,
                        'F'
                    );
                    
                } else if(prop === null){
                    valueStr = '—';
                }
                
                const textWidth = page.getTextWidth(valueStr);
                page.text(valueStr, convertPxToMm(offsetXPx - contentRightOffsetPx) - textWidth, localOffsetY);
            }
        }
        const rowHeightPx = 22;
        const lineLengthPx = IterUtils.sum(headers, x => x.widthPx);
        for (const row of tableRows) {
            drawLine(page, textBuilder.leftOffsetPx, offsetYPx, lineLengthPx);
            drawRowText(row, rowHeightPx);
        }
    }

    private drawCutFillResultDiagram(
        page: jsPDF, 
        pageFrameOffsets: PageFrameOffsets, 
        textBuilder: TextBuilder,
    ){
        const totalMetrics = this.metrics.find((x) => x.type === AreaTypeEnum.Total);
        if (!totalMetrics) {
            return;
        }
        textBuilder.addRow({text: "Results", headerLevel: 1, values: [], style: 'bold'});

        let offsetYPx = textBuilder.offsetYPx;
        const cut_fill_total = totalMetrics.metrics?.cut_fill_total?.as('m3') ?? 0;
        const cut = totalMetrics.metrics?.cut?.as('m3') ?? 0;
        const fill = totalMetrics.metrics?.fill?.as('m3') ?? 0;
        const net_balance = totalMetrics.metrics?.cut_fill_net_balance?.as('m3') ?? 0;
        const terrain_area_ha = IterUtils.sum(this.bim.instances.peekByTypeIdent('terrain-heightmap'), ([, inst]) => inst.properties.get("metrics | area_latest")?.as('ha') ?? 0);
        const cut_area_ha = totalMetrics.metrics?.cut_area?.as('ha') ?? 0;
        const fill_area_ha = totalMetrics.metrics?.fill_area?.as('ha') ?? 0;

        const pageWidth = page.internal.pageSize.getWidth();
        const leftOffset = convertPxToMm(pageFrameOffsets.leftRightOffsetPx);
        const totalWidthDiagram = pageWidth * pageFrameOffsets.leftPartContentWidthRatio - convertPxToMm(pageFrameOffsets.leftRightOffsetPx);
        const cutColor = "#CC0505";
        const fillColor = "#2828E9";
        const defaultColor = "#000000";
        function drawPercentLine(left: string, mid: string, right: string){
            const offsetY = convertPxToMm(offsetYPx);
            setTextStyle(page, 'bold', 10);
            page.setTextColor(cutColor);
            page.text(left, leftOffset, offsetY);
            page.setTextColor(defaultColor);
            const midWidth = page.getTextWidth(mid);
            page.text(mid, leftOffset + totalWidthDiagram / 2 - midWidth / 2, offsetY);
            page.setTextColor(fillColor);
            const rightWidth = page.getTextWidth(right);
            page.text(right, leftOffset + totalWidthDiagram - rightWidth, offsetY);
        }

        function drawResultsLine(left: ValueAndUnit, mid: ValueAndUnit, right: ValueAndUnit){
            page.setTextColor(defaultColor);
            const fontSize = 13;
            const offsetY = convertPxToMm(offsetYPx + 5);
            textBuilder.drawValueUnit(left, leftOffset, offsetY, fontSize);
            textBuilder.drawValueUnit(mid, leftOffset + totalWidthDiagram / 2, offsetY, fontSize, "center");
            textBuilder.drawValueUnit(right, leftOffset + totalWidthDiagram, offsetY, fontSize, "right");
        }

        function drawCutFillVolumeBar(x: number, y: number, cut: number, fill: number){
            const height = convertPxToMm(28);
            page.setFillColor(cutColor);
            const cutWidth = cut / (cut + fill) * totalWidthDiagram;
            page.rect(x, y, cutWidth, height, "F");
            page.setFillColor(fillColor);
            page.rect(x + cutWidth, y, totalWidthDiagram - cutWidth, height, "F");
            page.setFillColor(255, 255, 255);
            page.setGState(new GState({opacity: 0.56}));
            const widthLine = convertPxToMm(1);
            const offset = convertPxToMm(2);
            page.rect(x + totalWidthDiagram / 2 - widthLine / 2, y + offset, widthLine, height - offset * 2, "F");
            page.setGState(new GState({opacity: 1}));
        }

        function drawCutFillAreaBar(x: number, y: number, cut: number, fill: number, total: number){
            const height = convertPxToMm(16);
            page.setFillColor("#E67979");
            const cutWidth = cut / total * totalWidthDiagram;
            page.rect(x, y, cutWidth, height, "F");

            page.setFillColor("#103A52");
            page.setGState(new GState({opacity: 0.08}));
            const undDisturbedWidth = (total - cut - fill) / total * totalWidthDiagram;
            page.rect(x + cutWidth, y, undDisturbedWidth, height, "F");

            page.setFillColor("#1A1E1F");
            page.setGState(new GState({opacity: 0.12}));
            const heightSubLine = convertPxToMm(3);
            page.rect(x + cutWidth, y + height - heightSubLine, undDisturbedWidth, heightSubLine, "F");
            page.setGState(new GState({opacity: 1}));
            
            page.setFillColor("#7575F1");
            const fillWidth = fill / total * totalWidthDiagram;
            page.rect(x + cutWidth + undDisturbedWidth, y, fillWidth, height, "F");
        }
        offsetYPx += 20;
        const cutVolume = `${(cut/cut_fill_total * 100).toFixed(2)} % Cut volume`;
        const netVolume = `Net balance`;
        const fillVolume = `${(fill/cut_fill_total * 100).toFixed(2)} % Fill volume`;
        drawPercentLine(cutVolume, netVolume, fillVolume);
        offsetYPx += 20;
        drawResultsLine({value: cut, unit: "m3"}, {value: net_balance, unit: "m3"}, {value: fill, unit: "m3"});
        offsetYPx += 17;
        drawCutFillAreaBar(leftOffset, convertPxToMm(offsetYPx + 24), cut_area_ha, fill_area_ha, terrain_area_ha);
        drawCutFillVolumeBar(leftOffset, convertPxToMm(offsetYPx), cut, fill);
        offsetYPx += 62;
        const cut_area_percent = cut_area_ha / terrain_area_ha * 100
        const cut_area_str = `${cut_area_percent.toFixed(2)} % Cut disturbed area`;
        const fill_area_percent = fill_area_ha / terrain_area_ha * 100;
        const fill_area_str = `${fill_area_percent.toFixed(2)} % Fill disturbed area`;
        const undDisturbed_area_str = `${(100 - cut_area_percent - fill_area_percent).toFixed(2)} % Undisturbed area`;
        drawPercentLine(cut_area_str, undDisturbed_area_str, fill_area_str);
        offsetYPx += 20;
        drawResultsLine({value: cut_area_ha, unit: "ha"}, {value: terrain_area_ha, unit: "ha"}, {value: fill_area_ha, unit: "ha"});
    }
}


function* createCutFillPallette(bim: Bim, logger: ScopedLogger){
    const unit = 'm';
    const configuredUnit = bim.unitsMapper.mapToConfigured({value: 1, unit}).unit!;
    const terrains = bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent);
    const defaultPallete = new TerrainMetricsRanges(TerrainMetricsType.CutFill, unit,  []);
    const defaultMetrics = yield* TerrainMetrics.calcuateBimObjsMetricsForCutfill(logger, defaultPallete, bim, terrains);

    const maxRangeFromOneSide = 4;
    const step = Math.max(Math.abs(defaultMetrics.fullRange.min), Math.abs(defaultMetrics.fullRange.max)) / maxRangeFromOneSide;

    const midValue = bim.unitsMapper.converter.convertValue(0.01, configuredUnit, unit);
    const ranges = [-midValue, midValue];
    let prevValue = -midValue;
    while (defaultMetrics.fullRange.min < prevValue) {
        prevValue -= step;
        if(prevValue < defaultMetrics.fullRange.min){
            ranges.unshift(defaultMetrics.fullRange.min);
        } else {
            ranges.unshift(prevValue);
        }
    }
    const middleIdx = ranges.length - 2;

    prevValue = midValue;
    while (defaultMetrics.fullRange.max > prevValue) {
        prevValue += step;
        if(prevValue > defaultMetrics.fullRange.max){
            ranges.push(defaultMetrics.fullRange.max);
        } else {
            ranges.push(prevValue);
        }
    }

    const colorOffset = Math.floor(cutFillPalette.length / 2) - middleIdx;
    const slices: TerrainPaletteSlice[] = [];
    for (let i = 0; i < ranges.length - 1; i++) {
        const min = ranges[i];
        const max = ranges[i + 1];
        slices.push({
            min: NumberProperty.new({ value: min, unit: unit }),
            max: NumberProperty.new({ value: max, unit: unit }),
            color: ColorProperty.new({ value: cutFillPalette[i + colorOffset] }),
        });
    }

    const metrics = yield* TerrainMetrics.calcuateBimObjsMetricsForCutfill(
        logger, 
        new TerrainMetricsRanges(TerrainMetricsType.CutFill, configuredUnit, ranges), 
        bim, 
        terrains
    );
    const tableRows: CutFillRangeProps[] = [];
    for (let i = 0; i < slices.length; i++) {
        const area = metrics.perSliceArea[i];
        const volume = metrics.perSliceArea[i];
        const areaShare = area && metrics.totalArea ? 100 * area / metrics.totalArea : 0;
        const volumeShare = volume && metrics.totalVolume ? 100 * volume / metrics.totalVolume : 0;
        tableRows.push({
            index: NumberProperty.new({value: i}),
            color: slices[i].color,
            min: slices[i].min,
            max: slices[i].max,
            area: NumberProperty.new({ value: area, unit: "m2" }),
            areaShare: NumberProperty.new({ value: areaShare, unit: "%" }),
            volume: NumberProperty.new({ value: volume, unit: "m3" }),
            volumeShare: NumberProperty.new({ value: volumeShare, unit: "%" }),
        });
    }

    const tableRowsCount = 9;
    if(tableRows.length < tableRowsCount){
        const rowsCount = tableRows.length;
        const emptyRowsCount = tableRowsCount - rowsCount;
        const insertStart = Math.abs(tableRows[0].min?.value ?? 0) < Math.abs(tableRows[tableRows.length - 1].max?.value ?? 0);
        for (let i = 0; i < emptyRowsCount; i++) {
            if(insertStart){
                const color = cutFillPalette[i];
                tableRows.unshift({
                    index: NumberProperty.new({ value: i }),
                    color: ColorProperty.new({ value: color }),
                    min: null,
                    max: null,
                });
            } else {
                const color = cutFillPalette[(rowsCount + i) % cutFillPalette.length];
                tableRows.push({
                    index: NumberProperty.new({value: i}),
                    color: ColorProperty.new({ value: color }),
                    min: null,
                    max: null,
                });
            }
        }
    }

    for (let i = 0; i < tableRows.length; i++) {
        const row = tableRows[i];
        row.index = NumberProperty.new({value: i - Math.floor(tableRows.length / 2)});
    }

    return {slices, metrics, tableRows};
}

