import {
    AreaTypeEnum, Bim, Catalog,
    IdBimScene,
    mergeMetricsToTable,
    PdfExportTemplate, ProjectMetrics,

    TerrainHeightMapRepresentation,

    TransformerIdent,
    URLS
} from 'bim-ts';
import { KreoEngine } from 'engine-ts';
import {
    getResultValueOrThrow, LazyVersioned, RGBA,
    RGBAHex, ScopedLogger, Yield
} from 'engine-utils-ts';
import jsPDF from 'jspdf';
import { Vector2 } from 'math-ts';
import { ExportedFileDescription, FileExporter, FileExporterContext } from 'ui-bindings';

import {
    ProjectInfo,
    VerdataSyncerType
} from '../';
import { tryGetProjectIdFromWindowLocation } from '../CommonExportSettings';
import { BlockSchedulePageTable, DcAcRationDiagram, ScreenshotByDcAcRatio } from './pages/BlocksSchedulePage';
import { LayoutMetricsTable, ScreenshotByTracker } from './pages/LayoutPage';
import { OverviewMetricsTable, ScreenshotByBlock } from './pages/OverveiwPage';
import { ScreenshotBySubarea, SubareasMetricsTable } from './pages/SubareasBreakdown';
import { PageFrame, PageFrameOffsets, PdfReportBuilder } from './PdfReportBuilder';
import { setTextStyle } from './TextBuilder';
import { convertPxToMm, PalettePerType } from './PdfCommon';
import { CutFillMetrics, CutFillScreenshot } from './pages/CutFillPage';

export class PdfExporterSettings {

}

export class PdfExporter implements FileExporter<PdfExporterSettings> {
    private logger: ScopedLogger;
    constructor(
        readonly bim: Bim, 
        readonly catalog: Catalog,
        readonly metrics: ProjectMetrics,
        readonly projectInfo: LazyVersioned<ProjectInfo | undefined>,
        readonly projectVerdataSyncer: VerdataSyncerType,
        readonly catalogVerdataSyncer: VerdataSyncerType,
    ) {
        this.logger = new ScopedLogger('pdf-exporter');
    }

    initialSettings(): ReturnType<
        FileExporter<PdfExporterSettings>["initialSettings"]
    > {
        return {
            defaultValue: new PdfExporterSettings(),
        };
    }

    *startExport(
        settings: PdfExporterSettings,
        context: FileExporterContext
    ): Generator<Yield, ExportedFileDescription[], unknown> {
        yield* this.bim.runUpdatesTillCompletion({forceRun: true});
        yield* this.bim.instances.basicPropsView.waitTillCompletion(10_000);

        const metricsResult = yield* this.metrics.waitTillCompletion();

        const metrics = getResultValueOrThrow(metricsResult, 'Metrics calculation failed');
        const areas = this.metrics.areasContext.poll().areas;
        const table = mergeMetricsToTable(metrics, areas, this.bim.unitsMapper, this.logger, PdfExportTemplate);

        const engine: KreoEngine = context.additionalContext["engine"];
        if (!(engine instanceof KreoEngine)) {
            throw new Error("Engine is not provided");
        }


        const projectId = tryGetProjectIdFromWindowLocation();
        const projectInfo = this.projectInfo.poll();
        const projectName = projectInfo?.name ? projectInfo.name :`Project #${projectId}`;
        const pageFrame = new PageFrame(
            projectName,
            { 
                version: this.projectVerdataSyncer.getCurrentVersionId(), 
                modified: this.projectVerdataSyncer.haveUpdatesForSave()
            },
            { 
                version: this.catalogVerdataSyncer.getCurrentVersionId(), 
                modified: this.catalogVerdataSyncer.haveUpdatesForSave(),
            },
            projectInfo?.company ?? 'Company',
        );

        const trackerPalette = new PalettePerType(defaultColors.slice());
        const subareasPalette = new PalettePerType(subAreasColors.slice());
        const paletteIlr = new Map<IdBimScene, RGBAHex | 0>();

        const builder = new PdfReportBuilder({
            bim: this.bim, 
            engine: engine,
            frame: pageFrame,
            logger: this.logger,
        })
        .addPage({
            name: "Overview",
            format: "a3",
            elements: [
                new OverviewMetricsTable(this.bim, metrics, this.logger),
                new ScreenshotByBlock(this.bim, this.logger),
            ],
            overflow: true,
        })
        .addPage({
            name: "Layout",
            format: "a3",
            elements: [
                new LayoutMetricsTable(this.bim, metrics, trackerPalette, this.logger),
                new ScreenshotByTracker(this.bim, this.catalog, trackerPalette, this.logger),
            ],
            overflow: true,
        })
        .addPage({
            name: "Subareas breakdown",
            format: "a3",
            elements: [
                new SubareasMetricsTable(this.bim, this.logger, table, subareasPalette),
                new ScreenshotBySubarea(this.bim, this.logger, metrics, areas, subareasPalette),
            ],
            overflow: true,
            isEmpty: () => {
                return !this.metrics.areasContext.poll().areas.some(a => a.type === AreaTypeEnum.Subarea);
            }
        })
        .addPage({
            name: "Blocks schedule",
            format: "a3",
            elements: [
                new BlockSchedulePageTable(this.bim, this.logger),
                new DcAcRationDiagram(this.bim, this.logger, paletteIlr),
                new ScreenshotByDcAcRatio(this.bim, this.logger, paletteIlr),
            ],
            overflow: true,
            isEmpty: () => {
                const transformers = this.bim.instances.peekByTypeIdents([TransformerIdent]);
                return transformers.every(([, inst]) => inst.properties.get("circuit | position | block_number") === undefined);
            }
        })
        .addPage({
            name: "Cut fill",
            format: "a3",
            elements: [
                new CutFillMetrics(this.bim, metrics, this.logger),
                new CutFillScreenshot(this.bim, this.logger.newScope("cut-fill-screenshot")),
            ],
            isEmpty: () => {
                if(URLS.isProduction()){
                    return true;
                }
                
                const terrains =  this.bim.instances.peekByTypeIdent('terrain-heightmap');
                for (const [_, inst] of terrains) {
                    const repr = inst.representation;
                    if(repr instanceof TerrainHeightMapRepresentation) {
                        for (const [, tile] of repr.tiles) {
                            if(tile.updatedGeo){
                                return false;
                            }
                        }
                    }
                }
                
                return true;
            }
        });

        const pdfResult = yield* builder.createReport();
        const pdfFile = getResultValueOrThrow(pdfResult);

        const file: ExportedFileDescription = {
            name: projectName,
            file: pdfFile,
            extension: "pdf",
        };

        return [file];
    }
}

const defaultColors:ReadonlyArray<RGBAHex> = [
    RGBA.new(0.8, 0.8, 0, 1),
    RGBA.new(0.004, 0.89, 0, 1),
    RGBA.new(0.8, 0.004, 0, 1),
    RGBA.new(0.8, 0.004, 0.8, 1),
    RGBA.new(0.004, 0.1, 0.89, 1),
];

const subAreasColors:ReadonlyArray<RGBAHex> = [
    RGBA.new(0.83, 0.113, 0.42, 1),
    RGBA.new(0.6, 0.29, 1, 1),
    RGBA.new(0.8, 0.57, 0, 1),
    RGBA.new(0.07, 0.63, 1, 1),
];

function addImageOnPageByTemplate2(page:jsPDF, image: Uint8Array, name: string, maxWidthMm: number, headerOffsetPx: number, offsets: PageFrameOffsets){
    const fontSize = 15;
    setTextStyle(page, "bold", fontSize);
    const pageWidth = page.internal.pageSize.getWidth();
    const leftOffset = pageWidth - maxWidthMm - convertPxToMm(offsets.leftRightOffsetPx);
    const upOffsetPx = offsets.upOffsetPx + offsets.topContentOffsetPx + headerOffsetPx - fontSize;
    page.text(name, leftOffset, convertPxToMm(upOffsetPx));

    const imageInfo = page.getImageProperties(image);
    const imageWidth = imageInfo.width;
    const imageHeight = imageInfo.height;
    
    const imagedToPageScale = Math.min(
        maxWidthMm / imageWidth,
    );

    const imageDrawSize = new Vector2(
        imageWidth,
        imageHeight
    ).multiplyScalar(imagedToPageScale);

    const drawingCenter = new Vector2(
        pageWidth - maxWidthMm * 0.5 - convertPxToMm(offsets.leftRightOffsetPx),
        convertPxToMm(upOffsetPx + fontSize) + imageDrawSize.y * 0.5
    );


    const pos = new Vector2(
        drawingCenter.x - imageDrawSize.x * 0.5,
        drawingCenter.y - imageDrawSize.y * 0.5,
    );
    page.addImage(image, "PNG", pos.x, pos.y, imageDrawSize.x, imageDrawSize.y);
}