import { IterUtils } from 'engine-utils-ts';
import type { AnyTrackerProps } from './AnyTracker';
import { BimMaterial, BimMaterialStdRenderParams, calculateTorqueTubePoints, calculateTrackerPilesConfig, CubeGeometry, DefaultPileLength, ExtrudedPolygonGeometry, NumberProperty, Points2D, PolylineGeometry, StdMeshRepresentation, StdSubmeshesLod, StdSubmeshRepresentation, type Bim, type BimGeometryType, type BimMaterialType, type PolylineGeometries } from '..';
import { Matrix4, Quaternion, Transform, Vec3X, Vector2, Vector3 } from 'math-ts';
import type { SharedEntitiesInterner } from '../collections/SharedEntitiesInterner';
import { PilesFeaturesAndOffsetsProperty, getPileOffsetInModules, getPileMotorType, PileMotorType, getPileOffsetInMeters, PileFeaturesAndOffsets } from './PilesFeatures';


export function calculateTrackersPilesTopsPositionsInLocalCoords(props: AnyTrackerProps) {
    const flatRepr = calculateTrackerFlatRepr(props);
    if (props.piles._segments_slopes === null) {
        return flatRepr.piles_offsets.map(p => new Vector3(0, -p, 0)); // torque tube is at 0 elevation
    } else {
        return calculateTorqueTubePoints(flatRepr.piles_offsets, props.piles._segments_slopes.values, undefined);
    }
}

export function calculateTrackerFlatRepr(props: AnyTrackerProps, makeMiddleAt0: boolean = true): TrackerFlatRepresentation {

    const params = TrackerGeometryParamsBase.fromTrackerProps(props, makeMiddleAt0);
    return calculateTrackerFlatReprBase(params);
}


export class TrackerGeometryParamsBase {
    readonly tube_overhang_north: number;
    readonly tube_overhang_south: number;

    readonly module_size_x: number;
    readonly module_size_y: number;

    readonly modules_count_x: number;
    readonly modules_count_y: number;

    readonly modules_gap_x: number;
    readonly modules_gap_y: number;
    readonly pile_bearings_gap: number;
    readonly motor_gap: number;

    readonly piles_descriptions: PilesFeaturesAndOffsetsProperty;

    readonly makeMiddleAt0: boolean;

    constructor(args: Partial<TrackerGeometryParamsBase>) {
        this.tube_overhang_north = args.tube_overhang_north ?? 0;
        this.tube_overhang_south = args.tube_overhang_south ?? 0;
        this.module_size_x = args.module_size_x ?? 0;
        this.module_size_y = args.module_size_y ?? 0;
        this.modules_count_x = args.modules_count_x ?? 0;
        this.modules_count_y = args.modules_count_y ?? 0;
        this.modules_gap_x = args.modules_gap_x ?? 0;
        this.modules_gap_y = args.modules_gap_y ?? 0;
        this.pile_bearings_gap = args.pile_bearings_gap ?? 0;
        this.motor_gap = args.motor_gap ?? 0;
        this.piles_descriptions = args.piles_descriptions ?? PilesFeaturesAndOffsetsProperty.new({features: [], offsets: []});
        this.makeMiddleAt0 = args.makeMiddleAt0 ?? true;
    }
    
    static fromTrackerProps(props: AnyTrackerProps, makeMiddleAt0: boolean): TrackerGeometryParamsBase {

        const piles = calculateTrackerPilesConfig(props);
        const moduleProps = props.module;
        const stringProps = props.tracker_frame.string;
        const frame = props.tracker_frame;
        const dimensions = frame.dimensions;

        const areModulesPlacedHorizontally = dimensions.modules_horizontal_placement.value;
        
        const params: TrackerGeometryParamsBase = {
            module_size_x: areModulesPlacedHorizontally ? moduleProps.length.as('m') : moduleProps.width.as('m'),
            module_size_y: areModulesPlacedHorizontally ? moduleProps.width.as('m') : moduleProps.length.as('m'),
            modules_count_x: stringProps.modules_count_x.value * dimensions.strings_count.value,
            modules_count_y: stringProps.modules_count_y.value,
            modules_gap_x: dimensions.modules_gap_x.as('m'),
            modules_gap_y: dimensions.modules_gap_y.as('m'),
            pile_bearings_gap: dimensions.pile_bearings_gap.as('m'),
            motor_gap: dimensions.motor_gap.as('m'),
            tube_overhang_north: dimensions.tube_overhang_north.as('m'),
            tube_overhang_south: dimensions.tube_overhang_south.as('m'),
            piles_descriptions: piles ?? PilesFeaturesAndOffsetsProperty.new({}),
            makeMiddleAt0: makeMiddleAt0,
        }

        return params;
    }
}


export interface TrackerFlatRepresentation {
    module_size_x: number;
    module_size_y: number;

    modules_gap_x: number;
    modules_gap_y: number;
    motor_gap: number;
    pile_bearings_gap: number;

    modules_rows_offsets: number[]; // modules rows centers x coords (meters)
    modules_count_y: number;
    
    piles_descriptions: PilesFeaturesAndOffsetsProperty;

    piles_offsets: number[]; // (meters)

    torque_tube_length: number;
}

const enum GapFlags {
    None,
    OverHangStart = 1,
    OverHangEnd = 2,
    ModulesGap = 4,
    PileGap = 8,
    MotorGap = 16,
};

export function calculateTrackerFlatReprBase(
    geometryParams: TrackerGeometryParamsBase,
): TrackerFlatRepresentation {

    const gd = geometryParams instanceof TrackerGeometryParamsBase ? geometryParams : new TrackerGeometryParamsBase(geometryParams);
    
    // start with pile beginning at 0,0

    const moduleRowsGaps: GapFlags[] = gd.modules_count_x > 0 ? IterUtils.newArray(
        Math.max(0, gd.modules_count_x + 1),
        (i) => (i === 0) ? GapFlags.OverHangStart : GapFlags.ModulesGap,
    ) : [];


    for (let i = 0; i < gd.piles_descriptions.offsets.length; ++i) {
        const pileFrameOffset = gd.piles_descriptions.offsets[i];
        const offsetInModules = getPileOffsetInModules(pileFrameOffset);
        if (offsetInModules == null) {
            continue;
        }
        moduleRowsGaps[offsetInModules] |= GapFlags.PileGap;

        const pileFeatures = gd.piles_descriptions.features[i];
        const hasMotor = getPileMotorType(pileFeatures) !== PileMotorType.None;
        if (hasMotor) {
            moduleRowsGaps[offsetInModules] |= GapFlags.MotorGap;
        }
    }

    const moduleRowsStartOffsets: number[] = new Array(moduleRowsGaps.length);

    let modulesStartOffset = 0;
    for (let i = 0; i < moduleRowsStartOffsets.length; ++i) {
        const gapType = moduleRowsGaps[i] ?? GapFlags.ModulesGap;
        let gap = 0;
        if (gapType & GapFlags.OverHangStart) {
            gap = gd.tube_overhang_north;
        }
        if (gapType & GapFlags.ModulesGap) {
            gap = Math.max(gap, gd.modules_gap_x);
        }
        if (gapType & GapFlags.PileGap) {
            gap = Math.max(gap, gd.pile_bearings_gap);
        }
        if (gapType & GapFlags.MotorGap) {
            gap = Math.max(gap, gd.motor_gap);
        }
        modulesStartOffset += gap;

        moduleRowsStartOffsets[i] = modulesStartOffset;
        modulesStartOffset += gd.module_size_x;
    }


    const pilesOffsets: number[] = new Array(gd.piles_descriptions.features.length);

    for (let i = 0; i < gd.piles_descriptions.offsets.length; ++i) {
        const pileFrameOffset = gd.piles_descriptions.offsets[i];
        const offsetInModules = getPileOffsetInModules(pileFrameOffset);
        const offsetInMeters = getPileOffsetInMeters(pileFrameOffset);

        let thisPileOffsetInMeters: number
        if (offsetInModules != null) {
            const modulesRowStart = moduleRowsStartOffsets[offsetInModules];
            const prevModulesRowStart = offsetInModules > 0 ? moduleRowsStartOffsets[offsetInModules - 1] + gd.module_size_x : 0;
            const gapCenter = (modulesRowStart + prevModulesRowStart) * 0.5;
            thisPileOffsetInMeters = gapCenter + offsetInMeters;
        } else {
            thisPileOffsetInMeters = offsetInMeters;
        }

        pilesOffsets[i] = thisPileOffsetInMeters;
    }


    const modulesRowsOffsets: number[] = [];
    for (let i = 0; i < gd.modules_count_x; ++i) {
        const startOffset = moduleRowsStartOffsets[i];
        modulesRowsOffsets.push(startOffset + gd.module_size_x * 0.5);
    }

    let tubeMinX = Math.min(
        (moduleRowsStartOffsets[0] ?? Infinity) - gd.tube_overhang_north,
        (pilesOffsets[0] ?? Infinity) - gd.pile_bearings_gap * 0.5,
    );
    let tubeMaxX = Math.max(
        (modulesRowsOffsets.at(-1) ?? -Infinity) + gd.module_size_x * 0.5 + gd.tube_overhang_south,
        (pilesOffsets.at(-1) ?? -Infinity) + gd.pile_bearings_gap * 0.5,
    );

    if (!(isFinite(tubeMinX) && isFinite(tubeMaxX))) {
        console.error('tube size is not finite', tubeMinX, tubeMaxX);
        tubeMinX = 0;
        tubeMaxX = 0;
    }

    const tubeLength = tubeMaxX - tubeMinX;

    if (geometryParams.makeMiddleAt0) {
        const offsetToCenter = (tubeMinX + tubeMaxX) * -0.5;

        for (let i = 0; i < modulesRowsOffsets.length; ++i) {
            modulesRowsOffsets[i] += offsetToCenter;
        }
        for (let i = 0; i < pilesOffsets.length; ++i) {
            pilesOffsets[i] += offsetToCenter;
        }
    }


    return {
        module_size_x: gd.module_size_x,
        module_size_y: gd.module_size_y,
        modules_gap_x: gd.modules_gap_x,
        modules_gap_y: gd.modules_gap_y,
        motor_gap: gd.motor_gap,
        pile_bearings_gap: gd.pile_bearings_gap,
        modules_rows_offsets: modulesRowsOffsets,
        modules_count_y: gd.modules_count_y,
        piles_descriptions: gd.piles_descriptions,
        piles_offsets: pilesOffsets,
        torque_tube_length: tubeLength,
    };
}


// interface TrackerMinimalRepresentation {

//     modules_transforms: Matrix4[], // assumed that module geo is cube of size 1x1x1 with center at 0,0,0

//     piles_transforms: Matrix4[], // assumed that piles is 1x1x1 size with top at 0,0,0, transforms positions are piles top positions

//     motors: {
//         pile_index: number,
//         is_under_modules: boolean,
//     }[];

//     torque_tube_radius: number,
//     torque_tube_points: Vector3[],

//     cap_ends_length: number,
// }


export class TrackerRepresentationCalculator {

    readonly bim: Bim;
    readonly unitCube: CubeGeometry;
    readonly pileGeo: ExtrudedPolygonGeometry;
    readonly motorGeoUnderModules: ExtrudedPolygonGeometry;
    readonly motorGeo: ExtrudedPolygonGeometry;
    readonly motorMaterial: BimMaterial;
    readonly moduleMaterial: BimMaterial;
    readonly pileMaterial: BimMaterial;
    readonly torqueTubeMaterial: BimMaterial;
    readonly cubeGeometriesShared: SharedEntitiesInterner<CubeGeometry, BimGeometryType>;
    readonly lineGeometries: PolylineGeometries;
    readonly extrudedPolygonGeometriesShared: SharedEntitiesInterner<ExtrudedPolygonGeometry, BimGeometryType>;
    readonly materialsShared: SharedEntitiesInterner<BimMaterial, BimMaterialType>;

    constructor(
        bim: Bim,
        cacheSize: number,
    ) {
        this.bim = bim;

        this.unitCube = new CubeGeometry(new Vector3(1,1,1));
        this.pileGeo = new ExtrudedPolygonGeometry(
            Points2D.newWithAutoIds([
                new Vector2(-0.5, -0.5),
                new Vector2(0.5, -0.5),
                new Vector2(0.5, -0.4),
                new Vector2(0.1, -0.4),
                new Vector2(0.1, 0.4),
                new Vector2(0.5, 0.4),
                new Vector2(0.5, 0.5),
                new Vector2(-0.5, 0.5),
                new Vector2(-0.5, 0.4),
                new Vector2(-0.1, 0.4),
                new Vector2(-0.1, -0.4),
                new Vector2(-0.5, -0.4),
                // new Vector2(-0.5, -0.4),
            ]),
            [],
            -1,
            0,
        );

        this.motorGeoUnderModules =  ExtrudedPolygonGeometry.newWithAutoIds([
            new Vector2(-0.5, -1).multiplyScalar(0.2),
            new Vector2(0.5,  -1).multiplyScalar(0.2),
            new Vector2(1,     0).multiplyScalar(0.2),
            new Vector2(0.5,   1).multiplyScalar(0.2),
            new Vector2(-0.5,  1).multiplyScalar(0.2),
        ], [], -0.49, 0.49);
        this.motorGeo =  ExtrudedPolygonGeometry.newWithAutoIds([
            new Vector2(-1,    0).multiplyScalar(0.4),
            new Vector2(-0.5, -1).multiplyScalar(0.4),
            new Vector2(0.5,  -1).multiplyScalar(0.4),
            new Vector2(1,     0).multiplyScalar(0.4),
            new Vector2(0.5,   1).multiplyScalar(0.4),
            new Vector2(-0.5,  1).multiplyScalar(0.4),
        ], [], -0.49, 0.49);

        this.motorMaterial = new BimMaterial(
            "motor",
            new BimMaterialStdRenderParams("#555555", 0, 0.7, 0.4),
        );
        this.moduleMaterial = new BimMaterial(
            "module",
            new BimMaterialStdRenderParams("#000077", 0, 0.85, 0.15),
        );
        this.pileMaterial = new BimMaterial(
            "pile",
            new BimMaterialStdRenderParams("#AAAAAA", 0, 0.4, 0.9),
        );
        this.torqueTubeMaterial = new BimMaterial(
            "pile",
            new BimMaterialStdRenderParams("#BBBBBB", 0, 0.8, 0.3),
        );

        this.cubeGeometriesShared = bim.cubeGeometries.shared!;
        this.lineGeometries = bim.polylineGeometries;
        this.extrudedPolygonGeometriesShared = bim.extrudedPolygonGeometries.shared!;
        this.materialsShared = bim.bimMaterials.shared!;
    }

    calculate(inputObj: {
        propsInOut: AnyTrackerProps,
        worldMatrix: Matrix4,
    }) {
        const props = inputObj.propsInOut;

        const torqueTubeRadius: number = 0.065;
        const pileRadius: number = 0.1;
        const moduleThickness = 0.03;

        const flatMeshgenParams = TrackerGeometryParamsBase.fromTrackerProps(inputObj.propsInOut, true);
        
        const fr = calculateTrackerFlatReprBase(flatMeshgenParams);

        const totalModulesArrayHeight = Math.max(0, fr.modules_count_y * (fr.module_size_y + fr.modules_gap_y) - fr.modules_gap_y);

        const modulesVerticalOffsets = IterUtils.newArray(
            fr.modules_count_y,
            i => (i * (fr.module_size_y + fr.modules_gap_y) - totalModulesArrayHeight * 0.5) + fr.module_size_y * 0.5
        );

        const segmentsSlopes = inputObj.propsInOut.piles._segments_slopes?.values;
        let torqueTubePoints: Vector3[];
        if (segmentsSlopes) {
            torqueTubePoints = calculateTorqueTubePoints(fr.piles_offsets, segmentsSlopes, fr.torque_tube_length);
        } else {
            torqueTubePoints = [
                new Vector3(0, fr.torque_tube_length * 0.5, 0),
                new Vector3(0, fr.torque_tube_length * -0.5, 0),
            ]
        }


        const unitCubeGeoId = this.cubeGeometriesShared.get(this.unitCube)!;
        const pileGeoId = this.extrudedPolygonGeometriesShared.get(this.pileGeo)!;
        const motorGeometry = fr.motor_gap > 0 ? this.motorGeo : this.motorGeoUnderModules;
        const motorGeoId = this.extrudedPolygonGeometriesShared.get(motorGeometry)!;
        const motorMaterialId = this.materialsShared.get(this.motorMaterial)!;
        const moduleMaterialId = this.materialsShared.get(this.moduleMaterial)!;
        const pileMaterialId = this.materialsShared.get(this.pileMaterial)!;


        const submeshes: StdSubmeshRepresentation[] = [];
        const lodSubmeshes: StdSubmeshRepresentation[] = [];

        const modulesTransforms: Transform[] = [];
        for (const modulesRowsOffset of fr.modules_rows_offsets) {
            const { position, angle } = calculatePositionAndAngleOnTorqueTubeByLocalOffset(
                -modulesRowsOffset, torqueTubePoints, segmentsSlopes, fr.piles_offsets, fr.torque_tube_length
            );
            const quaternion = new Quaternion().setFromAxisAngle(Vec3X, -angle);
            const shiftPerpendicular = new Vector3(0, 0, torqueTubeRadius + moduleThickness * 0.5).applyQuaternion(quaternion);
            position.add(shiftPerpendicular);
            
            for (const moduleYOffset of modulesVerticalOffsets) {
                const moduleTransform = new Transform(
                    new Vector3(moduleYOffset, position.y, position.z),
                    quaternion,
                    new Vector3(fr.module_size_y, fr.module_size_x, moduleThickness)
                );
                modulesTransforms.push(moduleTransform);
            }
        }
        for (const m of modulesTransforms) {
            submeshes.push(new StdSubmeshRepresentation(
                unitCubeGeoId,
                moduleMaterialId,
                m
            ));
        }
        if (fr.modules_rows_offsets.length > 0 && fr.modules_count_y > 0) {
            const totalModulesPlaneLength = fr.modules_rows_offsets.at(-1)! - fr.modules_rows_offsets[0] + fr.module_size_x;
            const lodGeo = this.cubeGeometriesShared.get(this.unitCube)!;
            const lodTranform = new Transform(
                new Vector3(0, 0, torqueTubeRadius + moduleThickness * 0.5),
                Quaternion.identity(),
                new Vector3(totalModulesArrayHeight, totalModulesPlaneLength, moduleThickness)
            );

            lodSubmeshes.push(new StdSubmeshRepresentation(
                lodGeo,
                moduleMaterialId,
                lodTranform
            ));
        }


        let pilesLength: number[]|undefined = inputObj.propsInOut.piles.lengths?.values;
        const pilesTransforms: Transform[] = [];

        for (let i = 0; i < fr.piles_offsets.length; ++i) {
            const pileOffset = -fr.piles_offsets[i];
            const pileLength = pilesLength?.[i] ?? DefaultPileLength;
            const pilePosition = calculatePositionAndAngleOnTorqueTubeByLocalOffset(
                pileOffset, torqueTubePoints, segmentsSlopes, fr.piles_offsets, fr.torque_tube_length
            ).position;

            const pileTransform = new Transform(
                pilePosition,
                Quaternion.identity(),
                new Vector3(pileRadius, pileRadius, pileLength)
            );

            pilesTransforms.push(pileTransform);
        }
        
        const pilesRotationToTheGround = new Quaternion()
            .setFromRotationMatrix(inputObj.worldMatrix)
            .invert();

        for (const tr of pilesTransforms) {
            tr.rotation.multiply(pilesRotationToTheGround);
            submeshes.push(new StdSubmeshRepresentation(
                pileGeoId,
                pileMaterialId,
                tr
            ));
        };

        for (let i = 0; i < fr.piles_descriptions.features.length; ++i) {
            const features = fr.piles_descriptions.features[i];
            const motor = getPileMotorType(features);
            if (motor === PileMotorType.Motor) {
                const motorWidth = Math.min(0.2, Math.max(fr.pile_bearings_gap, fr.motor_gap, 0.05));
                const m = new Matrix4()
                    .makeRotationX(Math.PI * 0.5)
                    .scale(new Vector3(0.75, 0.75, motorWidth))
                    .setPositionV(pilesTransforms[i].position.clone());

                submeshes.push(new StdSubmeshRepresentation(
                    motorGeoId,
                    motorMaterialId,
                    Transform.fromMatrix(m)
                ));
            }
        }

        const torqueTubeGeo = this.lineGeometries.shared!.get(PolylineGeometry.newWithAutoIds(torqueTubePoints, torqueTubeRadius))!;
        const torqueTubeMaterial = this.materialsShared.get(this.torqueTubeMaterial)!;
        submeshes.push(new StdSubmeshRepresentation(
            torqueTubeGeo,
            torqueTubeMaterial,
            new Transform()
        ));

        const lod0DetailSize: number = Math.max(
            2 * torqueTubeRadius,
            10 * fr.modules_gap_x,
            fr.motor_gap * 0.3,
            fr.pile_bearings_gap
        );
        const lodRepr = lodSubmeshes.length > 0 ? new StdSubmeshesLod(
            lodSubmeshes,
            lod0DetailSize
        ): null;

        const repr = new StdMeshRepresentation(submeshes, lodRepr, true);

        props.piles.active_configuration = fr.piles_descriptions;
        props.tracker_frame.dimensions.length = NumberProperty.new({value: fr.torque_tube_length, unit: 'm', isReadonly: true});
        props.tracker_frame.dimensions.max_width = NumberProperty.new({value: totalModulesArrayHeight, unit: 'm', isReadonly: true});
        
        return {
            repr,
        }
    }
}

export function convertPilePositionsToAbsolute(repr: TrackerFlatRepresentation, src: PileFeaturesAndOffsets[]): PileFeaturesAndOffsets[] {
    const result: PileFeaturesAndOffsets[] = [];
    for (let i = 0; i < repr.piles_offsets.length; ++i) {
        const pile = src[i];
        const offset = repr.piles_offsets[i];
        const pileCopy = new PileFeaturesAndOffsets({
            ...pile,
            offset_in_modules: null,
            offset_in_meters: offset,
        });
        result.push(pileCopy);
    }
    return result;
}

export function convertPilePositionsToRelative(repr: TrackerFlatRepresentation, src: PileFeaturesAndOffsets[]): PileFeaturesAndOffsets[] {
    const result: PileFeaturesAndOffsets[] = [];
    for (let i = 0; i < repr.piles_offsets.length; ++i) {
        const pile = src[i];
        const offset = repr.piles_offsets[i];
        let closestModulePositionIndex = -1;
        for (let j = 0; j < repr.modules_rows_offsets.length; j++) {
            const rowOffset = repr.modules_rows_offsets[j];
            const nextRowOffset = repr.modules_rows_offsets[j + 1] ?? Infinity;
            if(offset >= rowOffset && offset < nextRowOffset) {
                closestModulePositionIndex = j;
                break;
            }
            if (offset < rowOffset) {
                break;
            }
        }
        const moduleOffset = closestModulePositionIndex >= 0 ? repr.modules_rows_offsets[closestModulePositionIndex] : 0;
        const pileCopy = new PileFeaturesAndOffsets({
            ...pile,
            offset_in_modules: closestModulePositionIndex + 1,
            offset_in_meters: offset - moduleOffset,
        });
        result.push(pileCopy);
    }
    return result;
}

export function calculatePositionAndAngleOnTorqueTubeByLocalOffset(
    atLocalOffset: number,
    torqueTubePoints: Vector3[],
    segmentsSlopes: number[] | undefined,
    pilesOffsets: number[],
    torqueTubeLength: number,
): { position: Vector3, angle: number } {
    const pilesCoords = pilesOffsets.map(o => -o);

    if (torqueTubePoints.length === 2) {
        return { position: new Vector3(0, atLocalOffset, 0), angle: 0 };
    }

    if (atLocalOffset > pilesCoords[1]) {
        const relativePosition = (0.5 * torqueTubeLength - atLocalOffset) 
            / (0.5 * torqueTubeLength - pilesCoords[1]);
        const absolutePosition = torqueTubePoints[0].clone().multiplyScalar(1 - relativePosition)
            .add(torqueTubePoints[1].clone().multiplyScalar(relativePosition));
        return { position: absolutePosition, angle: segmentsSlopes![0] };
    }

    for (let i = 1; i < pilesCoords.length - 2; ++i) {
        if (atLocalOffset <= pilesCoords[i] && atLocalOffset > pilesCoords[i + 1]) {
            const relativePosition = (pilesCoords[i] - atLocalOffset) 
                / (pilesCoords[i] - pilesCoords[i + 1]);
            const absolutePosition = torqueTubePoints[i].clone().multiplyScalar(1 - relativePosition)
                .add(torqueTubePoints[i + 1].clone().multiplyScalar(relativePosition));
            return { position: absolutePosition, angle: segmentsSlopes![i] };
        }
    }

    const relativePosition = (0.5 * torqueTubeLength + atLocalOffset) 
        / (0.5 * torqueTubeLength + pilesCoords.at(-2)!);
    const absolutePosition = torqueTubePoints.at(-1)!.clone().multiplyScalar(1 - relativePosition)
        .add(torqueTubePoints.at(-2)!.clone().multiplyScalar(relativePosition));
    return { position: absolutePosition, angle: segmentsSlopes!.at(-1)! };
}