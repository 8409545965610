import type { SubmeshesCreationOutput, SubmeshesCreationResources } from "./ESSO";
import { ESSO } from "./ESSO";
import type { EngineSubmeshDescription, RenderJobUpdater, SubmeshAllocArgs } from "../scene/Submeshes2";
import { LodMask } from "../scene/Submeshes2";
import { newLodGroupLocalIdent } from "../scene/LodGroups";
import { EngineMaterialId } from "../pools/EngineMaterialId";
import { SelectHighlightOverlayJobUpdater } from "./ESSO_HighlightUpdaters";
import type { BlockOptions } from "../three-mesh-ui/components/Block";
import { Transform, Vector3 } from "math-ts";
import type { EngineFullGraphicsSettings } from '../GraphicsSettingsFull';
import type { UniformsFlat } from '../composer/DynamicUniforms';
import type { ShaderFlags } from '../shaders/ShaderFlags';
import type { ESO } from './ESO';
import { RGBA } from 'engine-utils-ts';



export class ESSO_TextAnnotationFrame extends ESSO<BlockOptions> {
    createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {


        const transform = this.localTransform ?? new Transform();
        transform.scale.set(this.repr.width, this.repr.height, 1);

        {
            const planeFrameId = resoures.geometries.planeGeometries.allocateOrReferenceSingle({ width: 1, height: 1 })!;
            const allocArgs: SubmeshAllocArgs = {
                id: this.id,
                lodMask: LodMask.All,
                lodGroupLocalIdent: newLodGroupLocalIdent(0, 0),
                subObjectRef: this,
                descr: {
                    geoId: planeFrameId,
                    localTransforms: [transform],
                    materialId: EngineMaterialId.Spatial,
                    mainRenderJobUpdater: new PolygonFrameRenderJobUpdater(),
                    overlayRenderJobUpdater: SelectHighlightJobUpdater,
                }
            };
            output.submeshes.push(allocArgs);
        }
    }
}

class PolygonFrameRenderJobUpdater implements RenderJobUpdater {
    updaterRenderJob(
        submeshDescription: Readonly<EngineSubmeshDescription<ESO>>,
        renderSettings: Readonly<EngineFullGraphicsSettings>,
        output: { flags: ShaderFlags; materialId: EngineMaterialId; uniforms: UniformsFlat; }
    ): void {
		if (submeshDescription.subObjectRef.isHidden) {
			return;
		}
		output.materialId = EngineMaterialId.Spatial;
        let alpha = 0.05;
		output.uniforms.push('alphaPerAxis', Vector3.fromScalar(alpha));
    }
}

const SelectHighlightJobUpdater = new SelectHighlightOverlayJobUpdater(
    EngineMaterialId.Highlight,
    true,
    RGBA.new(1, 0.55, 0, 0.2),
    0.5
);
