import type { Bim, IdBimScene, Catalog } from 'bim-ts';
import type { LazyVersioned, NamedEvents, ObservableStream, ProjectNetworkClient, UndoStack} from 'engine-utils-ts';
import {
    findMappedPathFor,
	LazyBasic, LazyDerived, LegacyLogger, LogLevel,
	ObjectUtils,
	ObservableObject, PngConverter,
	RGBA,
	ScopedLogger, TasksRunner, Yield,
} from 'engine-utils-ts';
import { Aabb, KrMath, Matrix4, Plane, Vector2, Vector3 } from 'math-ts';
import type {
	PersistedCollectionConfig, VerDataPersistedCollection, VerDataSyncer,
} from 'verdata-ts';

import { BasicDepthPacking, OrthographicCamera, PerspectiveCamera, Math as _Math } from './3rdParty/three';
import { ClipBox } from './clipbox/ClipBox';
import { GpuResources } from './composer/GpuResources';
import { GroundShadowFrustum } from './composer/GroundShadowFrustum';
import { HomeCameraPhiOrtho,
	HomeCameraTheta, MaxScreenshotSideSize, MinScreenshotSideSize,
} from './Constants';
import { EditModeControls } from './controls/EditControls';
import { GizmoPartsToRender } from './controls/GizmoPartsToRender';
import { InputController } from './controls/InputController';
import { GesturesMousePos } from './controls/MouseGesturesBase';
import type { KrCamera} from './controls/MovementControls';
import { MovementControls, setOrthoCameraAspect, setPerspCameraAspectFov } from './controls/MovementControls';
import type { Screenshot } from './data/Screenshot';
import { createScreenshot } from './data/Screenshot';
import { checkEdgesRenderMode } from './EdgesRenderMode';
import { RenderingEngineBase } from './EngineBase';
import { EngineControlsState } from './EngineControlsState';
import { EngineUiBindings } from './EngineUiBindings';
import { renderTypeFromString } from './esos/RenderType';
import { RenderTimeBudget } from './frameComposer/RenderTimeBudget';
import { StdFrameComposer } from './frameComposer/StdFrameComposer';
import { ClipBoxGizmo } from './gizmos/ClipboxGizmo';
import { GizmosController } from './gizmos/GizmosController';
import { RectSelectorGizmo } from './gizmos/RectSelectorGizmo';
import TeleportGizmo from './gizmos/TeleportGizmo';
import { TransformGizmo } from './gizmos/TransformGizmo';
import { GraphicsSettings } from './GraphicsSettings';
import type { EngineConstructionParams, EngineGraphicsSettings } from './KreoEngine';
import { TerrainDisplayEngineSettings } from "./TerrainDisplayEngineSettings";
import { GlobalUniforms } from './materials/GlobalUniforms';
import { MaterialsFactory } from './materials/MaterialsFactory';
import { MaterialsRegistry } from './materials/MaterialsRegistry';
import { TexturesLoader } from './materials/TexturesLoader';
import { EngineScene } from './scene/EngineScene';
import { InteractiveObjectsActive } from './scene/InteractiveSceneObjects';
import { InteractiveSceneObjectsBim } from './scene/InteractiveSceneObjectsBim';
import {
	InteractiveSceneObjectsEdit,
} from './scene/InteractiveSceneObjectsEdit';
import { SceneRaycaster } from './scene/SceneRaycaster';
import { SnappingSettings } from './SnappingSettings';
import { KrFrustum } from './structs/KrFrustum';
import { RaySection } from './structs/RaySection';
import type { EngineTerrainPalette} from './terrain/EngineTerrainPalette';
import { getDerivedEngineTerrainPalette } from './terrain/EngineTerrainPalette';
import { TextGeometries } from './text/TextGeometry';
import type { Time } from './time/TIme';
import { TotalBounds } from './TotalBounds';
import type { EngineUnits,
	MeasureLabelCopyArgs as MeasureLabelClickArgs} from './EngineLegacyUiUnits';
import {
	EngineLegacyUiUnits
} from './EngineLegacyUiUnits';


import Utils from './utils/Utils';
import { LoadEngineWasm } from './WasmBinaryLoad';
import type { EngineFullGraphicsSettings} from './GraphicsSettingsFull';
import { newFullGraphicsSettings } from './GraphicsSettingsFull';
import { CameraRotationPointGizmo } from './gizmos/CameraRotationPointGizmo';
import { MeshRenderType } from './EngineConsts';
import { CameraProjectionMode } from './EngineConsts';
import { UiBindings, RuntimeSystemExecutionStatus } from 'ui-bindings';
import { loadFontsFamilies } from './three-mesh-ui/content/FontLibrary';
import { AnnotationsSettings } from './annotations/AnnotationsSettingsUiBindings';
import { FrustumExt } from './structs/FrustumExt';
import { InteractiveSceneObjectsNone } from './scene/InteractiveSceneObjectsNone';
import type { Aabb2 } from 'node_modules/math-ts/src';
import { Compass } from './Compass';
import { TrackersPilesMarkupSettings } from './TrackersPilesMarkupSettingsUiBindings';

replaceThreesUUIDGenerator();

type optionalIdsArray = number[] | null | undefined;

export interface CommonArgs {
	undoStack: UndoStack,
	namedEvents: NamedEvents,
	time: Time,
	tasksRunner: TasksRunner,
	logger: ScopedLogger,
}

export class KreoEngineImpl extends RenderingEngineBase {

	readonly logger: ScopedLogger = new ScopedLogger('engine', LogLevel.Default);
	readonly undoStack: UndoStack;
	readonly networkClient: ProjectNetworkClient;

	readonly bim: Bim;

	readonly catalog: Catalog;

	readonly uiBindings: UiBindings;

	readonly tasksRunner: TasksRunner = new TasksRunner(this.logger);
	readonly serviceCoroutines: TasksRunner = new TasksRunner(this.logger); // routines that run until engine is disposed

	readonly commonArgs: CommonArgs;

	readonly totalBounds: TotalBounds = new TotalBounds();
	readonly clipBox: ClipBox;

	readonly groundShadowFrustum: GroundShadowFrustum = new GroundShadowFrustum();

	readonly controlsState: ObservableObject<EngineControlsState>;
	readonly snappingSettings: ObservableObject<SnappingSettings>;
	readonly annotationsSettings: ObservableObject<AnnotationsSettings>;
	readonly trackersPilesMarkupSettings : ObservableObject<TrackersPilesMarkupSettings>;

	readonly globalUniforms: GlobalUniforms = new GlobalUniforms();
	readonly textures: TexturesLoader;

	readonly engineScene: EngineScene;

	readonly interactiveBimObjects: InteractiveSceneObjectsBim;
	readonly interactiveEditObjects: InteractiveSceneObjectsEdit;
	readonly interactiveSceneObjectsNone: InteractiveSceneObjectsNone;
    readonly interactiveObjectsActive: InteractiveObjectsActive;

	readonly materialsRegistry: MaterialsRegistry;
	readonly materialsFactory;
	readonly gpuResources: GpuResources;

	// readonly siteMarkupTilesets: SiteMarkupTilesets;
	// readonly siteTilesMarkupControls: SiteTilesMarkupControls;

	readonly _cameraMovedEvent: ObservableStream<void> = this.namedEvents.registerEvent('cameraMoved');
	readonly _isRenderingEdgesEvent: ObservableStream<boolean> = this.namedEvents.registerEvent('isRenderingEdges');

	isRenderProgressive: boolean = true;

	selectedFocusIndex: number = 0;

	readonly textGeometries: TextGeometries = new TextGeometries();

	readonly cameraRotationPointGizmo: CameraRotationPointGizmo;

    readonly gizmoPartsToRender: GizmoPartsToRender;
	readonly editModeControls: EditModeControls;

	readonly rectSelector: RectSelectorGizmo;
	readonly input: InputController;
	readonly gizmosController: GizmosController;

	readonly heavyLoadTimePerFrame : number = 30;
	readonly serviceRoutineLimitPerFrame : number = 5;

	readonly rendCont: boolean = false;

	readonly uiUnits: EngineLegacyUiUnits = new EngineLegacyUiUnits();

	clipboxLastFocusIds: IdBimScene[] | null = null;

	readonly renderSettings: ObservableObject<GraphicsSettings> = new ObservableObject({
		identifier: 'engine-render-settings',
		initialState: new GraphicsSettings()
	});

	readonly movementControls: MovementControls = new MovementControls(this.time, this.totalBounds, this.namedEvents, this.renderSettings.poll());

	readonly compass: Compass;

	readonly composer: StdFrameComposer;
	readonly wasm_module: any;

	readonly mousePos: LazyBasic<GesturesMousePos> = new LazyBasic('mouse_pos', new GesturesMousePos(
		new Vector2(),
		new Vector2(),
		new Vector2(),
		new Vector2(),
	));

	readonly sceneRaycaster: SceneRaycaster;

	readonly transformGizmo: TransformGizmo;

	forceFullFrameNextTime: boolean = false;

	clipboxGizmo: ClipBoxGizmo;

	// _syncables: EngineSyncablesMap = newEngineSyncablesMap();

	_timeBudget: RenderTimeBudget = new RenderTimeBudget();
	terrainDisplaySettings: ObservableObject<TerrainDisplayEngineSettings>;
	terrainDerivedSettings: LazyVersioned<EngineTerrainPalette>;

	engineFullGraphicsSettings: LazyVersioned<EngineFullGraphicsSettings>;

	readonly _bimMeshGeneratorsStatus: LazyVersioned<RuntimeSystemExecutionStatus>;
	_verdata: VerDataSyncer|null = null;


	static async newAsync(params: EngineConstructionParams): Promise<KreoEngineImpl> {
        const binariesPath = findMappedPathFor('./dist/engine_wasm_bg.wasm');
		await LoadEngineWasm(binariesPath);
		await loadFontsFamilies();
		return new KreoEngineImpl(params.container, params.network, params.texturesUrl, params.bim, params.catalog);
	}

	constructor(
		container:HTMLElement,
		networkClient: ProjectNetworkClient,
		texturesUrl: string,
		bim: Bim,
		catalog: Catalog,
	) {
		super(container, {
			powerPreference: 'high-performance',
			stencil: false,
			depth: false
		});

		const renderer = this.renderer;

		console.assert(!!bim.undoStack, 'engine bim should have undo stack');

		renderer.physicallyCorrectLights = true;
		renderer.toneMappingExposure = 1.0;
		// renderer.gammaInput = true;
		// renderer.gammaOutput = true;
		renderer.clear(true);
		renderer.sortObjects = false;

		this.uiBindings = new UiBindings(this.logger),
		this.undoStack = bim.undoStack!;
		this.networkClient = networkClient;
		this.bim = bim;
		this.catalog = catalog;

		this.commonArgs = {
			undoStack: this.undoStack,
			namedEvents: this.namedEvents,
			time: this.time,
			tasksRunner: this.tasksRunner,
			logger: this.logger
		};

		this.materialsRegistry = new MaterialsRegistry(this.logger, this.globalUniforms);

		this.clipBox = new ClipBox(this.commonArgs, this.totalBounds);

		this.controlsState = new ObservableObject({
			identifier: 'engine-controls-state',
			initialState: new EngineControlsState(),
            throttling: {onlyFields: []}
		});
		this.snappingSettings = new ObservableObject({
			identifier: 'snapping',
			initialState: new SnappingSettings(),
		});
		this.annotationsSettings = new ObservableObject({
			identifier: 'engine-annotations-settings',
			initialState: new AnnotationsSettings()
		});
		this.trackersPilesMarkupSettings = new ObservableObject({
			identifier: 'engine-trackers-piles-markup-settings',
			initialState: new TrackersPilesMarkupSettings()
		});


		this.terrainDisplaySettings = new ObservableObject<TerrainDisplayEngineSettings>({
			identifier: 'engine-terrain-palette-mode',
			initialState: new TerrainDisplayEngineSettings(),
		});

		this.terrainDerivedSettings = getDerivedEngineTerrainPalette(this.bim, this.terrainDisplaySettings);
		// this.controlsState.addPatchValidator("controlsMode", (c) => {
		// 	return EnumUtils.isValidEnumValue(EngineControlsMode, c) ? c : EngineControlsMode.Default;
		// });

		// this.stdMaterialsTemplates = new StdMaterialsTemplates(
		// 	'stdMaterials',
		// 	0,
		// 	this.commonArgs
		// );

		this.engineFullGraphicsSettings = newFullGraphicsSettings(
			this.renderSettings,
			this.terrainDisplaySettings,
			this.terrainDerivedSettings
		);

		this.textures = new TexturesLoader({loadPath: texturesUrl});

		this.gpuResources = new GpuResources(this.renderer);

		this.engineScene = new EngineScene({
			logger: this.logger,
			network: this.networkClient,
			bim: this.bim,
			catalog: this.catalog,
			controlsState: this.controlsState,
			clipbox: this.clipBox,
			gpuResources: this.gpuResources,
			graphicsSettings: this.engineFullGraphicsSettings,
			uiUnits: this.uiUnits,
			textGeometries: this.textGeometries,
			globalUniforms: this.globalUniforms,
			frustum: this.movementControls.frustum,
			tasksRunner: this.tasksRunner,
			annotationsSettings: this.annotationsSettings,
			trackersPilesMarkupSettings: this.trackersPilesMarkupSettings
		});

        this.interactiveBimObjects = new InteractiveSceneObjectsBim(this.engineScene);
		this.interactiveEditObjects = new InteractiveSceneObjectsEdit(this.logger, this.engineScene);
		this.interactiveSceneObjectsNone = new InteractiveSceneObjectsNone(this.logger);
        this.interactiveObjectsActive = new InteractiveObjectsActive([
            this.interactiveBimObjects,
            this.interactiveEditObjects,
			this.interactiveSceneObjectsNone,
        ]);

		this.materialsFactory = new MaterialsFactory(
			this.engineScene.stdMaterials,
			this.globalUniforms,
			texturesUrl,
			this.textures,
			this.renderer,
			BasicDepthPacking,
		);

		this.gpuResources.setMaterialsFactory(this.materialsFactory);
		this.materialsFactory.loadSkybox(this.gpuResources.renderer);

        this.gizmoPartsToRender = new GizmoPartsToRender();

		this.editModeControls = new EditModeControls(
			this.renderSettings,
			this.controlsState,
			this.engineScene,
			this.commonArgs,
			this.uiBindings,
			this.interactiveObjectsActive,
			this.terrainDisplaySettings,
		);

		this.clipboxGizmo = new ClipBoxGizmo(
			this.totalBounds,
			this.clipBox,
			this.uiUnits,
			this.globalUniforms,
			this.textGeometries,
			this.time
		);
		this.transformGizmo = new TransformGizmo(this.interactiveObjectsActive, this.snappingSettings);

		this.sceneRaycaster = new SceneRaycaster(
			this.mousePos,
			this.movementControls.observableCamera,
			this.engineScene,
			[this.transformGizmo, this.clipboxGizmo],
			this.totalBounds,
			this.clipBox,
			this.interactiveObjectsActive
		);


		// this.siteMarkupTilesets = new SiteMarkupTilesets(this.commonArgs);
		// this.siteTilesMarkupControls = new SiteTilesMarkupControls(this.siteMarkupTilesets, this.controlsState, this.sceneRaycaster.sceneIntersection);

		const teleportGizmo = new TeleportGizmo(this.sceneRaycaster);
		this.rectSelector = new RectSelectorGizmo(this.engineScene, this.time);
		// this.

		this.cameraRotationPointGizmo = new CameraRotationPointGizmo(
			this.movementControls
		);
		this.gizmosController = new GizmosController(
			this.clipboxGizmo,
			this.transformGizmo,
			teleportGizmo,
			this.rectSelector,
			this.cameraRotationPointGizmo
		);

		this.input = new InputController (
			this.renderer.domElement,
			this,
		);

		this.addListener('measurementClick', (args:MeasureLabelClickArgs) => {
			navigator['clipboard']['writeText'](args['uiString'])
				.then(() => {
					// console.log('Text copied to clipboard');
				})
				.catch(err => {
					LegacyLogger.warn('could not copy measurement to clipboard', err);
				});
		});

		this.composer = new StdFrameComposer({
			ident: 'composer',
			logger: this.logger,
			gpuRes: this.gpuResources,
			graphicsSettings: this.renderSettings,
			frustum: this.movementControls.frustum,
			engine: this,
			renderToScreen: true,
			renderOverlays: true,
		});

		this.clear();

		this.addListener('resize', (width: number, height: number) => {
			const { x, y } = this.getResolutionConsideringDevicePixelRatio(width, height);

			this.movementControls.setAspect(x / y);
			// this.renderer.setViewport()

			this.composer.setSize(x, y);
		});

		this.compass = new Compass(this.container, this.setCameraAngles.bind(this));

		EngineUiBindings.addEngineUiBindings(this.uiBindings, this);

		this.engineScene.gc.forceRun(); // to see registration errors if any

		const meshGeneratorSolvers = bim.reactiveRuntimes._solversRunners.filter(s => s.solver.identifier.endsWith('mesh-gen'));

		
		this._bimMeshGeneratorsStatus = LazyDerived.new0(
			'mesh-generators-status',
			meshGeneratorSolvers,
			() => {
				for (const meshGenSolver of meshGeneratorSolvers) {
					const status = meshGenSolver.status();
					if (status === RuntimeSystemExecutionStatus.InProgress
						|| status === RuntimeSystemExecutionStatus.Waiting
					) {
						// console.warn('mesh generators are in progress', meshGenSolver.solver.identifier);
						return RuntimeSystemExecutionStatus.InProgress;
					}
				}
				// console.warn('mesh generators are done');
				return RuntimeSystemExecutionStatus.Done;
			},
		)
	}

	createPersistedCollections(): [PersistedCollectionConfig, VerDataPersistedCollection<any, any>][] {
		return [];
		// return [[
		// 	{
		// 		identifier: 'site-markup',
		// 		loadAfter: [],
		// 		loadInOneTickWith: [],
		// 		objectCountInBatchHint: 50,
		// 	},
		// 	new EntitiesPersisted<SiteMarkupTileset>({
		// 		entities: this.siteMarkupTilesets,
		// 		serializer: new SiteMarkupTilesetsSerializer(),
		// 	})
		// ]];
	}

	connectToVerdataEvents(verdata: VerDataSyncer) {
		if (this._verdata) {
			throw new Error('verdata already connected');
		}
		verdata.addListener('afterSync', () => {
			this.tasksRunner.scheduleGenerator(this._focusAfterLoadRoutine());
		});
		verdata.addListener('dispose', () => this._verdata = null);
		this._verdata = verdata;
	}

	*waitForEngineSceneToBeReady() {
		yield Yield.NextFrame;
		while (this._bimMeshGeneratorsStatus.poll() === RuntimeSystemExecutionStatus.InProgress
			|| this._bimMeshGeneratorsStatus.poll() === RuntimeSystemExecutionStatus.Waiting
		) {
			yield Yield.NextFrame;
		}
		yield Yield.NextFrame;
		while (this.engineScene.anyPendingUpdates()) {
			yield Yield.NextFrame;
		}
		yield Yield.NextFrame;
		return true;
	}

	private *_focusAfterLoadRoutine() {
		yield* this.waitForEngineSceneToBeReady();
		this.focusFrom(null, 0, 0);
	}

	_framesWaitingOnMeshGen: number = 0;

	protected _loopImpl(timeStamp: number) {
		LegacyLogger.logDeferred();

		if (this._framesCounter === 2) {
			this.composer.invalidateFrame();
		}

		this._timeBudget.startNewFrame();

		this.tasksRunner.run(this.heavyLoadTimePerFrame);
		this.serviceCoroutines.run(this.serviceRoutineLimitPerFrame);

		this.textGeometries.startNewFrame();

		this.uiUnits.updateIfNecessary(this.bim.unitsMapper);

		this.input.update();

		// this.siteMarkupTilesets.update(this.gpuResources);

		let shouldWaitForBimInstancesRuntime = false;
		if (this.engineScene.esos.perId.size === 0
			&& this.bim.instances.perId.size > 0
			&& this.bim.reactiveRuntimes._hasPendingUpdates()
		) {
			shouldWaitForBimInstancesRuntime = true;
		
		} else if (this._framesWaitingOnMeshGen < 200
			&& this._bimMeshGeneratorsStatus.poll() === RuntimeSystemExecutionStatus.InProgress
		) {
			shouldWaitForBimInstancesRuntime = true;
		}

		if (shouldWaitForBimInstancesRuntime) {
			this._framesWaitingOnMeshGen += 1;
			// allow meshgen to run when loading project
			console.log('engine waiting for meshgen');
			this.engineScene.engineGeometries.sync();
			this.engineScene.engineImages.sync();
			this.engineScene.stdMaterials.sync();
			return;
		}

		this.engineScene.syncWithBim();
		this.editModeControls.syncEditables();
		this.engineScene.reconcileSubmeshesWithSubobjects();

		this.engineScene.syncGeometriesWithGpu();
		this.engineScene.applySubmeshesUpdates();

		this.totalBounds.update(this.engineScene.submeshes);

		if (this.movementControls.update()) {
			this._cameraMovedEvent.notify_later_legacy();
		}

		this.globalUniforms._updateGridSizeSettingsIfNecessary(this.renderSettings, this.movementControls.camera, this.uiUnits, this.totalBounds);

		this.groundShadowFrustum.update(this.totalBounds);

		this.clipBox.update(this.totalBounds);

		this.engineScene.updateCullingAndRenderLists(this.clipBox, [this.movementControls.frustum]);

		if (this.transformGizmo.isActiveTotal()) {
			if (this.transformGizmo.isDragging()) {
				this.globalUniforms.setSelectionColorTransformActive();
			} else {
				this.globalUniforms.setSelectionColorTransformEnabled();
			}
		} else {
			this.globalUniforms.setSelectionColorStd();
		}

		this.globalUniforms.updateUniforms(
			this.totalBounds,
			this.clipBox,
			this.renderSettings.poll(),
			this.composer,
			this.terrainDerivedSettings.poll(),
			this.uiUnits,
			this.engineScene,
		);
		this.gizmosController.updateGizmos(this.movementControls.frustum, this.sceneRaycaster.mouseCone.poll()?.raySection ?? null);

		_reusedMatrix.extractRotation(this.movementControls.camera.matrixWorld);
		this.compass.update(_reusedMatrix);

		this.composer.render(this._timeBudget);

		this.engineScene.gc.runIfShould(this._timeBudget);

		LegacyLogger.logDeferred();

		if (this._tickResolve) {
			this._tickResolve(this._framesCounter);
			this._tickResolve = null;
			this._tickReject = null;
			this._nextTick = null
		}
	}

	_tickResolve: ((frame: number) => void) | null = null;
	_tickReject: (() => void) | null = null;
	_nextTick: Promise<number> | null = null;

	tick(): Promise<number> {
		if (!this._nextTick) {
			this._nextTick = new Promise((resolve, _tickReject) => {
				this._tickResolve = resolve;
				this._tickReject = null;
			})
		}
		return this._nextTick;
	}

	_calcBoundsForHandlesOrVisibleScene(ids: IdBimScene[]): Aabb {
		let bounds = this.engineScene.calcBoundsByIds(ids);
		if (bounds.isEmpty()) {
			const childrenIds = this.interactiveBimObjects.getChildrenOf(ids);
			bounds = this.engineScene.calcBoundsByIds(childrenIds);
		}
		if (bounds.isEmpty()) {
			bounds.copy(this.totalBounds.bounds);
		}
		return bounds;
	}

	_calcBoundsForHandlesOrClipbox(ids: IdBimScene[] | null): Aabb {
		if (ids?.length) {
			return this._calcBoundsForHandlesOrVisibleScene(ids);
		}
		if (this.clipBox.isEnabled()) {
			// expand the clip box to fit the dimensions
			// todo better
			const box = this.clipBox.current.clone();
			const box_size = box.getSize();
			box.expandByVector(box_size.multiplyScalar(0.05));
			return box;
		}
		return this._calcBoundsForHandlesOrVisibleScene([]);
	}

	// ----- public api begin -------

	_focusCameraOnBimsOrClipbox(ids: IdBimScene[] | null) {
		const bounds = this._calcBoundsForHandlesOrClipbox(ids);
		this.movementControls.focusCameraOnBounds(bounds);
	}

	focusCamera(bimIds?: number[]) {
		const ids = this._getActiveIdsOrAll(bimIds);
		this._focusCameraOnBimsOrClipbox(ids);
	}

	focusCameraByType(idsByType: IdBimScene[]) {
		const bounds = this._calcBoundsForIdsByTypeOrClipbox(idsByType);
		this.movementControls.focusCameraOnBounds(bounds);
	}

	cameraToHome() {
		// if (this.movementControls.getProjType() == CameraProjectionMode.Perspective) {
		// 	// calculate angle to be from the height of 2 meters above ground, looking at the center of bounding box
		// 	const bounds = this.totalBounds.bounds;
		// 	const boundsSize = bounds.getSize();
		// 	const heightToHoriz = boundsSize.y / (new Vector2(boundsSize.x, boundsSize.z).length());
		// 	const lerpFactor = KrMath.clamp(heightToHoriz * 0.4, 0, 1.0);
		// 	let phi = KrMath.lerp(
		// 		91,
		// 		110,
		// 		lerpFactor
		// 	);// good enough
		// 	phi = KrMath.degToRad(phi);
		// 	this.movementControls.focusCameraOnBounds(this.totalBounds.bounds, phi, HomeCameraTheta);
		// } else {
		// const bounds = this.totalBounds.bounds;
		// const boundsSize = bounds.getSize();
		// if (boundsSize.z < boundsSize.xy().length() * 10) {
		// 	this.movementControls.focusCameraOnBounds(this.totalBounds.bounds, 0, 0);
		// } else {
			this.movementControls.focusCameraOnBounds(this.totalBounds.bounds, HomeCameraPhiOrtho, HomeCameraTheta);
		// }
		// }
	}

	getCameraPolarAnglesTarget(): [number, number] {
		const angles = this.movementControls.getCameraTargetAngles();
		return [angles.phi, angles.theta];
	}

	focusFrom(ids_array_opt: number[] | null, vertAngle: number, horAngle: number, durationMultiplier:number = 1): boolean {
		if (!Utils.isNumber(vertAngle)) {
			LegacyLogger.error('invalid azimuthal angle phi ', vertAngle);
			return false;
		}
		if (!Utils.isNumber(horAngle)) {
			LegacyLogger.error('invalid polar angle theta ', horAngle);
			return false;
		}
		const ids = this._getActiveIdsOrAll(ids_array_opt);
		const bounds = this._calcBoundsForHandlesOrClipbox(ids);
		this.movementControls.focusCameraOnBounds(bounds, vertAngle, horAngle, durationMultiplier);
		return true;
	}

	focusFromByType(idsByType: IdBimScene[], vertAngle: number, horAngle: number, durationMultiplier: number = 1): void {
		const bounds = this._calcBoundsForIdsByTypeOrClipbox(idsByType);
		this.movementControls.focusCameraOnBounds(bounds, vertAngle, horAngle, durationMultiplier);
	}

	setCameraAngles(vert: number, hor: number, speedMult?: number) {
		const p = this.movementControls.targetPosition?.clone() ?? this.movementControls.target.clone();
		const sph = this.movementControls.targetSpherical?.clone() ?? this.movementControls.spherical.clone();
		sph.theta = hor;
		sph.phi = vert;
		this.movementControls.setTarget(p, sph, this.movementControls.getOrthoSize(), speedMult ?? 1);
	}

	toggleFirstPerson(b_enabled: boolean) {
		return this.movementControls.toggleFirstPerson(!!b_enabled);
	}

	setEdgesRenderMode(edgesRenderMode: number) {
		let rmode = checkEdgesRenderMode(edgesRenderMode);
		if (rmode === null) {
			LegacyLogger.error('uknown edges render mode', rmode);
			return;
		}
		this.renderSettings.applyPatch({ patch: { _edgesRenderMode: edgesRenderMode } });
	}

	calculateBoundsCenter(idsArray: number[]): [number, number, number] {
		const bounds = this.engineScene.calcBoundsByIds(idsArray);
		const center = bounds.getCenter_t();
		return [center.x, center.y, center.z];
	}

	_getActiveIdsOrAll(ids?: optionalIdsArray) {
		let res: IdBimScene[];
		const instances = this.bim.instances.perId;
		if (ids) {
			res = [];
			for (const id of ids) {
				if (instances.get(id)) {
					res.push(id);
				}
			}
		} else {
			res = Array.from(instances.keys());
		}
		return res;
	}

	toggleClipbox(enabled:boolean) {
		this._toggleClipbox(enabled, MeshRenderType.None);
	}

	setClipboxRendermodeOutside(str_rendermodeOutside:string) {
		let renderType = renderTypeFromString(str_rendermodeOutside);
		this.renderSettings.applyPatch({ patch: { _renderTypeOutsideClipbox: renderType } });
	}

	focusClipbox(ids_array_opt?: number[]) {
		if (!this.clipBox.isEnabled()) {
			this.clipBox.state.applyPatch({ patch: { isActive: true } });
		}
		const bounds = Aabb.empty();
		if (ids_array_opt) {
			bounds.union(this.engineScene.calcBoundsByIds(ids_array_opt));
		}
		if (bounds.isEmpty()) {
			this.clipBox.setTarget(this.totalBounds.bounds);
		} else {
			this.clipBox.setTarget(bounds);
		}
	}

	_toggleClipbox(enabled: boolean, renderType: MeshRenderType) {
		if (renderType !== undefined) {
			this.renderSettings.applyPatch({ patch: { _renderTypeOutsideClipbox: renderType } });
		}
		this.clipBox.setState(enabled);
	}

	_calcBoundsForIdsByTypeOrClipbox(typedIds: IdBimScene[]): Aabb {
		const bounds = this.engineScene.calcBoundsByIds(typedIds);
		if (bounds.isEmpty() && this.clipBox.isEnabled()) {
			bounds.copy(this.clipBox.getBounds());
		}
		if (bounds.isEmpty()) {
			bounds.copy(this.totalBounds.bounds);
		}
		return bounds;
	}

	_clipboxToggleAndFocus(ids: IdBimScene[]) {
		this.clipBox.state.forceResetThrottle();
		const elementsBounds = this.engineScene.calcBoundsByIds(ids);
		if (elementsBounds.isEmpty()) {
			if (this.clipBox.isMaxedOut(this.totalBounds)) {
				this.clipBox.state.applyPatch({ patch: { isActive: !this.clipBox.isEnabled() } });
			} else {
				this.clipBox.setTarget(this.totalBounds.bounds);
			}
		} else {
			if (Utils.areOptionalArraysEqualSorted(ids, this.clipboxLastFocusIds)) {
				if (this.clipBox.isMaxedOut(this.totalBounds)) {
					this.clipBox.setTarget(elementsBounds);
				} else {
					this.clipBox.setTarget(this.totalBounds.bounds);
				}
			} else {
				this.clipBox.state.applyPatch({ patch: { isActive: true } });
				this.clipBox.setTarget(elementsBounds);
			}
		}
		this.clipboxLastFocusIds = ids;
	}

	getInfoForMark() {
		return this.input.getInfoForMark();
	}

	getScreenCoordsOfPoint(pointArr:number[]): number[] | null{
		const v_t = Vector3.fromArray(pointArr, 0);
		if (!this.movementControls.frustum.frustum.containsPoint(v_t)) {
			LegacyLogger.warn('point is not visible to engine camera, check with isPointVisible before using this method');
			return null;
		}
		v_t.applyMatrix4(this.movementControls.camera.matrixWorldInverse as any);
		v_t.applyMatrix4(this.movementControls.camera.projectionMatrix as any);
		return [(v_t.x + 1) / 2 * this.container.clientWidth, (1 - v_t.y) / 2 * this.container.clientHeight];
	}

	isPointVisible(pointArr:number[]): boolean {
		const v_t = Vector3.fromArray(pointArr, 0);
		return this.movementControls.frustum.frustum.containsPoint(v_t);
	}

	isAnyInvisible() {
		return this.bim.instances.some(s => s.isHidden);
	}

	isAnyColorTinted() {
		return this.bim.instances.some(s => s.colorTint > 0);
	}

	isColorTinted(id: number) {
		const s = this.bim.instances.peekById(id);
		if (!s) {
			return false;
		}
		return s.colorTint > 0;
	}

	toggleCanvasTransparency(enabled: boolean) {
		const alpha = enabled ? 0 : 1;
		this.renderSettings.applyPatch({
			patch: {
				_backgroundAlpha: alpha,
			}
		});
	}

	getUiUnits(): EngineUnits {
		return this.uiUnits.getUnits();
	}

	setUiUnits(units: Partial<EngineUnits>) {
		this.uiUnits.setUnits(units);
	}

	async takecreenshotTopdown(wsCoords: Aabb2, screenshotSize: Vector2, renderSettings?: Partial<EngineGraphicsSettings>) {
		const totalSceneBounds = this.totalBounds.bounds;
		const camera = new OrthographicCamera(-0.5, 0.5, 0.5, -0.5, totalSceneBounds.minz() - 100, totalSceneBounds.maxz() + 100);
		camera.zoom = screenshotSize.x / screenshotSize.y / wsCoords.getSize().x;
		camera.position.set(wsCoords.getCenter().x, wsCoords.getCenter().y, 0);
		camera.updateMatrixWorld(true);
		const routine = this._screenshotRoutine({camera, width: screenshotSize.x, height: screenshotSize.y, renderOverlays: false, renderSettings});
		return this.tasksRunner.scheduleGenerator<ArrayBuffer>(
			routine,
			scr => scr instanceof ArrayBuffer
		);
	}

	async takeScreenshotRawPng(width: number, height: number, renderSettings?: Partial<EngineGraphicsSettings>): Promise<ArrayBuffer> {
		const camera = this.movementControls.camera.clone();
		const routine = this._screenshotRoutine({camera, width, height, renderOverlays: true, renderSettings});
		const promise = this.tasksRunner.scheduleGenerator<ArrayBuffer>(
			routine,
			scr => scr instanceof ArrayBuffer
		);
		promise.finally(() => {

		});
		return promise;
	}

	async takeScreenshot(width: number, height: number, renderSettings?: Partial<EngineGraphicsSettings>): Promise<Screenshot> {
		const raw = await this.takeScreenshotRawPng(width, height, renderSettings);
		return createScreenshot(width, height, raw);
	}

	async takeHighResScreenshotPng(renderSettings?: Partial<EngineGraphicsSettings>): Promise<ArrayBuffer> {
		const currentAspect = KrMath.clamp(this.movementControls.getAspect(), 0.5, 4);
		// choose size with current aspect ratio
		const desiredSize = 4096;
		const desiredWidth = Math.round(desiredSize * Math.sqrt(currentAspect));
		const desiredHeight = Math.round(desiredSize / Math.sqrt(currentAspect));
		return this.takeScreenshotRawPng(desiredWidth, desiredHeight, renderSettings);
	}


	private *_screenshotRoutine(args: {
		camera: KrCamera,
		width: number,
		height: number,
		renderOverlays?: boolean,
		renderSettings?: Partial<EngineGraphicsSettings>
	}): Generator<Yield, ArrayBuffer> {

		yield* this.waitForEngineSceneToBeReady();

		if (this._checkScreenshotParams(args.width, args.height)) {

			this.finishAnimations();

			const renderSettings = ObjectUtils.deepCloneObj(this.renderSettings.currentValue());
			if (args.renderSettings) {
				if (args.renderSettings.backgroundColor !== undefined) {
					renderSettings._backgroundColor = RGBA.colorOnlyFromHex(args.renderSettings.backgroundColor);
					renderSettings._backgroundAlpha = RGBA.alphaOnlyFromHex(args.renderSettings.backgroundColor);
				}
			}
			
			const frustum = new FrustumExt(2, args.camera);
			frustum.update(args.camera);
			const composer = new StdFrameComposer({
				ident: 'screenshort-composer',
				logger: this.logger.newScope('screenshot-compoer'),
				gpuRes: this.gpuResources,
				graphicsSettings: new LazyBasic('screenshot-render-params', renderSettings),
				frustum: frustum,
				engine: this,
				renderToScreen: false,
				renderOverlays: args.renderOverlays ?? true,
			});
			let pixels: Uint8Array | null = null;
			try {
				const rtWidth = Math.round(args.width);
				const rtHeight = Math.round(args.height);
				const aspect = rtWidth / rtHeight;	
				composer.setSize(rtWidth, rtHeight);
				if (args.camera instanceof PerspectiveCamera) {
					setPerspCameraAspectFov(args.camera, aspect);
				} else if (args.camera instanceof OrthographicCamera) {
					setOrthoCameraAspect(args.camera, aspect);
				}
				args.camera.updateMatrixWorld(true);
				args.camera.updateProjectionMatrix();
	
				this.forceFullFrameNextTime = true; // force engine to draw full frame next time
				yield Yield.NextFrame;
	
				const timeBudget = new RenderTimeBudget();
	
				for (let i = 0; ; ++i) {
					if (i > 1000) {
						throw new Error('process takes too long');
					}
					timeBudget.startNewFrame();
					if (composer.render(timeBudget)) {
						break;
					}
					const shouldStop = timeBudget.gpuTimeBudgetLeft() < 0 && timeBudget.jsTimeBudgetLeft() < 5;
					yield shouldStop ? Yield.NextFrame : Yield.Asap;
				}
	
				yield Yield.NextFrame;
				pixels = composer.downsampleAndReadPixels(composer.geLastRTIdent(), args.width, args.height, true);
			} finally {
				frustum.dispose();
				composer.dispose();
			}

			if (pixels != null) {
				const buffer = PngConverter.encode([pixels.buffer], args.width, args.height, 0);
				return buffer;

			} else {
				throw new Error('couldnt read pixels');
			}
		} else {
			throw new Error('invalid screenshot params');
		}
	}

	finishAnimations() {
		this.time.fastForwardAnimations();
	}

	clear() {
		// this.submeshes.render_jobs
		this.gpuResources.geometries.clear();
		this.gpuResources.materials.clear();
		this.materialsFactory.clear();

		this.tasksRunner.clear(true);
		this.undoStack.clear();

		this.totalBounds.reset();

		this.sceneRaycaster.clear();
		this.clipBox.setImmediately(this.totalBounds.bounds);

		this.cameraToHome();

		// this.clipboxAnimationControls.current.copy(this.sceneBounds);
	}

	toggleParallelProjection(enabled: boolean) {
		this.movementControls.setProjType(enabled ? CameraProjectionMode.Orthographic : CameraProjectionMode.Perspective);
	}

	isProjectionParallel() : boolean {
		return this.movementControls.getProjType() === CameraProjectionMode.Orthographic;
	}

	private _checkScreenshotParams(width:number, height:number) {
		if (!(width >= MinScreenshotSideSize && width <= MaxScreenshotSideSize)) {
			LegacyLogger.error('takeScreenshot: invalid width', width);
			return false;
		}
		if (!(height >= MinScreenshotSideSize && height <= MaxScreenshotSideSize)) {
			LegacyLogger.error('takeScreenshot: invalid height', height);
			return false;
		}
		return true;
	}

	*_waitForTexturesLoadsRoutine() {
		const startT = performance.now();
		while (this.textures.areTherePendingTextureLoads()) {
			if (performance.now() - startT > 1000 * 60 * 5) { // 5 max minutes waiting time
				throw new Error('5 minute wait time for textures load was surpassed, aborting');
			}
			yield Yield.NextFrame;
		}
		const notLoadedTextures = this.textures.getTexturesWithLoadErrors();
		if (notLoadedTextures.length > 0) {
			throw new Error('textures were not loaded ' + notLoadedTextures.join(','));
		}
	}

	getPositionInFrontOfCameraForImport() {
		const camera = this.movementControls.camera;
		let pos: Vector3 | undefined = undefined;

		if (this.transformGizmo.gizmoState.isActive()) {
			const cameraFrustum = new KrFrustum().setFromCamera(camera);
			const tr = this.transformGizmo.gizmoState.getGizmoPosition();
			if (tr !== null && cameraFrustum.containsPoint(tr.position)) {
				pos = tr.position.clone();
			}
		}
		if (pos === undefined) {
			const ray = new RaySection().setFromCamera(camera, 0, 0);
			pos = new Vector3();
			if (!ray.ray.intersectPlane_t(new Plane(new Vector3(0, 0, 1), 0), pos)) {
				pos = ray.ray.at(10, pos);
			}
		}
		return pos;
	}

	_dispose() {
		super.dispose();
		try {
			this.input.dispose();
			this.materialsFactory.dispose();
			this.textures.dispose();
			this.gizmosController.dispose();
			this.composer.dispose();
			this.serviceCoroutines.clear(false);
			this.textGeometries.dispose();
			this.gpuResources.dispose();
			this.container.removeChild(this.renderer.domElement);
			this.engineScene.dispose();
			this.compass.dispose();
		} catch (e) {
			console.error(e);
		}
	}
}

const _reusedMatrix = new Matrix4();

function replaceThreesUUIDGenerator() {
	// replace long uuids with simple counter
	// it's good enough for our purposes, and significantly decreases heap size
	// saves 5mb(10%) for Mayak0604, 90mb(23%) for HighRise
	_Math.generateUUID = function () {
		var counter = 66;
		return function () {
			return (counter++).toString(36);
		}
	}();
}
