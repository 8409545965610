import { IterUtils, LegacyLogger } from 'engine-utils-ts';
import { StringProperty, NumberProperty, BooleanProperty } from '../properties/PrimitiveProps';
import { PropsGroupBase } from '../properties/Props';
import { PropsFieldOneOf, PropsFieldFlags } from '../properties/PropsGroupComplexDefaults';
import { PropsGroupsRegistry } from '../properties/PropsGroupsRegistry';
import { SmallNumericArrayProperty } from '../properties/SmallNumberArrayProperty';
import { KrMath, Vector3 } from 'math-ts';
import { PilesProfilesProperty } from './PileProfileType';
import type { AnyTrackerProps } from './AnyTracker';
import { TrackerWindPosition } from './TrackerFeatures';
import { PilesFeaturesAndOffsetsProperty, type PileFeaturesFlags, getPileMotorType, getPileUndulationType, type PileFrameOffset, PileMotorType, newPileOffset, getDefaultPileFeaturesFor, PileUndulationType } from './PilesFeatures';
import type { PilesIndicesAndPositionsProperty } from './PilesIndices';



type PerWindPositionPileLengths = {
    [key in TrackerWindPosition]: PilesFeaturesAndOffsetsProperty | null;
}

export class PilesConfigsPerWindPosition extends PropsGroupBase implements PerWindPositionPileLengths {
    same_piles_offsets: BooleanProperty;

    interior: PilesFeaturesAndOffsetsProperty | null;
    exterior: PilesFeaturesAndOffsetsProperty | null;
    edge: PilesFeaturesAndOffsetsProperty | null;
    edge_bot: PilesFeaturesAndOffsetsProperty | null;
    edge_top: PilesFeaturesAndOffsetsProperty | null;

    constructor(args: Partial<PilesConfigsPerWindPosition>) {
        super();
        this.same_piles_offsets = args.same_piles_offsets ?? BooleanProperty.new({value: false});
        this.interior = args.interior ?? null;
        this.exterior = args.exterior ?? null;
        this.edge = args.edge ?? null;
        this.edge_bot = args.edge_bot ?? null;
        this.edge_top = args.edge_top ?? null;
    }

    getDefaultWindPosition(): TrackerWindPosition | null {
        if (this.interior) {
            return TrackerWindPosition.Interior;
        }
        if (this.exterior) {
            return TrackerWindPosition.Exterior;
        }
        if (this.edge) {
            return TrackerWindPosition.Edge;
        }
        if (this.edge_bot) {
            return TrackerWindPosition.EdgeBot;
        }
        if (this.edge_top) {
            return TrackerWindPosition.EdgeTop;
        }

        return null;
    }

    getDefault(): PilesFeaturesAndOffsetsProperty | null {
        const defaultWindPosition = this.getDefaultWindPosition();
        return defaultWindPosition ? this[defaultWindPosition] : null;
    }

    static _nextToTry(windPosition: TrackerWindPosition): TrackerWindPosition | null {
        switch (windPosition) {
            case TrackerWindPosition.EdgeBot:
            case TrackerWindPosition.EdgeTop:
                return TrackerWindPosition.Edge;
            case TrackerWindPosition.Edge:
            case TrackerWindPosition.Exterior:
                return TrackerWindPosition.Interior;
        }
        return null;
    }

    getFromWindPosition(windPosition: TrackerWindPosition): PilesFeaturesAndOffsetsProperty | null {
        let wp: TrackerWindPosition|null = windPosition;
        do {
            const piles = this[wp!];
            if (piles instanceof PilesFeaturesAndOffsetsProperty) {
                return piles;
            }
            wp = PilesConfigsPerWindPosition._nextToTry(wp);
        } while (wp !== null);
        return null;
    }

    *iterWindPositions(): Generator<[TrackerWindPosition, PilesFeaturesAndOffsetsProperty | null]> { 
        for (const windPosition of Object.values(TrackerWindPosition)) {
            const piles = this[windPosition];
            if (piles instanceof PilesFeaturesAndOffsetsProperty || piles === null) {
                yield [windPosition, piles];
            }
        }
    }

    getFromWindPositionString(windPositionStr: string): PilesFeaturesAndOffsetsProperty | null {
        if (!(windPositionStr in this)) {
            throw new Error(`unexpected wind positions string: ${windPositionStr}`);
        }
        return this.getFromWindPosition(windPositionStr as TrackerWindPosition);
    }

    static getDefaultPilesFeaturesForWindPosition(windPosition: TrackerWindPosition, piles: PilesFeaturesAndOffsetsProperty): PilesFeaturesAndOffsetsProperty {
        const features: PileFeaturesFlags[] = [];
        const hasMotor = piles.features.some(f => getPileMotorType(f) === PileMotorType.Motor);
        for (let i = 0; i < piles.count; ++i) {
            const feature = piles.features[i];
            let motor = getPileMotorType(feature);
            if (!hasMotor && Math.ceil(piles.count / 2) === (i + 1)) {
                motor = PileMotorType.Motor;
            }
            const undulation = getPileUndulationType(feature);

            const newFeatures = getDefaultPileFeaturesFor(
                windPosition,
                i,
                piles.count,
                motor,
                undulation
            );
            features.push(newFeatures);
        }

        return PilesFeaturesAndOffsetsProperty.new({features: features, offsets: piles.offsets});
    }

    static newWithAllDefault(piles: PilesFeaturesAndOffsetsProperty){
        return new PilesConfigsPerWindPosition({
            [TrackerWindPosition.Exterior]: PilesConfigsPerWindPosition.getDefaultPilesFeaturesForWindPosition(TrackerWindPosition.Exterior, piles),
            [TrackerWindPosition.Interior]: PilesConfigsPerWindPosition.getDefaultPilesFeaturesForWindPosition(TrackerWindPosition.Interior, piles),
            [TrackerWindPosition.Edge]: PilesConfigsPerWindPosition.getDefaultPilesFeaturesForWindPosition(TrackerWindPosition.Edge, piles),
            [TrackerWindPosition.EdgeTop]: PilesConfigsPerWindPosition.getDefaultPilesFeaturesForWindPosition(TrackerWindPosition.EdgeTop, piles),
            [TrackerWindPosition.EdgeBot]: PilesConfigsPerWindPosition.getDefaultPilesFeaturesForWindPosition(TrackerWindPosition.EdgeBot, piles),
        });
    }
}
PropsGroupsRegistry.register({
    class: PilesConfigsPerWindPosition,
    complexDefaults: {
        "interior": new PropsFieldOneOf(PropsFieldFlags.None, null, PilesFeaturesAndOffsetsProperty),
        "exterior": new PropsFieldOneOf(PropsFieldFlags.None, null, PilesFeaturesAndOffsetsProperty),
        "edge": new PropsFieldOneOf(PropsFieldFlags.None, null, PilesFeaturesAndOffsetsProperty),
        "edge_bot": new PropsFieldOneOf(PropsFieldFlags.None, null, PilesFeaturesAndOffsetsProperty),
        "edge_top": new PropsFieldOneOf(PropsFieldFlags.None, null, PilesFeaturesAndOffsetsProperty),
    }
});


export class PilesConfigModulesRow extends PropsGroupBase {
    modules_row: StringProperty;

    constructor(args: Partial<PilesConfigModulesRow>) {
        super();
        this.modules_row = args.modules_row ?? StringProperty.new({value: ``});
    }

    createPilesDescriptions(props: AnyTrackerProps): PilesFeaturesAndOffsetsProperty | null {
        const windPosition = props.position.wind_load_position?.value as TrackerWindPosition ?? TrackerWindPosition.Interior;
        return this.createPilesDescriptionsBase(windPosition);
    }

    createPilesDescriptionsBase(windPosition: TrackerWindPosition): PilesFeaturesAndOffsetsProperty | null {

        const parts = IterUtils.filterMap(
            this.modules_row.value.split(/\s*(?:;|-|\s|,|$)\s*/),
            (p) => {
                if (p.toUpperCase() === "M") {
                    return "M";
                }
                const asInt = Number.parseInt(p);
                if (asInt >= 0 && asInt <= 255) {
                    return asInt;
                }
                return undefined;
            }
        );

        const pilesAtModules: number[] = [];
        const motorsAtPilesIndices: number[] = [];

        {
            let currentOffsetInModules = 0;
            for (let i = 0; i < parts.length; ++i) {
                const p = parts[i];
                if (typeof p === 'number') {
                    currentOffsetInModules += p;
                    if (i < parts.length - 1) {
                        pilesAtModules.push(currentOffsetInModules);
                    }
                } else if (p === "M") {
                    if (pilesAtModules.length === 0) {
                        pilesAtModules.push(0);
                    }
                    motorsAtPilesIndices.push(pilesAtModules.length - 1);
                } else {
                    LegacyLogger.deferredWarn(`unexpected part, parsing modules row`, p);
                }
            }
        }

        const piles_offsets: PileFrameOffset[] = [];
        const piles_features: PileFeaturesFlags[] = [];

        for (let i = 0 ; i < pilesAtModules.length; ++i) {

            const offsetInModules = pilesAtModules[i];
            const offset = newPileOffset(offsetInModules, 0);

            const motor: PileMotorType = motorsAtPilesIndices.includes(i) ? PileMotorType.Motor : PileMotorType.None;
            const features = getDefaultPileFeaturesFor(
                windPosition, i, pilesAtModules.length, motor, PileUndulationType.Undulated
            );

            piles_offsets.push(offset);
            piles_features.push(features);
        }

        return piles_offsets.length > 0 ?
            PilesFeaturesAndOffsetsProperty.new({features: piles_features, offsets: piles_offsets})
            : null;
    }
}
PropsGroupsRegistry.register({
    class: PilesConfigModulesRow,
    complexDefaults: {
    }
});


export class PilesConfigModuleBaySize extends PropsGroupBase {
    first_pile_offset: NumberProperty;
    module_bay_size: NumberProperty;
    motor_at_pile: NumberProperty;

    constructor(args: Partial<PilesConfigModuleBaySize>) {
        super();
        this.first_pile_offset = args.first_pile_offset ?? NumberProperty.new({value: 0, range: [0, 255], step: 1});
        this.module_bay_size = args.module_bay_size ?? NumberProperty.new({value: 1, range: [1, 255], step: 1});
        this.motor_at_pile = args.motor_at_pile ?? NumberProperty.new({value: 0, range: [0, 10_000], step: 1});
    }

    createPilesDescriptionsBase(args: {
        modules_rows_count: number,
        wind_position: TrackerWindPosition,
    }): PilesFeaturesAndOffsetsProperty | null {
        
        let motor_placement = KrMath.clamp(this.motor_at_pile.value, 0, 10_000);
        const first_module_offset = Math.max(0, this.first_pile_offset.value | 0);
        
        const module_bay_size = Math.max(1, this.module_bay_size.value | 0);

        const piles_offsets: PileFrameOffset[] = [];
        const piles_features: PileFeaturesFlags[] = [];

        for (let inModsOffset = first_module_offset; inModsOffset < args.modules_rows_count + module_bay_size - 1; inModsOffset += module_bay_size) {
            const offset = newPileOffset(Math.min(inModsOffset, args.modules_rows_count), 0);
            piles_offsets.push(offset);
        }

        motor_placement = KrMath.clamp(motor_placement, 0, piles_offsets.length - 1);

        for (let i = 0; i < piles_offsets.length; ++i) {
            const hasMotor = motor_placement === i;
            const motor: PileMotorType = hasMotor ? PileMotorType.Motor : PileMotorType.None;
            const features = getDefaultPileFeaturesFor(args.wind_position, i, piles_offsets.length, motor, PileUndulationType.Undulated);
            piles_features.push(features);
        }

        return piles_features.length > 0 ?
            PilesFeaturesAndOffsetsProperty.new({features: piles_features, offsets: piles_offsets})
            : null;
    }

    createPilesDescriptions(props: AnyTrackerProps): PilesFeaturesAndOffsetsProperty | null {
        const strings_count = props.tracker_frame.dimensions.strings_count.value;
        const in_string_x_count = props.tracker_frame.string.modules_count_x.value;
        const modules_rows_count = Math.max(0, (strings_count * in_string_x_count) | 0);
        const wind_position = props.position.wind_load_position?.value as TrackerWindPosition ?? TrackerWindPosition.Interior;
        return this.createPilesDescriptionsBase({modules_rows_count, wind_position});
    }
}
PropsGroupsRegistry.register({
    class: PilesConfigModuleBaySize,
    complexDefaults: {
    }
});

export const DefaultPileLength = 2.4384;// 8 feet

export class PilesProps extends PropsGroupBase {

    // active_configuration_wind_position: StringProperty | null;;
    active_configuration: PilesFeaturesAndOffsetsProperty | null;

    profiles: PilesProfilesProperty | null; // can by computed and set by user
    
    lengths: SmallNumericArrayProperty<number> | null; // can be computed by cutfill and set by user
    _pile_tops_distance_to_ground: SmallNumericArrayProperty<number> | null; // computed by runtime

    indices: PilesIndicesAndPositionsProperty | null; // computed by runtime

    _segments_slopes: SmallNumericArrayProperty<number> | null; // computed by cutfill

    constructor(args: Partial<PilesProps>) {
        super();
        
        this.active_configuration = args.active_configuration ?? null;
        this.profiles = args.profiles ?? null;
        this.lengths = args.lengths ?? null;
        this._pile_tops_distance_to_ground = args._pile_tops_distance_to_ground ?? null;
        this.indices = args.indices ?? null;

        this._segments_slopes = args._segments_slopes ?? null;
    }

    setDescriptions(descriptions: PilesFeaturesAndOffsetsProperty|null) {
        this.active_configuration = descriptions;
        if (!descriptions || descriptions.count === 0) {
            this.profiles = null;
            this.lengths = null;
        } else {
            if (this.lengths?.count !== descriptions.count) {
                this.lengths = new SmallNumericArrayProperty({
                    values: IterUtils.newArray(descriptions.count, () => DefaultPileLength),
                    unit: 'm',
                });
            }
            if (this.profiles?.count !== descriptions.count) {
                this.profiles = PilesProfilesProperty.newDefaultPilesCrossSections(descriptions);
            }
        }
    }

    get piles_count(): NumberProperty {
        let count: number = 0;
        if (this.active_configuration) {
            count = this.active_configuration.count;
        }
        return NumberProperty.new({value: count, isReadonly: true, step: 1});
    }

    get reveal(): SmallNumericArrayProperty<number> | null {
        if (!this._pile_tops_distance_to_ground || !this.lengths
            || (this._pile_tops_distance_to_ground.count !== this.lengths.count)
        ) {
            return null;
        }
        const lengthValues = this.lengths.values;
        const distanceValues = this._pile_tops_distance_to_ground.values;
        const reveal = new SmallNumericArrayProperty({
            values: distanceValues.map(
                (d, i) => Math.min(Math.max(d, 0), lengthValues[i])
            ),
            unit: 'm',
        });
        return reveal;
    }
    get embedment(): SmallNumericArrayProperty<number> | null {
        if (!this._pile_tops_distance_to_ground || !this.lengths
            || (this._pile_tops_distance_to_ground.count !== this.lengths.count)
        ) {
            return null;
        }
        const lengthValues = this.lengths.values;
        const distanceValues = this._pile_tops_distance_to_ground.values;
        const embedment = new SmallNumericArrayProperty({
            values: lengthValues.map(
                (l, i) => Math.max(l - distanceValues[i], 0)
            ),
            unit: 'm',
        });
        return embedment;
    }
}
PropsGroupsRegistry.register({
    class: PilesProps,
    complexDefaults: {
        active_configuration: new PropsFieldOneOf(PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization, null, PilesFeaturesAndOffsetsProperty),
        profiles: new PropsFieldOneOf(PropsFieldFlags.SkipClone, null, PilesProfilesProperty),
        lengths: new PropsFieldOneOf(PropsFieldFlags.SkipClone, null, SmallNumericArrayProperty),
        _pile_tops_distance_to_ground: new PropsFieldOneOf(PropsFieldFlags.SkipClone, null, SmallNumericArrayProperty),
        indices: new PropsFieldOneOf(PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization, null, SmallNumericArrayProperty),
        _segments_slopes: new PropsFieldOneOf(PropsFieldFlags.SkipClone, null, SmallNumericArrayProperty),
        embedment: new PropsFieldOneOf(PropsFieldFlags.SkipSerialization, null, SmallNumericArrayProperty),
        reveal: new PropsFieldOneOf(PropsFieldFlags.SkipSerialization, null, SmallNumericArrayProperty),
    },
});



export function calculateTrackerPilesConfig(trackerProps: AnyTrackerProps): PilesFeaturesAndOffsetsProperty | null {
    
    const pilesConfigs = trackerProps.tracker_frame.piles_configurations;

    if (pilesConfigs instanceof PilesConfigModulesRow
        || pilesConfigs instanceof PilesConfigModuleBaySize
    ) {
        return pilesConfigs.createPilesDescriptions(trackerProps);

    } else if (pilesConfigs instanceof PilesConfigsPerWindPosition) {

        const windPositionStr = trackerProps.position.wind_load_position?.value;
        if (!windPositionStr) {
            return pilesConfigs.getDefault();
        } else {
            return pilesConfigs.getFromWindPositionString(windPositionStr);
        }
    } else {
        LegacyLogger.deferredError(`${calculateTrackerPilesConfig.name}(): unrecognized tracker props piles_configurations`, pilesConfigs);
        return null;
    }
}


export function getCharCodesForPileIndex(index: number, charCodes: number[]) {
    if(index >= 26) {
        const nextIndex = ((index / 26) >> 0) - 1;
        getCharCodesForPileIndex(nextIndex, charCodes);
    }

    charCodes.push(65 + (index % 26 >> 0))
}


export function calculateTorqueTubePoints(
    pilesOffsets: number[], 
    segmentsSlopes: number[],
    tubeLength: number | undefined, 
): Vector3[] {
    const pilesCoords = pilesOffsets.map(o => -o);
    
    const torqueTubePoints: Vector3[] = [new Vector3()];
    const tubeCenterOffset = new Vector3();
    
    let pileCoord: number;
    if (tubeLength) {
        pileCoord = 0.5 * tubeLength;
    } else {
        pileCoord = pilesCoords[0];
    }
    if (pilesCoords[1] < 0) {
        tubeCenterOffset.set(0,
            (0 - pileCoord) * Math.cos(segmentsSlopes[0]),
            (0 - pileCoord) * Math.sin(segmentsSlopes[0])
        );
    }
    torqueTubePoints.push(new Vector3(0,
        (pilesCoords[1] - pileCoord) * Math.cos(segmentsSlopes[0]),
        -(pilesCoords[1] - pileCoord) * Math.sin(segmentsSlopes[0])
    ));

    for (let i = 1; i < segmentsSlopes.length - 1; ++i) {
        if (pilesCoords[i + 1] <= 0 && pilesCoords[i] >= 0) {
            tubeCenterOffset.set(0,
                torqueTubePoints.at(-1)!.y + (0 - pilesCoords[i]) * Math.cos(segmentsSlopes[i]),
                torqueTubePoints.at(-1)!.z - (0 - pilesCoords[i]) * Math.sin(segmentsSlopes[i])
            );
        }
        torqueTubePoints.push(new Vector3(0,
            torqueTubePoints.at(-1)!.y + (pilesCoords[i + 1] - pilesCoords[i]) * Math.cos(segmentsSlopes[i]),
            torqueTubePoints.at(-1)!.z - (pilesCoords[i + 1] - pilesCoords[i]) * Math.sin(segmentsSlopes[i])
        ));
    }

    if (!tubeLength) {
        pileCoord = -pilesCoords.at(-1)!;
    }
    if (pilesCoords[segmentsSlopes.length - 1] > 0) {
        tubeCenterOffset.set(0,
            torqueTubePoints.at(-1)!.y + (0 - pilesCoords.at(-2)!) * Math.cos(segmentsSlopes.at(-1)!),
            torqueTubePoints.at(-1)!.z - (0 - pilesCoords.at(-2)!) * Math.sin(segmentsSlopes.at(-1)!)
        );
    }
    torqueTubePoints.push(new Vector3(0,
        torqueTubePoints.at(-1)!.y + (-pileCoord - pilesCoords.at(-2)!) * Math.cos(segmentsSlopes.at(-1)!),
        torqueTubePoints.at(-1)!.z - (-pileCoord - pilesCoords.at(-2)!) * Math.sin(segmentsSlopes.at(-1)!)
    ));

    for (let i = 0; i < torqueTubePoints.length; ++i) {
        torqueTubePoints[i].sub(tubeCenterOffset);
    }

    return torqueTubePoints;
}