import type { RGBAHex, Result} from "engine-utils-ts";
import { Failure, LruCache, ObjectUtils, RGBA, Success } from "engine-utils-ts";
import { KrMath, Euler, Quaternion, Vector3, Transform, Vec3Z, Vec3One, combineHashCodes, Vec3YNeg } from "math-ts";
import { type EntityIdAny } from "verdata-ts";
import { IterUtils } from "engine-utils-ts";
import type { BimPropertyData } from "../../bimDescriptions/BimProperty";
import { BimProperty } from "../../bimDescriptions/BimProperty";
import type { PropertiesGroupFormatters } from "../../bimDescriptions/PropertiesGroupFormatter";
import { PropertiesGroupFormatter } from "../../bimDescriptions/PropertiesGroupFormatter";
import type { IdBimMaterial } from "../../BimMaterials";
import { BimMaterialStdRenderParams } from "../../BimMaterials";
import type { AssetBasedCatalogItemCreators } from "../../catalog/CatalogItemCollection";
import { StdSubmeshRepresentation, StdSubmeshesLod, StdMeshRepresentation } from "../../representation/Representations";
import type { ReactiveSolverBase, SolverInstancePatchResult } from "../../runtime/ReactiveSolverBase";
import { SolverObjectInstance } from "../../runtime/SolverObjectInstance";
import { registerEquipmentCommonAssetToCatalogItem } from "../EquipmentCommon";
import { createTrackerModuleCostModelLink, trackerSlopeSolver } from "../../trackers/Tracker";
import { TrackerShadingProps } from '../../trackers/shading/TrackerShadingProps';
import { createModuleMountingProps_ShapeMigration, fixModuleModelIsNumber_ShapeMigration } from "../pv-module/PVModule";
import { BimPropertiesFormatter, sceneInstanceHierarchyPropsRegistry } from "../../catalog/SceneInstanceHierarchyPropsRegistry";
import { PropsFieldFlags, PropsFieldOneOf } from '../../properties/PropsGroupComplexDefaults';
import { addEnergyPVModuleProps_ShapeMigration, addFirstSolarModuleInTrackersFix_ShapeMigration } from "../pv-module/migrations/EnergyPvModulePropsMigration";
import { TrackerFrameTypeIdentifier, removeMaxSlopeMigration } from "../TrackerFrame";
import { removeEnergyPropsMigration } from "../transformer/Transformer";
import type { PropertiesPatch } from '../../bimDescriptions/PropertiesCollection';
import type { Bim } from "../../Bim";
import type { SceneInstanceShapeMigration } from "src/scene/SceneInstancesArhetypes";
import type { IdBimGeo } from "../../geometries/BimGeometries";
import type { SceneInstance } from "../../scene/SceneInstances";
import type { NumberProperty } from "../../properties/PrimitiveProps";
import { createFocusLinkOnSample, type ExpandLegacyPropsWithCostTableLinks } from "src/cost-model/capital";
import { FixedTiltFrameUniqueProps } from "src/cost-model/capital/tables/categories/structural/racking";
import { PUI_GroupNode } from "ui-bindings";
import { PropsGroupBase } from '../../properties/Props';
import { PropsGroupsRegistry } from '../../properties/PropsGroupsRegistry';
import { EnergyYieldPerStringProducer } from 'src/energy/EnergyYieldPerStringProducer';

export const FixedTiltTypeIdent = 'fixed-tilt';

export class FixedTiltProps extends PropsGroupBase {
    shading: TrackerShadingProps | null;
    energy_per_string: EnergyYieldPerStringProducer | null;

    constructor(args: Partial<FixedTiltProps>) {
        super();
        this.shading = args.shading ?? null;
        this.energy_per_string = args.energy_per_string ?? null;
    }
}


PropsGroupsRegistry.register({
    class: FixedTiltProps,
    complexDefaults: {
        shading: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone,
            null,
            TrackerShadingProps
        ),
		energy_per_string: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization,
            null,
            EnergyYieldPerStringProducer,
        ),
    }
})


export function registerFixedTiltTracker(bim: Bim) {
	bim.instances.archetypes.registerArchetype(
        {
            type_identifier: FixedTiltTypeIdent,
            mandatoryProps: [
				{ path: ["cost_bs", "level 1"], value: "FURNISH TRACKER SUBTOTAL" },
				{ path: ["cost_bs", "level 2"], value: "Trackers" },
			],
			propsShapeMigrations: migrations(),
			propsClass: FixedTiltProps,
        }
    );
    bim.reactiveRuntimes.registerRuntimeSolver(trackerSlopeSolver('fixed-tilt'));
    bim.reactiveRuntimes.registerRuntimeSolver(fixedTrackerMeshGenerator(bim));
}


function migrations(): SceneInstanceShapeMigration[] {
    return [
        fixModuleModelIsNumber_ShapeMigration(1),
        createModuleMountingProps_ShapeMigration(2),
		//rewrite version 4 addEnergyPVModuleProps_ShapeMigration
        addEnergyPVModuleProps_ShapeMigration(5),
		addFirstSolarModuleInTrackersFix_ShapeMigration(6),
		removeMaxSlopeMigration(7),
		removeEnergyPropsMigration(8),
        parameticPropsRefactor(9),
    ];
}



export function fixedTrackerMeshGenerator(bim: Bim): ReactiveSolverBase {

	interface FixedTiltProps {
		module_size_x: number,
		module_size_y: number,
		modules_count_x: number,
		modules_count_y: number,
		is_placement_horizontal: boolean,
		string_modules_count: number,
		modules_vert_overhang: number,
		piles_spacing: number,
		purlins_overhang: number,
		pile_reveal: number,
		pile_embedment: number,
		top_chords_tilt: number,
		modules_gap: number,
		piles_off_top_center: number,
		top_chords_height: number,
		kickers_off_pile_bottom: number,
		kickers_off_top_edge: number,
		purlins_height: number,
		slope: number,
		slope_direction: number,
    };

	const DefaultFixedTrackerMeshGenInput = {
		legacyProps: {
			module_size_x: BimProperty.NewShared({path: ["module", "width"], value: 0, unit: 'm'}),
			module_size_y: BimProperty.NewShared({path: ["module", "length"], value: 0, unit: 'm'}),

			modules_count_x: BimProperty.NewShared({path: ["modules", "count_x"], value: 0}),
			modules_count_y: BimProperty.NewShared({path: ["modules", "count_y"], value: 0}),
			horiz_placement: BimProperty.NewShared({path: ["dimensions", "modules_horizontal_placement"], value: false}),
			modules_gap: BimProperty.NewShared({path: ["dimensions", "modules_gap"], value: 0, unit: 'm'}),
			top_chords_tilt: BimProperty.NewShared({path: ["dimensions", "tilt"], value: 0, unit: 'deg'}),

			pile_reveal: BimProperty.NewShared({path: ["piles", "max_reveal"], value: 0.5, unit: 'm'}),
			pile_embedment: BimProperty.NewShared({path: ["piles", "min_embedment"], value: 0.5, unit: 'm'}),
			piles_spacing: BimProperty.NewShared({path: ["piles", "spacing"], value: 5, unit: 'm'}),
			purlins_overhang: BimProperty.NewShared({path: ["piles", "purlins_max_overhang"], value: 20, unit: '%', numeric_step: 0.1}),
			piles_off_top_center: BimProperty.NewShared({path: ["piles", "off_center"], value: 0, unit: '%'}),

			kickers_off_pile_bottom: BimProperty.NewShared({path: ["kickers", "off_pile_bottom"], value: 50, unit: '%', numeric_step: 0.1}),
			kickers_off_top_edge: BimProperty.NewShared({path: ["kickers", "off_top_edge"], value: 50, unit: '%', numeric_step: 0.1}),

			purlins_height: BimProperty.NewShared({path: ["purlins", "height"], value: 0, unit: 'm'}),
			modules_vert_overhang: BimProperty.NewShared({path: ["purlins", "modules_overhang"], value: 0, unit: '%', numeric_step: 0.1}),

			top_chords_height: BimProperty.NewShared({path: ["top_chords", "height"], value: 0, unit: 'm'}),

			string_modules_count: BimProperty.NewShared({path: ["string", "modules_count"], value: 0, numeric_step: 1, numeric_range: [1, 1000]}),

			slope: BimProperty.NewShared({ path: ["position", "slope_first_to_last"], value: 0, unit: '%' }),
			slope_direction: BimProperty.NewShared({ path: ["position", "_local-slope-direction"], value: 1 }),
		},
	}


	const calcultionsCache = new LruCache<FixedTiltProps, SolverInstancePatchResult>({
        identifier: `$tracker-mesh-gen-inner-cache`,
        maxSize: 100,
        hashFn: (props: FixedTiltProps) => {
            let hash: number = 0;
            for (const path in props) {
                //@ts-ignore
                const bimProp = props[path];
                hash = combineHashCodes(ObjectUtils.primitiveHash(bimProp), hash);
            }
            return hash;
        },
        factoryFn: (props: FixedTiltProps) => {
			const moduleLength = props.is_placement_horizontal ? props.module_size_y : props.module_size_x;
			const moduleHeight = props.is_placement_horizontal ? props.module_size_x : props.module_size_y;


			const in_string_modules_count = props.string_modules_count;

			const modules_count_x = Math.max(Math.round(props.modules_count_x), 0);
			const modules_count_y = Math.max(Math.round(props.modules_count_y), 0);
			const total_modules_count = modules_count_x * modules_count_y;
			const whole_strings_count = Math.floor(total_modules_count / in_string_modules_count);
			const excess_modules = total_modules_count - whole_strings_count * in_string_modules_count;

			const pileThickness = 0.15;

			const modulesGridLength = Math.max(
				pileThickness,
				(modules_count_x * (moduleLength + props.modules_gap) - props.modules_gap)
			); // length of the purlins

			const modulesGridHeight = Math.max(
				modules_count_y * (moduleHeight + props.modules_gap) - props.modules_gap,
				0
			);

			const modulesVertOverhangPerc = KrMath.clamp(
				props.modules_vert_overhang, 0, 50
			) / 100;
			const modulesVertOverhanMeters = modulesVertOverhangPerc * moduleHeight;

			const topChordsLength = Math.max(0, modulesGridHeight - modulesVertOverhanMeters * 2);

			const pilesCount = getFixedPilesCount(
				props.piles_spacing,
				props.purlins_overhang,
				modulesGridLength
			);

			let pilesLocations1d: number[];
			if (pilesCount > 1) {
				const overhangedSpace = Math.max(modulesGridLength - (pilesCount - 1) * props.piles_spacing, 0);
				pilesLocations1d = [];
				for (let i = 0; i < pilesCount; ++i) {
					const position1D = Math.min(props.piles_spacing * i + (overhangedSpace * 0.5), modulesGridLength);
					pilesLocations1d.push(position1D);
				}
			} else {
				pilesLocations1d = [0];
			}

			const modules1dVertCoords: number[] = [];
			const purlins1dVertCoords: number[] = [];
			{
				for (let iy = 0; iy < modules_count_y; ++iy) {
					const gridOffsetDown = modulesGridHeight * -0.5;
					const gapOffsetUp = iy * props.modules_gap;

					const moduleCenterYCoord = (iy + 0.5) * moduleHeight + gridOffsetDown + gapOffsetUp;

					modules1dVertCoords.push(moduleCenterYCoord);

					// also add 2 purlin coords
					purlins1dVertCoords.push(
						moduleCenterYCoord - moduleHeight * 0.5 + modulesVertOverhanMeters,
						moduleCenterYCoord + moduleHeight * 0.5 - modulesVertOverhanMeters,
					);
				}
			}

			const pilesHeight = props.pile_reveal + props.pile_embedment;

			const gridTilt = KrMath.clamp(props.top_chords_tilt, -Math.PI / 2, Math.PI / 2);
			const gridRotationQuat = Quaternion.identity().setFromEuler(new Euler(gridTilt, 0, 0));

			const pileTopAttachmentPointLerp = KrMath.clamp(props.piles_off_top_center / 100, -1, 1);
			let pileAttachmentOffCenter = topChordsLength * 0.5 * pileTopAttachmentPointLerp * Math.sign(gridTilt || 1);
			const pileAttachmentPointRotated = new Vector3(0, pileAttachmentOffCenter, 0).applyQuaternion(gridRotationQuat);
			pileAttachmentOffCenter = pileAttachmentPointRotated.y;

            const submeshesResult: StdSubmeshRepresentation[] = [];

			const unitCubeGeoId = bim.cubeGeometries.shared!.get({
                size: Vec3One,
            })!;

			const halfLength = modulesGridLength / 2;

			let generalRotation = new Quaternion();
            const generalRotationAngle = props.slope_direction * props.slope;
            if (generalRotationAngle < 0.7854 && generalRotationAngle > -0.7854) {
                generalRotation.setFromAxisAngle(Vec3YNeg, generalRotationAngle);
            }

			const startingPointOffset = new Vector3(0, 0, props.pile_reveal).applyQuaternion(generalRotation);
			const pilesOffset = new Vector3(0, 0, -0.5 * pilesHeight)
				.applyQuaternion(generalRotation).sub(new Vector3(0, 0, -0.5 * pilesHeight));
			const topChrodsOffset = new Vector3(0, 0, -0.5 * props.top_chords_height)
				.applyQuaternion(generalRotation).sub(new Vector3(0, 0, -0.5 * props.top_chords_height));
			const doubleTopChrodsOffset = topChrodsOffset.clone().multiplyScalar(2);

			{ // per pile
				const topChordsMatId = bim.bimMaterials.shared!.get({
					name: "default",
					stdRenderParams: new BimMaterialStdRenderParams("#666666", 0, 0.9, 0.2),
				})!;
				const topChordThickness = props.top_chords_height * 0.1;
				const geoScaleForTopChords = new Vector3(topChordThickness, topChordsLength, props.top_chords_height);
				const geoScaleForPile = new Vector3(pileThickness, pileThickness, pilesHeight);

				let kickerTransformWithoutOffset: Transform;
				{
					const kickerPilePoint = new Vector3(
						0,
						pileAttachmentOffCenter,
						pilesHeight * (0.01 * props.kickers_off_pile_bottom - 1)
					).applyQuaternion(generalRotation).add(doubleTopChrodsOffset);
					const kickerTopAttachmentLerp = KrMath.clamp(props.kickers_off_top_edge / 100, 0, 1);
					const kickerTopChordPoint = new Vector3(
						0,
						Math.sign(pileAttachmentOffCenter || 1) * (topChordsLength * - 0.5 + topChordsLength * kickerTopAttachmentLerp),
						0.5 * props.top_chords_height
					).applyQuaternion(gridRotationQuat).add(topChrodsOffset);

					const kickerCenterPos = kickerPilePoint.clone().lerp(kickerTopChordPoint, 0.5);
					const kickerRotation = Quaternion.fromUnitVectors(
						Vec3Z,
						kickerTopChordPoint.clone().sub(kickerPilePoint).normalize()
					);
					const kickerScale = new Vector3(
						topChordThickness * 0.5,
						props.top_chords_height * 0.5,
						kickerPilePoint.distanceTo(kickerTopChordPoint)
					);
					kickerTransformWithoutOffset = new Transform(
						kickerCenterPos,
						kickerRotation,
						kickerScale
					);
				}

				for (const pileLocation of pilesLocations1d) {
					// add top chord
					const chordInitalPos = new Vector3(pileLocation - halfLength, 0, 0.5 * props.top_chords_height)
						.add(topChrodsOffset).add(startingPointOffset);
					submeshesResult.push(new StdSubmeshRepresentation(
						unitCubeGeoId,
						topChordsMatId,
						new Transform(chordInitalPos,
							gridRotationQuat.clone().multiply(generalRotation),
							geoScaleForTopChords
						),
					));

					// add pile
					const pileInitalPos = new Vector3(pileLocation - halfLength, pileAttachmentOffCenter, -0.5 * pilesHeight);
					submeshesResult.push(new StdSubmeshRepresentation(
						unitCubeGeoId,
						topChordsMatId,
						new Transform(
							pileInitalPos.add(pilesOffset).add(doubleTopChrodsOffset).add(startingPointOffset),
							generalRotation,
							geoScaleForPile
						),
					));

					// add kicker
					const thisKickerTransform = kickerTransformWithoutOffset.clone();
					thisKickerTransform.position.x += pileLocation - halfLength;
					thisKickerTransform.position.add(startingPointOffset);
					submeshesResult.push(new StdSubmeshRepresentation(
						unitCubeGeoId,
						topChordsMatId,
						thisKickerTransform,
					));
				}
			}

			const purlinsVertOffset = 0.5 * props.purlins_height + props.top_chords_height;
			{// purlins
				const purlinsMatId = bim.bimMaterials.shared!.get({
					name: "default",
					stdRenderParams: new BimMaterialStdRenderParams("#777777", 0, 0.9, 0.2),
				})!;
				const geoScaleForPurlins = new Vector3(modulesGridLength, props.purlins_height * 0.1, props.purlins_height);
				for (const purlin1dCoord of purlins1dVertCoords) {
					const purlinInitialPos = new Vector3(
						0,
						purlin1dCoord,
						purlinsVertOffset
					).applyQuaternion(gridRotationQuat).add(startingPointOffset);
					submeshesResult.push(new StdSubmeshRepresentation(
						unitCubeGeoId,
						purlinsMatId,
						new Transform(purlinInitialPos,
							gridRotationQuat,
							geoScaleForPurlins
						),
					));
				};
			}

			const ModuleThickness = 0.05;

			const brightColor = RGBA.newRGB(0.25, 0.25, 0.6);
			const darkerColor = RGBA.newRGB(0.0, 0.0, 0.95);
			const colorsRequired = modules_count_y < 5 ? modules_count_y + 1 : modules_count_y - 1;
			const modulesColors: RGBAHex[] = new Array(colorsRequired);
			for (let i = 0; i < colorsRequired; ++i) {
				const mix = i / (colorsRequired - 1);
				modulesColors[i] = RGBA.lerpColorOnly(brightColor, darkerColor, mix);
			}

			const modulesMaterialsIds: IdBimMaterial[] = modulesColors.map(rgba => {
				const colorAsString = RGBA.toHexRgbString(rgba);
				return bim.bimMaterials.shared!.get({
					name: "fix_tilt_module_material",
					stdRenderParams: new BimMaterialStdRenderParams(colorAsString, 0, 0.85, 0.15),
				})!;
			});
			const excessModulesMaterial = bim.bimMaterials.shared!.get({
				name: "excess_modules_material",
				stdRenderParams: new BimMaterialStdRenderParams("#5a9ced", 0, 0.85, 0.15),
			})!;
			{// modules
				const moduleGeoId = bim.cubeGeometries.shared!.get({
					size: new Vector3(props.module_size_x, props.module_size_y, ModuleThickness),
				})!;

				const moduleDefaultRotation = props.is_placement_horizontal ? Quaternion.fromAxisAngle(Vec3Z, Math.PI / 2) : Quaternion.identity();

				const modulesVertOffset = purlinsVertOffset + 0.5 * (props.purlins_height);
				const moduleRotation = moduleDefaultRotation.clone().premultiply(gridRotationQuat);


				let currentInPlaneY = 0;
				const nextFreeIndexAtEachY = new Array(modules_count_y).fill(0);

				for (let moduleI = 0; moduleI < total_modules_count; ++moduleI) {
					const stringId = Math.floor(moduleI / in_string_modules_count);
					let materialId: IdBimMaterial;
					if (stringId < whole_strings_count) {
						const matInd = stringId % modulesMaterialsIds.length;
						materialId = modulesMaterialsIds[matInd];
					} else {
						materialId = excessModulesMaterial;
					}

					const ix = nextFreeIndexAtEachY[currentInPlaneY]++;
					const iy = currentInPlaneY++;
					if (currentInPlaneY === modules_count_y) {
						currentInPlaneY = 0;
					}

					const yCoord = modules1dVertCoords[iy];
					const xCoord = (ix + 0.5) * moduleLength + ix * props.modules_gap - halfLength;
					const modulesCoord = new Vector3(
						xCoord, yCoord, modulesVertOffset
					).applyQuaternion(gridRotationQuat).add(startingPointOffset);
					submeshesResult.push(
						new StdSubmeshRepresentation(
							moduleGeoId,
							materialId,
							new Transform(modulesCoord, moduleRotation)
						)
					);

				}
			}

			let lodRepr: StdSubmeshesLod | null = null;
			if (submeshesResult.length > 0) {
				const lowLodSubmeshes: StdSubmeshRepresentation[] = [];

				const lodBoxGeo = bim.cubeGeometries.shared!.get({
					size: Vec3One,
				})!;
				lowLodSubmeshes.push(
					new StdSubmeshRepresentation(
						lodBoxGeo,
						modulesMaterialsIds[0],
						new Transform(
							new Vector3(0, 0, -pileAttachmentPointRotated.z).add(startingPointOffset),
							gridRotationQuat,
							new Vector3(modulesGridLength, modulesGridHeight, ModuleThickness),
						),
					)
				);
				const lodEnableSize = Math.max(
					2 * pileThickness,
					10 * props.modules_gap
				);
				lodRepr = new StdSubmeshesLod(
					lowLodSubmeshes,
					lodEnableSize,
				);
			}


			const gridProjectedWidth = modulesGridHeight * Math.cos(gridTilt);
			const cakeProjectedWidth = (ModuleThickness + props.top_chords_height + props.purlins_height) * Math.sin(gridTilt);
			const totalProjectedWidth = Math.max(gridProjectedWidth, cakeProjectedWidth + pileThickness);

            return {
				repr: submeshesResult.length ? new StdMeshRepresentation(submeshesResult, lodRepr, true) : null,
                legacyProps:[
                    { path: ["dimensions", "length"], value: modulesGridLength, unit: "m" },
                    { path: ["dimensions", "width"], value: totalProjectedWidth, unit: "m" },
                    { path: ["modules", "count"], value: total_modules_count, numeric_step: 1 },
                    { path: ["modules", "count_in_strings"], value: whole_strings_count * in_string_modules_count, numeric_step: 1 },
                    { path: ["modules", "count_leftover"], value: excess_modules, numeric_step: 1 },
                    { path: ["dimensions", "strings_count"], value: whole_strings_count, numeric_step: 1 },
					{ path: ["piles", "count"], value: pilesCount },
                    { path: ["piles", "length"], value: pilesHeight, unit: "m"},
                    { path: ["string", "modules_count_x"], value: Math.round(props.string_modules_count / modules_count_y), numeric_step: 1, readonly: true },
                ]
            };
		}
	});

    return new SolverObjectInstance({
        solverIdentifier: 'fixed-tilt-mesh-gen',
        objectsDefaultArgs: DefaultFixedTrackerMeshGenInput,
        objectsIdentifier: 'fixed-tilt',
        cache: false,
        solverFunction: (inputObj): SolverInstancePatchResult => {
            const props: FixedTiltProps = {
                module_size_x: inputObj.legacyProps.module_size_x.as('m'),
                module_size_y: inputObj.legacyProps.module_size_y.as('m'),
                modules_count_x: inputObj.legacyProps.modules_count_x.asNumber(),
                modules_count_y: inputObj.legacyProps.modules_count_y.asNumber(),
				is_placement_horizontal: inputObj.legacyProps.horiz_placement.asBoolean(),
                string_modules_count: inputObj.legacyProps.string_modules_count.asNumber(),
				modules_vert_overhang: inputObj.legacyProps.modules_vert_overhang.as('%'),
				piles_spacing: inputObj.legacyProps.piles_spacing.as('m'),
				purlins_overhang: inputObj.legacyProps.purlins_overhang.as('%'),
                pile_reveal: inputObj.legacyProps.pile_reveal.as('m'),
                pile_embedment: inputObj.legacyProps.pile_embedment.as('m'),
                top_chords_tilt: inputObj.legacyProps.top_chords_tilt.as('rad'),
                modules_gap: inputObj.legacyProps.modules_gap.as('m'),
                piles_off_top_center: inputObj.legacyProps.piles_off_top_center.as('%'),
                top_chords_height: inputObj.legacyProps.top_chords_height.as('m'),
				kickers_off_pile_bottom: inputObj.legacyProps.kickers_off_pile_bottom.as('%'),
                kickers_off_top_edge: inputObj.legacyProps.kickers_off_top_edge.as('%'),
				purlins_height: inputObj.legacyProps.purlins_height.as('m'),
                slope: inputObj.legacyProps.slope.as('rad'),
                slope_direction: inputObj.legacyProps.slope_direction.asNumber(),
            }

			let angle = props.slope;
            angle = angle < 0.7854 ? KrMath.roundTo(angle, 0.001) : 0;
            props.slope = angle;

			const pilesHeight = props.pile_reveal + props.pile_embedment;

			props.pile_embedment = KrMath.roundTo(
                props.pile_embedment,
                Math.max(0.005, 0.01 * Math.floor(2 * props.pile_embedment)));
            props.pile_reveal = KrMath.roundTo(
                props.pile_reveal,
                Math.max(0.005, 0.01 * Math.floor(2 * props.pile_reveal)));

			const result = calcultionsCache.get(props);

			const legacyProps: BimPropertyData[] = [
                { path: ["piles", "length"], value: KrMath.roundTo(pilesHeight, 0.001), unit: "m", numeric_step: 0.001 },
            ];
            IterUtils.extendArray(legacyProps, result.legacyProps!);

			return {
                repr: result.repr,
                legacyProps: legacyProps
            };
        },
		invalidateInnerCache: (args: {
            geometriesIds: Set<IdBimGeo>,
            materialsIds: Set<IdBimMaterial>,
        }) => {
            const idsReused: EntityIdAny[] = [];
            function containsIdsFromSet(setToCheck: Set<EntityIdAny>) {
                for (const id of idsReused) {
                    if (setToCheck.has(id)) {
                        return true;
                    }
                }
                return false;
            }
            calcultionsCache.filter((output) => {
                if (output.repr) {
                    idsReused.length = 0;
                    output.repr.geometriesIdsReferences(idsReused);
                    if (containsIdsFromSet(args.geometriesIds)) {
                        return false;
                    }
                    idsReused.length = 0;
                    output.repr.materialIdsReferences(idsReused);
                    if (containsIdsFromSet(args.materialsIds)) {
                        return false;
                    }
                }
                if (output.reprAnalytical) {
                    idsReused.length = 0;
                    output.reprAnalytical.geometriesIdsReferences(idsReused);
                    if (containsIdsFromSet(args.geometriesIds)) {
                        return false;
                    }
                    idsReused.length = 0;
                    output.reprAnalytical.materialIdsReferences(idsReused);
                    if (containsIdsFromSet(args.materialsIds)) {
                        return false;
                    }
                }
                return true;
            });
        }
    })
}


export function getFixedPilesCount(
	pilesSpacing: number,
	purlinsOverhang: number,
	modulesGridLength: number
){
	const purlinsOverhangPerc = KrMath.clamp(
		purlinsOverhang / 100, 0, 1
	);

	const lengthOverhangMaxSum = purlinsOverhangPerc * pilesSpacing * 2;

	const pilesCount = modulesGridLength > 0 ?
		Math.max(1, Math.ceil((modulesGridLength - lengthOverhangMaxSum) / pilesSpacing)) + 1 :
		1;

	return pilesCount;
}

export const FixedTiltKeyProps = {
    model: BimProperty.NewShared({
        path: ['commercial', 'model'],
        value: 'unknown_model'
    }),
    moduleModel: BimProperty.NewShared({
        path: ['module', 'model'],
        value: 'unknown_model',
    }),
    stringModulesCount: BimProperty.NewShared({
        path: ['string', 'modules_count'],
        value: 0,
    }),
    modulesCount: BimProperty.NewShared({
        path: ['circuit', 'equipment', 'modules_count'],
        value: 0,
    }),
    length: BimProperty.NewShared({
        path: ['dimensions', 'length'],
        value: 0,
        unit: 'm',
    }),
    width: BimProperty.NewShared({
        path: ['dimensions', 'width'],
        value: 0,
        unit: 'm'
    }),
    pilesCount: BimProperty.NewShared({
        path: ['piles', 'count'],
        value: 0,
    })
};


export function registerFixedTiltKeyPropsFormatter(group: PropertiesGroupFormatters) {
    group.register(
        FixedTiltTypeIdent,
        new PropertiesGroupFormatter(
            FixedTiltKeyProps,
            (props, unitsMapper) => {
                return [
                    props.model.asText(),
                    props.moduleModel.asText(),
                    [
                        props.stringModulesCount,
                        props.modulesCount
                    ].map(x => x.valueUnitUiString(unitsMapper)).join('/') + 'MOD',
                ].join(' ');
            }
        )
    )
}


export function registerFixedTiltAssetToCatalogItem(group: AssetBasedCatalogItemCreators) {
    registerEquipmentCommonAssetToCatalogItem(FixedTiltTypeIdent, group);
}


sceneInstanceHierarchyPropsRegistry.set(
    FixedTiltTypeIdent,
    [
        new BimPropertiesFormatter(
            {
                model: BimProperty.NewShared({
                    path: ['commercial', 'model'],
                    value: '',
                }),
            },
            (props) => [props.model.asText() || 'model not specified'],
        ),
        new BimPropertiesFormatter(
            {
                module_model: BimProperty.NewShared({
                    path: ['module', 'model'],
                    value: '',
                }),
            },
            (props) => [props.module_model.asText() || 'module model not specified'],
        )
    ]
);




export function parameticPropsRefactor(toVersion: number): SceneInstanceShapeMigration {
	//
    return {
        toVersion,
        validation: {
            updatedProps: [
                { path: ['modules', 'count_x'] },
                { path: ['modules', 'count_y'] },
                { path: ['dimensions', 'modules_gap'] },
                { path: ['dimensions', 'modules_horizontal_placement'] },
            ],
            deletedProps: [
                { path: ['dimensions', 'max_length'] },
                { path: ['strings', 'min_modules_count'] },
                { path: ['strings', 'max_modules_count'] },
                { path: ['modules', 'gap'] },
                { path: ['modules', 'horizontal_placement'] },
            ],
        },
        patch: (inst) => {
			let modules_count_y = inst.properties.getPropNumberAs('modules | count_y', 1, '');
			let modules_gap = inst.properties.getPropNumberAs('modules | gap', 0.01, 'm');
			let horizontal_placement = inst.properties.get('modules | horizontal_placement')?.value ?? false;
			const string_min_modules = inst.properties.getPropNumberAs('strings | min_modules_count', 0, '');
			const string_max_modules = inst.properties.getPropNumberAs('strings | max_modules_count', 0, '');

			let modules_count_x: number;
			// calculate modules_count_x using logic from the old meshgen, not to change geometry with this migration
			{
				let module_size_x = inst.properties.getPropNumberAs('module | width', 0, 'm');
				let module_size_y = inst.properties.getPropNumberAs('module | height', 0, 'm');
				if (horizontal_placement) {
					[module_size_x, module_size_y] = [module_size_y, module_size_x];
				}
				const max_length = inst.properties.getPropNumberAs('dimensions | max_length', 0, 'm');
				const modules_gap = inst.properties.getPropNumberAs('modules | gap', 0, 'm');



				modules_count_x = module_size_x > 0 ?
					Math.floor((max_length + modules_gap) / (modules_gap + module_size_x)) :
					0;
				if (modules_count_x < string_min_modules) {
					modules_count_x = 0;
				}

				const wholeStringsCount = Math.floor(modules_count_x / string_max_modules);
				if (string_min_modules > modules_count_x - wholeStringsCount * string_max_modules) {
					modules_count_x = wholeStringsCount * string_max_modules;
				}
			}

			modules_count_x = Math.max(modules_count_x, 1);
			modules_count_y = Math.max(modules_count_y, 1);

			const patch: PropertiesPatch = [
				[BimProperty.MergedPath(['dimensions', 'max_length']), null],
				[BimProperty.MergedPath(['string', 'modules_count']), null],
				[BimProperty.MergedPath(['strings', 'count']), null],
				[BimProperty.MergedPath(['strings', 'min_modules_count']), null],
				[BimProperty.MergedPath(['strings', 'max_modules_count']), null],
				[BimProperty.MergedPath(['modules', 'count']), null],
				[BimProperty.MergedPath(['modules', 'count_x']), null],
				[BimProperty.MergedPath(['modules', 'count_y']), null],
				[BimProperty.MergedPath(['modules', 'gap']), null],
				[BimProperty.MergedPath(['modules', 'horizontal_placement']), null],

				[BimProperty.MergedPath(['string', 'modules_count']), {path: ['string', 'modules_count'], value: string_max_modules, numeric_step: 1, numeric_range: [1, 1000]}],

				[BimProperty.MergedPath(['modules', 'count_x']), {path: ['modules', 'count_x'], value: modules_count_x, numeric_step: 1, numeric_range: [1, 1000]}],
				[BimProperty.MergedPath(['modules', 'count_y']), {path: ['modules', 'count_y'], value: modules_count_y, numeric_step: 1, numeric_range: [1, 1000]}],
				[BimProperty.MergedPath(['dimensions', 'modules_gap']), {path: ['dimensions', 'modules_gap'], value: modules_gap, unit: 'm', numeric_range: [0, 1]}],
				[BimProperty.MergedPath(['dimensions', 'modules_horizontal_placement']), {path: ['dimensions', 'modules_horizontal_placement'], value: horizontal_placement}],
			];

            inst.properties.applyPatch(patch);
        }
    }
}

export const expandFixedTiltLegacyPropsWithCostTableLinks: ExpandLegacyPropsWithCostTableLinks = (params) => {
    const sis = Array.from(params.bim.instances.peekByIds(params.ids).values());
    if (sis.some(x => x.type_identifier !== FixedTiltTypeIdent)) {
        return;
    }

    createTrackerModuleCostModelLink(params);

    frame: {
        const groupByFrame = Array.from(IterUtils.groupBy(
            sis,
            (o) => {
                const props = o.properties.extractPropertiesGroup(FixedTiltFrameUniqueProps)
                return [
                    props.manufacturer.asText(),
                    props.model.asText(),
                    props.loadWindPosition.asText(),
                    props.modulesCountX.asNumber(),
                ].join('/');
            },
        ))
        if (groupByFrame.length !== 1) {
            break frame;
        }
        createFocusLinkOnSample({
            costModelFocusApi: params.costModelFocusApi,
            targetPui: PUI_GroupNode.tryGetNestedChild(params.pui, ['cost', 'frame']),
            sample: groupByFrame[0][1][0],
            label: 'Setup frame costs',
            type_identifier: TrackerFrameTypeIdentifier,
        })
    }

    createFocusLinkOnSample({
        costModelFocusApi: params.costModelFocusApi,
        targetPui: PUI_GroupNode.tryGetNestedChild(params.pui, ['cost', 'piles']),
        type_identifier: `tracker-pile`,
        label: 'Setup piles costs'
    })
}
