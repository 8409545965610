import type { ActionParams, SettingParams } from './ActionsBindings';
import { ActionDescription, SettingDescription } from './ActionsBindings';
import type { DefaultMessage, UiViewSource } from './UI_Source';
import type { DialogDescription } from './DialogDescription';
import type { NotificationDescription } from './NotificationDescription';
import type { LazyVersioned, ObservableObject, ProjectNetworkClient, Yield } from 'engine-utils-ts';
import { ScopedLogger, Registry, ObservableStream } from 'engine-utils-ts';
import type { PUI_BuilderParams } from './PUI_Builder';
import type { PUI_ConfigBasedBuilderParams } from './PUI_BuilderConfigBased';
import type { PUI_GroupNode } from './PUI_Nodes';
import type { Vector2Like, Vector3 } from 'math-ts';
import { TelemetryEvent } from './TelemetryEvents';

export type MenuPath = string[];

export class UiBindings {

    _logger: ScopedLogger;

    dialogsStream: ObservableStream<DialogDescription<any, any>>;

    views: Registry<PanelViewDescription>;
    dropdownViews: Registry<DropdownViewDescription>;
    statusViews: Registry<LazyVersioned<DropdownViewDescription[]>>;
    notificationsStream: ObservableStream<NotificationDescription>;
    telemetryStream: ObservableStream<TelemetryEvent>;
    actions: Map<string, ActionDescription<any>>;
    settings: Map<string, SettingDescription>;
	contextualActions: Map<string, ContextualActions>;
    filesImporters: Registry<FileImporter>;
    filesExporters: Registry<FileExporter<any>>;
	runtimeSystemsUi: Map<string, RuntimeSystemUiDescription>;
    contextMenuViews: ObservableStream<ContextMenuConfig>;

    _version: number = 0;

    constructor(
        logger: ScopedLogger,
    ) {
        this._logger = logger;
        this.views = new Registry(logger, `ui-views`);
        this.dropdownViews = new Registry(logger, `dropdown-views`);
        this.statusViews = new Registry(logger, `status-views`);
        this.dialogsStream = new ObservableStream({ identifier: `${logger.scopeMsg}-dialogs-stream` });
        this.notificationsStream = new ObservableStream({ identifier: `${logger.scopeMsg}-notifications-stream` });
        this.telemetryStream = new ObservableStream({ identifier: `${logger.scopeMsg}-telemetry-stream` });
        this.actions = new Map();
        this.settings = new Map();
		this.contextualActions = new Map();
        this.filesImporters = new Registry(logger, `file-importers`);
        this.filesExporters = new Registry(logger, `file-exporters`);
        this.runtimeSystemsUi = new Map();
        this.contextMenuViews = new ObservableStream({ identifier: `${logger.scopeMsg}-context-menus-stream` });
    }

    dispose() {
        this.views.dispose();
        this.dropdownViews.dispose();
        this.statusViews.dispose();
        this.dialogsStream.dispose();
        this.notificationsStream.dispose();
        this.telemetryStream.dispose();
        this.contextMenuViews.dispose();
    }

    public static merged(uis: UiBindings[]) {

        const logger = new ScopedLogger(uis.map(u => u._logger.scopeMsg).join(':'));
        const ui = new UiBindings(logger);

        ui.views.mergeFrom(uis.map(u => u.views));

        ui.dropdownViews.mergeFrom(uis.map(u => u.dropdownViews));

        ui.statusViews.mergeFrom(uis.map(u => u.statusViews));

        ui.filesImporters.mergeFrom(uis.map(u => u.filesImporters));
        ui.filesExporters.mergeFrom(uis.map(u => u.filesExporters));


        for (const toMerge of uis) {
            ui.dialogsStream.mergeFrom(toMerge.dialogsStream);
            ui.notificationsStream.mergeFrom(toMerge.notificationsStream);
            ui.contextMenuViews.mergeFrom(toMerge.contextMenuViews);

            for (const [str, act] of toMerge.actions) {
                if (ui.actions.has(str)){
                    console.error('action bindings merge conflict', str);
                }
                ui.actions.set(str, act);
            }
			for (const [str, actions] of toMerge.contextualActions) {
				if (ui.contextualActions.has(str)) {
					console.error('dynamic actions merge conflict', str);
				}
				ui.contextualActions.set(str, actions);
			}
            for (const [str, setting] of toMerge.settings) {
                if (ui.settings.has(str)){
                    console.error('settings bindings merge conflict', str);
                }
                ui.settings.set(str, setting);
            }

            for (const [str, runtimeUi] of toMerge.runtimeSystemsUi) {
				if (ui.runtimeSystemsUi.has(str)) {
                    console.error('runtime systems ui merge conflict', str);
				}
				ui.runtimeSystemsUi.set(str, runtimeUi);
			}
        }
        return ui;
    }

    public addDialog<T, R>(dialog: DialogDescription<T, R>) {
        this.dialogsStream.pushNext(dialog);
    }

    public addView<T>(path: MenuPath, viewSource: UiViewSource<T>, options?: ToolbarTabOptions) {
        const viewDescription: PanelViewDescription = {
            viewSource,
            minWidth: options?.minWidth,
            visibility: PanelViewVisibility.Toolbar,
            position: PanelViewPosition.None,
            name: options?.name ?? path.slice(-1)[0],
        }
        this.views.register(path, viewDescription);
    }

    public addViewToNavbar<T>(path: MenuPath, viewSource: UiViewSource<T>, options: NavbarTabOptions) {
        const viewDescription: PanelViewDescription = {
            viewSource,
            minWidth: options.minWidth,
            visibility: PanelViewVisibility.Navbar,
            position: options.position ?? PanelViewPosition.Float,
            name: options.name,
            iconName: options.iconName,
            group: options.group,
            sortOrder: options.sortOrder ?? 0,
            dynamicLabel: options.dynamicLabel,
        }
        this.views.register(path, viewDescription);
    }

    public addDropdownView(path: MenuPath, descr: DropdownViewDescription) {
        this.dropdownViews.register(path, descr);
    }

    public addStatusViews(path: MenuPath, descr: LazyVersioned<DropdownViewDescription[]>) {
        this.statusViews.register(path, descr);
    }

    public addNotification(notification: NotificationDescription) {
        this.notificationsStream.pushNext(notification);
    }

    public pushTelemetryEvent(te: TelemetryEvent | Promise<TelemetryEvent>) {
        if (te instanceof TelemetryEvent) {
            this.telemetryStream.pushNext(te);
        } else if (te instanceof Promise) {
            te.then(
                te => this.telemetryStream.pushNext(te),
                (err) => console.error('error while resolving telemetry event', err),
            );
        } else {
            console.error('invalid telemetry event', te);
        }
    }

    public addAction<T extends (string | number | string[] | number[] | undefined)>(actionDescription: ActionParams<T>) {
        const str = actionDescription.name.join();
        if (this.actions.has(str)) {
            console.error('action with the same name already added, replacing', str, actionDescription, this.actions.get(str));
        }
        this.actions.set(str, new ActionDescription(actionDescription));
    }

    public addSetting(description: SettingParams) {
        const str = description.name.join();
        if (this.settings.has(str)) {
            console.error('setting with the same name already added, replacing', str, description, this.settings.get(str));
        }
        this.settings.set(str, new SettingDescription(description));
    }

	public addContextualActions(ident: string, actions: ContextualActions) {
		if (this.contextualActions.has(ident)) {
			console.error(`contextual actions double entry ${ident}`, actions);
		}
		this.contextualActions.set(ident, actions);
	}

    public addFileImporter(menuPath: string[], importer: FileImporter) {
        this.filesImporters.register(menuPath, importer);
    }

    public addFileExporter(ident: string[], exporter: FileExporter<any>) {
        this.filesExporters.register(ident, exporter);
    }

    public addRuntimeSystemUi(ident: string, runtimeUi: RuntimeSystemUiDescription) {
        if (this.runtimeSystemsUi.has(ident)) {
            console.error(`runtime system ui double entry ${ident}`, runtimeUi);
        }
        this.runtimeSystemsUi.set(ident, runtimeUi);
    }

    public addContextMenuView(config: ContextMenuConfig){
        this.contextMenuViews.pushNext(config);
    }
}

export interface ContextMenuAction {
    name: string;
    actionFn?: () => void;
    isEnabled?: LazyVersioned<boolean>;
}

export class ContextMenuConfig {
    public readonly identity: string;
    public readonly viewSource: UiViewSource<any>;
    public readonly action?: ContextMenuAction;
    public readonly positionPx: Vector2Like;
    public readonly header?: string;
    public readonly widthPx?: number;
    public readonly maxHeightPx?: number;
    private _disposeFn: (() => void) | null = null;
    
    constructor(args:{
         identity: string,
         viewSource: UiViewSource<any>;
         action?: ContextMenuAction,
         positionPx: Vector2Like,
         header?: string,
         widthPx?: number,
         maxHeightPx?: number,
    }) {
        this.identity = args.identity;
        this.viewSource = args.viewSource;
        this.action = args.action;
        this.positionPx = args.positionPx;
        this.header = args.header;
        this.widthPx = args.widthPx;
        this.maxHeightPx = args.maxHeightPx;
    }

    close() {
        if(this._disposeFn){
            this._disposeFn();
        }
    }

    withDisposeFn(fn: () => void) {
        this._disposeFn = fn;
    }
}

export interface DropdownViewDescription {
    viewSource: UiViewSource<any>,
    header: LazyVersioned<string>,
    activateOpened?: boolean,
}

export enum PanelViewVisibility {
    Toolbar,
    Navbar,
}

export interface ToolbarTabOptions {
    minWidth?: number;
    name?: string;
}

export interface NavbarTabOptions {
    minWidth?: number;
    name: string;
    iconName: string;
    group: string;
    sortOrder?: number;
    dynamicLabel?: LazyVersioned<string>;
    position?: PanelViewPosition;
}

export enum PanelViewPosition {
    None,
    Float,
    Fixed,
    Mixed,
    Overlay
}

export interface PanelViewDescription {
    viewSource: UiViewSource<any>;
    minWidth?: number;
    visibility: PanelViewVisibility;
    position: PanelViewPosition;
    name: string;
    iconName?: string;
    group?: string;
    sortOrder?: number;
    dynamicLabel?: LazyVersioned<string>;
}

export type ContextualActions = LazyVersioned<Array<ActionDescription<any> | SettingDescription>>; // actions that are generated dynamically

export interface FileImporter {
    fileExtensions: string[];
    additionalFileCheck?: (file: FileToImport) => boolean;
    startImport(context: FileImporterContext, file: FileToImport): Generator<Yield, void>;
}
export interface FileToImport {
    filename: string;
    fileArrayBuffer: ArrayBuffer;
    extension: string;
    size: number;
}
export interface ObservableConfigObjectUiBuilderParams {
    configBuilderParams?: PUI_ConfigBasedBuilderParams,
    puiBuilderParams?: PUI_BuilderParams,
    defaultMessage?: DefaultMessage,
}

export abstract class CustomUiBuilder<T extends Object, Context = any> {
   abstract buildUi(settings: ObservableObject<T>, submitAction: () => void): UiViewSource<Context>
}
export interface FileImporterContext {
    logger: ScopedLogger;
    network?: ProjectNetworkClient;
    setMessage(text: string, isError?: boolean): void;
    requestSettings<Settings extends Object>(
        args: {
            ident: string,
            defaultValue: Settings,
            uiBuilderParams?: ObservableConfigObjectUiBuilderParams | CustomUiBuilder<Settings>,
        },
        getSettingsDialog?: GetSettingsDialog<Settings>,
    ): Generator<Yield, Settings>;
    importInstances(generator: Generator<Yield, void>): void;
    onFinish(ids: number[]): Generator<Yield, void>;
    setForm(form: LazyVersioned<PUI_GroupNode>): void;
    getPositionInFrontOfEngineCamera(): Vector3;
    sendNotification(notification: NotificationDescription): void;
}
type GetSettingsDialog<Settings extends Object> = (
    settingsObs: ObservableObject<Settings>,
    params: {
        header: string,
        uiBuilderParams?: ObservableConfigObjectUiBuilderParams | CustomUiBuilder<Settings>,
    },
    submitAction: {label: string, action: () => void},
    cancelAction: () => void,
) => DialogDescription<PUI_GroupNode, undefined>;

export interface ExportedFileDescription {
    extension: string;
    name?: string;
    file: ArrayBuffer;
}

export interface FileExporter<InitialSettings extends Object> {
    initialSettings(): {
        defaultValue: InitialSettings,
        uiBuilderParams?: ObservableConfigObjectUiBuilderParams | CustomUiBuilder<InitialSettings>,
    };
    startExport: (settings: InitialSettings, context: FileExporterContext) => Generator<Yield, ExportedFileDescription[]>;
}
export interface FileExporterContext {
    logger: ScopedLogger;
    network?: ProjectNetworkClient;

    additionalContext: {[key: string]: any};

    requestSettings<Settings extends Object>(args: {
        ident: string,
        defaultValue: Settings,
        uiBuilderParams?: ObservableConfigObjectUiBuilderParams | CustomUiBuilder<Settings>,
    }): Generator<Yield, Settings>;

    sendNotification(notification: NotificationDescription): void;
    addDialog<T, Context>(description: DialogDescription<T, Context>): void;
}

export enum RuntimeSystemExecutionStatus {
    Waiting,
    InProgress,
    Done,
    Disabled,
}

export interface RuntimeSystemUiDescription {
    group_sort_key: string;
    // sort_key: number;
    name: string;
    executionStatus: LazyVersioned<RuntimeSystemExecutionStatus>;
    ui?: LazyVersioned<PUI_GroupNode>;
}


