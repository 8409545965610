import { CutFillContoursTypes
} from 'bim-ts';
import { BoundaryTypeIdent, InverterTypeIdent, NumberProperty, RoadTypeIdent, SubstationTypeIdent, TransformerIdent
} from 'bim-ts';
import type { SceneInstance,
    Bim, Catalog, Config, CutFillConfig, CutFillServiceInput, IdBimScene, MathSolversApi, PropertyGroup, RepresentationBase, ResetTerrainInput, SceneInstancesProperty,
    NumberPropertyWithOptionsContext,
    NumberPropertyWithOptions
} from 'bim-ts';
import { BoundaryType, ConfigUtils, CutFillConfigType, FixedTiltTypeIdent, GraphGeometry,
    LocalIdsCounter,
    placeEquipmentOnTerrain,
    PlacementMethod, PolylineGeometry, resetTerrain, SceneInstances, SceneObjDiff, TerrainHeightMapRepresentation, TerrainInstanceTypeIdent, TrackerTypeIdent
} from 'bim-ts';
import type { LazyVersioned, ResultAsync} from 'engine-utils-ts';
import { type TasksRunner, type ScopedLogger, ObjectUtils, LazyDerived, LazyBasic, Immer, IterUtils, Yield, LazyDerivedAsync, Success, Failure, InProgress, EnumUtils, DefaultMap } from 'engine-utils-ts';
import type { Aabb} from 'math-ts';
import { PointsInPolygonChecker, Vector2, Vector3, Clipper, Aabb2, PolygonUtils, Segment2} from 'math-ts';
import { type UiBindings, PUI_ConfigBasedBuilderParams, type PUI_Builder, buildFromLazyConfigObject, PUI_GroupNode, PanelViewPosition } from 'ui-bindings';
import { addProperty } from '../panels-config-ui/PropertyBuilders';
import type { KreoEngineDescr} from '../panels-config-ui/GeneratePanelUiBindings';
import { getSceneInstancesInvalidator } from '../panels-config-ui/GeneratePanelUiBindings';
import { removeDeletedSceneInstances } from '../panels-config-ui/RemoveDeletedSceneInstances';
import { isEqual, orderContour, orderHole } from '../LayoutUtils';
import type { TrackerSlopeProperties } from './CutFillCatalogValuesValidationUi';


type ConfigObject = PropertyGroup;


const typesForCutFill = [ 
    "any-tracker",
    TrackerTypeIdent, 
    FixedTiltTypeIdent, 
    RoadTypeIdent, 
    InverterTypeIdent, 
    TransformerIdent, 
    SubstationTypeIdent 
];

interface CutFillContext {
    previousConfig: CutFillConfig, 
    system: string, 
    selectedIds: Set<IdBimScene>,
    terrains: {
        name: string,
        id: IdBimScene,
        haveChanges: boolean,
    }[],
    trackers: TrackerSlopeProperties[],
    selectedCutFillAreas: CutFillSelectedAreas | undefined,
}

export function createCutFillUi(
    ui: UiBindings,
    configBuilderParams: PUI_ConfigBasedBuilderParams,
    mathSolversApi: MathSolversApi,
    tasksRunner: TasksRunner,
    logger: ScopedLogger,
    bim: Bim,
    engine: KreoEngineDescr,
    catalog: Catalog
) {
    // const trackerSlopeProperties = createTrackerPropsValidator(bim, logger);
    // const solarArraysView = createSolarArraysCheckerView(bim, catalog, logger, trackerSlopeProperties);
    function getConfig(): CutFillConfig | undefined {
        const config = bim.configs.peekSingleton(CutFillConfigType);
        return config?.get<CutFillConfig>();
    }

    function patchConfig(newProps:PropertyGroup){
        bim.configs.applyPatchToSingleton(CutFillConfigType, {properties: newProps});
    }
    function incrementUpdatesCounter(){
        const properties = getConfig();
        if(!properties){
            logger.error(`config type (${CutFillConfigType}) not found`);
            return;
        }
        const updatedProps = Immer.produce(properties, draft => {
            const counter = draft?.updates_terrain_counter?.value ?? 0;
            draft.updates_terrain_counter = NumberProperty.new({value: counter + 1, step: 1});
        });

        patchConfig(updatedProps);
    }
    const lazySelectedAreas = createLazyEquipmentBoundaries(bim, logger);
    const lazyConfig = bim.configs.getLazySingletonOf({type_identifier: CutFillConfigType});
    const lazyListTerrains = bim.instances.getLazyListOf({
        type_identifier: TerrainInstanceTypeIdent,
        relevantUpdateFlags: SceneObjDiff.Name | SceneObjDiff.Representation
    });
    const lazyTerrains = LazyDerived.new1(
        "terrain-lazy",
        null, 
        [lazyListTerrains], 
        ([terrains]) => {
            const terrainDescriptions: {
                name: string,
                id: IdBimScene,
                haveChanges: boolean,
            }[] = [];
            for (const [id, instance] of terrains) {
                if (!(instance.representation instanceof TerrainHeightMapRepresentation)) {
                    console.error(`unexpected terrain representation`, [id, instance.representation]);
                    continue;
                }
                let haveChanges = false;
                for (const [_tileId, tile] of instance.representation.tiles) {
                    if(tile.updatedGeo !=0){
                        haveChanges = true;
                        break;
                    }
                }
                terrainDescriptions.push({
                    id,
                    name: extractNewUiTerrainName(id, instance),
                    haveChanges,
                })
            }
            return terrainDescriptions;
        });
    const isPlacingEquipmentRunning = new LazyBasic('isPacingEquipment', false);
    
    const isEnabledClearWithinSelected = LazyDerived.new3(
        "isEnabledClearWithInSelected", 
        null, 
        [isPlacingEquipmentRunning, lazyTerrains, lazyConfig], 
        ([isPacingEquipment, terrains, config]) => {
            const props = config?.get<CutFillConfig>();
            if(!props){
                return false;
            }
            const selectMode = convertSelectModeToEnum(props.selected_area.value);
            const areItemsSelected = selectMode === SelectMode.Equipment 
                ? bim.instances.peekByTypeIdents(props.equipment.types).length > 0
                : selectMode === SelectMode.EquipmentInBoundary 
                    ?  bim.instances.peekByTypeIdents(props.boundary.types).length > 0  
                    : true;
            return !isPacingEquipment && terrains.some(t => t.haveChanges) && areItemsSelected;
        });

    function checkSelectedAreas(areaM2: number){
        return areaM2 > 20e6;
    }

    const placingFn = async function () {
        const config = getConfig();
        if (!config) {
            console.error('config not found');
            throw new Error('config not found');
        }

        if(!config.terrain.value) {
            await resetFn({ terrainIds: [], resetBySelected: false, resetTerrain: false, resetEquipment: true, taskRunner: tasksRunner});
            return;
        }

        const placeMethod = convertPlacingMethodToEnum(
            config.cut_fill_limits.update_within_selection.value,
            config.terrain.value
        );
        const selectMode = convertSelectModeToEnum(
            config.selected_area.value
        );
        if (placeMethod === PlacementMethod.None || selectMode === SelectMode.Unknown) {
            const message = 'Invalid configuration';
            console.error(message, config);
            throw new Error(message);
        }
        const selectedItems = selectMode === SelectMode.Equipment 
            ? config.equipment 
            : config.boundary;
        isPlacingEquipmentRunning.replaceWith(true);
        const selectedAreas = await tasksRunner.newLongTask({
            identifier: "selected-areas",
            defaultGenerator: extractEquipment(selectMode, selectedItems, logger, bim),
        }).asPromise();

        const input: CutFillServiceInput = {
            placeMethod,
            contoursByType: selectedAreas.contoursByType,
            equipment: selectedAreas.ids,

            gridSize: 8,
            eastWestSlopeMaxPercent: config.cut_fill_limits.east_west_slope.as('%'),
            northSlopeMaxPercent: config.cut_fill_limits.north_slope.as('%'),
            southSlopeMaxPercent: config.cut_fill_limits.south_slope.as('%'),
            axisSlopeMaxPercent: config.solar_arrays_limits.slope_along_axis.as('%'),
            netBalanceMeter3: config.cut_fill_limits.net_balance.selectedOption === 'auto' ? undefined : config.cut_fill_limits.net_balance.as('m3'),
            
            bayToBaySlopeMaxPercent: config.solar_arrays_limits.slope_change_bay_to_bay.as('%'),
            cumulativeSlopeMaxPercent: config.solar_arrays_limits.cumulative.as('%'),
            toleranceMeter: config.cut_fill_limits.tolerance.as('m'),

            timeLimit: config.cut_fill_limits.timeout.as('sec'),

            minPileRevealMeter: config.piles.min_pile_reveal.as('m'),
            maxPileRevealMeter: config.terrain.value ? calcPileMaxReveal(config, 'm') : config.piles.min_pile_reveal.as('m'),
            minPileEmbedmentMeter: config.piles.min_pile_embedment.as('m'),
        };
        await placeEquipmentOnTerrain({
            input: ObjectUtils.deepCloneObj(input),
            bim,
            mathSolversApi,
            tasksRunner,
            uiBindings: ui,
        }).then((isSuccess) => {
            if(placeMethod === PlacementMethod.CutFill && isSuccess){
                incrementUpdatesCounter();
            }
        }).finally(() => {
            isPlacingEquipmentRunning.replaceWith(false);
        });
    };

    const isPlacingAvailable = LazyDerived.new3(
        'isPlacingAvailable', 
        null,
        [lazyConfig, isPlacingEquipmentRunning, lazyTerrains],  
        ([config, isPacingEquipment, terrains]) => {
        const property = config?.get<CutFillConfig>();
        if(isPacingEquipment || terrains.length === 0){
            return false;
        }
        if (property) {
            const selectMode = convertSelectModeToEnum(property.selected_area.value);
            if(!property.terrain.value){
                return true;
            }else if(selectMode === SelectMode.Equipment){
                return property.equipment.value.length > 0 || property.equipment.value.length === 0 && bim.instances.peekByTypeIdents(property.equipment.types).length > 0;
            }else if(selectMode === SelectMode.EquipmentInBoundary){
                return property.boundary.value.length > 0  || property.boundary.value.length === 0 && bim.instances.peekByTypeIdents(property.boundary.types).length > 0;;
            }else {
                return true;
            }
        }
        return false;
    });

    async function resetFn(args: { 
        terrainIds: IdBimScene[], 
        resetBySelected: boolean; 
        resetTerrain: boolean; 
        resetEquipment: boolean;
        taskRunner: TasksRunner; 
    }) {
        const config = getConfig();
        if (config) {
            const selectMode = args.resetBySelected 
                ? convertSelectModeToEnum(config.selected_area.value) 
                : SelectMode.All;
            const selectedEquipment = selectMode === SelectMode.Equipment || selectMode === SelectMode.All ? config.equipment : config.boundary;
            const task = args.taskRunner.newLongTask({
                identifier: "extract-equipment",
                defaultGenerator: extractEquipment(selectMode, selectedEquipment, logger, bim),
            });
            const {contoursByType, ids} = await task.asPromise();

            const input: ResetTerrainInput = {
                boundaries: Array.from(IterUtils.iterMap(contoursByType, c => c[1])).flat(),
                resetByBoundaries: args.resetBySelected,
                resetTerrain: args.resetTerrain,
                resetEquipment: args.resetEquipment,
                ids: ids,
                terrainIds: args.terrainIds,
            };
            isPlacingEquipmentRunning.replaceWith(true);
            await resetTerrain({
                input,
                bim,
                tasksRunner,
                uiBindings: ui,
            }).finally(() => {
                isPlacingEquipmentRunning.replaceWith(false);
            });
        } else {
            console.error('config not found');
        }
    }


    const puiCallbacks = (builder: PUI_Builder, context: CutFillContext) => {
        const properties = context.previousConfig;
        if (!properties) {
            logger.error(`config type (${CutFillConfigType}) not found`);
            return;
        }
        const colorPrimary = '';
        const selectMode = convertSelectModeToEnum(
            properties.selected_area.value
        );
        let order = 0;
        const addProp = ({path, name, readonly, tag, notActive}:{path: string[], name?: string, readonly?: boolean, tag?: LazyVersioned<string>, notActive?: boolean}) => {
            addProperty({ logger, config: properties, patch: patchConfig, builder, path, sortKey: ++order, name, readonly, tag, notActive });
        };

        const addDivider = () => {
            const id = ++order;
            builder.addCustomProp({
                name: 'divider-' + id,
                onChange: () => {},
                context: {},
                value: {},
                type_ident: 'divider',
                typeSortKeyOverride: id,
            });
        }
        const haveTerrains = context.terrains.length > 0;

        builder.addSwitcherProp({
            name: 'Selected area',
            value: properties.selected_area.value,
            options: properties.selected_area.options.map(v => ({value: v, label: v})),
            onChange: (v) => {
                const updatedProps = Immer.produce(properties, draft => {
                    if(typeof v === 'string'){
                        draft.selected_area = draft.selected_area.withDifferentValue(v);
                    } else {
                        console.error('invalid value for placement_method', v);
                    }
                });
                patchConfig(updatedProps);
            },
            typeSortKeyOverride: ++order,
        });
        if(selectMode === SelectMode.Equipment){
            builder.addSceneInstancesSelectorProp({
                name: "  ",
                typeSortKeyOverride: ++order,
                value: properties.equipment.value.map(v => ({value: v})),
                onChange: (v) => {
                    const updatedProps = Immer.produce(properties, draft => {
                        draft.equipment = draft.equipment.withDifferentValue(v.map(v=>v.value));
                    });
                    patchConfig(updatedProps);
                },
                customSelectedItemsMessage: (selected, allItems) => {
                    if(allItems.length === 0){
                        return 'No equipment selected'
                    } else if(selected.length === allItems.length || selected.length === 0){
                        return "All equipment";
                    } else if(selected.length === 1) {
                        const instance = bim.instances.peekById(selected[0]);
                        const name = SceneInstances.uiNameFor(selected[0], instance!);
                        return name;
                    }else {
                        return `${selected.length} equipment`;
                    }
                },
                types: properties.equipment.types,
            });
        } else if(selectMode === SelectMode.EquipmentInBoundary){
            builder.addSceneInstancesSelectorProp({
                name: "  ",
                typeSortKeyOverride: ++order,
                value: properties.boundary.value.map(v=> ({value: v})),
                onChange: (v) => {
                    const updatedProps = Immer.produce(properties, draft => {
                        draft.boundary = draft.boundary.withDifferentValue(v.map(v=>v.value));
                    });
                    patchConfig(updatedProps);
                },
                customSelectedItemsMessage: (selected, allItems) => {
                    if(allItems.length === 0){
                        return 'No boundaries selected'
                    } else if(selected.length === allItems.length || selected.length === 0){
                        return "All boundaries";
                    } else if(selected.length === 1) {
                        return "Line " + selected[0];
                    } else {
                        return `${selected.length} boundaries`;
                    }
                },
                filterItems: (id) => {
                    const inst = bim.instances.peekById(id);
                    const result = filterBoundary(id, inst);
                    return result;
                },
                types: properties.boundary.types,
            });
        } else {
            console.error('invalid select mode', selectMode);
        }
        addDivider();

        const terrains = context.terrains;    
        const terrainOptionName = terrains.length === 0 
            ? 'No terrain imported' : terrains.length === 1 ? terrains[0].name : `${terrains.length} Terrain imported`;
        interface TerrainOption {
            value: string | number;
            label: string;
            disabled?: boolean;
            selectTerrains?: () => void;
            openSiteContext?: boolean;
            options: ( {
                label: string;
                type: "clearData";
                cleanSelectedArea?: () => void;
                cleanAllData: () => void;
            } |
            {
                label: string;
                type: "terrain";
                selectTerrain: () => void;
            })[];
        }
        type TerrainOptions = TerrainOption['options'];

        const terrainOptions : TerrainOptions = [];
        for (const terrain of terrains) {
             if(terrains.length > 1) {
                terrainOptions.push({
                    label: terrain.name,
                    type: "terrain",
                    selectTerrain: () => {
                        bim.instances.setSelected([terrain.id]);
                        engine.focusCamera([terrain.id]);
                    },
                    
                });
            }
        }

        if(haveTerrains){
            if(terrains.some(t => t.haveChanges)){
                const updatesCount = properties.updates_terrain_counter?.value;
                const label = updatesCount == undefined || updatesCount < 2
                    ? "Cut-fill applied" 
                    : `Cut-fill, ${updatesCount} updates applied`;
                terrainOptions.push({
                    label: label,
                    type: "clearData",
                    cleanSelectedArea: isEnabledClearWithinSelected.poll() ? async () => {
                        await resetFn({terrainIds: terrains.map(t => t.id), resetBySelected: true, resetTerrain: true, resetEquipment: true, taskRunner: tasksRunner});
                    } : undefined,
                    cleanAllData: async () => {
                        await resetFn({terrainIds: terrains.map(t => t.id), resetBySelected: false, resetTerrain: true, resetEquipment: true, taskRunner: tasksRunner});
                    },
                });
            }
        }

        const terrainMode:TerrainOption[] = [ {
            value: 0,
            label: "Place everything on a flat 2D plane",
            options: [],    
        }, {
            value: 1,
            label: terrainOptionName,
            options: terrainOptions,
            selectTerrains: haveTerrains 
                ? () => {
                        bim.instances.setSelected(terrains.map(t => t.id));
                        engine.focusCamera(terrains.map(t => t.id));
                    } 
                : undefined,
            openSiteContext: haveTerrains,
            disabled: !haveTerrains,
        }];
        builder.addCustomProp({
            name: 'Terrain',
            type_ident: "select-terrain-property",
            value: !properties.terrain.value || !haveTerrains ? 0 : 1,
            typeSortKeyOverride: ++order,
            onChange: (v) => {
                const updatedProps = Immer.produce(properties, draft => {
                    draft.terrain = draft.terrain.withDifferentValue(!!v);
                });
                patchConfig(updatedProps);
            },
            context: {
                options: terrainMode,
            },
        });

        const isCutFillReadOnly = !properties.cut_fill_limits.update_within_selection.value || !properties.terrain.value || terrains.length === 0;
        const cutFillUpdateWithinSelection = !properties.terrain.value ||  terrains.length === 0;
        builder.addCustomProp({
            name: "Cut-fill",
            type_ident: "custom-group-name",
            value: "Update within selection",
            typeSortKeyOverride: ++order,
            onChange: () => {},
            context: {
                checkBoxBefore: {
                    value: properties.cut_fill_limits.update_within_selection.value,
                    onClick: () => {
                        const updatedProps = Immer.produce(properties, draft => {
                            const value = draft.cut_fill_limits.update_within_selection.value
                            draft.cut_fill_limits.update_within_selection 
                                = draft.cut_fill_limits.update_within_selection.withDifferentValue(!value);
                        });
                        patchConfig(updatedProps);
                    },
                    readonly: cutFillUpdateWithinSelection,
                }
            },
            notActive: cutFillUpdateWithinSelection
        });

        const maxTag = new LazyBasic("max-slope", "max");
        addProp({
            path: [
                "cut_fill_limits", 
                "north_slope"
            ],
            name: "North terrain slope",
            readonly: isCutFillReadOnly,
            notActive: isCutFillReadOnly,
            tag: maxTag,
        });
        addProp({
            path: [
                "cut_fill_limits", 
                "south_slope"
            ],
            name: "South terrain slope",
            readonly: isCutFillReadOnly,
            notActive: isCutFillReadOnly,
            tag: maxTag,
        });
        addProp({
            path: [
                "cut_fill_limits", 
                "east_west_slope"
            ],
            name: "E-W Terrain slope",
            readonly: isCutFillReadOnly,
            notActive: isCutFillReadOnly,
            tag: maxTag,
        });
        builder.addCustomProp({
            value: [properties.cut_fill_limits.tolerance.value, properties.cut_fill_limits.tolerance.value * 2],
            name: "Tolerance/Grading window",
            type_ident: "range-property",
            onChange: (v) => {
                const updatedProps = Immer.produce(properties, draft => {
                    if(isEqual(v[0], properties.cut_fill_limits.tolerance.value)){
                        draft.cut_fill_limits.tolerance = draft.cut_fill_limits.tolerance.withDifferentValue(v[1] / 2);
                    } else {
                        draft.cut_fill_limits.tolerance = draft.cut_fill_limits.tolerance.withDifferentValue(v[0]);
                    }
                });
                patchConfig(updatedProps);
            },
            context: {
                unit: properties.cut_fill_limits.tolerance.unit,
                minMax: properties.cut_fill_limits.tolerance.range,
                step: properties.cut_fill_limits.tolerance.step,
                minTag: "±",
                colorMin: colorPrimary,
                colorMax: colorPrimary,
                decimals: 2,
            },
            typeSortKeyOverride: ++order,
            readonly: isCutFillReadOnly,
            notActive: isCutFillReadOnly,
        });
        builder.addCustomProp<NumberPropertyWithOptions, NumberPropertyWithOptionsContext<any>>({
            name: "Net balance",
            type_ident: "number-property-with-options",
            value: properties.cut_fill_limits.net_balance,
            typeSortKeyOverride: ++order,
            context: {
                valueRenderFormatter: {
                    context: {},
                    formatter: (args) => { 
                        const decimals = properties.cut_fill_limits.net_balance.decimals;
                        const valAsStr = args.value.toFixed(decimals);
                        return args.option === 'set' ? `${valAsStr} ${args.unit}` : ``
                    },
                    isReadonly: (args) => args.option === 'auto',
                }
            },
            onChange: (v) => { 
                const updatedProps = Immer.produce(properties, draft => {
                    draft.cut_fill_limits.net_balance = v;
                });
                patchConfig(updatedProps);
            },
            readonly: false
        });
        const isImperial = bim.unitsMapper.isImperial();
        const cutFillGridSizeToolTip = `
        PVFarm using a ${isImperial ? "24ft" : "8m"} DEM grid to calculate cut-
        fill estimates. Such grid provides a reasonable
        balance between precision and feasibility.<br/>
        `;
        const cutFillGridSizeMessage = `Standard, ${isImperial ? "24ft" : "8m"}`;
        const timeOutValues = [30, 120, 480];
        const tip = [
            `<div>Draft</div>`, 
            `<div>Medium</div>`, 
            `<div>Maximum</div>`, 
        ];
        const mapToIdx = new Map(timeOutValues.map((v, i) => [v, i]));
        const idx = mapToIdx.get(properties.cut_fill_limits.timeout.value) ?? 0;

        builder.addCustomProp({
            name: "Cut-fill grid size",
            type_ident: "custom-group-name",
            value: cutFillGridSizeMessage,
            typeSortKeyOverride: ++order,
            onChange: () => {},
            context: {
                isProperty: true,
                hasError: checkSelectedAreas(context.selectedCutFillAreas?.area ?? 0),
            },
            description: cutFillGridSizeToolTip,
            notActive: isCutFillReadOnly,
        });

        builder.addSliderProp({
            name: 'Calculation precision',
            value: idx,
            minMax: [0, 2],
            step: 1,
            onChange: (idx) => { 
                const updatedProps = Immer.produce(properties, draft => {
                    const timeout = timeOutValues[idx] ?? 30;
                    draft.cut_fill_limits.timeout = draft.cut_fill_limits.timeout.withDifferentValue(timeout);
                });
                patchConfig(updatedProps);
            },
            settings: {
                minValueLabel: tip[idx],
            },
            typeSortKeyOverride: ++order,
            readonly: isCutFillReadOnly,
            notActive: isCutFillReadOnly,
        });
        

        const isSolarArraysLimitsReadOnly = !properties.terrain.value || terrains.length === 0;
        builder.addCustomProp({
            name: "Solar Arrays limits",
            type_ident: "custom-group-name",
            value: " ",
            typeSortKeyOverride: ++order,
            onChange: () => {},
            context: {
                // iconAfter: {
                //     iconName: "Info",
                //     onClick: (p: Vector2) => {
                //         const config = solarArraysView(p);
                //         ui.addContextMenuView(config);
                //     },
                //     hasError: checkTrackerLimits(properties),
                // }
            },
            notActive: isSolarArraysLimitsReadOnly,
        });
        function checkTrackerLimits(config: CutFillConfig) {
            const value = config.solar_arrays_limits.slope_along_axis.as("%");
            const min = IterUtils.min(context.trackers.map(x => x.maxSlopePercent ?? 0));
            return min && value > min;
        }
        builder.addNumberProp({
            name: 'Slope along axis',
            value: properties.solar_arrays_limits.slope_along_axis.value,
            unit: properties.solar_arrays_limits.slope_along_axis.unit,
            onChange: (v) => {
                const updatedProps = Immer.produce(properties, draft => {
                    draft.solar_arrays_limits.slope_along_axis = draft.solar_arrays_limits.slope_along_axis.withDifferentValue(v);
                });
                patchConfig(updatedProps);
            },
            typeSortKeyOverride: ++order,
            readonly: isSolarArraysLimitsReadOnly,
            notActive: isSolarArraysLimitsReadOnly,
            tag: new LazyBasic("net-balance", "max, affects Cut-fill"),
            icon:  checkTrackerLimits(properties) ? {
                iconName: "Restore",
                onClick: () => {
                    const v = IterUtils.min(context.trackers.map(x => x.maxSlopePercent ?? 0));
                    if(v){
                        const updatedProps = Immer.produce(properties, draft => {
                            draft.solar_arrays_limits.slope_along_axis = draft.solar_arrays_limits.slope_along_axis.withDifferentValue(v);
                        });
                        patchConfig(updatedProps);
                    }
                }
            }: undefined,
            valueRenderFormatter: () => {
                const isValid = checkTrackerLimits(properties);
                return isValid ? 'red' : 'inherit';
            }
        });
        
        addProp({
            path: [
                "solar_arrays_limits", 
                "slope_change_bay_to_bay"
            ],
            name: "Slope change, Bay to bay",
            readonly: isSolarArraysLimitsReadOnly,
            notActive: isSolarArraysLimitsReadOnly,
            tag: maxTag,
        });
        addProp({
            path: [
                "solar_arrays_limits", 
                "cumulative"
            ],
            readonly: isSolarArraysLimitsReadOnly,
            notActive: isSolarArraysLimitsReadOnly,
            tag: maxTag,
        });
        const isPilesGroupReadOnly = terrains.length === 0;
        builder.addCustomProp({
            name: "Piles",
            type_ident: "custom-group-name",
            value: " ",
            typeSortKeyOverride: ++order,
            onChange: () => {},
            context: { },
            notActive: isPilesGroupReadOnly,
        });
        addProp({
            path: [
                "piles", 
                "min_pile_embedment"
            ],
            name: 'Embedment',
            tag: new LazyBasic("embedment", "min"),
            readonly: isPilesGroupReadOnly,
            notActive: isPilesGroupReadOnly,
        });
        const isUpdateWithinSelection = properties.cut_fill_limits.update_within_selection.value;
        const maxReveal = isSolarArraysLimitsReadOnly || !isUpdateWithinSelection ? null : calcPileMaxReveal(properties, properties.piles.min_pile_reveal.unit);
        builder.addCustomProp({
            value: [properties.piles.min_pile_reveal.value, maxReveal] as const,
            name: "Reveal",
            type_ident: "range-property",
            onChange: ([min, max]) => {
                const updatedProps = Immer.produce(properties, draft => {
                    if(!isEqual(min, properties.piles.min_pile_reveal.value)){
                        draft.piles.min_pile_reveal = draft.piles.min_pile_reveal.withDifferentValue(min);
                    } else {
                        if(max === null){
                            console.error('max is null');
                            return;
                        }
                        const minValue = Math.min(min, max);
                        const tolerance = (max - min) / 2;
                        draft.cut_fill_limits.tolerance = draft.cut_fill_limits.tolerance.withDifferentValue(tolerance);
                        draft.piles.min_pile_reveal = draft.piles.min_pile_reveal.withDifferentValue(minValue);
                    }
                });
                patchConfig(updatedProps);
            },
            context: {
                unit: properties.piles.min_pile_reveal.unit,
                minMax: properties.piles.min_pile_reveal.range,
                step: properties.piles.min_pile_reveal.step,
                minTag: "min",
                maxTag: "max",
                maxReadonly: isSolarArraysLimitsReadOnly || !isUpdateWithinSelection,
                colorMax: maxReveal !== null ? colorPrimary : undefined,
                decimals: 2,
            },
            typeSortKeyOverride: ++order,
            readonly: isPilesGroupReadOnly,
            notActive: isPilesGroupReadOnly,
        });

        const buttonMessage = properties.cut_fill_limits.update_within_selection.value && properties.terrain.value
            ? "Update piles, Cut-fill" 
            : "Update piles";
        builder.addActionsNode({
            name: "place_equipment",
            typeSortKeyOverride: 9999,
            context: context,
            actions: [
                {
                    label: buttonMessage,
                    action: placingFn,
                    isEnabled: isPlacingAvailable,
                    style: {
                        type: 'primary',
                    },
                    
                }
            ],
        });

        let toUpdateConfig = properties;
        removeDeletedSceneInstances(toUpdateConfig, bim, logger, [], (newConfig) => {
            toUpdateConfig = newConfig;
        });

        if(terrains.every(t => !t.haveChanges)){ 
            toUpdateConfig = Immer.produce(toUpdateConfig, draft => {
                draft.updates_terrain_counter = null;
            });
        }

        patchConfig(toUpdateConfig);
    };

    const selectedItemsLazy = getSceneInstancesInvalidator(bim, CutFillConfigType);
    const props = LazyDerived.new4(
        'Properties',
        [bim.unitsMapper],
        [lazyConfig, selectedItemsLazy, lazyTerrains, lazySelectedAreas],
        ([config, selectedIds, terrains, areas]) => {
            const prop = config?.get<CutFillConfig>();

            const configSample: Partial<ConfigObject> = ObjectUtils.isObjectEmpty(prop) ? {} : ConfigUtils.copyTo(prop);
            const context: CutFillContext = {
                previousConfig: prop, 
                system: bim.unitsMapper.currentSystemOfUnits, 
                selectedIds: selectedIds,
                terrains: terrains.slice(),
                trackers: [],
                selectedCutFillAreas: areas,
            };
            return { 
                configSample, 
                context 
            };
        }
    );

    const builderParams = PUI_ConfigBasedBuilderParams.new(
        [],
        [
            "selected_area",
            "equipment",
            "boundary",
            "terrain",
            "cut_fill_limits",
            "solar_arrays_limits",
            "piles",
            "updates_terrain_counter",
        ]
    ).mergedWith(configBuilderParams);

    ui.addViewToNavbar(
        ["Generate", "Equipment Placement"],
        buildFromLazyConfigObject({
            configObj: props,
            configBuilderParams: builderParams,
            puiBuilderParams: {
                onTypeFilteredAfterNodeCallbacks: [
                {
                    ctor: PUI_GroupNode,
                    callBack: (builder, node: PUI_GroupNode, context)=> {
                        if (node.hasParent()) {
                            return;
                        }
                        puiCallbacks(builder, context)
                    },
                }
            ],
            },
            patchCallback: (patch, context) => {
                if (patch) {
                    const newProps = Immer.produce(context.previousConfig, (draft) => {
                        for (const key in patch) {
                            const value = patch[key];
                            if (context.previousConfig[key] !== undefined && value !== undefined) {
                                draft[key] = patch[key] as any;
                            }
                        }
                    });
                    logger.batchedInfo("new props", [patch, context.previousConfig, newProps]);
                    patchConfig(newProps);
                } else {
                    logger.batchedError('the config is not patched', [patch, context]);
                }
            }
        }),
        {
            name: 'Terrain and Piles',
            iconName: 'Terrain',
            group: 'General',
            sortOrder: 5,
            position: PanelViewPosition.Fixed,
        }
    );
}

function convertPlacingMethodToEnum(updateCutFill:boolean, terrain: boolean){
    let placeMethod = PlacementMethod.None;
    if (terrain && updateCutFill){
        placeMethod = PlacementMethod.CutFill;
    } else if(terrain && !updateCutFill){
        placeMethod = PlacementMethod.PilesOptimization;
    } else if(!terrain){
        placeMethod = PlacementMethod.Basic;
    } else {
        console.error('not implemented placement method');
    }
    return placeMethod;
}

enum SelectMode {
    Unknown,
    Equipment,
    EquipmentInBoundary,
    All,
}

function convertSelectModeToEnum(selectedMode: string){
    let mode = SelectMode.Unknown;
    if(selectedMode === 'Equipment'){
        mode = SelectMode.Equipment;
    } else if(selectedMode === 'Boundary'){
        mode = SelectMode.EquipmentInBoundary;
    } else {
        console.error('not implemented select mode', selectedMode);
    }
    return mode;
}

function extractNewUiTerrainName(id: IdBimScene, inst: SceneInstance){
    const sourceFileName = inst.properties.get('terrain | source_file_name')?.asText() ?? null;
    const name = inst.name 
        ? inst.name 
        : sourceFileName && sourceFileName != 'unknown' 
            ? sourceFileName 
            : SceneInstances.uiNameFor(id, inst);
    return  name;
}

function calcPileMaxReveal(props: CutFillConfig, unit: string){
    return props.piles.min_pile_reveal.as(unit) + props.cut_fill_limits.tolerance.as(unit) * 2;
}

function filterBoundary(id: IdBimScene, instance: SceneInstance | undefined) {
    const source_type = instance?.properties.get("boundary | source_type")?.asText();
    return source_type === 'equipment';
};

function createLazyEquipmentBoundaries(bim: Bim, logger: ScopedLogger){
    const lazyBoundariesInstances = bim.instances.getLazyListOf({
        type_identifier: BoundaryTypeIdent, 
        relevantUpdateFlags: SceneObjDiff.Representation | SceneObjDiff.LegacyProps | SceneObjDiff.NewProps | SceneObjDiff.WorldPosition
    });
    const lazyBoundaries = LazyDerived.new0('lazy-boundaries', [lazyBoundariesInstances], () => {
        return bim.extractBoundaries();
    });
    const lazyTrackersList: LazyVersioned<[IdBimScene, SceneInstance][]>[] = [];
    for (const type of typesForCutFill) {
        const lazyObjs = bim.instances.getLazyListOf({
            type_identifier: type, 
            relevantUpdateFlags: SceneObjDiff.WorldPosition
        });
        lazyTrackersList.push(lazyObjs);
    }
    const lazyTrackers = LazyDerived.fromArr('lazy-list-of-trackers', null, lazyTrackersList, (arr) => {
        let itemsIds:[IdBimScene, SceneInstance][] = [];
        for (const arrItem of arr) {
            for (const item of arrItem) {
                itemsIds.push(item);
            };
        }
        return itemsIds;
    }).withoutEqCheck();

    const lazyConfig = bim.configs.getLazySingletonOf({type_identifier: CutFillConfigType});
    const lazySelectedItems = LazyDerived.new3(
        'lazy-selected-items', 
        null, 
        [lazyConfig, lazyBoundaries, lazyTrackers], 
        ([config, boundaries]) => {
        const props = config?.get<CutFillConfig>();
        if(!props){
            return;    
        }
        const selectMode = convertSelectModeToEnum(props.selected_area.value);
        if(selectMode === SelectMode.Equipment || selectMode === SelectMode.EquipmentInBoundary){
            const selectedBoundariesIds = new Set(props.boundary.value);
            const selectedBoundaries = selectedBoundariesIds.size 
                ? IterUtils.filter(boundaries, (b) => selectedBoundariesIds.has(b.bimObjectId))
                : boundaries;
            
            const selectedEquipment: [IdBimScene, Vector3][] = [];
            const items = props.equipment.value.length === 0 
                ? bim.instances.peekByTypeIdents(props.equipment.types) 
                : bim.instances.readAll();
            for (const [id, inst] of items) {
                selectedEquipment.push([id, inst.worldMatrix.extractPosition()]);
            }
            return {
                boundaries: props.boundary,
                equipment: props.equipment,
                selectedBoundaries: selectedBoundaries,
                selectedEquipment: selectedEquipment
            };
        } else {
            logger.error('invalid select mode', selectMode);
            return;
        }
    });

    const lazyAsync = LazyDerivedAsync.new1('lazy-async-equipment-boundaries', null, [lazySelectedItems], function* ([selectedItems]) {
        if(!selectedItems){
            return;
        }
        const byEquipment = yield* extractEquipment(SelectMode.Equipment, selectedItems.equipment, logger, bim);
        const byBoundaries = yield* extractEquipment(SelectMode.EquipmentInBoundary, selectedItems.boundaries, logger, bim);
        return {
            boundaries: byBoundaries,
            equipment: byEquipment,
        };
    });

    const lazyResult = LazyDerived.new2<
        CutFillSelectedAreas | undefined,
        Config,
        ResultAsync<{
            boundaries: CutFillSelectedAreas;
            equipment: CutFillSelectedAreas;
        }| undefined>
    >("lazy-result", null, [lazyConfig, lazyAsync], ([config, task], prevResult) => {
        if (!config) {
            return prevResult;
        }
        const props = config.get<CutFillConfig>();
        const selectMode = convertSelectModeToEnum(props.selected_area.value);

        if (task instanceof Success) {
            return selectMode === SelectMode.Equipment ? task.value?.equipment : task.value?.boundaries;
        } else if (task instanceof Failure) {
            console.error(task.uiMsg);
            return;
        } else if (task instanceof InProgress) {
            return;
        } else {
            return;
        }
    });

    return lazyResult;
}

interface CutFillSelectedAreas {
    selectedMode: SelectMode,
    ids: IdBimScene[],
    contoursByType: Map<CutFillContoursTypes, {
        polygon: Vector2[];
        holes: Vector2[][];
    }[]>,
    area: number,
}

function* extractEquipment(selectedMode: SelectMode, selectedItems: SceneInstancesProperty, logger: ScopedLogger, bim: Bim): Generator<Yield, CutFillSelectedAreas, unknown>{
    const simplifyingEps = 1.0;

    yield Yield.NextFrame;
    const goemetriesAabbs = bim.allBimGeometries.aabbs.poll();
    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));
    
    const ids: IdBimScene[] = [];
    const contoursByType = new Map<CutFillContoursTypes, { polygon: Vector2[], holes: Vector2[][] }[]>();
    if (selectedMode === SelectMode.EquipmentInBoundary){
        const contours: { polygon: Vector2[], holes: Vector2[][] }[] = [];
        const boundariesIds = selectedItems.value.length === 0 
            ? bim.instances.peekByTypeIdents(selectedItems.types)
                .filter(([id, inst]) => !filterBoundary(id, inst))
                .map(i => i[0])
            : selectedItems.value;
        
        const boundariesIdsSet = new Set(boundariesIds);
        const boundaryDescriptions = bim.extractBoundaries()
            .filter(b => boundariesIdsSet.has(b.bimObjectId))
            .filter(b => b.pointsWorldSpace.length > 2);
        let includeBoundaries = boundaryDescriptions
            .filter(b => b.boundaryType === BoundaryType.Include)
            .map(b => PolygonUtils.simplifyContour(b.pointsWorldSpace, simplifyingEps));
        includeBoundaries = includeBoundaries
            .filter(b => b.length >= 3)
            .map(b => orderContour(b));
        let excludeBoundaries = boundaryDescriptions
            .filter(b => b.boundaryType === BoundaryType.Exclude)
            .map(b => PolygonUtils.simplifyContour(b.pointsWorldSpace, simplifyingEps));
        excludeBoundaries = excludeBoundaries
            .filter(b => b.length >= 3)
            .map(b => orderHole(b));
        const unionPolygons = includeBoundaries.length > 0 ? Clipper.unionPolygons2D(includeBoundaries) : [];
        
        yield Yield.Asap;
        
        for (let i = 0; i < unionPolygons.length; i++) {
            let polygon = unionPolygons[i];
            
            if (Math.abs(PolygonUtils.area(polygon)) < 20) {
                continue;
            }
            
            const polygons = Clipper.subtractPolygons(polygon, excludeBoundaries);

            IterUtils.extendArray(contours, splitPolygonsIntoContours(polygons));
            
            yield Yield.Asap;
        }

        contoursByType.set(CutFillContoursTypes.Trackers, contours);

        const checkers = contours.map(c => new PointsInPolygonChecker([c.polygon], c.holes));

        const polygonsAabb2s = contours.map(c => Aabb2.empty().setFromPoints(c.polygon));
        const allInstances = bim.instances.readAll();
        for (let i = 0; i < allInstances.length; i++) {
            const [id, instance] = allInstances[i];
            if(instance.type_identifier === BoundaryTypeIdent){
                continue;
            }
            if (instance.representation) {
                const reprAabb = reprsBboxes.getOrCreate(instance.representation);
                if(reprAabb.isEmpty()){
                    continue;
                }
                const aabb = reprAabb.clone().applyMatrix4(instance.worldMatrix);
                const aabb2 = aabb.xy();
                for (let i = 0; i < contours.length; ++i) {
                    if (checkers[i].isPointInside(aabb2.getCenter())) {
                        ids.push(id);
                        break;                        
                    }
                }
            } else if (instance.representationAnalytical) {
                const geom = bim.allBimGeometries.peekById(instance.representationAnalytical.geometryId ?? 0);
                if(!geom){
                    continue;
                }

                const aabb = geom.calcAabb().applyMatrix4(instance.worldMatrix);
                const aabb2 = aabb.xy();
                if (geom instanceof GraphGeometry){
                    for (let i = 0; i < contours.length; ++i) {
                        if (!polygonsAabb2s[i].intersectsBox2(aabb2)) {
                            continue;
                        }
                        for (const [edge, _] of geom.edges) {
                            const [id1, id2] = LocalIdsCounter.edgeToTuple(edge);
                            const p1 = geom.points.get(id1)!.clone().applyMatrix4(instance.worldMatrix).xy();
                            const p2 = geom.points.get(id2)!.clone().applyMatrix4(instance.worldMatrix).xy();
                            const p3 = p1.clone().add(p2).multiplyScalar(0.5);
                            if (checkers[i].isPointInside(p1) || checkers[i].isPointInside(p2) || checkers[i].isPointInside(p3)) {
                                ids.push(id);
                                break;
                            }
                        }
                        if (ids.length !== 0 && ids.at(-1)! === id) {
                            break;
                        }
                    }
                } else if (geom instanceof PolylineGeometry) {
                    const points = Vector3.arrayFromFlatArray(geom.points3d)
                        .map(p => p.applyMatrix4(instance.worldMatrix).xy());
                    for (let i = 0; i < contours.length; ++i) {
                        if (polygonsAabb2s[i].intersectsBox2(aabb2) &&
                            points.some(p => checkers[i].isPointInside(p))
                        ) {
                            ids.push(id);
                            break;                        
                        }
                    }
                } else {
                    logger.warn(
                        "geometry is not supported for",
                        instance.type_identifier
                    );
                }
            }
        }
    } else if (selectedMode === SelectMode.Equipment || selectedMode === SelectMode.All) {
        const equipmentContours = new Map<CutFillContoursTypes, Vector2[][]>([
            [CutFillContoursTypes.Trackers, []],
            [CutFillContoursTypes.Road, []],
            [CutFillContoursTypes.Platform, []],
        ]);
        const instances = SelectMode.All === selectedMode || selectedItems.value.length === 0
            ? bim.instances.peekByTypeIdents(selectedItems.types) 
            : bim.instances.peekByIds(selectedItems.value);
        for (const [id, instance] of instances) {
            ids.push(id);

            if (typesForCutFill.includes(instance.type_identifier)) {
                const representation = instance?.representation;
                if (representation) {
                    const cutFillType = (
                        instance.type_identifier === TrackerTypeIdent 
                        || instance.type_identifier === FixedTiltTypeIdent
                        || instance.type_identifier === "any-tracker"
                    ) ? CutFillContoursTypes.Trackers : CutFillContoursTypes.Platform;

                    const reprAabb = reprsBboxes.getOrCreate(representation);
                    if(reprAabb.isEmpty()){
                        continue;
                    }
                    const aabb2 = reprAabb.clone().applyMatrix4(instance!.worldMatrix).xy();
                    aabb2.expandByScalar(12);
                    const contours = equipmentContours.get(cutFillType)!;
                    contours.push([
                        aabb2.min, 
                        new Vector2(aabb2.max.x, aabb2.min.y), 
                        aabb2.max, 
                        new Vector2(aabb2.min.x, aabb2.max.y)
                    ]);
                } else {
                    const reprAnalyt = instance?.representationAnalytical;
                    if (!reprAnalyt) {
                        continue;
                    }

                    const geom = bim.allBimGeometries.peekById(reprAnalyt.geometryId);
                    if (geom instanceof GraphGeometry) {
                        const width = instance.properties.get("road | width")?.as('m');
                        if (width) {
                            const polygons: Vector2[][] = [];
                            for (const [start, end] of geom.iterSegments()) {
                                polygons.push(new Segment2(
                                    start.clone().applyMatrix4(instance.worldMatrix).xy(), 
                                    end.clone().applyMatrix4(instance.worldMatrix).xy()
                                ).segmentPolygonPoints(width));
                            }
                            if (polygons.length > 0) {
                                const contours = equipmentContours.get(CutFillContoursTypes.Road)!;
                                IterUtils.extendArray(contours, Clipper.offsetPolygons2D(polygons, 12));
                            }
                        }
                    }
                }
            }
        }

        yield Yield.Asap;

        let allContours: Vector2[][] = [];
        for (const type of EnumUtils.getAllEnumConstsValues(CutFillContoursTypes)) {
            const contours = equipmentContours.get(type)!;
            if (contours.length > 0) {
                IterUtils.extendArray(allContours, contours);
            }
            yield Yield.Asap;
        }
        if (allContours.length > 0) {
            allContours = Clipper.unionPolygons2D(allContours);
            allContours = Clipper.offsetPolygons2D(allContours, -10);
            allContours = allContours.map(contour => PolygonUtils.simplifyContour(contour, simplifyingEps));

            contoursByType.set(CutFillContoursTypes.Trackers, splitPolygonsIntoContours(allContours));
        }
    } else {
        throw new Error("Select mode not implemented");
    }
    
    let area = 0;
    for (const [_, contours] of contoursByType) {
        for (const contour of contours) {
            area += Math.abs(PolygonUtils.area(contour.polygon));
        }
    }
    yield Yield.Asap;
    const result: CutFillSelectedAreas = {
        selectedMode,
        ids,
        contoursByType: contoursByType,
        area,
    };
    return result;
}

function splitPolygonsIntoContours(polygons: Vector2[][]): { polygon: Vector2[], holes: Vector2[][] }[] {
    const contours: { polygon: Vector2[], holes: Vector2[][] }[] = [];
    const holes: Vector2[][] = [];
    for (const p of polygons) {
        const area = PolygonUtils.area(p);
        if (area < -20) {
            contours.push({polygon: p, holes: []});
        } else if (area > 10) {
            holes.push(p);
        }
    }

    const checkers = contours.map(c => new PointsInPolygonChecker([c.polygon], []));

    for (const h of holes) {
        for (let i = 0; i < contours.length; ++i) {
            if (checkers[i].isPointInside(h[0])) {
                contours[i].holes.push(h);
                break;
            }
        }
    }
    
    return contours;
}
