import type {
	IdBimGeo,
	SceneInstance, TerrainTile, TerrainTileId,
	PerTileCutFillHeatmap,
	IrregularHeightmapGeometries,
	SceneObjDiff, CutFillHeatmapCalcJobArgs,
	BimGeometries
} from 'bim-ts';
import {
	IrregularHeightmapGeometry, TerrainHeightMapRepresentation,
	BimGeometryType,
	TerrainGeoVersionSelector,
	RegularHeightmapGeometry,
	CutFillHeatmapCalculationExecutor
} from 'bim-ts';
import type { BasicCollectionUpdates, BasicDataSource, RGBAHex, ScopedLogger} from 'engine-utils-ts';
import { Allocated, Deleted, IterUtils, Success, LegacyLogger, MappedCollectionParallel, ObjectUtils, ObservableStream, RGBA, Updated, InProgress, Failure } from 'engine-utils-ts';
import { Aabb2, Transform, Vector3, Vector4 } from 'math-ts';
import { entityTypeFromId } from 'verdata-ts';
import type { TextureDataType} from '../3rdParty/three';
import { DataTexture, RedFormat, FloatType, UVMapping, ClampToEdgeWrapping, LinearFilter } from '../3rdParty/three';
import { Texture } from '../3rdParty/three';

import type { UniformsFlat } from '../composer/DynamicUniforms';
import type { GridGeoLodDescr } from '../geometries/EngineGeoTerrainRegular';
import type { EngineFullGraphicsSettings } from '../GraphicsSettingsFull';
import { EngineMaterialId } from '../pools/EngineMaterialId';
import { InObjFullId, InObjIdType, InObjLocalId } from '../scene/EngineSceneIds';
import type { ESOHandle, ESOsCollection } from '../scene/ESOsCollection';
import { newLodGroupLocalIdent } from '../scene/LodGroups';
import type {
	EngineSubmeshDescription, RenderJobOutput, RenderJobUpdater} from '../scene/Submeshes2';
import { LodMask
} from '../scene/Submeshes2';
import type { ShaderFlags } from '../shaders/ShaderFlags';
import { TerrainDisplayMode } from '../TerrainDisplayEngineSettings';
import { ESO } from './ESO';
import type { ESOsHandlerInputs } from './ESOsHandlerBase';
import { ESOsHandlerBase } from './ESOsHandlerBase';
import type { ESSO_Any, SubmeshesCreationOutput, SubmeshesCreationResources} from './ESSO';
import {
	ESSO
} from './ESSO';
import { SelectHighlightOverlayJobUpdater } from './ESSO_HighlightUpdaters';
import { ESO_Diff } from './ESO_Diff';


export class ESO_TerrainHeightmap extends ESO {

	constructor(
		sceneInstanceRef: Readonly<SceneInstance>,
	) {
		super(sceneInstanceRef);
	}

	terrainRepr(): Readonly<TerrainHeightMapRepresentation> | null {
		if (this.sceneInstanceRef.representation instanceof TerrainHeightMapRepresentation) {
			return this.sceneInstanceRef.representation;
		}
		return null;
	}
}




export class ESO_TerrainHeightmapHandler extends ESOsHandlerBase<ESO_TerrainHeightmap> {

	// readonly fillTilesIdsCounter: DefaultMapObjectKey<FullTileId, FullTileIdN>;
	readonly calculationsPerInstance = new Map<ESOHandle, PerTerrainInstanceCalculations>();

	readonly _heatmapsOfObjsToRecalc = new Set<ESOHandle>();
	readonly _geometriesIdsUpdated = new Set<IdBimGeo>();

	readonly _throttledDirtyTerrainObjs = new Map<ESOHandle, {delayLeftInFrames: number, dirtySubobjNotificationsCount: 0}>();

	constructor(
		identifier: string,
		args: ESOsHandlerInputs
	) {
		super(identifier, args);

		const regularGeos = this.bimGeos.getCollectionByType<IrregularHeightmapGeometries>(BimGeometryType.HeightmapRegular);
		regularGeos.updatesStream.subscribe({
			settings: { immediateMode: true },
			onNext: (update) => {
				if (update instanceof Updated) {
					IterUtils.extendSet(this._geometriesIdsUpdated, update.ids);
				}
			}
		});
		const irregularGeos = this.bimGeos.getCollectionByType<IrregularHeightmapGeometries>(BimGeometryType.HeightmapIrregular);
		irregularGeos.updatesStream.subscribe({
			settings: { immediateMode: true },
			onNext: (update) => {
				if (update instanceof Updated) {
					IterUtils.extendSet(this._geometriesIdsUpdated, update.ids);
				}
			}
		});

	}

    isSynced(): boolean {
        if (this._dirtyObjects.size > 0 || this._throttledDirtyTerrainObjs.size > 0) {
            return false;
        }
        for (const heatmapCalcs of this.calculationsPerInstance.values()) {
            if (!heatmapCalcs.cutFillTextures.isSynced()) {
                return false;
            }
        }
        return true;
    }

	tryCreateESO(instance: SceneInstance): ESO_TerrainHeightmap | undefined {
		if (instance.representation instanceof TerrainHeightMapRepresentation) {
			return new ESO_TerrainHeightmap(instance);
		}
		return undefined;
	}
	esosTypesToHandle(): Iterable<new (...args: any[]) => ESO_TerrainHeightmap> {
		return [ESO_TerrainHeightmap];
	}

	applyBimDiffToESO(obj: ESO_TerrainHeightmap, diff: SceneObjDiff, handle: ESOHandle): ESO_Diff {
		let esoDiff = super.applyBimDiffToESO(obj, diff, handle);
		if (esoDiff & ESO_Diff.RepresentationBreaking) {
			this._heatmapsOfObjsToRecalc.add(handle);
		}
		return esoDiff;
	}

	onAllocated(handles: Iterable<ESOHandle>): void {
		super.onAllocated(handles);
		IterUtils.extendSet(this._heatmapsOfObjsToRecalc, handles);
	}
	onDeleted(handles: ESOHandle[]): void {
		super.onDeleted(handles);
		for (const h of handles) {
			const calculations = this.calculationsPerInstance.get(h);
			if (calculations !== undefined) {
				this.calculationsPerInstance.delete(h);
				calculations.dispose();
			}
			this._throttledDirtyTerrainObjs.delete(h);
		}
	}

	applySelfImposedUpdates(esos: ESOsCollection): void {

		const geometriesToInvalidate = Array.from(this._geometriesIdsUpdated);
		this._geometriesIdsUpdated.clear();
		// if (geometriesToInvalidate.length) {
		// 	for (const [handle, calculations] of this.calculationsPerInstance) {
		// 		const toUpdateArgs = [];
		// 		for (const [id, args] of calculations.cutFillData.per)
		// 	}
		// }

		for (const [h, calculations] of this.calculationsPerInstance) {
			calculations.cutFillTextures.poll();
			// calculations.cutFillTextures.handleDirtyCalculations();
		}


		const heatmapObjsToInvalidate = Array.from(this._heatmapsOfObjsToRecalc);
		this._heatmapsOfObjsToRecalc.clear();
		for (const h of heatmapObjsToInvalidate) {
			const obj = esos.peek(h);
			if (obj == undefined) {
				continue;
			}
			const terrainInstance = obj as ESO_TerrainHeightmap;
			const repr = terrainInstance.terrainRepr();
			if (repr == null) {
				this.logger.warn('terrain has no representation', h, obj);
				continue;
			}
			let calculations = this.calculationsPerInstance.get(h);
			if (calculations === undefined) {
				calculations = new PerTerrainInstanceCalculations(h, this.logger);
				this.calculationsPerInstance.set(h, calculations);
				calculations.cutFillTextures.updatesStream.subscribe({
					settings: { immediateMode: true },
					onNext: (_update) => {
						let throttled = this._throttledDirtyTerrainObjs.get(h);
						if (throttled === undefined) {
							throttled = { delayLeftInFrames: 200, dirtySubobjNotificationsCount: 0 };
							this._throttledDirtyTerrainObjs.set(h, throttled);
						}
						throttled.dirtySubobjNotificationsCount += 1;
						if (calculations?.cutFillTextures.isSynced()) {
							throttled.delayLeftInFrames = 0;
						}
					}
				})
			} else {
			}
			calculations.reprAsCollection.reconcile(repr, this.bimGeos);
		}

		if (this._throttledDirtyTerrainObjs.size) {
			for (const [h, throttled] of this._throttledDirtyTerrainObjs) {
				throttled.delayLeftInFrames -= 1;
				if (throttled.delayLeftInFrames <= 0) {
					this.markDirty(h, ESO_Diff.ForceReprRecheck);
					this._throttledDirtyTerrainObjs.delete(h);
				}
			}
		}
	}

	createSubObjectsFor(objectsToRealloc: [ESOHandle, ESO_TerrainHeightmap][]): Iterable<[ESOHandle, ESSO<any>[]]> {
		const result: [ESOHandle, ESSO<any>[]][] = [];
		for (const [handle, obj] of objectsToRealloc) {
			const subobjs: ESSO_Any[] = [];
			this.createSelfESSOs(handle, obj, subobjs);
			result.push([handle, subobjs]);
		}
		return result;
	}

	createSelfESSOs(handle: ESOHandle, obj: ESO_TerrainHeightmap, result: ESSO_Any[]): void {
		const repr = obj.terrainRepr();
		if (!repr) {
			this.logger.error('terrain representation is required, instead', obj.bimRepresentation);
			return;
		}
		let counter = 0;

		const calculationsForInstance = this.calculationsPerInstance.get(handle);

		if (calculationsForInstance === undefined) {
			this.logger.error(`unexpected abscence of calculations for`, handle);
		}

		const textures = calculationsForInstance?.cutFillTextures.peekByIds(repr.tiles.keys());

		for (const [tileId, tile] of repr.tiles) {
			counter += 1;

			const cutfillTextureAsync = textures?.get(tileId);

			if (cutfillTextureAsync instanceof InProgress) {
				continue;
			}
			if (cutfillTextureAsync instanceof Failure) {
				this.logger.batchedError('failed to calculate cutfill texture', [cutfillTextureAsync, tileId, tile]);
			}

			const heatmapTexture = (cutfillTextureAsync instanceof Success && cutfillTextureAsync.value)
				? cutfillTextureAsync.value : DefaultHeatmapDataTexture;

			const esso = new ESSO_HeightmapSubOBj(
				obj,
				InObjFullId.new(handle, InObjLocalId.new(InObjIdType.ObjSelf, counter)),
				[tileId, tile, repr.tileSize, heatmapTexture],
				null,
			);
			result.push(esso);
		}
	}
}

const DefaultHeatmapDataTexture = new Texture();

export class ESSO_HeightmapSubOBj extends ESSO<[TerrainTileId, TerrainTile, number, Texture]> {

	get colorTint() { return super.colorTint || RGBA.new(0.3, 0.3, 0.3, 1) }

	isRepresentationTheSame(repr: [TerrainTileId, TerrainTile, number, Texture]) {
		for (let i = 0; i < this.repr.length; ++i) {
			if (!(this.repr[i] === repr[i])) {
				return false;
			}
		}
		return true;
	}

	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {
		const [tileId, tileDescr, tileSize, texture] = this.repr;

		const tileAabb2 = Aabb2.empty();
		tileId.toAabb(tileAabb2, tileSize);

		for (const bimGeoId of [tileDescr.initialGeo, tileDescr.updatedGeo]) {
			const geoId = resoures.geometries.mapBimIdToEngineId(bimGeoId);
			if (!geoId) {
				continue;
			}
			const isOriginalGeo = bimGeoId === tileDescr.initialGeo;
			let flag = isOriginalGeo ? TerrainSubmeshFlag.Original : TerrainSubmeshFlag.Latest;
			if (isOriginalGeo && !tileDescr.updatedGeo) {
				flag |= TerrainSubmeshFlag.Latest;
			}


			let localTransform: Transform | null;
			let lod1Descr: GridGeoLodDescr | undefined;
			let materialId: EngineMaterialId;
			let highglightMaterial: EngineMaterialId;

			if (entityTypeFromId<BimGeometryType>(bimGeoId) == BimGeometryType.HeightmapIrregular) {
				localTransform = null;
				lod1Descr = undefined;
				materialId = EngineMaterialId.Terrain;
				highglightMaterial = EngineMaterialId.Highlight;
			} else {
				localTransform = new Transform(new Vector3(tileId.x * tileSize, tileId.y * tileSize, 0));
				lod1Descr = resoures.geometries.terrainRegular.getLodGeoId(geoId);
				materialId = EngineMaterialId.TerrainRegular;
				highglightMaterial = EngineMaterialId.TerrainRegularBasicTransparent;
			}

			let lodMask: LodMask;
			let lodGroupLocalIdent: number;
			if (lod1Descr == undefined) {
				lodMask = LodMask.All;
				lodGroupLocalIdent = newLodGroupLocalIdent(this.id.inObjId.localId, 0);
			} else {
				lodMask = LodMask.Lod0;
				lodGroupLocalIdent = newLodGroupLocalIdent(this.id.inObjId.localId, lod1Descr.detailSize);
			}

			output.submeshes.push({
				id: this.id,
				lodMask,
				lodGroupLocalIdent,
				subObjectRef: this,
				descr: {
					geoId: geoId,
					materialId,
					localTransforms: [localTransform],
					mainRenderJobUpdater: new TerrainRenderJobUpdater(flag, tileAabb2, texture),
					overlayRenderJobUpdater: new TerrainSelectHightlightUpdater(
						highglightMaterial, true, RGBA.new(1, 1, 1, 0.02), 0,
						flag
					),
				}
			});

			if (lod1Descr) {
				output.submeshes.push({
					id: this.id,
					lodMask: LodMask.Lod1,
					lodGroupLocalIdent,
					subObjectRef: this,
					descr: {
						geoId: lod1Descr.lod1GeoId,
						materialId,
						localTransforms: [localTransform],
						mainRenderJobUpdater: new TerrainRenderJobUpdater(flag, tileAabb2, texture),
						overlayRenderJobUpdater: new TerrainSelectHightlightUpdater(
							highglightMaterial, true, RGBA.new(1, 1, 1, 0.03), 0,
							flag
						),
					}
				});
				output.submeshes.push({
					id: this.id,
					lodMask: LodMask.Lod2,
					lodGroupLocalIdent,
					subObjectRef: this,
					descr: {
						geoId: lod1Descr.lod2GeoId,
						materialId,
						localTransforms: [localTransform],
						mainRenderJobUpdater: new TerrainRenderJobUpdater(flag, tileAabb2, texture),
						overlayRenderJobUpdater: new TerrainSelectHightlightUpdater(
							highglightMaterial, true, RGBA.new(1, 1, 1, 0.03), 0,
							flag
						)
					}
				});
			}
		}
	}
}


enum TerrainSubmeshFlag {
	Latest = 1,
	Original = 2,
}

function shouldRender(terrainFlags: TerrainSubmeshFlag, renderSettings: Readonly<EngineFullGraphicsSettings>) {
	let flagsToShow: TerrainSubmeshFlag | 0 = 0;
	if (renderSettings.terrainDisplay.terrainVersion === TerrainGeoVersionSelector.Latest) {
		flagsToShow |= TerrainSubmeshFlag.Latest;
	} else if (renderSettings.terrainDisplay.terrainVersion === TerrainGeoVersionSelector.Initial) {
		flagsToShow |= TerrainSubmeshFlag.Original;
	} else {
		LegacyLogger.deferredWarn('unrecognized terrain to show setting', renderSettings.terrainDisplay.terrainVersion);
	}
	return (terrainFlags & flagsToShow) != 0;
}

export class TerrainRenderJobUpdater implements RenderJobUpdater {

	constructor(
		readonly terrainFlags: TerrainSubmeshFlag,
		readonly uvsLocalAabb: Aabb2,
		readonly heatmapTexture: Texture,
	) {
	}

	updaterRenderJob(
		submeshDescription: Readonly<EngineSubmeshDescription>,
		renderSettings: Readonly<EngineFullGraphicsSettings>,
		output: { flags: ShaderFlags; materialId: EngineMaterialId; uniforms: UniformsFlat; }
	): void {
		if (submeshDescription.subObjectRef.isHidden) {
			return;
		}
		if (!shouldRender(this.terrainFlags, renderSettings)) {
			return;
		}
		output.materialId = submeshDescription.localDescr.materialId;
		if (renderSettings.terrainDisplay.mode === TerrainDisplayMode.BasicTransparent) {
			if (output.materialId === EngineMaterialId.Terrain) {
				output.materialId = EngineMaterialId.TerrainBasicTransparent;
			} else if (output.materialId === EngineMaterialId.TerrainRegular) {
				output.materialId = EngineMaterialId.TerrainRegularBasicTransparent;
				output.uniforms.push(
					'color',
					new Vector4(0.3, 0.3, 0.3, 0.4),
				);
				output.uniforms.push(
					'gridColor',
					new Vector4(0.0, 0.0, 0.0, 0.5),
				);
			} else {
				console.warn('cant chhose terrain basic material', output.materialId);
			}
		}
		output.uniforms.push(
			'heatmapInCm',
			this.heatmapTexture
		);

		if (output.materialId === EngineMaterialId.Terrain) {
			const aabb = this.uvsLocalAabb;
			const size = aabb.getSize();
			output.uniforms.push(
				'uvsLocalPosOffsetSizeMult',
				new Vector4(aabb.min.x, aabb.min.y, 1 / size.x, 1 / size.y),
			);
		} else if (output.materialId === EngineMaterialId.TerrainRegular) {
		}
	}
}

class TerrainSelectHightlightUpdater extends SelectHighlightOverlayJobUpdater {
	constructor(
		public readonly materialId: EngineMaterialId,
		public readonly disableInEdit: boolean,
		public readonly selectionMaxColor: RGBAHex,
		public readonly blendInitialColor01: number,
		public readonly terrainFlags: TerrainSubmeshFlag,
	) {
		super(materialId, disableInEdit, selectionMaxColor, blendInitialColor01);
	}

	updaterRenderJob(submeshDescription: Readonly<EngineSubmeshDescription<Object>>, renderSettings: Readonly<EngineFullGraphicsSettings>, output: RenderJobOutput): void {
		if (!shouldRender(this.terrainFlags, renderSettings)) {
			return;
		}
		super.updaterRenderJob(submeshDescription, renderSettings, output);
		if (output.materialId === EngineMaterialId.TerrainRegularBasicTransparent) {
			const colorNameIndex = output.uniforms.indexOf('color');
			if (colorNameIndex >= 0) {
				const colorVector = output.uniforms[colorNameIndex + 1] as Vector4;
				output.uniforms[colorNameIndex] = 'gridColor';
				output.uniforms.push(
					'color',
					colorVector.clone().multiplyScalar(0.0),
				);
			}
		}
	}
}



class PerTerrainInstanceCalculations {

	readonly reprAsCollection: TerrainRepresentationAsCollection;
	readonly cutFillTextures: MappedCollectionParallel<Texture | null, TerrainTileId, CutFillHeatmapCalcJobArgs, CutFillHeatmapCalcJobArgs, PerTileCutFillHeatmap | null>;

	constructor(
		handle: ESOHandle,
		logger: ScopedLogger,
	) {
		this.reprAsCollection = new TerrainRepresentationAsCollection(logger);
		this.cutFillTextures = new MappedCollectionParallel<Texture | null, TerrainTileId, CutFillHeatmapCalcJobArgs, CutFillHeatmapCalcJobArgs, PerTileCutFillHeatmap | null>({
			logger: logger,
			identifier: `$${handle}-eso-terrain-cutfill-textures`,
			dataSource: this.reprAsCollection,
			mapExecutorCtor: CutFillHeatmapCalculationExecutor,
			fromJobResultConverter: (cutfillData) => {
				if (cutfillData == null) {
					return null;
				}
				// TODO: more compact texture representation for heatmaps
				let textDatType: TextureDataType;
				let textData: Float32Array | Uint16Array;
				// half floats are bugged for some reason, huge conversion errors on some values
				// if (cutfillData.cutFillInCm instanceof Int8Array) {
				// 	textDatType = HalfFloatType;
				// 	textData = new Uint16Array(cutfillData.cutFillInCm.map(v => DataUtils.toHalfFloat(v)));
				// 	for (let i = 0; i < textData.length; ++i) {
				// 		const valueReconverted = DataUtils.fromHalfFloat(textData[i]);
				// 		const valueOriginal = cutfillData.cutFillInCm[i];
				// 		if (valueOriginal !== valueReconverted) {
				// 			console.log('value reconvertion', valueReconverted, valueOriginal);
				// 		}

				// 	}
				// 	console.log('half float heatmap', cutfillData.cutFillInCm, textData);
				// } else {
				textDatType = FloatType;
				textData = new Float32Array(cutfillData.cutFillInCm);
				// }


				const texture = new DataTexture(
					textData, cutfillData.heatmapSize, cutfillData.heatmapSize,
					RedFormat, textDatType, UVMapping, ClampToEdgeWrapping, ClampToEdgeWrapping,
					LinearFilter, LinearFilter
				);
				texture.needsUpdate = true;
				return texture;
			},
			disposeFn: (texture) => texture?.dispose(),
		});
	}

	dispose() {
		this.cutFillTextures.dispose();
		this.reprAsCollection.dispose();
	}
}
class TerrainRepresentationAsCollection implements BasicDataSource<CutFillHeatmapCalcJobArgs, TerrainTileId> {

	readonly logger: ScopedLogger;
	readonly updatesStream: ObservableStream<BasicCollectionUpdates<TerrainTileId>>;

	readonly _tiles = new Map<TerrainTileId, CutFillHeatmapCalcJobArgs>();

	constructor(
		logger: ScopedLogger,
	) {
		this.logger = logger.newScope('terrain-repr-coll')
		this.updatesStream = new ObservableStream({
			identifier: 'terrain-repr-updates',
			defaultValueForNewSubscribersFactory: () => new Allocated(Array.from(this._tiles.keys())),
		});

	}

	dispose(): void {
	}
	version(): number {
		return this.updatesStream.version();
	}

	_clear() {
		if (this._tiles.size === 0) {
			return;
		}
		const tilesIds = Array.from(this._tiles.keys());
		this._tiles.clear();
		if (tilesIds.length > 0) {
			this.updatesStream.pushNext(new Deleted(tilesIds));
		}
	}

	reconcile(repr: TerrainHeightMapRepresentation | null, bimGeometries: BimGeometries) {
		if (repr == null) {
			this._clear();
			return;
		}
		const deleted = [];
		const updated = [];
		const allocated = [];

		const allIds = new Set(repr.tiles.keys());
		IterUtils.extendSet(allIds, this._tiles.keys());
		for (const id of allIds) {

			const currDescr = this._tiles.get(id);
			const newTile = repr.tiles.get(id);
			if (newTile === undefined) {
				if (this._tiles.delete(id)) {
					deleted.push(id);
				}
			} else {
				const newDescr = this._extractFromTile(id, repr.tileSize, newTile, bimGeometries);
				if (newDescr === null) {
					if (this._tiles.delete(id)) {
						deleted.push(id);
					}
				} else if (!ObjectUtils.areObjectsEqual(newDescr, currDescr)) {
					this._tiles.set(id, newDescr);
					if (currDescr !== undefined) {
						updated.push(id);
					} else {
						allocated.push(id);
					}
				}
			}
		}
		if (deleted.length) {
			this.updatesStream.pushNext(new Deleted(deleted));
		}
		if (updated.length) {
			this.updatesStream.pushNext(new Updated(updated));
		}
		if (allocated.length) {
			this.updatesStream.pushNext(new Allocated(allocated));
		}
	}

	_extractFromTile(tileId: TerrainTileId, tileSize: number, tile: TerrainTile, bimGeometries: BimGeometries): CutFillHeatmapCalcJobArgs | null {
		const initialGeo = bimGeometries.peekById(tile.initialGeo);
		if (initialGeo === undefined) {
			this.logger.error('initial geo absent', tile);
			return null;
		}
		if (!ObjectUtils.isOneOfTypes(initialGeo, [IrregularHeightmapGeometry, RegularHeightmapGeometry])) {
			this.logger.error('unexpected geo type', initialGeo);
			return null;
		}
		const udpatedGeo = tile.updatedGeo > 0 ? bimGeometries.peekById(tile.updatedGeo) : null;
		if (udpatedGeo === undefined) {
			this.logger.error('updated geo absent', tile);
			return null;
		}
		return {
			tileMaxAabb: tileId.aabb(tileSize),
			initialGeo: initialGeo as IrregularHeightmapGeometry | RegularHeightmapGeometry,
			updatedGeo: udpatedGeo as IrregularHeightmapGeometry | RegularHeightmapGeometry | null
		}
	}

	poll(): ReadonlyMap<TerrainTileId, CutFillHeatmapCalcJobArgs> {
		return this._tiles;
	}
	allIds(): IterableIterator<TerrainTileId> {
		return this._tiles.keys();
	}
	peekByIds(ids: Iterable<TerrainTileId>): Map<TerrainTileId, CutFillHeatmapCalcJobArgs | undefined> {
		const result = new Map();
		for (const id of ids) {
			const tile = this._tiles.get(id);
			result.set(id, tile);
		}
		return result;
	}

}
