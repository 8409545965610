import type { BimGeometries } from 'bim-ts';
import type {
	Allocated, Deleted, LazyVersioned, ScopedLogger} from 'engine-utils-ts';
import { DefaultMapWeak, IterUtils, RGBA, StreamAccumulator,
	VersionedInvalidator,
} from 'engine-utils-ts';
import type { Transform} from 'math-ts';
import { Aabb, EmptyBox, Matrix4, Vector4 } from 'math-ts';
import type { EntityId } from 'verdata-ts';

import type { ClipBox } from '../clipbox/ClipBox';
import type { HashedUniforms, UniformsFlat} from '../composer/DynamicUniforms';
import {
	EmptyHashedUniforms, UniformsInterner,
} from '../composer/DynamicUniforms';
import type { RenderJobBase } from '../composer/RenderJob';
import type { RenderJobsGenerator} from '../composer/RenderLists';
import { RenderListFlags } from '../composer/RenderLists';
import type { ESSO } from '../esos/ESSO';
import { ESSO_Diff } from '../esos/ESSO_Diff';
import type {
	AllEngineGeometries, EngineGeoType, IdEngineGeo,
} from '../geometries/AllEngineGeometries';
import type { EngineGeometry} from '../geometries/EngineGeometry';
import { EngineGeometrySharedGpu } from '../geometries/EngineGeometry';
import { IntersectionType } from '../geometries/GeometryUtils';
import type { GeometryGpuId, GpuGeosUser } from '../geometries/GpuGeometries';
import type { EngineFullGraphicsSettings } from '../GraphicsSettingsFull';
import type { EngineStdMaterials } from '../materials/EngineStdMaterials';
import { AllocationSynchronizer } from '../memory/AllocationSynchronizer';
import type { Handle} from '../memory/Handle';
import { HandleIndexMask } from '../memory/Handle';
import {
	createAndBindBinaryAllocator, createAndBindManagedAllocator,
	createAndBindSubmeshesInstancingAllocator,
} from '../memory/MemoryUtils';
import type {
	EngineMaterialId, EngineMaterialIdFlags} from '../pools/EngineMaterialId';
import { GetMaterialIDF,
} from '../pools/EngineMaterialId';
import { OneToManyHandles } from '../scene/OneToManyHandles';
import type { ShaderFlags } from '../shaders/ShaderFlags';
import { EnumsGateway } from '../structs/EnumsGateway';
import type { FrustumExt } from '../structs/FrustumExt';
import { KrBoundsGateway } from '../structs/KrBoundsGateway';
import { EmptyMatrix } from '../structs/KrMatrixGateway';
import type { index } from '../utils/Utils';
import { BoundsSceneWrap, ProgressiveLoddedList } from './BoundsSceneWrap';
import type { EngineResourcesGC } from './EngineResourcesGC';
import type { InObjFullId } from './EngineSceneIds';
import type { ESOHandle, ESOsCollection } from './ESOsCollection';
import type { LodGroupFullId, LodGroupLocalIdent} from './LodGroups';
import { LodGroups } from './LodGroups';
import {
	OpaqueSubmeshesRenderJobsGenerator, TransparentSubmeshesRenderJobsGenerator,
} from './SubmeshesRenderJobsProviders';
import { SubmeshesInstancingRawBlocks } from './SubmeshesInstancingRawBlocks';

export interface SubmeshAllocArgs {
	id: InObjFullId,
	lodMask: LodMask,
	lodGroupLocalIdent: LodGroupLocalIdent,
	descr: SubmeshLocalDescr,
	subObjectRef: ESSO<Object>,
}

export interface RenderJobOutput {
	flags: ShaderFlags,
	materialId: EngineMaterialId,
	uniforms: UniformsFlat,
}

export interface RenderJobUpdater {
	updaterRenderJob(
		submeshDescription: Readonly<EngineSubmeshDescription>,
		renderSettings: Readonly<EngineFullGraphicsSettings>,
		output: RenderJobOutput
	): void;
};

export const NoopJobUpdate: RenderJobUpdater = {
	updaterRenderJob: () => {},
}

export interface SubmeshLocalDescr {
	readonly geoId: IdEngineGeo;
	readonly materialId: EngineMaterialId;
	readonly localTransforms: (Readonly<Transform> | null)[] | null;
	readonly mainRenderJobUpdater: RenderJobUpdater;
	readonly overlayRenderJobUpdater: RenderJobUpdater;
}


export const enum LodMask {
	Lod0 = 1,
	Lod1 = 2,
	Lod2 = 4,

	All = Lod0 | Lod1 | Lod2,
}


export interface EngineSubmeshDescription<R = Object> {
	readonly fullId: InObjFullId;
	readonly subObjectRef: ESSO<R>;
	readonly lodGroupId: LodGroupFullId;
	readonly lodMask: LodMask;

	readonly localDescr: SubmeshLocalDescr;

	readonly geoGpuId: GeometryGpuId;
	readonly geometry: EngineGeometry;
}



// note on memory consumption
// total amortized memory cost of 1 submesh inside large model
// not including geometries and materials
// is about 160 bytes

//comparsion of submeshes instancing and geometry merger:
//memory efficiency:
//when no pile bins, used geometry merger consumes approximate the same amount of memory as submeshes instancing option
//difference can be about ~2-5% in memory consumption both less or more (maybe just a calculation errors)
//when pile bins are used, geometry merger consumes more memory than submeshes instancing option (~10-20% more)
//rendeinrg speed:
//submeshes instancing option in general performs a bit worse than geometry merger, ~1 ms more for a frame to render
//but this difference is consistent - no matter what is the scale of the project it remains the same ~1ms

//note on submeshes instances count:
//instances count per submesh affect memmory efficiency and rendering performance
//the more instances we can pack in a single submesh the more memory efficient and performant it will be 
// for the reference: 
//limiting project with 3_000 trackers to 10 instances max per submesh results in ~5% more memory consumption and ~18% more time for a frame to render
//limiting the same proejct to 1 instance max per submesh results in ~15% more memory consumption and ~38% more time for a frame to render
export class Submeshes2 implements GpuGeosUser {

	readonly _logger: ScopedLogger;

	readonly allocSyncer: AllocationSynchronizer<SubmeshHandle, EngineSubmeshDescription>;
	readonly geometries: (EngineGeometry|null)[]; // TODO: free objects refs on delete
	readonly geometriesIds: IdEngineGeo[];
	readonly submeshesDescriptions: (EngineSubmeshDescription | null)[];

	readonly clipboxPositions: EnumsGateway<IntersectionType, Uint8Array>;
	readonly renderListsIdents: EnumsGateway<RenderListFlags, Uint8Array>;
	readonly lodMasks: EnumsGateway<LodMask | 0, Uint8Array>;
	readonly submeshesInstancingRawBlocks: SubmeshesInstancingRawBlocks;
	readonly geomsGpuHandles: EnumsGateway<GeometryGpuId, Uint32Array>;
	readonly renderJobsMain: SubmeshRenderJob[];
	readonly renderJobsOverlay: SubmeshRenderJob[];

	readonly scene: BoundsSceneWrap<SubmeshHandle>;
	readonly bounds: KrBoundsGateway;

	readonly rootObjsRefs: OneToManyHandles<ESOHandle, SubmeshHandle> = new OneToManyHandles();
	readonly subObjsRefs: OneToManyHandles<InObjFullId, SubmeshHandle> = new OneToManyHandles();

	readonly lodGroups: LodGroups;
	readonly lodGroupsRefs: OneToManyHandles<LodGroupFullId, SubmeshHandle> = new OneToManyHandles();

	readonly sceneEntitiesRenderSettings: LazyVersioned<EngineFullGraphicsSettings>;
	readonly bimGeometries: BimGeometries;
	readonly engineGeometries: AllEngineGeometries;
	readonly engineMaterials: EngineStdMaterials;

	_gpuUploadsSub: StreamAccumulator<Allocated<IdEngineGeo> | Deleted<IdEngineGeo>>;
	_submeshesWaitingGpuQueue: SubmeshAllocArgs[] = [];

	readonly dirtyHandles: Map<SubmeshHandle, ESSO_Diff> = new Map();

	readonly std_opaque_provider: RenderJobsGenerator;
	readonly std_transp_provider: RenderJobsGenerator;
	readonly overlay_jobs_provider: RenderJobsGenerator;

	_dirtyRenderLists: RenderListFlags = 0;

	readonly uniformInterner: UniformsInterner = new UniformsInterner();

	hash: VersionedInvalidator; //TODO: replace with per flat invalidation
	private _prevRenderSettingsVersion: number = 0;

	public esos!: ESOsCollection;
	private _retryAllocation: boolean = false;

	private _cullingPerFrustum: DefaultMapWeak<FrustumExt, ProgressiveLoddedList>;

	constructor(
		logger: ScopedLogger,
		gc: EngineResourcesGC,
		bimGeometries: BimGeometries,
		engineGeometries: AllEngineGeometries,
		engineMaterials: EngineStdMaterials,
		sceneEntitiesRenderSettings: LazyVersioned<EngineFullGraphicsSettings>,
		clipbox: ClipBox,
	) {
		this._logger = logger.newScope('submeshes');

		this.bimGeometries = bimGeometries;
		this.engineGeometries = engineGeometries;
		this.engineMaterials = engineMaterials;
		this.sceneEntitiesRenderSettings = sceneEntitiesRenderSettings;
		this._gpuUploadsSub = new StreamAccumulator(engineGeometries.gpuGeometries.gpuUploadsStream);

		this.allocSyncer = new AllocationSynchronizer<SubmeshHandle, EngineSubmeshDescription>(
			'submeshes-alloc',
			this._logger
		);

		this.submeshesDescriptions = createAndBindManagedAllocator<(EngineSubmeshDescription | null), EngineSubmeshDescription>(
			this.allocSyncer,
			args => args,
			(_) => null
		);

		this.geometries = createAndBindManagedAllocator<EngineGeometry|null, EngineSubmeshDescription>(
			this.allocSyncer,
			args => args.geometry,
			() => null,
		);

		this.geometriesIds = createAndBindManagedAllocator(
			this.allocSyncer,
			args => args.localDescr.geoId,
			id => { return 0; }
		);

		this.scene = new BoundsSceneWrap(clipbox, 0.);

		this.bounds = new KrBoundsGateway('submeshes bounds');
		createAndBindBinaryAllocator<Aabb, Float64Array, KrBoundsGateway, EngineSubmeshDescription>(
			this.allocSyncer,
			this.bounds,
			_args => EmptyBox,
			size => this.scene.reallocateBoundsBuffer(size),
			(b, i) => b.emptyOut(i),
		);
		this.clipboxPositions = new EnumsGateway<IntersectionType, Uint8Array>('submeshes clipbox positions');
		createAndBindBinaryAllocator<IntersectionType, Uint8Array, EnumsGateway<IntersectionType, Uint8Array>, EngineSubmeshDescription>(
			this.allocSyncer,
			this.clipboxPositions,
			_args => IntersectionType.Full,
			size => this.scene.reallocateClipboxRelativePositionsBuffer(size),
		);

		this.renderListsIdents = new EnumsGateway<RenderListFlags, Uint8Array>('renderListIdents');
		createAndBindBinaryAllocator<RenderListFlags, Uint8Array, EnumsGateway<RenderListFlags, Uint8Array>, EngineSubmeshDescription>(
			this.allocSyncer,
			this.renderListsIdents,
			(_args) => 0,
			size => new Uint8Array(size),
			(self, index) => self.set(index, 0),
		);

		const defaultRawDataArgs: [Matrix4, Vector4] = [EmptyMatrix, new Vector4(0, 0, 0, 0)];

		this.lodMasks = new EnumsGateway<LodMask | 0, Uint8Array>('lod-masks');
		createAndBindBinaryAllocator<LodMask | 0, Uint8Array, EnumsGateway<LodMask | 0, Uint8Array>, EngineSubmeshDescription>(
			this.allocSyncer,
			this.lodMasks,
			(args) => args.lodMask,
			size => new Uint8Array(size),
			(self, index) => self.set(index, 0),
		);

		this.submeshesInstancingRawBlocks = new SubmeshesInstancingRawBlocks();
		createAndBindSubmeshesInstancingAllocator<EngineSubmeshDescription>(
			this.allocSyncer,
			this.submeshesInstancingRawBlocks,
			args => args.localDescr.localTransforms?.length ?? 1,
		);

		this.geomsGpuHandles = new EnumsGateway<GeometryGpuId, Uint32Array>('submeshes:posToClipbox');
		createAndBindBinaryAllocator<GeometryGpuId, Uint32Array, EnumsGateway<GeometryGpuId, Uint32Array>, EngineSubmeshDescription>(
			this.allocSyncer,
			this.geomsGpuHandles,
			args => args.geoGpuId,
			size => new Uint32Array(size)
		);

		this.renderJobsMain = createAndBindManagedAllocator<SubmeshRenderJob, EngineSubmeshDescription>(
			this.allocSyncer,
			(args) => new SubmeshRenderJob(0, args.geoGpuId),
			rj => rj.reset(),
		);
		this.renderJobsOverlay = createAndBindManagedAllocator<SubmeshRenderJob, EngineSubmeshDescription>(
			this.allocSyncer,
			(args) => new SubmeshRenderJob(0, args.geoGpuId),
			rj => rj.reset(),
		);

		this.hash = new VersionedInvalidator([this.allocSyncer]);

		this.lodGroups = new LodGroups(this, clipbox);

		this.std_opaque_provider = new OpaqueSubmeshesRenderJobsGenerator(this, this.renderJobsMain, RenderListFlags.Opaque);
		this.std_transp_provider = new TransparentSubmeshesRenderJobsGenerator(this, this.renderJobsMain, RenderListFlags.Transp);
		this.overlay_jobs_provider = new TransparentSubmeshesRenderJobsGenerator(this, this.renderJobsOverlay, RenderListFlags.Overlay);
		// this.std_shadow_batch = new StdShadowMaterialBatch( groundShadow, this );
		// this.highlighBatch = new StdSingleMaterialBatch( GetMaterialIDF(SpecMatsIds.Highlight, 0), this );
		// this.selectionBatch = new StdSingleMaterialBatch( GetMaterialIDF(SpecMatsIds.Selection, 0), this );

		this._cullingPerFrustum = new DefaultMapWeak((fr) => new ProgressiveLoddedList(fr, this));

		engineGeometries.gpuGeometries.registerGpuGeosUser(this);

		gc.registerGCedEntitiesUser(
			'geometries',
			{
				getIdsToRetain: (result: EntityId<EngineGeoType>[]) => {
					let prevGeoId: number = 0;
					for (const index of this.allocSyncer.getActiveIndices()) {
						const geoId = this.geometriesIds[index];
						if (geoId === prevGeoId) {
							continue;
						}
						prevGeoId = geoId;
						result.push(geoId);
					}
					for (const waiting of this._submeshesWaitingGpuQueue) {
						result.push(waiting.descr.geoId);
					}
				},
				updatesStream: this.allocSyncer.updatesStream,
			}
		);
	}

	dispose() {
		this._gpuUploadsSub.dispose();
		this.scene.dispose();
	}

    isSynced() {
        return this._submeshesWaitingGpuQueue.length === 0;
    }

	cullingFor(fr: FrustumExt): ProgressiveLoddedList {
		let cb = this._cullingPerFrustum.getOrCreate(fr);
		return cb;
	}

	getGpuGeosWaitingFor(): IdEngineGeo[] {
		const ids = [];
		for (const s of this._submeshesWaitingGpuQueue) {
			ids.push(s.descr.geoId);
		}
		return ids;
	}

	anySubmeshesArentReady(): boolean {
		return this._submeshesWaitingGpuQueue.length > 0 || this.dirtyHandles.size > 0;
	}

	bimIdsWaitingAlloc(): Set<ESOHandle> {
		const bimIds = new Set<ESOHandle>();
		for (const s of this._submeshesWaitingGpuQueue.values()) {
			bimIds.add(s.id.objHandle);
		}
		return bimIds;
	}

	addForAllocation(submeshesArgs: SubmeshAllocArgs[]) {

		// defer allocation to allow geometries sync to end
		IterUtils.extendArray(this._submeshesWaitingGpuQueue, submeshesArgs);
		this._retryAllocation = true;
	}

	tryAllocateDeferred() {

		const toAlloc: EngineSubmeshDescription[] = [];

		const submeshesArgs = this._submeshesWaitingGpuQueue.slice();
		this._submeshesWaitingGpuQueue.length = 0;

		for (const submeshArgs of submeshesArgs) {
			const descr = submeshArgs.descr;

			const geometry = this.engineGeometries.peekById(descr.geoId);
			if (!geometry) {
				this._logger.batchedError(`could not allocate submesh, geometry absent`, submeshArgs);
				continue;
			}
			const rooObjRef = this.esos.peek(submeshArgs.id.objHandle);
			if (rooObjRef === undefined) {
				this._logger.batchedError(`could not allocate submesh, root obj absent`, submeshArgs);
				continue;
			}

			const geoGpuId = this.engineGeometries.gpuGeometries.tryGetGpuId(descr.geoId);
			if (geoGpuId === undefined) {
				this._submeshesWaitingGpuQueue.push(submeshArgs);
				continue;
			}
			const submesh: EngineSubmeshDescription = {
				subObjectRef: submeshArgs.subObjectRef,
				fullId: submeshArgs.id,
				lodMask: submeshArgs.lodMask,
				lodGroupId: this.lodGroups.getLodGroupId(submeshArgs.id.objHandle, submeshArgs.lodGroupLocalIdent),
				localDescr: submeshArgs.descr,
				geoGpuId,
				geometry: geometry as EngineGeometry,
			};
			toAlloc.push(submesh);
		}


		if (toAlloc.length) {
			const handlesAllocated = this.allocSyncer.allocate(toAlloc);
			for (let i = 0; i < handlesAllocated.length; ++i) {
				const submeshHandlle = handlesAllocated[i];
				const submesh = toAlloc[i];
				this.dirtyHandles.set(submeshHandlle, ESSO_Diff.All);
				this.rootObjsRefs.add(submesh.fullId.objHandle, submeshHandlle);
				this.subObjsRefs.add(submesh.fullId, submeshHandlle);
				this.lodGroupsRefs.add(submesh.lodGroupId, submeshHandlle);
			}
		}
	}

	deleteByParentHandles(parentHandles: ESOHandle[]) {
		const submeshesToRemove: SubmeshHandle[] = [];
		for (const parentH of parentHandles) {
			for (const sh of this.rootObjsRefs.getReferenced(parentH)) {
				submeshesToRemove.push(sh);
			}
		}
		this._deleteActiveSubmeshesByHandles(submeshesToRemove);

		if (this._submeshesWaitingGpuQueue.length) {
			const removedParentsSet = new Set(parentHandles);
			this._submeshesWaitingGpuQueue
				= this._submeshesWaitingGpuQueue.filter(s => !removedParentsSet.has(s.id.objHandle));
		}
	}

	deleteByFullIds(fullIds: InObjFullId[]) {
		const submeshesToRemove: SubmeshHandle[] = [];
		for (const fullId of fullIds) {
			for (const sh of this.subObjsRefs.getReferenced(fullId)) {
				submeshesToRemove.push(sh);
			}
		}
		this._deleteActiveSubmeshesByHandles(submeshesToRemove);

		if (this._submeshesWaitingGpuQueue.length) {
			const removedParentsSet = new Set(fullIds);
			this._submeshesWaitingGpuQueue
				= this._submeshesWaitingGpuQueue.filter(s => !removedParentsSet.has(s.id));
		}
	}

	_deleteActiveSubmeshesByHandles(handles: SubmeshHandle[]) {
		if (!handles.length) {
			return;
		}
		const lodGroupsIds = new Map<SubmeshHandle, LodGroupFullId | undefined>();
		const renderLists = new Map<SubmeshHandle, RenderListFlags>();

		for (const sh of handles) {
			const rl = this.renderListsIdents.get(sh & HandleIndexMask);
			const lodGroupId = this.submeshesDescriptions[sh & HandleIndexMask]?.lodGroupId;
			lodGroupsIds.set(sh, lodGroupId);
			renderLists.set(sh, rl);
		}

		const {removed} = this.allocSyncer.delete(handles);

		for (const sh of removed) {
			const rl = renderLists.get(sh)!;
			this._dirtyRenderLists |= rl;

			const lodGroupfullId = lodGroupsIds.get(sh);
			if (lodGroupfullId) {
				this.lodGroups.markDirtyById(lodGroupfullId);
			}

			this.scene.markDirty(sh);
			this.subObjsRefs.freeChildRef(sh);
			this.rootObjsRefs.freeChildRef(sh);
			this.lodGroupsRefs.freeChildRef(sh);
			this.hash.invalidate();
		}
	}


	markDirtyBytDiffs(parentDiffs: [InObjFullId, ESSO_Diff][]) {
		for (const [id, diff] of parentDiffs) {
			for (const sh of this.subObjsRefs.getReferenced(id)) {
				this.dirtyHandles.set(sh, (this.dirtyHandles.get(sh) as number) | diff);
			}
		}
	}

	updateCullingAndRenderLists(clipbox: ClipBox, frustums: FrustumExt[]) {
		this.scene.prepareForCulling({ frustums });
	}

	applyUpdates() {
		const geometriesUploaded = this._gpuUploadsSub.consume();
		if (geometriesUploaded?.length) {
			this._logger.debug('geometries gpu uploaded, retry alloc', geometriesUploaded);
			this._retryAllocation = true;
		}

		if (this._retryAllocation) {
			this._retryAllocation = false;
			this.tryAllocateDeferred();
		}

		const renderSettings = this.sceneEntitiesRenderSettings.poll();
		const renderSettingsVersion = this.sceneEntitiesRenderSettings.version();
		const isRenderSettingsDirty = this._prevRenderSettingsVersion !== renderSettingsVersion;
		this._prevRenderSettingsVersion = renderSettingsVersion;

		if (isRenderSettingsDirty) {
			for (const h of this.allocSyncer.getActiveHandles()) {
				const flags = this.dirtyHandles.get(h) ?? 0;
				const flagsToAdd = ESSO_Diff.RepresentationSoft | ESSO_Diff.RepresentationOverlaySoft;
				this.dirtyHandles.set(h, flags | flagsToAdd);
			}
		}

		if (this.dirtyHandles.size !== 0) {
			try {
				this._updateDirtyRenderJobsFor(this.dirtyHandles, renderSettings);
			} finally {
				this.dirtyHandles.clear();
			}
		}

		this.scene.updateWasmSceneHierarchy();

		this.lodGroups.applyUpdates();

		if (this._dirtyRenderLists) {
			for (const prov of [
				this.std_opaque_provider,
				this.std_transp_provider,
				this.overlay_jobs_provider
			]) {
				if (prov.renderListFlag & this._dirtyRenderLists) {
					prov.markDirty();
				}
			}
			this._dirtyRenderLists = 0;
		}
	}

	_updateDirtyRenderJobsFor(
		dirtySubmeshes: Iterable<[SubmeshHandle, ESSO_Diff]>,
		renderSettings: Readonly<EngineFullGraphicsSettings>,
	) {


		const reusedColorTintVec = new Vector4(0, 0, 0, 0);

		const rjOutput = new RenderJobOutputImpl();

		const materials = this.engineMaterials;

		const MainRenderLists = RenderListFlags.Opaque | RenderListFlags.Transp;
		const uniqueUniforms = this.uniformInterner;

		for (const [sh, diff] of dirtySubmeshes) {
			const sInd = this.allocSyncer.tryGetIndex(sh);
			if (!(sInd >= 0)) {
				continue;
			}
			const submesh = this.submeshesDescriptions[sInd];
			if (!submesh) {
				this._logger.batchedError('unexpected absence of submesh', sInd);
				continue;
			}

			const prevRenderLists = this.renderListsIdents.buffer[sInd];

			let dirtyRenderLists = RenderListFlags.None;
			let newRenderLists = RenderListFlags.None;

			const objState = submesh.subObjectRef;
			let mainRenderJobUpdated: boolean;
			if (diff & ESSO_Diff.RepresentationSoft) {
				mainRenderJobUpdated = rjOutput.tryUpdate(
					submesh.localDescr.mainRenderJobUpdater,
					uniqueUniforms,
					submesh.geometry,
					submesh,
					renderSettings,
					this.renderJobsMain[sInd]
				)
			} else {
				mainRenderJobUpdated = false;
			}
			if (mainRenderJobUpdated) {
				let renderList: RenderListFlags;
				if (rjOutput.materialId === 0) {
					renderList = RenderListFlags.None;
				} else {
					renderList = materials.isTransparent(rjOutput.materialId) ? RenderListFlags.Transp : RenderListFlags.Opaque;
				}
				newRenderLists |= renderList;
				dirtyRenderLists |= ((newRenderLists | prevRenderLists) & MainRenderLists);
			} else {
				newRenderLists |= (prevRenderLists & MainRenderLists);
			}

			let overlayRenderJobUpdated: boolean;
			if (diff & ESSO_Diff.RepresentationOverlaySoft) {
				overlayRenderJobUpdated = rjOutput.tryUpdate(
					submesh.localDescr.overlayRenderJobUpdater,
					uniqueUniforms,
					submesh.geometry,
					submesh,
					renderSettings,
					this.renderJobsOverlay[sInd]
				);

			} else {
				overlayRenderJobUpdated = false;
			}
			if (overlayRenderJobUpdated) {
				const renderList = rjOutput.materialId ? RenderListFlags.Overlay : RenderListFlags.None;
				newRenderLists |= renderList;
				dirtyRenderLists |= ((newRenderLists | prevRenderLists) & RenderListFlags.Overlay);
			} else {
				newRenderLists |= (prevRenderLists & RenderListFlags.Overlay);
			}

			if (diff & ESSO_Diff.Position) {
				const wm = objState.parentWorldMatrix;
				//submesh.subObjectRef.calcWorldMatrix(reusedMatrix4); value of suboject transform not used
				const submeshLocalTransforms = submesh.localDescr.localTransforms;
				this.submeshesInstancingRawBlocks.setMatricies(submeshLocalTransforms, wm, reusedMatrix4, sInd);

				dirtyRenderLists |= newRenderLists;
			}

			if (diff & ESSO_Diff.ColorTint) {
				const tint = objState.colorTint || RGBA.new(0, 0, 0, 0);
				const vColor = RGBA.RGBAHexToVec4(tint, reusedColorTintVec);
				this.submeshesInstancingRawBlocks.setVector(vColor, sInd);

				dirtyRenderLists |= newRenderLists;
			}


			const isVisible = newRenderLists !== 0;
			const prevVisible = prevRenderLists !== 0;
			if (prevVisible !== isVisible || (diff & ESSO_Diff.Position)) { // update bounds
				if (isVisible) {
					//read ri transform
					const originAabb = this.geometries[sInd]!.aabb();
					reusedBox3.copy(EmptyBox);

					this.submeshesInstancingRawBlocks.forEachInstance(sInd, reusedMatrix4, (instanceMatrix: Matrix4) => {
						reusedInstancingBox3.copy(originAabb);
						reusedInstancingBox3.applyMatrix4(instanceMatrix);
						reusedBox3.union(reusedInstancingBox3)
					});
				} else {
					reusedBox3.copy(EmptyBox);
				}
				if (this.bounds.toBufferEqualityCheck(reusedBox3, sInd)) {
					this.scene.markDirty(sInd);
					this.lodGroups.markDirtyById(submesh.lodGroupId);
				}
			}

			this.renderListsIdents.buffer[sInd] = newRenderLists;

			if (dirtyRenderLists) {
				this._dirtyRenderLists |= dirtyRenderLists;
				this.hash.invalidate();
			}
		}
	}

	calcBoundsByParentHandles(ids: Iterable<ESOHandle>): Aabb {
		const bounds = Aabb.empty();
		for (const id of ids) {
			this.calcBoundsByParentHandle(id, bounds);
		}
		return bounds;
	}

	calcBoundsByParentHandle(handle: ESOHandle, result: Aabb) {

		for (const sh of this.rootObjsRefs.getReferenced(handle)) {
			const sBounds = this.calcBounds_t(sh);
			if (!sBounds.isEmpty()) {
				result.union(sBounds);
			}
		}
	}

	getByParentEsoHandle(parentId: ESOHandle): IterableIterator<SubmeshHandle> {
		return this.rootObjsRefs.getReferenced(parentId);
	}

	getByParentEsso(id: InObjFullId): IterableIterator<SubmeshHandle> {
		return this.subObjsRefs.getReferenced(id);
	}

	getParentEsoHandle(handle: index | SubmeshHandle): ESOHandle | undefined {
		return this.rootObjsRefs.getParentRef(handle);
	}
	getParentEssoId(handle: index | SubmeshHandle): InObjFullId | undefined {
		return this.subObjsRefs.getParentRef(handle);
	}

	calcBounds_t(handle: SubmeshHandle): Aabb {
		let index = handle & HandleIndexMask;
		this.bounds.toStruct(index, reusedBox3);
		// const ri = this.renderInfosRefs[index];
		// if (ri.animation) {
		// 	const a = ri.animation;
		// 	if (a.positionOffset) {
		// 		defaultBounds.translate(a.positionOffset);
		// 	}
		// }
		return reusedBox3;
	}
}

class RenderJobOutputImpl implements RenderJobOutput {
	flags: ShaderFlags = 0;
	materialId: EngineMaterialId = 0;
	uniforms: UniformsFlat = [];


	reset() {
		this.flags = 0;
		this.materialId = 0;
		this.uniforms.length = 0;
	}

	tryUpdate(
		renderJobUpdate: RenderJobUpdater,
		uniformsReducer: UniformsInterner,
		geometry: EngineGeometry,
		submeshDescription: Readonly<EngineSubmeshDescription>,
		renderSettings: Readonly<EngineFullGraphicsSettings>,
		rjOutput: SubmeshRenderJob,
	): boolean {
		this.flags = 0;
		this.materialId = 0;
		this.uniforms.length = 0;

		renderJobUpdate.updaterRenderJob(submeshDescription, renderSettings, this);

		let matIdFull = 0;
		if (this.materialId !== 0) {
			const gpuGeo = geometry.asGpuRepr();
			if (gpuGeo.shaderInfo) {
				this.flags |= gpuGeo.shaderInfo.flags;
				this.uniforms.push(...gpuGeo.shaderInfo.uniforms);
			}
	
			if (geometry instanceof EngineGeometrySharedGpu) {
				this.flags |= geometry.additionalShaderInfo.flags;
				this.uniforms.push(...geometry.additionalShaderInfo.uniforms);
			}
			matIdFull = GetMaterialIDF(this.materialId, this.flags);
		} else {
			matIdFull = 0;
		}


		if (matIdFull !== rjOutput.materialIDF || !rjOutput.dynamicUniforms.equalTo(this.uniforms)) {
			rjOutput.setMaterial(matIdFull, uniformsReducer.getUniqued(this.uniforms));
			return true;
		}
		return false;
	}
}


export type SubmeshHandle = Handle & Submeshes2;


export class SubmeshRenderJob implements RenderJobBase {

	geometryGpuId: GeometryGpuId;
	materialIDF: EngineMaterialIdFlags;
	dynamicUniforms: HashedUniforms;

	constructor(
		materialId: EngineMaterialIdFlags,
		geometry: GeometryGpuId, // temp
	) {
		this.materialIDF = materialId;
		this.geometryGpuId = geometry;
		this.dynamicUniforms = EmptyHashedUniforms;
	}

	setMaterial(
		materialId: EngineMaterialIdFlags,
		dynamicUniforms: HashedUniforms,
	) {
		this.materialIDF = materialId;
		this.dynamicUniforms = dynamicUniforms ?? EmptyHashedUniforms;
	}

	reset() {
		this.geometryGpuId = 0;
		this.materialIDF = 0;
		this.dynamicUniforms = EmptyHashedUniforms;
		return this;
	}
}


const reusedBox3 = Aabb.empty();
const reusedInstancingBox3 = Aabb.empty();
const reusedMatrix4: Matrix4 = new Matrix4();

