import {
    CubicBezier,
    Object2D,
    QuadraticBezier,
    VectorPrimitive,
    VectorPrimitiveCircle,
    VectorPrimitivePath,
    VectorPrimitiveRectangle,
    VectorPrimitiveText,
} from "vector-graphic";
import { DxfFileManager } from "./DxfFileManager";
import { blockInsertData, DXFNodeFactory, dxfTextData } from "./DxfFileFactory";
import { KrMath, Matrix3, Vector2 } from "math-ts";
import { DXFNode } from "./DxfNode";
import { BlockTypeFlag, HorizontalJustification, VerticalJustification } from "./DxfEnums";
import { ObjectUtils, RGBA, ScopedLogger } from "engine-utils-ts";
import { getClosestColorFromAutocadPalette } from "./DxfColor";
import { sanitizeBlockName } from "./DxfDataService";

const defaultColors = new Map<string, number>([
    ['black', 250],
    ['white', 255]
]);

const sldLayer = "pvfarm_sld";

class SldId {
    private static _handle: number = 0;
    static get():number{   
        return this._handle++;
    }
}

export class SldDataService {

    public static blockCache: Map<string, Map<string, Object2D>> = new Map();
     static _scale: number = 1;
     public static get scale():number{
        return this._scale;
     }


    public static createSldDiagram(
        manager: DxfFileManager,
        sldDiagram: Object2D,
        layoutName: string | undefined,
        scale: number,
        logger: ScopedLogger
    ) {
        this._scale = scale;
        const scaleMatrix = new Matrix3().scale(scale,-scale);
        proccessObject2d(logger, manager, sldDiagram, scaleMatrix, layoutName);
    }
}


function proccessObject2d(
    logger:ScopedLogger,
    manager: DxfFileManager,
    obj: Object2D,
    scaleMatrix:Matrix3,
    parentBlockName?: string,
    parentMtrx?: Matrix3

) {

    function proccesObject(object:VectorPrimitive, parentBlockName:string|undefined, matrix?: Matrix3){
        
        if(object instanceof Object2D){
            proccessObject2d(logger, manager, object, scaleMatrix, parentBlockName, matrix);
        }else{
            proccessPrimitive(logger, manager, object, scaleMatrix, parentBlockName, matrix);
        }
    }

    function simplifyObject2(obj: Object2D): Partial<Object2D> {
        return {
            name: obj.name,
            position: obj.position.clone(),  
            rotation: obj.rotation,     
            scale: obj.scale.clone(),        
            matrix: obj.matrix.clone(),      
            primitives: obj.primitives,
            children: obj.children,  
            zIndex: obj.zIndex,         
            aabb: obj.aabb                   
        };
    }

    function simplifyObject(obj: Object2D): {primitives:VectorPrimitive[], children:Partial<Object2D>[] } {
        const simplifiedChildren = obj.children.map(simplifyObject2);
        return {
            primitives: obj.primitives,
            children: simplifiedChildren,
        };
    }

    const  rotation = obj.rotation;

    // sort by rendering order
    const sortedItems: Array<Object2D | VectorPrimitive> = [
        ...obj.primitives,
        ...obj.children,
    ].reverse().sort((l, r) => l.zIndex - r.zIndex);

    let name = obj.name;


    const localMatrix = obj.matrix.clone();
    if(parentMtrx){
        localMatrix.premultiply(parentMtrx);
    }
    const scaledLocalMatrix = localMatrix.clone().premultiply(scaleMatrix)


    if(!name){

        for(const item of sortedItems){
            proccesObject(item, parentBlockName, localMatrix);     
        }

        return;
    }

    let blockName = sanitizeBlockName(`pvfarm_sld_${name}_${SldId.get()}`);
    const simpObject = simplifyObject(obj);
    let cachedObjects = SldDataService.blockCache.get(name) || new Map;


    let matchedBlockName: string | null = null;
    for (const cachedObj of cachedObjects) {
        if (ObjectUtils.areObjectsEqual(cachedObj[1], simpObject, 3)) {
            matchedBlockName = cachedObj[0];
            break;
        }
    }

    if (matchedBlockName) {
        blockName = matchedBlockName; 
    } else {
        cachedObjects.set(blockName, simpObject);
        SldDataService.blockCache.set(name, cachedObjects);
    }

    const insertPoint = new Vector2().applyMatrix3(scaledLocalMatrix);
    
    const block = manager.getBlocksNames().find((n) => n === blockName);

    if (!block) {
        
        manager.addBlock(blockName, BlockTypeFlag.SimpleBlock);

        for(const item of sortedItems){
            proccesObject(item, blockName)
        }
    }
    
    const trackerInsertData: blockInsertData = {
        blockName, 
        insertPoint,
        rotation: KrMath.radToDeg(rotation),
        layer: sldLayer  
    }

    const insert = DXFNodeFactory.createBlockInsert(
        manager,
        trackerInsertData,
        parentBlockName
    );

    if(!insert){
        return;
    }

    
    if(blockName){
        const ownerHandle = manager.getBlockRecord(blockName)?.properties.get(5) as string;
        insert.properties.set(330, ownerHandle);
    }
    insert.properties.set(67, 1);
}

function proccessPrimitive(
    logger:ScopedLogger,
    manager: DxfFileManager,
    primitive: VectorPrimitive,
    scaleMatrix: Matrix3,
    blockName?: string,
    parentMatrix?: Matrix3,
): DXFNode | undefined {

    const matrix = primitive.matrix?.clone() ?? new Matrix3();
    if(parentMatrix){
        matrix.premultiply(parentMatrix)
    }
    matrix.premultiply(scaleMatrix);

    if (primitive instanceof VectorPrimitiveRectangle) {
        return proccessRectangle(
            logger,
            manager,
            primitive,
            matrix,
            blockName
        );
    } else if (primitive instanceof VectorPrimitivePath) {
        proccessPath(
            logger,
            manager,
            primitive,
            matrix,
            blockName
        );
    } else if (primitive instanceof VectorPrimitiveCircle) {
        return proccessCircle(logger, manager, primitive,  matrix, blockName);
    } else if (primitive instanceof VectorPrimitiveText) {
        return proccessText(logger, manager, primitive, matrix, blockName);
    }

    return;
}

function proccessRectangle(
    logger:ScopedLogger,
    manager: DxfFileManager,
    rectangle: VectorPrimitiveRectangle,
    matrix: Matrix3,
    blockName?: string
): DXFNode | undefined {


    const bb = rectangle.recalculateAabb().clone();

    bb.applyMatrix3(matrix);
    
    if (!(bb.getSize().isFinite())){
        logger.error(`Rectangle bb is infinite!`)
        return;
    }

    const vertices = Array.from(bb.cornerPoints());

    const width = rectangle.stroke
        ? rectangle.strokeWidth
            ? rectangle.strokeWidth * SldDataService.scale
            : 1 * SldDataService.scale
        : 0;

    const dxfPolyline = DXFNodeFactory.createPolyLine(
        manager,
        { vertices, width, closed: true, layer:sldLayer },
        blockName
    );

    dxfPolyline.properties.set(67, 1);
    
    if(rectangle.fill){
        
        const dxfHatch = DXFNodeFactory.addRectangleHatch(manager, vertices, 250, sldLayer, blockName);
        if(dxfHatch){
            dxfHatch.properties.set(67, 1);
        }else{
            logger.error(`Can not create rectangle hatch`)
        }
    }

    return dxfPolyline;
}

function proccessPath(
    logger:ScopedLogger,
    manager: DxfFileManager,
    path: VectorPrimitivePath,
    matrix: Matrix3,
    blockName?: string
) {

    for (const pathElement of path.paths) {

        const vertices: Vector2[] = [];

        for (const element of pathElement.items) {

            if (element instanceof Vector2) {
                const vert = element.clone();
                vert.applyMatrix3(matrix);

                vertices.push(vert);
                //vertices.push(new Vector2(vert.x, -vert.y));
                
            } else if (element instanceof CubicBezier) {
                const from = element.from.clone().applyMatrix3(matrix);
                const fromDir = element.fromDir.clone().applyMatrix3(matrix);
                const toDir = element.toDir.clone().applyMatrix3(matrix);
                const to = element.to.clone().applyMatrix3(matrix);

                const cubicknots = [from, fromDir, toDir, to];

                const dxfSpline = DXFNodeFactory.addSpline(manager,{knots:cubicknots, layer:sldLayer}, blockName);

                dxfSpline.properties.set(67, 1);

            } else if (element instanceof QuadraticBezier) {
                const from = element.from.clone().applyMatrix3(matrix);
                const control = element.control.clone().applyMatrix3(matrix);
                const to = element.to.clone().applyMatrix3(matrix);

                const quadraticknots = [from, control, to];

                const dxfSpline = DXFNodeFactory.addSpline(manager,{knots:quadraticknots, layer:sldLayer}, blockName);

                dxfSpline.properties.set(67, 1);

            }
        }

        if(vertices.length>1){
            const dxfPolyline = DXFNodeFactory.createPolyLine(
                manager,
                { vertices, width: 0, closed: pathElement.closedPath, layer:sldLayer },
                blockName
            );
            
            dxfPolyline.properties.set(67, 1);
        }else if(vertices.length<2){
            logger.error(`Polyline didn't create. It has less than 2 vertices!`)
        }
    }
}

function proccessCircle(
    logger:ScopedLogger,
    manager: DxfFileManager,
    circle: VectorPrimitiveCircle,
    matrix: Matrix3,
    blockName?: string
): DXFNode | undefined {

    const center = new Vector2(circle.cx, circle.cy);

    center.applyMatrix3(matrix) 
    const elementScale = getScaleFromMatrix3(matrix)
    const radius = circle.radius*elementScale;

    const dxfCircle = DXFNodeFactory.createCircle(
        manager,
        center,
        radius,
        sldLayer,
        blockName
    );

    dxfCircle.properties.set(67, 1);
     
    if(circle.fill){    
        const dxfHatch = DXFNodeFactory.addRoundHatch(manager, radius, center, 250, sldLayer, blockName);
        dxfHatch.properties.set(67, 1);
    }

    return;
}

function proccessText(
    logger:ScopedLogger,
    manager: DxfFileManager,
    text: VectorPrimitiveText,
    matrix: Matrix3,
    parentBlockName?:string
): DXFNode | undefined {
   
    const position = new Vector2(text.x ?? 0, text.y ?? 0);

    position.applyMatrix3(matrix);
    
    const rotation = getRotationFromMatrix3(text.matrix);

    let vJustification = VerticalJustification.Bottom;
    let hJustification = HorizontalJustification.Center;

    const vAlignment = text.verticalAlignment;
    if (vAlignment) {
        switch (vAlignment) {
            case "baseline":
                vJustification = VerticalJustification.Baseline;
                break;
            case "hanging":
                vJustification = VerticalJustification.Top;
                break;
            case "middle":
                vJustification = VerticalJustification.Middle;
                break;
            case "text-after-edge":
                vJustification = VerticalJustification.Baseline;
                break;
            case "text-before-edge":
                vJustification = VerticalJustification.Baseline;
                break;
            default:
                break;
        }
    }
    const hAlignment = text.anchor;
    if (hAlignment) {
        switch (hAlignment) {
            case "start":
                hJustification = HorizontalJustification.Left;
                break;
            case "middle":
                hJustification = HorizontalJustification.Center;
                break;
            case "end":
                hJustification = HorizontalJustification.Right;
        }
    }

    const color = getDxfColor(text.color);

    const textData: dxfTextData = {
        text: text.text,
        position,
        rotation,
        height: (text.fontSize * SldDataService.scale) / 2,
        layer: sldLayer,
        verticalJustification: vJustification,
        horizontalJustification: hJustification,
        color
    };

    const dxfText = DXFNodeFactory.createText(
        manager,
        textData,
        parentBlockName
    );
    if(!dxfText){
        logger.error(`Can't create text ${text.text} with given parameters!`)
        return;
    }

    dxfText.properties.set(67, 1);

    return;
}

function getDxfColor(svgColor?: string): number | undefined {
    if (!svgColor) {
        return;
    }

    if (defaultColors.has(svgColor)) {
        return defaultColors.get(svgColor)!;
    }
    const rgba = RGBA.parseFromHexString(svgColor);
    if (rgba === 0) {
        return defaultColors.get("black");
    }

    const dxfColor = getClosestColorFromAutocadPalette(rgba).paletteIndex;
    return dxfColor;
}


function getRotationFromMatrix3(matrix?: Matrix3): number {
    if(!matrix){
        return 0;
    }
    const m11 = matrix.elements[0];
    const m21 = matrix.elements[3];

    // Calculate the rotation angle in radians
    return KrMath.radToDeg(Math.atan2(m21, m11));
}

function getScaleFromMatrix3(matrix?: Matrix3): number {
    if(!matrix){
        return 0;
    }
    const elements = matrix.elements;
    const a = elements[0]; 
    const b = elements[1]; 
    const c = elements[3]; 
    const d = elements[4]; 

    const scaleX = Math.sqrt(a * a + b * b);
    const scaleY = Math.sqrt(c * c + d * d);

    const scale = (scaleX + scaleY) / 2;

    return scale;
}
