import type { RGBAHex } from 'engine-utils-ts';
import { DefaultMap, RGBA } from 'engine-utils-ts';
import type { Aabb, Transform, Vector4 } from 'math-ts';
import { Vec3Zero, Vector2, Vector3 } from 'math-ts';

import { GeoSprite } from '../geometries/EngineGeoSprite';
import type { EngineFullGraphicsSettings } from '../GraphicsSettingsFull';
import { EngineMaterialId } from '../pools/EngineMaterialId';
import type { InObjLocalId } from '../scene/EngineSceneIds';
import { InObjFullId } from '../scene/EngineSceneIds';
import { newLodGroupLocalIdent } from '../scene/LodGroups';
import type {
	EngineSubmeshDescription, RenderJobOutput, RenderJobUpdater} from '../scene/Submeshes2';
import { LodMask
} from '../scene/Submeshes2';
import type { SubmeshesCreationOutput, SubmeshesCreationResources} from './ESSO';
import {
	ESSO_Interactive
} from './ESSO';

const EditPointGeo = new GeoSprite(new Vector2(10, 10), 5);

export type EditPointIndex = number;

export class ESSO_EditPoint<Context> extends ESSO_Interactive<{}, Context> {

	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {
		const controlPointId = resoures.geometries.spriteGeometries.allocateOrReferenceSingle(EditPointGeo)!;

		output.submeshes.push({
			id: this.id,
			lodMask: LodMask.All,
			lodGroupLocalIdent: newLodGroupLocalIdent(0, 0),
			subObjectRef: this,
			descr: {
				geoId: controlPointId,
				materialId: EngineMaterialId.Sprite,
				localTransforms: [this.localTransform],
				mainRenderJobUpdater: NoopJobUpdate,
				overlayRenderJobUpdater: EditPointJobUpdater,
			}
		});
	}

	aabb(result: Aabb): void {
		let pos = this.localTransform?.position ?? Vec3Zero;
		pos = pos.clone().applyMatrix4(this.parentWorldMatrix);
		result.setFromCenterAndSize(pos, mmVector);
	}

	localPoint(): Readonly<Vector3> {
		if (this.localTransform) {
			return this.localTransform.position;
		} else {
			return Vec3Zero;
		}
	}

	clone(newId: InObjLocalId, transform: Transform, context: Context): ESSO_EditPoint<Context> {
		const fullId = InObjFullId.new(this.id.objHandle, newId);
		return new ESSO_EditPoint(
			this.rootRef,
			fullId,
			this.repr,
			transform,
			context
		);
	}
}

const mmVector = Object.freeze(new Vector3(0.01, 0.01, 0.01));

const NoopJobUpdate: RenderJobUpdater = {
	updaterRenderJob: () => {},
}

const EditPointSelectionColor = RGBA.new(1, 0.55, 0, 1);

class EditPointSpriteJobUpdater implements RenderJobUpdater {
	updaterRenderJob(
		submeshDescription: Readonly<EngineSubmeshDescription<Object>>,
		renderSettings: Readonly<EngineFullGraphicsSettings>,
		output: RenderJobOutput
	): void {
		const highlightPower = submeshDescription.subObjectRef.selectionHighlightPower();
		if (submeshDescription.subObjectRef.isHidden && highlightPower === 0) {
			return;
		}
		output.materialId = EngineMaterialId.Sprite;

		let objColor = RGBA.new(0.1, 0.1, 0.1, 0.6);
		if (submeshDescription.subObjectRef.colorTint) {
			objColor = RGBA.lerpColorOnly(objColor, submeshDescription.subObjectRef.colorTint, 0.4);
		}
		if (highlightPower) {
			objColor = RGBA.lerpRGBAHex(objColor, EditPointSelectionColor, Math.pow(highlightPower, 0.7));
		}
		output.uniforms.push('color', rgbaVecs.getOrCreate(objColor));
	}
}

const rgbaVecs = new DefaultMap<RGBAHex, Vector4>(c => RGBA.RGBAHexToVec4(c));

const EditPointJobUpdater = new EditPointSpriteJobUpdater();
