import { Failure, IterUtils, LazyDerived, ObjectUtils } from "engine-utils-ts";
import type { Bim, PropertiesGroupFormatters } from "..";
import type { BimPropertyData } from "../bimDescriptions/BimProperty";
import { BimProperty } from "../bimDescriptions/BimProperty";
import { extractValueUnitPropsGroup, flattenNamedPropsGroups } from "../bimDescriptions/NamedBimPropertiesGroup";
import { SolverObjectInstance } from "../runtime/SolverObjectInstance";
import { InstanceCostsGlobal } from "./EquipmentCommon";
import { PropertiesGroupFormatter } from "src/bimDescriptions/PropertiesGroupFormatter";
import type { CostsConfigProvider, ExpandLegacyPropsWithCostTableLinks} from "src/cost-model/capital";
import { createFocusLinkOnSample, mergeCostComponents, sumCostComponents } from "src/cost-model/capital";
import { createDefaultACCostsPerMeter } from "src/cost-model/capital/tables/categories/electrical-subtotal/mv";
import { PUI_GroupNode } from "ui-bindings";

export const MvWireTypeIdent = 'wire';

export function registerMvWire(_bim: Bim) {}


export const MvWirePricingRelatedPropsExtra = {
    length: BimProperty.NewShared({
        path: ['computed_result', 'length'],
        value: 0,
        unit: 'ft',
    }),
};

export function registerMvWirePriceSovler(bim: Bim, costs: CostsConfigProvider) {
    const global = LazyDerived.new1(
        'global',
        [bim.unitsMapper],
        [costs.lazyInstanceCostsByType(MvWireTypeIdent)],
        ([costs]) => {
            return new InstanceCostsGlobal(costs, bim.unitsMapper);
        }
    )

    const MvWirePricingSharedArgGlobalIdent = 'mv-wire-pricing-shared-arg-global-ident';
    bim.runtimeGlobals.registerByIdent(MvWirePricingSharedArgGlobalIdent, global)

    const legacyPropsGroups = {
        MvWireSpecKeyProps,
        MvWirePricingRelatedPropsExtra
    };

    const [flattenedProps, unflatten] = flattenNamedPropsGroups(legacyPropsGroups);

    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstance({
        cache: true,
        solverIdentifier: 'mv-wire-pricing-solver',
        objectsDefaultArgs: {
            legacyProps: flattenedProps,
        },
        objectsIdentifier: MvWireTypeIdent,
        globalArgsSelector: {
            [MvWirePricingSharedArgGlobalIdent]: InstanceCostsGlobal,
        },
        solverFunction: (props, globals) => {
            const legacyPropsGroups = unflatten(props.legacyProps);
            const sharedReply = globals[MvWirePricingSharedArgGlobalIdent];
            if (sharedReply instanceof Failure) {
                return {}
            }
            const shared = sharedReply.value

            const currentIdProps = extractValueUnitPropsGroup(legacyPropsGroups.MvWireSpecKeyProps);
            const costPerMeter = sumCostComponents(mergeCostComponents(
                shared.costs.find(x => ObjectUtils.areObjectsEqual(x.props, currentIdProps))?.costs,
                createDefaultACCostsPerMeter(),
            ));

            const extraProps = legacyPropsGroups.MvWirePricingRelatedPropsExtra;
            const length = extraProps.length.as('m');

            let fullCost = length * costPerMeter;
            fullCost = isFinite(fullCost) ? fullCost : 0;

            const ref = bim.keyPropertiesGroupFormatter.formatNamedProps(MvWireTypeIdent, legacyPropsGroups.MvWireSpecKeyProps)

            const patch: BimPropertyData[] = [
                {
                    path: ['cost', 'cost_item'],
                    value: ref ?? 'unknown',
                    readonly: true,
                },
                {
                    path: ['cost', 'total_cost'],
                    unit: 'usd',
                    value: fullCost ??  0,
                    readonly: true,
                },
            ];
            return {
                legacyProps: patch,
                removeProps: [
                    BimProperty.MergedPath(['cost', 'status']),
                    BimProperty.MergedPath(['cost', 'has_zero_costs']),
                    BimProperty.MergedPath(['cost', 'length_unit']),
                    BimProperty.MergedPath(['cost', 'per_length_cost']),
                ]
            }
        }
    }));
}

export const MvWireSpecKeyProps = {
    gauge: BimProperty.NewShared({
        path: ['commercial', 'size'],
        value: 'unknown_model'
    }),
    material: BimProperty.NewShared({
        path: ['commercial', 'material'],
        value: 'unknown_material'
    }),
};

export function registerMvWireKeyPropertiesGroupFormatter(group: PropertiesGroupFormatters) {
    group.register(
        MvWireTypeIdent,
        new PropertiesGroupFormatter(
            MvWireSpecKeyProps,
            (props) => {
                return Array.from(new Set([
                    props.gauge,
                    props.material
                ].map(x => x.asText()))).join(' ');
            }
        )
    )
}

export const expandMvLegacyPropsWithCostTableLinks: ExpandLegacyPropsWithCostTableLinks = (params) => {
    const sis = Array.from(params.bim.instances.peekByIds(params.ids).values());
    const groupByModule = Array.from(IterUtils.groupBy(
        sis,
        (o) => {
            const props = o.properties.extractPropertiesGroup(MvWireSpecKeyProps)
            return [props.gauge.asText(), props.material.asText()].join('/');
        },
    ))
    if (groupByModule.length !== 1) {
        return;
    }
    createFocusLinkOnSample({
        costModelFocusApi: params.costModelFocusApi,
        sample: sis[0],
        targetPui: PUI_GroupNode.tryGetNestedChild(params.pui, ['cost']),
        label: 'Setup wire costs'
    })
}
