import type { SceneInstance } from 'bim-ts';
import { PolylineGeometry, SceneImageRepresentation } from 'bim-ts';
import { IterUtils } from 'engine-utils-ts';
import { Transform, Vec3Zero, Vector2, Vector3 } from 'math-ts';

import type { Texture } from '../3rdParty/three';
import type { UniformsFlat } from '../composer/DynamicUniforms';
import type { EngineFullGraphicsSettings } from '../GraphicsSettingsFull';
import { EngineMaterialId } from '../pools/EngineMaterialId';
import {
	InObjFullId, InObjIdType, InObjLocalId,
} from '../scene/EngineSceneIds';
import type { ESOHandle } from '../scene/ESOsCollection';
import { newLodGroupLocalIdent } from '../scene/LodGroups';
import type {
	EngineSubmeshDescription, RenderJobUpdater} from '../scene/Submeshes2';
import { LodMask, NoopJobUpdate
} from '../scene/Submeshes2';
import type { ShaderFlags } from '../shaders/ShaderFlags';
import { ESO } from './ESO';
import { ESOsHandlerBase } from './ESOsHandlerBase';
import type { SubmeshesCreationOutput, SubmeshesCreationResources} from './ESSO';
import {
	ESSO
} from './ESSO';
import { SharedSelectHighlightJobUpdater } from './ESSO_HighlightUpdaters';

export class ESO_SceneImage extends ESO {

	constructor(
		sceneInstanceRef: Readonly<SceneInstance>,
		_repr: SceneImageRepresentation,
	) {
		super(sceneInstanceRef);
	}

}

export class ESSO_SceneImageSelf extends ESSO<SceneImageRepresentation> {

	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {
		const imageId = resoures.images.mapBimIdToEngineId(this.repr.imageId);
		if (!imageId) {
			console.error(`bim-image invalid ref, engine image is not loaded`);
			return;
		}
		const texture = resoures.images.getAsEngineImage(imageId);
		if (!texture) {
			return;
		}
	
		const imageGeoId = resoures.geometries.planeGeometries.allocateOrReferenceSingle({
			centerLocalSpace: new Vector2(0.5, 0.5),
			width: this.repr.worldSize.x,
			height: this.repr.worldSize.y
		});


		
		if (!imageGeoId) {
			resoures.logger.error('could not allocate geometry for image', this);
			return;
		}
	
		texture.generateMipmaps = true;
	
		output.submeshes.push({
			id: this.id,
			lodMask: LodMask.All,
			lodGroupLocalIdent: newLodGroupLocalIdent(0, 0),
			subObjectRef: this,
			descr: {
				geoId: imageGeoId,
				localTransforms: null,
				materialId: EngineMaterialId.SceneImage as number as EngineMaterialId,
				mainRenderJobUpdater: new SceneImageRenderJobUpdater(texture),
				overlayRenderJobUpdater: NoopJobUpdate,
			}
		})

		const outlineGeoId = resoures.geometries.analytPolylines.allocateOrReferenceShared(PolylineGeometry.newWithAutoIds([
			new Vector3(-0.5, -0.5, 0),
			new Vector3(-0.5, 0.5, 0),
			new Vector3(0.5, 0.5, 0),
			new Vector3(0.5, -0.5, 0),
			new Vector3(-0.5, -0.5, 0),
		]));

		output.submeshes.push({
			id: this.id,
			lodMask: LodMask.All,
			lodGroupLocalIdent: newLodGroupLocalIdent(0, 0),
			subObjectRef: this,
			descr: {
				geoId: outlineGeoId,
				localTransforms: [new Transform(Vec3Zero, undefined, new Vector3(this.repr.worldSize.x, this.repr.worldSize.y, 1))],
				materialId: EngineMaterialId.SceneImage as number as EngineMaterialId,
				mainRenderJobUpdater: NoopJobUpdate,
				overlayRenderJobUpdater: SharedSelectHighlightJobUpdater
			}
		})
	}

}



const SelfIdZero =  InObjLocalId.new(InObjIdType.ObjSelf, 0);


export class ESO_SceneImageHandler extends ESOsHandlerBase<ESO_SceneImage> {


	tryCreateESO(instance: SceneInstance): ESO_SceneImage | undefined {
		if (instance.representation instanceof SceneImageRepresentation) {
			return new ESO_SceneImage(instance, instance.representation);
        }
		return undefined;
	}

	esosTypesToHandle(): Iterable<new (...args: any[]) => ESO_SceneImage> {
		return [ESO_SceneImage];
	}

	createSubObjectsFor(objectsToRealloc: [ESOHandle, ESO_SceneImage][]): Iterable<[ESOHandle, ESSO<any>[]]> {
		return IterUtils.filterMap(
			objectsToRealloc,
			([handle, obj]) => {
			if (obj.sceneInstanceRef.representation instanceof SceneImageRepresentation) {
				const subObj = new ESSO_SceneImageSelf(obj, InObjFullId.new(handle, SelfIdZero), obj.sceneInstanceRef.representation, null)
				return [handle, [subObj]];
			}
			return undefined;
		});
	}
}

class SceneImageRenderJobUpdater implements RenderJobUpdater {

    constructor(
        readonly texture: Texture,
    ) {
    }
    
    updaterRenderJob(
        submeshDescription: Readonly<EngineSubmeshDescription>,
        renderSettings: Readonly<EngineFullGraphicsSettings>,
        output: { flags: ShaderFlags; materialId: EngineMaterialId; uniforms: UniformsFlat; }
    ): void {
		if (submeshDescription.subObjectRef.isHidden) {
			return;
		}
        output.uniforms.push('image', this.texture);
        output.uniforms.push('opacity', new Vector2(1, 1));
        output.materialId = EngineMaterialId.SceneImage;
    }
}