import { KrMath, Matrix4, Quaternion, Vec3X, Vec3Y, Vector3 } from 'math-ts';
import type { ReactiveSolverBase, SolverInstancePatchResult } from '../runtime/ReactiveSolverBase';
import { SolverObjectInstance } from '../runtime/SolverObjectInstance';
import { AnyTrackerProps } from './AnyTracker';
import { calculateTrackerPilesConfig } from './PilesProps';
import { TrackerRepresentationCalculator } from './AnyTrackerMeshgen';
import { PropertiesGroupFormatter, type PropertiesGroupFormatters } from '../bimDescriptions/PropertiesGroupFormatter';
import { BimProperty } from '../bimDescriptions/BimProperty';
import { NumberProperty, StringProperty } from '../properties/PrimitiveProps';
import type { Bim } from '../Bim';
import { TrackerFacing } from './TrackerFeatures';
import { getPileMotorType, PileMotorType } from './PilesFeatures';
import { SmallNumericArrayProperty } from 'src/properties/SmallNumberArrayProperty';
import { IterUtils } from 'engine-utils-ts';



export function trackersPilesConfigsRuntime(): ReactiveSolverBase {
    return new SolverObjectInstance({
        solverIdentifier: 'any-tracker-piles-gen',
        objectsIdentifier: 'any-tracker',
        objectsDefaultArgs: {
            propsInOut: new AnyTrackerProps({}),
        },
        solverFunction: (inputObj): SolverInstancePatchResult => {

            const pilesConfig = calculateTrackerPilesConfig(inputObj.propsInOut);
            inputObj.propsInOut.piles.setDescriptions(pilesConfig)

            return {};
        }
    });
}


export function trackersSlopeFacingRuntime(): ReactiveSolverBase {
    const quatReused = new Quaternion();
    const vecReused = new Vector3();
    const projReused = new Vector3();
    
    return new SolverObjectInstance({
        solverIdentifier: 'any-tracker-slope-facing-gen',
        objectsIdentifier: 'any-tracker',
        objectsDefaultArgs: {
            propsInOut: new AnyTrackerProps({}),
            worldMatrix: new Matrix4(),
        },
        solverFunction: (args): SolverInstancePatchResult => {

            quatReused.setFromRotationMatrix(args.worldMatrix);
            
            vecReused.copy(Vec3Y).applyQuaternion(quatReused);
            projReused.set(vecReused.x, vecReused.y, 0);

            const slopeAngle = vecReused.angleTo(projReused);
            let angleValue: number;
            let angleUnit: string;
            if (slopeAngle <= Math.PI / 4) {
                angleValue = 100 * Math.tan(slopeAngle);
                angleUnit = '%';
            } else {
                angleValue = Math.round(KrMath.radToDeg(slopeAngle));
                angleUnit = 'deg';
            }

            args.propsInOut.position.slope_first_to_last_pile = 
                NumberProperty.new({ value: -Math.sign(vecReused.z) * angleValue, unit: angleUnit, isReadonly: true });

            let facing: TrackerFacing;
            const angleXY = projReused.angleTo(Vec3X);
            if (angleXY < 0.25 * Math.PI) {
                facing = vecReused.z >= 0 ? TrackerFacing.West : TrackerFacing.East;
            } else if (angleXY > 0.75 * Math.PI) {
                facing = vecReused.z >= 0 ? TrackerFacing.East : TrackerFacing.West;
            } else if (projReused.y >= 0) {
                facing = vecReused.z >= 0 ? TrackerFacing.South : TrackerFacing.North;
            } else {
                facing = vecReused.z >= 0 ? TrackerFacing.North : TrackerFacing.South;
            }

            args.propsInOut.position.facing_first_to_last_pile = 
                StringProperty.new({ value: facing, isReadonly: true });

            return {};
        }
    });
}


export function trackersUndulatedPropsRuntime(): ReactiveSolverBase {
    return new SolverObjectInstance({
        solverIdentifier: 'any-tracker-undulated-props-gen',
        objectsIdentifier: 'any-tracker',
        objectsDefaultArgs: {
            propsInOut: new AnyTrackerProps({}),
        },
        solverFunction: (args): SolverInstancePatchResult => {
            if (!args.propsInOut.piles._segments_slopes) {
                args.propsInOut.position.max_slope_per_bay = NumberProperty.new(
                    { value: Math.abs(args.propsInOut.position.slope_first_to_last_pile.as('%')), unit: '%', isReadonly: true }
                );
                
                return {};
            }

            const segmentsSlopes = args.propsInOut.piles._segments_slopes.values;

            const slopeFirstToLast = args.propsInOut.position.slope_first_to_last_pile.as('rad');
            const slopeDirection = Math.sign(slopeFirstToLast);
            let maxBaySlopeOriented = 0;
            for (let i = 0; i < segmentsSlopes.length; ++i) {
                if (slopeDirection * segmentsSlopes[i] > maxBaySlopeOriented) {
                    maxBaySlopeOriented = slopeDirection * segmentsSlopes[i];
                }

            }
            args.propsInOut.position.max_slope_per_bay = NumberProperty.new(
                { value: 100 * Math.tan(maxBaySlopeOriented + slopeDirection * slopeFirstToLast), unit: '%', isReadonly: true }
            );

            const cumulativeSlopes: number[] = [];
            let maxBayToBaySlopeChange = 0;
            let cumulativeSlope = 0;
            for (let i = 1; i < segmentsSlopes.length; ++i) {
                const bayToBaySlopeChange = Math.abs(segmentsSlopes[i] - segmentsSlopes[i - 1]);
                if (bayToBaySlopeChange > maxBayToBaySlopeChange) {
                    maxBayToBaySlopeChange = bayToBaySlopeChange;
                }

                if (getPileMotorType(args.propsInOut.piles.active_configuration!.features[i]) === PileMotorType.Motor) {
                    cumulativeSlope += 0.5 * bayToBaySlopeChange;
                    cumulativeSlopes.push(cumulativeSlope);
                    cumulativeSlope = 0.5 * bayToBaySlopeChange;
                } else {
                    cumulativeSlope += bayToBaySlopeChange;
                }
            }
            cumulativeSlopes.push(cumulativeSlope);

            args.propsInOut.position.max_bay_to_bay_slope_change = NumberProperty.new(
                { value: 100 * Math.tan(maxBayToBaySlopeChange), unit: '%', isReadonly: true }
            );
            args.propsInOut.position.cumulative_slopes = new SmallNumericArrayProperty(
                { values: cumulativeSlopes.map(s => 100 * Math.tan(s)), unit: '%' }
            );
            args.propsInOut.position.cumulative_slope_first_to_last = NumberProperty.new(
                { value: 100 * Math.tan(IterUtils.sum(cumulativeSlopes, s => s)), unit: '%', isReadonly: true }
            );

            return {};
        }
    });
}


export function trackersMeshgenRuntime(bim: Bim): ReactiveSolverBase {

    const reprsCalculator = new TrackerRepresentationCalculator(bim, 100);

    return new SolverObjectInstance({
        solverIdentifier: 'any-tracker-mesh-gen',
        objectsIdentifier: 'any-tracker',
        objectsDefaultArgs: {
            propsInOut: new AnyTrackerProps({}),
            worldMatrix: new Matrix4(),
        },
        solverFunction: (inputObj): SolverInstancePatchResult => {

            const repr = reprsCalculator.calculate(inputObj);

            // set frame module_count_x
            {
                const stringCount = inputObj.propsInOut.tracker_frame.dimensions.strings_count.value;
                const moduleCountXPerString = inputObj.propsInOut.tracker_frame.string.modules_count_x.value;
                const frameModuleCountX = stringCount * moduleCountXPerString;
                inputObj.propsInOut.tracker_frame.modules.modules_count_x =
                    inputObj.propsInOut.tracker_frame.modules.modules_count_x.withDifferentValue(frameModuleCountX);
            }

            return {
                repr: repr.repr,
            }
        }
    });
}

export function trackerCircuitPropsRuntime(): ReactiveSolverBase {

    return new SolverObjectInstance({
        solverIdentifier: "any-tracker-circuit-props",
        objectsIdentifier: "any-tracker",
        objectsDefaultArgs: {
            propsInOut: new AnyTrackerProps({}),
        },
        solverFunction: (inputObj) => {

            const props = inputObj.propsInOut;
            const moduleProps = props.module;
            const stringProps = props.tracker_frame.string;

            const string_count = props.tracker_frame.dimensions.strings_count.value;
            const module_current = moduleProps.current.as("A");
            const module_voltage = moduleProps.voltage.as("V");
            const module_max_current = moduleProps.short_circuit_current.as("A");
            const module_max_voltage = moduleProps.max_system_voltage.as("V");
            const module_maximum_power = moduleProps.maximum_power.as("W");
            const string_modules_count_x = stringProps.modules_count_x.value;
            const string_modules_count_y = stringProps.modules_count_y.value;
            const string_modules_count = string_modules_count_x * string_modules_count_y;

            const stringVoltage = module_voltage * string_modules_count;
            const stringPower = module_maximum_power * string_modules_count;

            const tracker_modules_count = string_modules_count * string_count;
            const trackerDcPower = string_count * stringPower * 1e-3;

            const circuit_max_voltage = module_max_voltage;
            const circuit_operating_voltage = stringVoltage;

            const circuit_max_current = module_max_current * string_count;
            const circuit_operating_current = module_current * string_count;

            stringProps.current = NumberProperty.new({value: module_current, unit: "A", isReadonly: true });
            stringProps.voltage = NumberProperty.new({value: stringVoltage, unit: "V", isReadonly: true });
            stringProps.power = NumberProperty.new({value: stringPower, unit: "W", isReadonly: true });
            stringProps.max_current = NumberProperty.new({value: module_max_current, unit: "A", isReadonly: true});

            return {
                legacyProps: [
                    // { path: ["tracker-frame", "string", "current"], value: module_current, unit: "A" },
                    // { path: ["tracker-frame", "string", "voltage"], value: stringVoltage, unit: "V" },
                    // { path: ["tracker-frame", "string", "power"], value: stringPower, unit: "W" },
                    // { path: ["tracker-frame", "string", "max_current"], value: module_max_current, unit: "A" },
                    // { path: ["tracker-frame", "string", "modules_count"], value: string_modules_count, numeric_step:1 },

                    { path: ["circuit", "equipment", "modules_count"], value: tracker_modules_count },
                    { path: ["circuit", "aggregated_capacity", "dc_power"], value: trackerDcPower, unit: "kW" },
                    { path: ["circuit", "equipment", "strings_count"], value: string_count },
                    { path: ["circuit", "aggregated_capacity", "max_current"], value: circuit_max_current, unit: "A" },
                    { path: ["circuit", "aggregated_capacity", "operating_current"], value: circuit_operating_current, unit: "A" },
                    { path: ["circuit", "aggregated_capacity", "max_voltage"], value: circuit_max_voltage, unit: "V" },
                    { path: ["circuit", "aggregated_capacity", "operating_voltage"], value: circuit_operating_voltage, unit: "V" },
                ],
            }
               
        },
    });
}


export const TrackerKeyProps = {
    model: BimProperty.NewShared({
        path: ['tracker_frame', 'commercial', 'model'],
        value: 'unknown_model',
    }),
    moduleModel: BimProperty.NewShared({
        path: ['module', 'model'],
        value: 'unknown_model',
    }),
    stringModulesCount: BimProperty.NewShared({
        path: ['tracker_frame', 'string', 'modules_count'],
        value: 0,
    }),
    modulesCount: BimProperty.NewShared({
        path: ['circuit', 'equipment', 'modules_count'],
        value: 0,
    }),
    length: BimProperty.NewShared({
        path: ['tracker_frame', 'dimensions', 'length'],
        value: 0,
        unit: 'm',
    }),
    max_width: BimProperty.NewShared({
        path: ['tracker_frame', 'dimensions', 'max_width'],
        value: 0,
        unit: 'm',
    }),
    pilesCount: BimProperty.NewShared({
        path: ['tracker_frame', 'piles', 'count'],
        value: 0,
    })
};

export const AnyTrackerKeyProps = TrackerKeyProps;

export function registerAnyTrackerKeyPropsGroupFormatter(group: PropertiesGroupFormatters) {
    group.register(
        `any-tracker`,
        new PropertiesGroupFormatter(
            TrackerKeyProps,
            (props, unitsMapper) => {
                return [
                    [
                        props.model,
                        props.moduleModel,
                    ].map(x => x.asText()),
                    [
                        props.stringModulesCount,
                        props.modulesCount
                    ].map(x => x.valueUnitUiString(unitsMapper)).join('/') + 'MOD',
                ].flat().join(' ');
            }
        )
    )
}

export function registerAnyTracker(bim: Bim) {
    
    bim.instances.archetypes.registerArchetype({
        type_identifier: 'any-tracker',
        propsClass: AnyTrackerProps,
        mandatoryProps: [],
    });

    bim.reactiveRuntimes.registerRuntimeSolver(trackersPilesConfigsRuntime());
    bim.reactiveRuntimes.registerRuntimeSolver(trackersSlopeFacingRuntime());
    bim.reactiveRuntimes.registerRuntimeSolver(trackersUndulatedPropsRuntime());
    bim.reactiveRuntimes.registerRuntimeSolver(trackersMeshgenRuntime(bim));
    bim.reactiveRuntimes.registerRuntimeSolver(trackerCircuitPropsRuntime());

}
