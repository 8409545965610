import { FixedTiltTypeIdent, TrackerTypeIdent } from "bim-ts";
import { RGBA, RGBAHex } from "engine-utils-ts";

export function convertPxToMm(v: number) {
    return v * 420 / 1190
}

export const SolarArraysTypes = [FixedTiltTypeIdent, TrackerTypeIdent, "any-tracker"];

export const SubstationColor = RGBA.new(0.8, 0, 0, 1);
export const RoadColor = RGBA.new(0.08, 0.035, 0.24, 1);
export const EquipmentDefaultColor = RGBA.new(0.08, 0.035, 0.24, 1);
export const IncludeBoundaryColor = RGBA.new(0, 0.1, 0.9, 1);
export const ExcludeBoundaryBorderColor = RGBA.new(0.5, 0, 0, 1);
export const ExcludeBoundaryColor = RGBA.new(0.9, 0.8, 0.8, 1);

export class PalettePerType {
    private _counter = 0;
    private readonly _perType: Map<string, RGBAHex> = new Map();

    constructor(readonly colors: RGBAHex[]){

    }

    getNext(type: string){
        if(this._perType.has(type)){
            return this._perType.get(type)!;
        } else {
            const color = this.colors[this._counter++ % this.colors.length];
            this._perType.set(type, color);
            return color;
        }
    }

    getUsedColors() {
        return Array.from(this._perType.values());
    }
}