import type { DC_CNSTS, IdBimScene, MathSolversApi, MaximizeRowToRowArgs, MaximizeRowToRowResult, PolygonFitterArgs, PolygonFitterResult, SceneInstance, SolverWithNSRoadsArgs, SolverWithNSRoadsResult, TrackerInstallerArgs, TrackerInstallerResult } from "bim-ts";
import { Aabb2, Vector2 } from 'math-ts';
import { orderContours, orderHoles } from '../LayoutUtils';
import { MaximizeRowToRowExecutor, MaximizeRowToRowRegularExecutor, PolygonFillerExecutor, RegularFillerExecutor, URLS, trimTrackerIrregular, trimTrackerRegular} from "bim-ts";
import { IterUtils, WorkerPool } from "engine-utils-ts";
import { CIHorTrunkbusSolver, CIHorTrunkbusTrenchSolver, CIMultVertTrunkbusSolver, CIMultiharnessSolver, ElectricalPatternSolvers, SIMultiharnessSolver, convertTracker, filterRoads } from "./ElectricalPatternSolvers";
import type { BlockExecuterNewAlgorithmRequest, BlockExecuterNewAlgorithmResponse, BlockWithLabel, Point, RoadLine, RoadSideType, Row, Site } from "./LayoutSolversTypes";

export type PatternName = DC_CNSTS.PatternName;

export interface Contour {
    id: IdBimScene;
    contour: Vector2[];
    rotated: Vector2[];
    include: boolean;
    equipmentBoundary: boolean;
}

export enum EquipmentRoadsOption {
    Generate,
    UseExisting,
}
export enum SupportRoadsOption {
    Ignore,
    Generate,
    UseExisting,
}

export interface LayoutInput {
    contours: Contour[];
    selectedAreaIndex: number;
    
    pattern: PatternName | "";
    rowToRowSpace: number;
    heightPreference: number;
    trackers: TrackerData[];
    blockCases: BlockData[];
    combinerBox: CombinerBoxData;
    max_length: number;
    equipmentGlassToGlass: number;
    supportGlassToGlass: number;
    equipmentRoadWidth: number;
    supportRoadWidth: number;
    necMultiplier: number;
    crossRoad: boolean;
    shiftTan?: number;
    angleDeg: number;
    shiftDeg: number | undefined;
    basePos: Vector2;
    road_side: RoadSideType,
    outerOffset: number;
    inverter_offset: number;
    transformer_offset: number;
    equipmentOffset: number;
    block_offset:number;
    targetValue?: number;
    equipmentRoadsOption: EquipmentRoadsOption;
    supportRoadsOption: SupportRoadsOption;
    globalSupportRoads: boolean;
    roadStep: number;
    roads: { points: Vector2[]; edges:{id:IdBimScene, fst:number, snd:number, width: number}[]},
    find_max_power: boolean;
    invertersIgnore: boolean;
    find_max_r2r: boolean;
    alignArrays: boolean;
}

export interface BlockData {
    label: number,
    transformer: TransformerData;
    inverter: InverterData;

    ilr_min: number,
    ilr_max: number,
    number_of_blocks: number | undefined,
    number_of_inverters: number | undefined,
}


export interface TrackerData {
    src: SceneInstance;
    isFixed:boolean;
    length: number;
    dc_power: number;
    max_current: number;
    width: number;
    string_max_current: number;
    string_power: number;
}

export interface TransformerData {
    src: SceneInstance;
    power: number;
    length: number;
    width: number;
}

export interface InverterData {
    src: SceneInstance;
    max_power: number;
    max_current_output: number;
    max_current_input: number;
    dc_inputs_number: number;
    length: number;
    width: number;
}

interface CombinerBoxData {
    src: SceneInstance;
    current: number;
}

export interface RawLayout {
    roads: RoadDef[];
    trackers: (ObjectPosition & { r: number, s: number })[];
    combinerBoxes: ObjectPosition[];
    stringInverters: ObjectPosition[];
    centralInverters: ObjectPosition[];
    transformers: ObjectPosition[];
}

export interface FarmLayout {
    layout: RawLayout;
    max_r2r_result: number | undefined;
}

export interface EquipmentPosition {
    id: IdBimScene;
    position: Vector2;
    parentId?: IdBimScene;
}

export interface ObjectPosition {
    id: number;
    type: string,
    src: SceneInstance;
    position: Vector2;
    size?: Vector2;
    parentId?: number;
    rotateDeg: number;
    label?: number;
}

export interface RoadDef {
    polyline: [Vector2, Vector2];
    width: number;
}


export async function getLayout(input: LayoutInput, mathSolverApi: MathSolversApi): Promise<FarmLayout> {
    const samples = input.trackers.map(t => ({ height: t.length + input.equipmentOffset, value: t.dc_power }));
    const maxTrackerHeight = Math.max(...samples.map(s => s.height));
    const trackersSamples: { h: number, v: number, o: number }[] = [];
    if (input.alignArrays) {
        for (const sample of samples) {
            trackersSamples.push({ h: sample.height, o: 0, v: sample.value });
            if (sample.height < maxTrackerHeight - 0.01) {
                trackersSamples.push({ h: sample.height, o: maxTrackerHeight - sample.height, v: sample.value });
            }
        }
    } else {
        for (const sample of samples) {
            trackersSamples.push({ h: sample.height, v: sample.value, o: 0 });
        }
    }

    const trackersLayout = await getTrackersLayout(input, mathSolverApi, trackersSamples);
    let layout: RawLayout = {
        roads: [],
        centralInverters: [],
        combinerBoxes: [],
        stringInverters: [],
        trackers: [],
        transformers: [],
    };

    if (trackersLayout.sites && (input.invertersIgnore || input.equipmentRoadsOption === EquipmentRoadsOption.UseExisting)) {
        let trackersPerBlock: Row[][] = trackersLayout.sites.map(t => t.trackers);
        let equpment_roads = trackersLayout.equipment_roads.slice();
        let support_roads = trackersLayout.support_roads.slice();
        if(!input.find_max_r2r && !input.find_max_power){
            const blockExecuterResponse = await blockLayout([{
                v_min: 0,
                v_max: input.targetValue!,
            }], mathSolverApi, input.find_max_power, input.targetValue, trackersLayout.sites, input.rowToRowSpace);
            const roads = filterRoads(blockExecuterResponse, trackersLayout);
            equpment_roads = roads.equipment_roads;
            support_roads = roads.support_roads;
            trackersPerBlock = blockExecuterResponse.blocks.map(t => t.trackers);
        }
        let counter = 0;
        for (const block of trackersPerBlock) {
            for (const tracker of block) {
                layout.trackers.push(
                    convertTracker(++counter, input.trackers, tracker)
                );
            }
        }
        for (const road of equpment_roads) {
            layout.roads.push({ 
                width: input.equipmentRoadWidth, 
                polyline: [new Vector2(road.lhs.x, road.lhs.y), new Vector2(road.rhs.x, road.rhs.y)] 
            });
        }
        for (const road of support_roads) {
            layout.roads.push({ 
                width: input.supportRoadWidth, 
                polyline: [new Vector2(road.lhs.x, road.lhs.y), new Vector2(road.rhs.x, road.rhs.y)] 
            });
        }
    } else if (trackersLayout.sites) {
        const maxTrimPowers = calcMaxTrimPowers(input, samples, trackersLayout.max_r2r_result);

        const electricalPatternSolvers = new ElectricalPatternSolvers(
            mathSolverApi, [
            ['CI_HorTrunkbus', new CIHorTrunkbusSolver()],
            ['CI_HorTrunkbus_Trench', new CIHorTrunkbusTrenchSolver()],
            ['CI_MultVertTrunkbus', new CIMultVertTrunkbusSolver()],
            //['CI_VertTrunkbus', new CIVertTrunkbusSolver()],
            ['CI_Multiharness', new CIMultiharnessSolver()],
            ['SI_Multiharness', new SIMultiharnessSolver()],
        ]);
        layout = await electricalPatternSolvers.solve({
            input, trackersLayout, pattern: input.pattern, maxTrimPowers
        });

        let contours: Vector2[][] = [];
        
        IterUtils.extendArray(contours, layout.centralInverters.map(i => [
            new Vector2(i.position.x - 0.5 * i.size!.x, i.position.y - 0.5 * i.size!.y),
            new Vector2(i.position.x - 0.5 * i.size!.x, i.position.y + 0.5 * i.size!.y),
            new Vector2(i.position.x + 0.5 * i.size!.x, i.position.y + 0.5 * i.size!.y),
            new Vector2(i.position.x + 0.5 * i.size!.x, i.position.y - 0.5 * i.size!.y),
        ]));
        
        IterUtils.extendArray(contours, layout.transformers.map(t => [
            new Vector2(t.position.x - 0.5 * t.size!.x, t.position.y - 0.5 * t.size!.y),
            new Vector2(t.position.x - 0.5 * t.size!.x, t.position.y + 0.5 * t.size!.y),
            new Vector2(t.position.x + 0.5 * t.size!.x, t.position.y + 0.5 * t.size!.y),
            new Vector2(t.position.x + 0.5 * t.size!.x, t.position.y - 0.5 * t.size!.y),
        ]));

        if (contours.length !== 0) {
            const bboxes = contours.map(c => Aabb2.empty().setFromPoints(c));

            for (let i = 0; i < layout.trackers.length; ++i) {
                const tracker = layout.trackers[i];
                const trackerAabb = Aabb2.empty().setFromCenterAndSize(
                    tracker.position, 
                    new Vector2(tracker.size!.x, tracker.size!.y)
                );

                for (let j = 0; j < contours.length; ++j) {
                    if (!bboxes[j].intersectsBox2(trackerAabb)) {
                        continue;
                    }

                    const trackerToTrim = { 
                        x: tracker.position.x, 
                        y: tracker.position.y - trackersSamples[tracker.s].o - 0.5 * trackersSamples[tracker.s].h, 
                        s: tracker.s, 
                        r: tracker.r 
                    };
                    let trackerNew: { x: number; y: number; s: number; r: number; } | undefined;
                    if (input.alignArrays) {
                        trackerNew = trimTrackerRegular(
                            contours[j], 
                            trackerToTrim, 
                            tracker.size!.x, 
                            trackersSamples
                        );
                    } else {
                        trackerNew = trimTrackerIrregular(
                            contours[j], 
                            trackerToTrim, 
                            tracker.size!.x, 
                            trackersSamples
                        );
                    }
                    if (trackerNew) {
                        if (trackerNew.s !== tracker.s) {
                            const sample = trackersSamples[trackerNew.s];
                            const row = {
                                x: trackerNew.x - 0.5 * input.rowToRowSpace,
                                y: trackerNew.y + sample.o,
                                w: input.rowToRowSpace,
                                h: sample.h,
                                v: sample.v,
                                r: tracker.r,
                                s: tracker.s
                            };
                            layout.trackers[i] = convertTracker(tracker.id, input.trackers, row, tracker.parentId);
                        }
                    } else {
                        layout.trackers.splice(i, 1);
                        --i;
                        break;
                    }
                }
            }
        }
    }

    return {
        layout,
        max_r2r_result: trackersLayout.max_r2r_result,
    };
}
export interface BlocksResponse {
    blocks: BlockWithLabel[],
    emptyBlockIndexes: number[],
}

function calcMaxTrimPowers(
    input: LayoutInput, 
    samples: { height: number, value: number}[], 
    max_r2r_result: number | undefined
): number[] {
    const maxTrimPowers = new Array(input.blockCases.length).fill(0);

    const minSampleValue = IterUtils.minBy(samples, s => s.value)!.value;
    const r2r = max_r2r_result ? max_r2r_result : input.rowToRowSpace;

    for (let i = 0; i < input.blockCases.length; ++i) {
        const block = input.blockCases[i];
        if (0.5 * input.equipmentGlassToGlass < input.inverter_offset + 0.5 * block.inverter.length) {
            maxTrimPowers[i] += minSampleValue;
        }
        if (0.5 * input.equipmentGlassToGlass < input.transformer_offset + 0.5 * block.transformer.length) {
            maxTrimPowers[i] += minSampleValue;
        }
    }

    return maxTrimPowers;
}

export async function getBlocks(
    input: LayoutInput, 
    mathSolverApi: MathSolversApi, 
    sites: Site[], 
    tracker_width: number,
    maxTrimPowers: number[]
): Promise<BlocksResponse>{
    const samples: { v_min: number; v_max: number; n?: number; }[] = [];

    for (let i = 0; i < input.blockCases.length; ++i) {
        const block = input.blockCases[i];
        const { v_min, v_max } = getBlockPower(input, block);
        samples.push({
            v_min: Math.min(v_min + maxTrimPowers[i], v_max),
            v_max,
            n: block.number_of_blocks
        });
    }
    const response: BlocksResponse = await blockLayout(samples, mathSolverApi, input.find_max_power, input.targetValue, sites, tracker_width);
    return response;
}

async function blockLayout(
    samples: { v_min: number; v_max: number; n?: number; }[], 
    mathSolverApi: MathSolversApi,
    findMax: boolean, targetValue: number|undefined, 
    sites: Site[], 
    tracker_width: number,
) {

    let maxDc: number = 0;
    for (const site of sites) {
        for (const t of site.trackers) {
            maxDc += t.v;
        }
    }

    const blockExecuterResponse = await mathSolverApi.callSolver<
        BlockExecuterNewAlgorithmRequest,
        BlockExecuterNewAlgorithmResponse
    >({
        solverName: "blocks-extractor-new",
        solverType: "single",
        request: {
            patches: sites.map((s) => ({
                trackers: s.trackers,
                lines: s.lines.map<[Point, Point]>((l) => [{ x: l.lhs.x, y: l.lhs.y }, { x: l.rhs.x, y: l.rhs.y }]),
            })),
            samples,
            target_value: findMax ? maxDc : targetValue,
        },
    });

    const response: BlocksResponse = {
        blocks: [],
        emptyBlockIndexes: []
    };

    for (let idx = 0; idx < blockExecuterResponse.blocks.length; idx++) {
        const block = blockExecuterResponse.blocks[idx];
        if (block.length === 0 && response.emptyBlockIndexes) {
            response.emptyBlockIndexes.push(idx);
        }
        for (const patch of block) {
            const trackers: Row[] = [];
            for (const tracker of patch.trackers) {
                trackers.push({
                    ...tracker,
                    w: tracker_width,
                });
            }
            response.blocks.push({
                label: patch.sample,
                lines: patch.lines.map(l => ({ lhs: new Vector2(l[0].x, l[0].y), rhs: new Vector2(l[1].x, l[1].y) })),
                trackers
            });
        }
    }
    return response;
}

function getBlockPower(input: LayoutInput, block: BlockData){
    let v_min = 0;
    let v_max = 0;
    if(input.pattern === 'SI_Multiharness' && block.number_of_inverters){
        v_min = block.inverter.max_power * block.ilr_min * block.number_of_inverters;
        v_max = block.inverter.max_power * block.ilr_max * block.number_of_inverters;
    } else if(input.pattern === 'SI_Multiharness' && !block.number_of_inverters){
        v_min = block.transformer.power * block.ilr_min;
        v_max = block.transformer.power * block.ilr_max;
    } else {
        v_min = block.inverter.max_power * block.ilr_min;
        v_max = block.inverter.max_power * block.ilr_max;
    }

    return {v_min, v_max};
}

export type RoadLineData = RoadLine & { patchIndex: number};
export interface TrackersLayout {
    sites: Site[];
    equipment_roads: RoadLineData[];
    support_roads: RoadLineData[];
    max_r2r_result: number | undefined;
}

async function getTrackersLayout(
    input: LayoutInput,
    mathSolversApi: MathSolversApi,
    samples: { h: number, v: number, o: number }[],
): Promise<TrackersLayout> {
    const block_offset = input.block_offset - input.equipmentOffset > 0
        ? input.block_offset - input.equipmentOffset
        : 0;
    const max_tracker_width = IterUtils.max(input.trackers.map(t => t.width))!;

    const contours = convertContours(input.contours);

    const sites: Site[] = [];
    const equipment_roads: RoadLineData[] = [];
    const support_roads: RoadLineData[] = [];
    let max_r2r_result : number | undefined = undefined;

    if (input.equipmentRoadsOption === EquipmentRoadsOption.Generate) {
        const extendsRoads = 200;
        const {min_block_value, max_block_value} = getMinMaxBlockPower(input);

        let result: MaximizeRowToRowResult | PolygonFitterResult | SolverWithNSRoadsResult;
        if(input.invertersIgnore && !input.find_max_power && input.find_max_r2r){
            const args: MaximizeRowToRowArgs = {
                contours,
                samples,
                max_tracker_width: max_tracker_width,
                min_row_to_row: input.rowToRowSpace,
                column_height: input.heightPreference,
                equipment_road_offset: input.equipmentGlassToGlass / 2,
                gap: block_offset,
                ext: extendsRoads,
                min_total_value: input.targetValue!,
                angle: input.shiftTan,
                min_block_value,
                max_block_value,
                support_roads: input.supportRoadsOption === SupportRoadsOption.Generate,
                support_road_offset: input.supportGlassToGlass / 2,
                global_support_roads: input.globalSupportRoads,
                roads: input.roads,
                road_step: input.roadStep,
            };
            if (!URLS.isProduction()) {
                console.log("maximize row to row request", JSON.stringify(args));
            }
            if (input.alignArrays) {
                result = await WorkerPool.execute(MaximizeRowToRowRegularExecutor, args);
            } else {
                result = await WorkerPool.execute(MaximizeRowToRowExecutor, args);
            }
            IterUtils.extendArray(equipment_roads, result.layouts.flatMap(
                (layout, idx) => layout.lines.map(line => ({ lhs: line[0], rhs: line[1], patchIndex: idx }))
            ));
            max_r2r_result = (result as MaximizeRowToRowResult).row_to_row;
        } else if (input.invertersIgnore || !input.shiftTan || Math.abs(input.shiftTan) < 100) {
            const args: PolygonFitterArgs = {
                contours,
                samples,
                max_tracker_width: max_tracker_width,
                row_to_row: input.rowToRowSpace,
                column_height: input.heightPreference,
                support_roads: input.supportRoadsOption === SupportRoadsOption.Generate,
                global_support_roads: input.globalSupportRoads,
                roads: input.roads,
                equipment_road_offset: input.equipmentGlassToGlass / 2,
                support_road_offset: input.supportGlassToGlass / 2,
                support_roads_step: input.roadStep,
                gap: block_offset, 
                ext: extendsRoads,
                min_block_value,
                max_block_value,
                angle: input.shiftTan,
            }
            if (!URLS.isProduction()) {
                console.log("polygon fitter request", JSON.stringify(args));
            }
            if (input.alignArrays) {
                result = await WorkerPool.execute(RegularFillerExecutor, args);
            } else {
                result = await WorkerPool.execute(PolygonFillerExecutor, args);
            }
            IterUtils.extendArray(equipment_roads, result.layouts.flatMap(
                (layout, idx) => layout.lines.map(line => ({ lhs: line[0], rhs: line[1], patchIndex: idx }))
            ));
        } else {
            const horLineWidth = input.supportRoadsOption === SupportRoadsOption.Generate ?
                input.supportGlassToGlass : input.equipmentOffset;
            samples = samples.filter(s => s.o === 0);
            const args: SolverWithNSRoadsArgs = {
                contours: contours,
                tracker_heights: samples.map(s => s.h),
                tracker_values: samples.map(s => s.v),
                tracker_width: max_tracker_width,
                ver_gap: input.equipmentOffset,
                hor_gap: input.rowToRowSpace - max_tracker_width,
                num_rows: input.heightPreference,
                num_cols: Math.floor((input.roadStep - input.equipmentGlassToGlass) / input.rowToRowSpace),
                block_offset: input.block_offset,
                ver_line_width: input.equipmentGlassToGlass,
                hor_line_width: horLineWidth,
                block_values: [[min_block_value, max_block_value]],
                aligned: input.alignArrays,
            }

            const url = "simple_solver_with_ns_roads";
            const solverResult = await mathSolversApi
                .callSolver<SolverWithNSRoadsArgs, SolverWithNSRoadsResult>({
                    solverName: url,
                    solverType: "single",
                    request: args,
                });
            result = {
                layouts: solverResult.blocks.map(b => ({ 
                    trackers: b.map(tr => ({ x: tr.x, y: tr.y, s: tr.i, r: tr.r })), 
                    lines: solverResult.ver_lines 
                })),
                vRoads: input.supportRoadsOption === SupportRoadsOption.Generate ? solverResult.hor_lines : [],
            };
            for (const line of solverResult.ver_lines) {
                equipment_roads.push({ lhs: line[0], rhs: line[1], patchIndex: 0 });
            }
        }

        for (let idx = 0; idx < result.layouts.length; idx++) {
            const layout = result.layouts[idx];
            const lines: RoadLine[] = [];
            for (const [lhs, rhs] of layout.lines) {
                lines.push({ lhs, rhs });
            }
            const trackers: Row[] = [];
            for (const tracker of layout.trackers) {
                const sample = samples[tracker.s];
                const pos = new Vector2(tracker.x, tracker.y);
                trackers.push({
                    x: pos.x - 0.5 * max_tracker_width,
                    y: pos.y + sample.o,
                    w: max_tracker_width,
                    h: sample.h,
                    v: sample.v,
                    r: tracker.r,
                    s: tracker.s
                });
            }
            sites.push({
                trackers,
                lines,
            });
        }
        for (const vr of result.vRoads) {
            support_roads.push({ lhs: vr[0], rhs: vr[1], patchIndex: -1 });
        }
    } else {
        const road_segments: [Point, Point][] = [];
        for (const edge of input.roads.edges) {
            road_segments.push([
                input.roads.points[edge.fst],
                input.roads.points[edge.snd]
            ]);
        }
        const args: TrackerInstallerArgs = {
            contours,
            tracker_values: samples.map(s => s.v),
            tracker_heights: samples.map(s => s.h),
            tracker_width: max_tracker_width,
            row_to_row: input.rowToRowSpace,
            road_segments: road_segments,
            road_width: input.equipmentGlassToGlass,
        };
        
        const url = "simple_tracker_installer";
        const result = await mathSolversApi
            .callSolver<TrackerInstallerArgs, TrackerInstallerResult>({
                solverName: url,
                solverType: "single",
                request: args,
            });

        const trackers: Row[] = [];
        for (const tracker of result.trackers) {
            const sample = samples[tracker.i];
            const pos = new Vector2(tracker.x, tracker.y);
            trackers.push({
                x: pos.x - 0.5 * max_tracker_width,
                y: pos.y + sample.o,
                w: max_tracker_width,
                h: sample.h,
                v: sample.v,
                r: NaN,
                s: tracker.i
            });
        }
        sites.push({
            trackers,
            lines: []
        });
    }

    return { sites, equipment_roads, support_roads, max_r2r_result };
}

function convertContours(contours: Contour[]){
    const orderedContours: Point[][] = [];
    for (const contour of contours) {
        const ordered = contour.include 
            ? orderContours([contour.rotated])
            : orderHoles([contour.rotated]);
        orderedContours.push(ordered[0]);
    }
    return orderedContours;
}

function getMinMaxBlockPower(input: LayoutInput) {
    let min_block_value = Number.MAX_VALUE;
    let max_block_value = Number.MIN_VALUE;
    for (const block of input.blockCases) {
        const {v_min, v_max} = getBlockPower(input, block);
        min_block_value = Math.min(v_min, min_block_value);
        max_block_value = Math.max(v_max, max_block_value);
    }
    min_block_value = input.invertersIgnore ? 1 : min_block_value;
    max_block_value = input.invertersIgnore ? 1 : max_block_value;
    return {min_block_value, max_block_value};
}


