import type { Bim } from "../Bim";
import type { SceneInstanceShapeMigration } from "../scene/SceneInstancesArhetypes";
import type { BimPropertyData } from "../bimDescriptions/BimProperty";
import { BimProperty } from "../bimDescriptions/BimProperty";
import {Failure, IterUtils, LazyDerived, ObjectUtils } from "engine-utils-ts";
import { SolverObjectInstance } from "../runtime/SolverObjectInstance";
import { extractValueUnitPropsGroup, flattenNamedPropsGroups } from "../bimDescriptions/NamedBimPropertiesGroup";
import type { PropertiesGroupFormatters } from "../bimDescriptions/PropertiesGroupFormatter";
import { PropertiesGroupFormatter } from "../bimDescriptions/PropertiesGroupFormatter";
import type { CostsConfigProvider, ExpandLegacyPropsWithCostTableLinks} from "src/cost-model/capital";
import { createFocusLinkOnSample, mergeCostComponents, sumCostComponents } from "src/cost-model/capital";
import { DcWireIdProps, createDefaultDCCostsPerMeter } from "src/cost-model/capital/tables/categories/electrical-subtotal/lv";
import { InstanceCostsGlobal } from "./EquipmentCommon";
import { PUI_GroupNode } from "ui-bindings";


export const LvWireTypeIdent = 'lv-wire';

export function registerLvWire(bim: Bim){
	bim.instances.archetypes.registerArchetype(
		{
			type_identifier: LvWireTypeIdent,
			mandatoryProps: [
                { path: ['cost_bs', 'level 1'], value: "ELECTRICAL SUBTOTAL", },
                { path: ['cost_bs', 'level 2'], value: "DC", },
			],
			propsShapeMigrations: migrations(),
		}
	);
}

function migrations() : SceneInstanceShapeMigration[]{
	return [
		{
			toVersion: 1,
			validation: {
				updatedProps: [
					{path: ['cost_bs', 'level 1']},
					{path: ['cost_bs', 'level 2']},
				],
				deletedProps: [],
			},
			patch: (inst)=>{
				inst.properties.applyPatch([
					['cost_bs | level 1', { path: ['cost_bs', 'level 1'], value: "ELECTRICAL SUBTOTAL", }],
					['cost_bs | level 2', { path: ['cost_bs', 'level 2'], value: "DC", }],
				])
			}
		}
	]
}

export const LvWirePricingRelatedPropsExtra = {
    length: BimProperty.NewShared({
        path: ['computed_result', 'length'],
        value: 0,
        unit: 'ft',
    }),
    type: BimProperty.NewShared({
        path: ['specification', 'type'],
        value: '',
    }),
};

const LvWireSpecPricingArgs = {
    gauge: BimProperty.NewShared({
        path: ['specification', 'gauge'],
        value: 'unknown_model'
    }),
    material: BimProperty.NewShared({
        path: ['specification', 'material'],
        value: 'unknown_material'
    }),
};

export const priceRelatedPropsGrouping = {
    LvWireSpecPricingArgs,
    LvWirePricingRelatedPropsExtra
}

export function registerLvWirePriceSolver(bim: Bim, costs: CostsConfigProvider) {
    const global = LazyDerived.new1(
        'global',
        [bim.unitsMapper],
        [costs.lazyInstanceCostsByType(LvWireTypeIdent)],
        ([costs]) => {
            return new InstanceCostsGlobal(costs, bim.unitsMapper);
        }
    )

    const LvWirePricingSharedArgGlobaIdent = 'lv-wire-pricing-shared-arg-global-ident';
    bim.runtimeGlobals.registerByIdent(LvWirePricingSharedArgGlobaIdent, global)

    const [flattenedProps, unflatten] = flattenNamedPropsGroups({
        LvWirePricingRelatedPropsExtra,
        DcWireIdProps,
        LvWireSpecProps,
    });
    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstance({
        cache: true,
        solverIdentifier: 'lv-wire-pricing-solver',
        objectsDefaultArgs: {
            legacyProps: flattenedProps,
        },
        objectsIdentifier: LvWireTypeIdent,
        globalArgsSelector: {
            [LvWirePricingSharedArgGlobaIdent]: InstanceCostsGlobal,
        },
        solverFunction: (props, globals) => {
            const legacyPropsGroups = unflatten(props.legacyProps);
            const sharedReply = globals[LvWirePricingSharedArgGlobaIdent];
            if (sharedReply instanceof Failure) {
                return {}
            }
            const shared = sharedReply.value;
            const currentIdProps = extractValueUnitPropsGroup(legacyPropsGroups.DcWireIdProps);
            const costPerMeter = sumCostComponents(mergeCostComponents(
                shared.costs.find(x => ObjectUtils.areObjectsEqual(x.props, currentIdProps))?.costs,
                createDefaultDCCostsPerMeter(),
            ));

            const extraProps = legacyPropsGroups.LvWirePricingRelatedPropsExtra;
            const length = extraProps.length.as('m');
            const cableType = extraProps.type.asText() || 'generic';


            let fullCost = length * costPerMeter;
            fullCost = isFinite(fullCost) ? fullCost : 0;


            const ref = bim.keyPropertiesGroupFormatter.formatNamedProps(LvWireTypeIdent, legacyPropsGroups.LvWireSpecProps)

            const patch: BimPropertyData[] = [
                {
                    path: ['cost', 'cost_item'],
                    value: ref ?? 'unknown',
                    readonly: true,
                },
                {
                    path: ['cost', 'cable_type'],
                    value: cableType,
                    readonly: true,
                },
                {
                    path: ['cost', 'total_cost'],
                    unit: 'usd',
                    value: fullCost,
                    readonly: true,
                },
            ];
            return {
                legacyProps: patch,
                removeProps: [
                    BimProperty.MergedPath(['cost', 'status']),
                    BimProperty.MergedPath(['cost', 'has_zero_costs']),
                    BimProperty.MergedPath(['cost', 'length_unit']),
                    BimProperty.MergedPath(['cost', 'per_length_cost']),
                ]
            };
        }
    }));
}

export const LvWireSpecProps = {
    gauge: BimProperty.NewShared({
        path: ['specification', 'gauge'],
        value: 'unknown_model'
    }),
    material: BimProperty.NewShared({
        path: ['specification', 'material'],
        value: 'unknown_material'
    }),
};

export function registerLvWireKeyPropertiesGroupFormatter(group: PropertiesGroupFormatters) {
    group.register(
        LvWireTypeIdent,
        new PropertiesGroupFormatter(
            LvWireSpecProps,
            (props) => {
                return Array.from(new Set([
                    props.gauge,
                    props.material
                ].map(x => x.asText()))).join(' ');
            }
        )
    )
}

export const expandLvLegacyPropsWithCostTableLinks: ExpandLegacyPropsWithCostTableLinks = (params) => {
    const sis = Array.from(params.bim.instances.peekByIds(params.ids).values());
    const groupByModule = Array.from(IterUtils.groupBy(
        sis,
        (o) => {
            const props = o.properties.extractPropertiesGroup(DcWireIdProps)
            return [props.gauge.asText(), props.material.asText(), props.type.asText()].join('/');
        },
    ))
    if (groupByModule.length !== 1) {
        return;
    }
    createFocusLinkOnSample({
        costModelFocusApi: params.costModelFocusApi,
        sample: sis[0],
        targetPui: PUI_GroupNode.tryGetNestedChild(params.pui, ['cost']),
        label: 'Setup wire costs'
    })
}
