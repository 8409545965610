import type { TypedArray } from 'math-ts';
import { KrMath } from 'math-ts';
import { LazyOnce } from './LazyOnce';
import type { TypedNumbersArr } from './TypedNumbersVec';
import { DefaultMap } from './DefaultMap';

export class ObjectUtils {

	static keysIncludingGetters<T extends Object>(obj: T): (keyof T & string)[] {
		const keys = Object.keys(obj) as (keyof T)[];
		if (obj.constructor !== Object) {
			const getters = ObjectUtils.getClassGetters(obj.constructor as any);
			for (const getter of getters) {
				keys.push(getter as keyof T);
			}
		}
		return keys as (keyof T & string)[];
	}

	static getClassGetters<T extends {new(...args: any[]): T}>(ctor: T): readonly (keyof T & string)[] {
		return perClassGetters.getOrCreate(ctor) as (keyof T & string)[];
	}

    static combineHashCodes(h1: number, h2: number) {
        h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
        h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
        return 4294967296 * (2097151 & h2) + (h1>>>0);
    }

	static stringHash(str: string, seed: number = 0): number {
		let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
		for (let i = 0, ch; i < str.length; i++) {
			ch = str.charCodeAt(i);
			h1 = Math.imul(h1 ^ ch, 2654435761);
			h2 = Math.imul(h2 ^ ch, 1597334677);
		}
		h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
		h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
		return 4294967296 * (2097151 & h2) + (h1>>>0);
	}

	static keys<T extends Object>(obj: T): (keyof T)[] {
		return Object.keys(obj) as (keyof T)[];
	}

	static primitiveHash(p: number | string | boolean | undefined | null) {
		if (typeof p == 'string') {
			return ObjectUtils.stringHash(p);
		}
		let asNumber = Number(p) || 0;

        if (Math.abs(asNumber) < 1) {
            asNumber *= 9973;
        } else if (Math.abs(asNumber) < 10) {
            asNumber *= 997;
        }
		return (asNumber * 271) | 0;
	}

    static objectHash(obj: any): number {
        let hash: number;
        if (typeof obj === 'object' && obj !== null) {
            hash = 13;
            if (ObjectUtils.isIterable(obj)) {
                if (Array.isArray(obj)) {

                    let arraySum = 0
                    for (const v of obj) {
                        if (typeof v === 'object') {
                            arraySum = ObjectUtils.combineHashCodes(arraySum, ObjectUtils.objectHash(v));
                        } else {
                            arraySum = ObjectUtils.combineHashCodes(arraySum, ObjectUtils.primitiveHash(v));
                        }
                    }
                    hash = ObjectUtils.combineHashCodes(hash, arraySum + obj.length);

                } else if (ArrayBuffer.isView(obj)) {
                    let arraySum = 0
                    for (const v of obj as TypedArray) {
                        arraySum = ObjectUtils.combineHashCodes(arraySum, v);
                    }
                    hash = ObjectUtils.combineHashCodes(hash, arraySum + obj.byteLength);
                } else {
                    for (const item of obj) {
                        hash = ObjectUtils.combineHashCodes(hash, ObjectUtils.objectHash(item));
                    }
                }
            } else {
                let hadOwnFields = false;
                for (const key in obj) {
                    const prop = (obj as any)[key];
                    if (typeof prop !== 'function') {
                        let fieldHash = ObjectUtils.combineHashCodes(ObjectUtils.stringHash(key), ObjectUtils.objectHash(prop));
                        hash = ObjectUtils.combineHashCodes(hash, fieldHash);
                        hadOwnFields = true;
                    }
                }
                if (!hadOwnFields) {
                    hash = ObjectUtils.combineHashCodes(hash, ObjectUtils.stringHash(obj.toString()));
                }
            }
        } else if (typeof obj !== 'function') {
            hash = ObjectUtils.primitiveHash(obj);
        } else {
            hash = 0;
        }
        return hash;
    }

	static isOneOfTypes(obj: any, ctors: {new(...args: any[]): any}[]) {
		for (const ty of ctors) {
			if (obj instanceof ty) {
				return true;
			}
		}
		return false;
	}

	static deepFreeze<T>(obj: T, recursionLimit: number = Infinity): Readonly<T> {
		if (Object.isFrozen(obj) || recursionLimit < 0) {
			return obj;
		}
		if (Array.isArray(obj)) {
			Object.freeze(obj);
			for (const o of obj) {
				if (typeof o == 'object') {
					ObjectUtils.deepFreeze(o, recursionLimit - 1);
				} else {
					break;
				}
			}
		}  else if (typeof obj == 'object') {
			if (ArrayBuffer.isView(obj) || obj instanceof Map) {
				// sadly cannot be freezed, simply skip
				return obj;
			}
			Object.freeze(obj);
			for (const key in obj) {
				const f = obj[key];
				if (typeof f == 'object') {
					ObjectUtils.deepFreeze(f, recursionLimit - 1);
				}
			}
		}
		return obj;
	}

	static isClassConstructor<T>(obj: any): obj is {new (...args: any[]): T} {
		if (typeof obj !== 'function') {
			return false;
		}
		return obj.prototype?.constructor === obj;
	}

	static isIterable(obj: any): obj is Iterable<any> {
		return obj && typeof obj[Symbol.iterator] === 'function';
	}
	static asIterable(obj: any): Iterable<any> | null {
		if (ObjectUtils.isIterable(obj)) {
			return obj as Iterable<any>;
		} else {
			return null;
		}
	}


	static isTheSameShapeDeep(source: any, patch: any): boolean {
		if (typeof source != typeof patch) {
			return false;
		}
		for (const propName in patch) {
            const pp = patch[propName];
            if (pp === undefined || typeof pp == 'function') {
                continue;
			}
			const sp = source[propName];
			if (sp === undefined || typeof sp !== typeof pp) {
				return false;
			}
			if (!(ObjectUtils.isPrimitive(sp) || ObjectUtils.isTheSameShapeDeep(sp, pp))) {
				return false;
			}
		}
		return true;
	}

	static isTheSameShapeAs(source: any, patch: any): boolean {
		if (typeof source != typeof patch) {
			return false;
		}
		for (const propName in patch) {
            const pp = patch[propName];
            if (pp === undefined || typeof pp == 'function') {
                continue;
			}
			const sp = source[propName];
			if (sp === undefined || typeof sp !== typeof pp) {
				return false;
			}
		}
		return true;
	}

	static isPrimitive(obj: any): boolean {
		const type = typeof obj;
  		return obj == null || (type != 'object' && type != 'function');
	}

	static shallowEqual<T>(o1: T, o2: T) {
		if (Object.is(o1, o2)) {
			return true;
		}
		if (ObjectUtils.isPrimitive(o1) || ObjectUtils.isPrimitive(o2)) {
			return false;
		}
		for (const key in o1) {
			const p1 = o1[key];
			if (typeof p1 == 'function') {
				continue;
			}
			const p2 = o2[key];
			if (!Object.is(p1, p2)) {
				return false;
			}
		}
		// check that o2 does not have fields that are not present in o1
		for (const key in o2) {
			const p2 = o2[key];
			if (typeof p2 == 'function') {
				continue;
			}
			const p1 = o1[key];
			if (p2 !== undefined && p1 === undefined) {
				return false;
			}
		}
		return true;
	}

	static areObjectsEqual<T>(o1: T, o2: T, recursionLevel: number = 0) {
		if (Object.is(o1, o2)) {
			return true;
		}
		if (ObjectUtils.isPrimitive(o1) || ObjectUtils.isPrimitive(o2)) {
			return o1 === o2; // to support -0 === 0
		}
		if (recursionLevel > 50) {
			console.error('ObjectUtils.areObjectsEqual, recursion is too high, probably a cycle', recursionLevel, o1, o2);
			return false;
		}
		recursionLevel += 1;
		if (Array.isArray(o1)) {
			if (!Array.isArray(o2) || o1.length !== o2.length) {
				return false;
			}
			for (let i = 0; i < o1.length; ++i) {
				if (!ObjectUtils.areObjectsEqual(o1[i], o2[i], recursionLevel)) {
					return false;
				}
			}
		} else if (o1 instanceof Map) {
			if (!(o2 instanceof Map) || o1.size !== o2.size) {
				return false;
			}
			for (const [key, v1] of o1) {
				const v2 = o2.get(key);
				if (!ObjectUtils.areObjectsEqual(v1, v2, recursionLevel)) {
					return false;
				}
			}
		} else if (o1 instanceof Set) {
			if (!(o2 instanceof Set) || o1.size !== o2.size) {
				return false;
			}
			for (const v of o1) {
				if (!(o2.has(v))) {
					return false;
				}
			}
		} else if (ArrayBuffer.isView(o1)) {
			if (!ArrayBuffer.isView(o2) || o1.byteLength !== o2.byteLength) {
				return false;
			}
			for (let i = 0; i < (o1 as unknown as TypedNumbersArr).length; ++i) {
				if (!Object.is((o1 as any)[i], (o2 as any)[i])) {
					return false;
				}
			}
		} else if (o1 instanceof Date) {
			return o2 instanceof Date && o1.valueOf() === o2.valueOf();
		} else {
			for (const key in o1) {
				const p1 = o1[key];
				if (typeof p1 == 'function') {
					continue;
				}
				const p2 = o2[key];
				if (!ObjectUtils.areObjectsEqual(p1, p2, recursionLevel)) {
					return false;
				}
			}
			// check that o2 does not have fields that are not present in o1
			for (const key in o2) {
				const p2 = o2[key];
				if (typeof p2 == 'function') {
					continue;
				}
				const p1 = o1[key];
				if (p2 !== undefined && p1 === undefined) {
					return false;
				}
			}
		}
		return true;
	}

	static patchObject<T extends object>(obj: T, patch: Partial<T>):
		{ revertPatch: Partial<T>, appliedPatch: Partial<T> } | null
	{
		let revert: any | null = null;
		let applied: any = {};
		for (const fieldName in obj) {
			const currField = obj[fieldName];

			const patchField = patch[fieldName] as any;
			if (!obj.hasOwnProperty(fieldName) || typeof patchField === 'function') {
				continue;
			}
			if (patchField !== undefined) {
				if (typeof currField != typeof patchField && currField !== null && patchField !== null) {
					console.error('object patch, fields should have the same type', fieldName);
					continue;
				}
				if (!ObjectUtils.areObjectsEqual(currField, patchField)) {
					(revert = revert || {})[fieldName] = ObjectUtils.deepCloneObj(currField);

					obj[fieldName] = ObjectUtils.deepCloneObj(patchField);
					applied[fieldName] = patchField;
				}
			}
		}
		if (revert === null) {
			return null;
		} else {
			return { revertPatch: revert, appliedPatch: applied };
		}
	}

	static applyPatchDeep<T>(name: string | number, patch: Partial<T>, full: T): any {
		if (ObjectUtils.isPrimitive(full)) {
			if (typeof patch !== typeof full) {
				if (full !== null && patch !== null) {
					console.error('patch field type mismatch', name, patch, full);
					return full;
				}
			}
			return patch;
		} else if (Array.isArray(patch)) {
			if (!Array.isArray(full)) {
				throw new Error('type mismath, target is Array |' + name);
			}
			for (let i = 0; i < patch.length; ++i) {
				let p = patch[i];
				let f = full[i];
				if (f == undefined || !ObjectUtils.deepPatchEqualsToObject(p, f)) { // full state array is shorter than the patch
					(full as any[])[i] = ObjectUtils.deepCloneObj(p);
				}
			}
			(full as any[]).length = patch.length; // if full state shorter, equalize length
		} else if (typeof full === 'object') {
			if (patch === null) {
				return null;
			}
			if (ArrayBuffer.isView(patch)) {
				if (ArrayBuffer.isView(full) && full.byteLength === patch.byteLength) {
					(full as any).set(patch);
				} else {
					return (patch as any).slice();
				}
			} else if (full instanceof Map) {
				if (patch instanceof Map) {
					full.clear();
					for (const [k, v] of patch) {
						full.set(k, ObjectUtils.deepCloneObj(v));
					}
				} else {
					throw new Error('type mismath, target is Map |' + name);
				}

			} else {
				for (const fieldName in patch) {
					if (!(patch as any).hasOwnProperty(fieldName)) {
						continue;
					}
					const patchField = patch[fieldName];
					const fullField = (full as any)[fieldName];
					if (fullField === undefined) {
						console.error('patch field doesnt exist in full state', fieldName);
						continue;
					}
					if (typeof fullField !== typeof fullField) {
						console.error('patch field type mismatch', fieldName);
						continue;
					}
					(full as any)[fieldName] = ObjectUtils.applyPatchDeep(fieldName, patchField as any, fullField);
				}
			}

		}
		return full;
	}

	static cloneInto<T extends Object>(target: T, source: T) {

		if (Array.isArray(source)) {
			if (Array.isArray(target)) {
				if (source.length == target.length) {
					for (let i = 0; i < source.length; ++i) {
						const sf = source[i];
						if (typeof sf == 'function') {
							continue;
						}
						if (ObjectUtils.isPrimitive(sf)) {
							target[i] = sf;
						} else {
							ObjectUtils.cloneInto(target[i], sf);
						}
					}
				} else {
					throw new Error('Object cloneInto does not support different size arrays');
				}
			} else {
				throw new Error('Object cloneInto, source is array and destination is not');
			}
		} else if (target instanceof Map) {
			if (source instanceof Map) {
				target.clear();
				for (const [k, v] of source) {
					target.set(k, ObjectUtils.deepCloneObj(v));
				}
			} else {
				throw new Error('type mismath, target is Map |' + name);
			}

		} else {
			for (const fieldName in source) {
				const sf = source[fieldName];
				if (typeof sf == 'function') {
					continue;
				}
				if (ObjectUtils.isPrimitive(sf)) {
					target[fieldName] = sf;
				} else if (typeof target[fieldName] === 'object') {
					ObjectUtils.cloneInto(target[fieldName] as Object, sf as Object);
				} else {
					throw new Error('type mismath, expected target and source to be of object types ' + fieldName);
				}
			}
		}

	}


	static deepPatchEqualsToObject<T>(obj1: Partial<T>, obj2: T): boolean {
		if (obj1 == obj2) {
			return true;
		}
		if (ObjectUtils.isPrimitive(obj1) || ObjectUtils.isPrimitive(obj2)) {
			return false;
		};
		if (Array.isArray(obj1)) {
			if (!Array.isArray(obj2) || obj1.length !== obj2.length) {
				return false;
			}
			for (let i = 0; i < obj1.length; ++i) {
				const i1 = obj1[i];
				const i2 = obj2[i];
				if (!ObjectUtils.deepPatchEqualsToObject(i1, i2)) {
					return false;
				}
			}
		} else if (ArrayBuffer.isView(obj1)) {
			if (!ArrayBuffer.isView(obj2) || obj1.byteLength !== obj2.byteLength) {
				return false;
			}
			for (let i = 0; i < (obj1 as any).length; ++i) {
				if (!Object.is((obj1 as any)[i], (obj2 as any)[i])) {
					return false;
				}
			}
		} else if (obj1 instanceof Map) {
			if (obj2 instanceof Map) {
				if (obj1.size != obj2.size) {
					return false;
				}
				for (const [k, v] of obj1) {
					if (!ObjectUtils.deepPatchEqualsToObject(v, obj2.get(k))) {
						return false;
					}
				}

			} else {
				return false;
			}
		} else {
			for (const key in obj1) {
				const f1: any = (obj1 as any)[key];
				const f2: any = (obj2 as any)[key];
				if (!ObjectUtils.deepPatchEqualsToObject(f1, f2)) {
					return false;
				}
			}
		}
		return true;
	}

	static deepCloneObj<T>(obj: T): T {
		if (ObjectUtils.isPrimitive(obj) || Object.isFrozen(obj)) {
			return obj;
		}
		if (Array.isArray(obj)) {
			const res = (obj as any[]).map((f: any) => ObjectUtils.deepCloneObj(f));
			return res as any as T;
		}
		if (ArrayBuffer.isView(obj)) {
			return (obj as any).slice();
		}

		// if ((obj as any).clone instanceof Function) {
		// 	return (obj as any).clone();f
		// }
		if (obj instanceof Map) {
			const res = new Map();
			for (const [k, v] of obj) {
				res.set(k, ObjectUtils.deepCloneObj(v));
			}
			return res as any;
		}
		if (obj instanceof Set) {
			const res = new Set();
			for (const v of obj) {
				res.add(ObjectUtils.deepCloneObj(v));
			}
			return res as any;
		}
		if (obj instanceof Date) {
			return new Date(obj.valueOf()) as any as T;
		}
		let proto = Object.getPrototypeOf(obj);
		let cloned: Partial<T> = proto ? Object.create(proto) : {};
		for (const fieldName in obj) {
			const currField = obj[fieldName];
			if (typeof currField === 'function') {
				continue;
			}
			cloned[fieldName] = ObjectUtils.deepCloneObj(currField);
		}
		return cloned as T;
	}

	static cloneOnlyPrimitives<T extends Object>(obj:T): Object {
		const res: any = {};
		for (const key in obj) {
			const v = obj[key];
			if (ObjectUtils.isPrimitive(v)) {
				res[key] = v;
			}
		}
		return res;
	}

	static isObjectEmpty<T>(obj: T | Partial<T> | null | undefined): boolean {
		if (!obj) {
			return true;
		}
		if (ObjectUtils.isIterable(obj)) {
			for (const _ of obj) {
				return false;
			}
			return true;
		}
		if (typeof obj === 'object') {
			for (const key in obj) {
				const v = obj[key];
				if (v === undefined) {
					continue;
				} else {
					return false;
				}
			}
		}
		return true;
	}

	static toString(obj: any, recursionLevel: number = 0): string {
		if (ObjectUtils.isPrimitive(obj)) {
			return obj.toString();
		}
		if (recursionLevel > 50) {
			console.error('ObjectUtils.toString, recursion is too high, probably a cycle', recursionLevel, obj);
			return '';
		}
		recursionLevel += 1;
		if (Array.isArray(obj)) {
			let res = '[';
			for (const v of obj) {
				res += `${ObjectUtils.toString(v)};`;
			}
			return res + ']';
		} else if (obj instanceof Map) {
			let res = '[M';
			for (const [key, v] of obj) {
				res += `(${ObjectUtils.toString(key)}:${ObjectUtils.toString(v)}})`;
			}
			return res + ']';
		} else if (obj instanceof Set) {
			let res = '[S';
			for (const v of obj) {
				res += `${ObjectUtils.toString(v)};`;
			}
			return res + ']';
		} else if (ArrayBuffer.isView(obj)) {
			let res = '[A';
			for (let i = 0; i < (obj as unknown as TypedNumbersArr).length; ++i) {
				const v = (obj as any)[i];
				res += `${v};`;
			}
			return res + ']';
		} else if (obj instanceof Date) {
			return obj.toLocaleString();
		} else {
			let res = '{';
			for (const key in obj) {
				const v = obj[key];
				if (typeof v == 'function') {
					continue;
				}
				res += `(${ObjectUtils.toString(key)}:${ObjectUtils.toString(v)}})`;
			}
			return res;
		}
	}

	static fastEvaluateObjectSizeInbytes(obj: any) {
		const additionalArgs = {evaluated: new Set<Object>(), cacheHitsCount: 0, recusionOverflow: false};
		const size = ObjectUtils._evalUndoArgsMemSizeImpl(obj, 0, additionalArgs);
		if (additionalArgs.recusionOverflow) {
			console.error('fastEvaluateObjectSizeInMb, data structure is too deep', obj);
		}
		return size;
	}

	static getDiff<T>(o1: T, o2: T, recursionLevel: number = 0): Partial<T> | undefined {

		if (recursionLevel > 15) {
			console.error('ObjectUtils.getDiff, recursion is too high, probably a cycle', recursionLevel, o1, o2);
			return undefined;
		}

		if (o1 === o2)
			return undefined;

		if (o1 instanceof Map) {
			if (!(o2 instanceof Map))
				return o1;

			if (o1.size == 0)
				return o2.size == 0 ? undefined : o1;

			let diff: Partial<T> | undefined;
			for (const [key, value1] of o1.entries()) {
				const value2 = o2.get(key);
				if (!ObjectUtils.areObjectsEqual(value1, value2, recursionLevel + 1)) {
					(diff ?? (diff = new Map() as any)).set(key, value1);
				}
			}
			return diff;
		}
		if (o1 instanceof Set && o2 instanceof Set) {
			if (!(o2 instanceof Set))
				return o1;

			if (o1.size == 0)
				return o2.size == 0 ? undefined : o1;

			let diff: Partial<T> | undefined;
			for (const value1 of o1) {
				if (!o2.has(value1)) {
					(diff ?? (diff = new Set() as any)).add(value1);
				}
			}
			return diff;
		}
		if (ArrayBuffer.isView(o1) && ArrayBuffer.isView(o2)) {
			if (o1.byteLength !== o2.byteLength || !ObjectUtils.areObjectsEqual(Array.from(o1 as any), Array.from(o2 as any), recursionLevel + 1)) {
				return o1;
			}
			return undefined;
		}
		if (o1 instanceof Date && o2 instanceof Date) {
			if (o1.getTime() !== o2.getTime()) {
				return o1;
			}
			return undefined;
		}
		if (Array.isArray(o1) && Array.isArray(o2)) {

			if (!(Array.isArray(o2)))
				return o1;

			if (o1.length == 0)
				return o2.length == 0 ? undefined : o1;

			let diff: Partial<T> | undefined;
			for (let i = 0; i < o1.length; i++) {
				if (!ObjectUtils.areObjectsEqual(o1[i], o2[i], recursionLevel + 1)) {
					(diff ?? (diff = [] as any)).push(o1[i]);
				}
			}
			return diff;
		}
		if (typeof o1 === 'object' && typeof o2 === 'object' && o1 && o2) {

			let diff: Partial<T> | undefined;

			for (const key in o1) {
				const value1 = o1[key];
				const value2 = o2[key];

				if (typeof value1 == 'function') {
					continue;
				}

				if (ObjectUtils.isPrimitive(value1) || ObjectUtils.isPrimitive(value2)) {
					if (value1 !== value2) {
						(diff ?? (diff = {}) as Partial<T>)[key] = value1;
					}
				}
				else {
					const nestedDiff = this.getDiff(value1, value2, recursionLevel + 1);
					if (nestedDiff !== undefined) {
						(diff ?? (diff = {}) as any)[key] = nestedDiff;
					}
				}
			}
			for (const key in o2) {
				if (!(key in o1)) {
					(diff ?? (diff = {}) as Partial<T>)[key] = o2[key];
				}
			}
			return diff;

		} else {
			return o1;
		}
	}

	private static _evalUndoArgsMemSizeImpl<T>(
		args: T, recursionLevel: number,
		objects: {evaluated: Set<Object>, cacheHitsCount: number, recusionOverflow: boolean}
	): number {
		if (ObjectUtils.isPrimitive(args)) {
			if (typeof args === 'string') {
				return args.length * 2;
			}
			return 8;
		}
		if (objects.evaluated.has(args as Object)) {
			objects.cacheHitsCount += 1;
			return 8;
		}
		if (Array.isArray(args) || args instanceof Set || args instanceof Map) {
			let evaluatedItemsSum = 0;
			let evaluatedItemMin = Infinity;
			let i = 0;
			const sharedObjectsStartCount = objects.cacheHitsCount;
			for (const it of args.values()) {
				i += 1;
				const itemSize = ObjectUtils._evalUndoArgsMemSizeImpl(it, recursionLevel + 1, objects);
				evaluatedItemsSum += itemSize;
				evaluatedItemMin = Math.min(evaluatedItemMin, itemSize);
				if (i >= IterCheckMaxCount) {
					break;
				}
			}
			if (evaluatedItemMin === Infinity) {
				return 16;
			}
			const count = Array.isArray(args) ? args.length : args.size;
			let averageItem = (evaluatedItemsSum / i);

			const areManyShared = (objects.cacheHitsCount - sharedObjectsStartCount) / i > 0.3;

			if (areManyShared && averageItem > 16 && count > 50 && evaluatedItemMin > 0 && evaluatedItemMin < Infinity) {
				// large arrays can contain many references to the same objects
				// which will decrease average object size substantially
				// because we are only evaluating first n objects
				// we will overestimate memory consumption for shared objects case
				// use minimum object size as proxy for such cases
				// and lean towards minimum size a little bit
				const leanFactor = Math.min(count / 100_000, 0.25);
				averageItem = Math.round(KrMath.lerp(averageItem, evaluatedItemMin, leanFactor));
			}
			return 16 + Math.ceil(averageItem) * count;

		} else if (ArrayBuffer.isView(args)) {
			return args.byteLength + 16;

		} else {
			let sum = 16;

			for (const key in args) {
				const p1 = args[key];
				if (typeof p1 == 'function') {
					continue;
				}
				if (recursionLevel > 25) {
					sum += 100; // just add random size, we are too deep, no time to evaluate so much
					// this should not happen under normal circumstances
					objects.recusionOverflow = true;
				} else {
					sum += ObjectUtils._evalUndoArgsMemSizeImpl(p1, recursionLevel + 1, objects);
				}
			}
			objects.evaluated.add(args as Object);
			return sum;
		}
	}
}

const perClassGetters = new DefaultMap<{new (args: any): any}, readonly string[]>(
	(ctor): readonly string[] => {
		const descriptors = Object.getOwnPropertyDescriptors(ctor.prototype);
		const getters: string[] = [];
		for (const key in descriptors) {
			const d = descriptors[key];
			if (d.get) {
				getters.push(key);
			}
		}
		return Object.freeze(getters);
	}
);

(globalThis as any).ObjectUtils = ObjectUtils;

const IterCheckMaxCount = 50;

const LUT: LazyOnce<string[]> = new LazyOnce(
	'lut',
    () => {
        const lut = [];
        for (var i = 0; i < 256; i++) {
            lut[i] = (i < 16 ? '0' : '') + (i).toString(16);
        }
        return lut;
    }
);

const d = new Uint32Array(4);

export function generateGuid(): string {
    const lut = LUT.poll();
    crypto.getRandomValues(d);
    const d0 = d[0];
    const d1 = d[1];
    const d2 = d[2];
    const d3 = d[3];
    return lut[d0&0xff]+lut[d0>>8&0xff]+lut[d0>>16&0xff]+lut[d0>>24&0xff]+'-'+
        lut[d1&0xff]+lut[d1>>8&0xff]+'-'+lut[d1>>16&0x0f|0x40]+lut[d1>>24&0xff]+'-'+
        lut[d2&0x3f|0x80]+lut[d2>>8&0xff]+'-'+lut[d2>>16&0xff]+lut[d2>>24&0xff]+
        lut[d3&0xff]+lut[d3>>8&0xff]+lut[d3>>16&0xff]+lut[d3>>24&0xff];
}


