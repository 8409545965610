import type { Result} from 'engine-utils-ts';
import { convertUnits, DefaultMap, Failure, IterUtils, LruCache, ObjectUtils, Success } from 'engine-utils-ts';
import { combineHashCodes } from 'math-ts';
import type { EntityId } from 'verdata-ts';

import type { UnitsMapper } from '../UnitsMapper';
import { NumberProperty, StringProperty, BooleanProperty } from '../properties/PrimitiveProps';

export type BimPropertyValue = number | string | boolean | EntityId<any>;


export interface BimPropertyData {
    path: string[];
    description?: string;

    isComputedBy?: string;
    // computedFromObjects?: IdBimScene[] | null;

    value: BimPropertyValue;
    unit?: string;
    numeric_step?: number;
    discrete_variants?: (number | string)[] | null;
    numeric_range?: [number, number] | null;
    // Some properties should not be changed by the user via ui.
    // For example, property that is dynamically calculated by
    // the solver to be equal to sum of some numeric properties
    // of its children. If so, explicitly set 'readonly = true'
    readonly?: boolean;
}


export class BimProperty implements BimPropertyData/*, VersionedValue*/ {

    public readonly _mergedPath: string;

    public readonly path: string[];

    public readonly description: string;
    public readonly isComputedBy: string;
    // public readonly computedFromObjects: IdBimScene[] | null;

    public readonly value: BimPropertyValue;
    public readonly unit: string;
    public readonly discrete_variants: (number | string)[] | null;
    public readonly numeric_range: [number, number] | null;
    public readonly numeric_step: number;
    public readonly readonly: boolean;

    public readonly valueUnitHash: number;

    constructor(params: BimPropertyData) {
        this.path = params.path;
        this.description = params.description ?? "";
        this.isComputedBy = params.isComputedBy ?? "";
        this.value = params.value;
        this.unit = params.unit ?? "";
        this.discrete_variants = params.discrete_variants ?? null;
        this.readonly = params.readonly ?? false;
        // this.computedFromObjects = params.computedFromObjects ?? null;
        this.numeric_step = params.numeric_step ?? 0;
        this.numeric_range = params.numeric_range ?? null;
        this._mergedPath = BimProperty.MergedPath(this.path);
        this.valueUnitHash = combineHashCodes(
            ObjectUtils.primitiveHash(this.value),
            ObjectUtils.primitiveHash(this.unit)
        );
        Object.freeze(this);
    }

    public static MergedPath(path: (string|number)[]): string {
        const merged = path.join(' | ');
        return _props_all_merged_paths.getOrCreate(merged);
    }

    public static convert(value: number, from: string, to: string) {
        const r = convertUnits(value, from, to);
        if (r instanceof Success) {
            return r.value;
        }
        console.error(`units conversion error value:${value} from:${from} to:${to}`, r.errorMsg());
        return 0;
    }

    public static NewShared(prop: BimProperty | BimPropertyData): BimProperty {
        if (!(prop instanceof BimProperty)) {
            prop = new BimProperty(prop);
        }
        return propsInterner.get(prop as BimProperty);
    }


    isNumeric(): boolean { return typeof this.value == 'number';}
    isText(): boolean { return typeof this.value == 'string';}
    isBoolean(): boolean { return typeof this.value == 'boolean'; }


    asBoolean(): boolean {
        if (this.isBoolean()) {
            return this.value as boolean;
        }
        throw new Error(`property value is not bool ${this.value}`);
    }
    asNumber(): number {
        if (this.isNumeric()) {
            return this.value as number;
        }
        throw new Error(`property value is not text ${this.value}`);
    }
    asText(): string {
        if (this.isText()) {
            return this.value as string;
        }
        throw new Error(`property value is not text ${this.value}`);
    }

    as(unit: string): number {
        if (this.unit === unit) {
            return this.value as number;
        }
        return BimProperty.convert(this.value as number, this.unit as string, unit);
    }

    convertedInto(unit: string): Result<number> {
        if (this.isNumeric()) {
            if (this.unit) {
                return convertUnits(this.value as number, this.unit, unit);
            } else if (!(this.unit || unit)) {
                return new Success(this.value as number);
            }
            return new Failure({msg: `cannot convert from ${this.unit} into ${unit}`});
        } else {
            return new Failure({msg: 'property has no numeric value'});
        }
    }

    hasDiscreteVariants(): boolean {
        return this.discrete_variants != undefined && this.discrete_variants.length > 0;
    }

    withDifferentValue(value: BimPropertyValue): BimProperty {
        return BimProperty.NewShared({
            ...this,
            value: value,
        })
    }

    valueUnitUiString(mapper: UnitsMapper): string {
        if (this.unit && typeof this.value == 'number') {
            const {value, unit} = mapper.mapToConfigured({value: this.value, unit: this.unit})!;
            return `${value!.toFixed(1)} ${unit}`;
        }
        return this.value + '';
    }
    valueUnitUiStringPrecise(mapper: UnitsMapper): string {
        if (this.unit && typeof this.value == 'number') {
            const {value, unit} = mapper.mapToConfigured({value: this.value, unit: this.unit})!;
            return `${value.toFixed(4)} ${unit}`;
        }
        return this.value + '';
    }

    valueEqual(other: BimPropertyData): boolean {
        return Object.is(this.value, other.value)
            && (this.unit == (other.unit ?? ""));
    }

    metaEqual(r: BimProperty) {
        return this._mergedPath === r._mergedPath
            && this.description === r.description
            && this.isComputedBy === r.isComputedBy
            && this.readonly === r.readonly
            && this.numeric_step === r.numeric_step
            && IterUtils.areOptionalArraysEqual(this.discrete_variants, r.discrete_variants, Object.is)
            && IterUtils.areOptionalArraysEqual(this.numeric_range, r.numeric_range, Object.is)
    }
    equals(r: BimProperty) {
        if (this === r) { // fast path for equal interned props
            return true;
        }
        return this.valueEqual(r) && this.metaEqual(r);
    }
}

const _props_all_merged_paths = new DefaultMap<string, string>((p) => p);
(globalThis as any)['all_merged_paths'] = _props_all_merged_paths;

const propsInterner: LruCache<BimProperty, BimProperty> = new LruCache({
    identifier: `interned-bim-props`,
    maxSize: 10000,
    hashFn: (p) => {
        return `${p._mergedPath}=${p.value}${p.unit}:${p.isComputedBy ?? ''}`;
    },
    eqFunction: (a, b) => a.equals(b),
    factoryFn: (p) => p,
});

if ((globalThis as any)['all_interned_props']) {
    console.error('interned props double init, check bundling');
} else {
    (globalThis as any)['all_interned_props'] = propsInterner;
}


export function BimPropertyToProperty(bimProp: BimProperty) {
    if (typeof bimProp.value === 'number') {
        return NumberProperty.new({
            unit: bimProp.unit ?? '',
            value: bimProp.value,
        })
    } else if (typeof bimProp.value === 'string') {
        return StringProperty.new({
            value: bimProp.value,
        })
    } else if (typeof bimProp.value === 'boolean') {
        return BooleanProperty.new({
            value: bimProp.value,
        })
    }
    throw new Error('not supported bim property type ' + bimProp)
}
