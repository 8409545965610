import type { Result } from 'engine-utils-ts';
import { CachedCalculations, Failure, IterUtils, LazyBasic, LruCache, ObjectUtils, Success } from 'engine-utils-ts';
import { KrMath, Matrix4, Quaternion, Transform, Vec3One, Vec3X, Vec3Y, Vec3ZNeg, Vec3Zero, Vector2, Vector3, combineHashCodes,
} from 'math-ts';
import type { Bim } from '../Bim';

import type { BimPropertyData } from '../bimDescriptions/BimProperty';
import { BimProperty } from '../bimDescriptions/BimProperty';
import type { PropertiesGroupFormatters} from '../bimDescriptions/PropertiesGroupFormatter';
import { extractIntoNamedPropsGroup, PropertiesGroupFormatter } from '../bimDescriptions/PropertiesGroupFormatter';
import type { IdBimMaterial } from '../BimMaterials';
import { BimMaterialStdRenderParams } from '../BimMaterials';
import type { AssetBasedCatalogItemCreators } from '../catalog/CatalogItemCollection';
import { ExtrudedPolygonGeometry } from '../geometries/ExtrudedPolygonGeometries';
import { PolylineGeometry } from '../geometries/PolylineGeometries';
import type { NumberProperty } from '../properties/PrimitiveProps';
import { PropsGroupBase } from '../properties/Props';
import { StdGroupedMeshRepresentation, StdMeshRepresentation, StdSubmeshesLod, StdSubmeshRepresentation, SubmeshGroupRepresentation } from '../representation/Representations';
import type { ReactiveSolverBase, SolverInstancePatchResult } from '../runtime/ReactiveSolverBase';
import { SolverObjectInstance } from '../runtime/SolverObjectInstance';
import type { SceneInstance } from '../scene/SceneInstances';
import { registerEquipmentCommonAssetToCatalogItem } from '../archetypes/EquipmentCommon';
import { TrackerShadingProps } from './shading/TrackerShadingProps';
import { PropsGroupsRegistry } from '../properties/PropsGroupsRegistry';
import { PropsFieldFlags, PropsFieldOneOf } from '../properties/PropsGroupComplexDefaults';
import type { SceneInstanceShapeMigration } from '../scene/SceneInstancesArhetypes';
import { createModuleMountingProps_ShapeMigration, fixModuleModelIsNumber_ShapeMigration, ModuleHeight, ModuleUniqueProps, PVModuleTypeIdent } from '../archetypes/pv-module/PVModule';
import type { PropertiesPatch } from '../bimDescriptions/PropertiesCollection';
import {
    createTrackerFrameMountingProps_ShapeMigration, createTrackerFramePropsInsideTracker_ShapeMigration,
    createTrackerFrameSeries_ShapeMigration, fillMissingUseModuleRowField_ShapeMigration, getModulesRows,
    removeMaxSlopeMigration, TrackerFrameTypeIdentifier, undulatedProps_ShapeMigration
} from '../archetypes/TrackerFrame';
import { BimPropertiesFormatter, sceneInstanceHierarchyPropsRegistry } from '../catalog/SceneInstanceHierarchyPropsRegistry';
import type { IdBimGeo } from '../geometries/BimGeometries';
import type { EntityIdAny } from 'verdata-ts';
import { addEnergyPVModuleProps_ShapeMigration, addFirstSolarModuleInTrackersFix_ShapeMigration } from '../archetypes/pv-module/migrations/EnergyPvModulePropsMigration';
import { EnergyYieldPerStringProducer } from 'src/energy/EnergyYieldPerStringProducer';
import { removeEnergyPropsMigration } from 'src/archetypes/transformer/Transformer';
import { PUI_GroupNode } from 'ui-bindings';
import { TrackerFrameUniqueProps } from 'src/cost-model/capital/tables/categories/structural/racking';
import type { ExpandLegacyPropsWithCostTableLinks} from 'src/cost-model/capital';
import { createFocusLinkOnSample } from 'src/cost-model/capital';

export const TrackerTypeIdent = 'tracker';

export class TrackerProps extends PropsGroupBase {
    shading: TrackerShadingProps | null;
    energy_per_string: EnergyYieldPerStringProducer | null;

    constructor(args: Partial<TrackerProps>) {
        super();
        this.shading = args.shading ?? null;
        this.energy_per_string = args.energy_per_string ?? null;
    }
}

PropsGroupsRegistry.register({
    class: TrackerProps,
    complexDefaults: {
        shading: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone,
            null,
            TrackerShadingProps
        ),
        energy_per_string: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization,
            null,
            EnergyYieldPerStringProducer,
        ),
    }
});


export function registerTracker(bim: Bim) {
    bim.instances.archetypes.registerArchetype({
        type_identifier: TrackerTypeIdent,
        mandatoryProps: [
            { path: ["cost_bs", "level 1"], value: "FURNISH TRACKER SUBTOTAL" },
            { path: ["cost_bs", "level 2"], value: "Trackers" },
        ],
        propsClass: TrackerProps,
        propsShapeMigrations: migrations(),
    });

    bim.reactiveRuntimes.registerRuntimeSolver(trackerSlopeSolver('tracker'));
    bim.reactiveRuntimes.registerRuntimeSolver(trackerMeshGenerator(bim));

}

function migrations(): SceneInstanceShapeMigration[] {
    return [
        fixModuleModelIsNumber_ShapeMigration(1),
        createModuleMountingProps_ShapeMigration(2),
        createTrackerFramePropsInsideTracker_ShapeMigration(3),
        createTrackerFrameMountingProps_ShapeMigration(4),
        movePilesPropsToTrackerFrame_ShapeMigration(5),
        createTrackerFrameSeries_ShapeMigration(6),
        fillMissingUseModuleRowField_ShapeMigration(7),
        undulatedProps_ShapeMigration(8),
        //rewrite version 10 addEnergyPVModuleProps_ShapeMigration
        addEnergyPVModuleProps_ShapeMigration(11),
        addFirstSolarModuleInTrackersFix_ShapeMigration(12),
        removeMaxSlopeMigration(13),
        removeEnergyPropsMigration(14),
    ]
}


export function trackerSlopeSolver(typeIdent: string): ReactiveSolverBase {

    const TrackerSlopeSolverInput = {
        worldMatrix: new Matrix4(),
    }

    const vReused = new Vector3();
    const projReused = new Vector3();
    const quatReused = new Quaternion();

    return new SolverObjectInstance({
        solverIdentifier: `${typeIdent}-slope`,
        objectsDefaultArgs: TrackerSlopeSolverInput,
        objectsIdentifier: typeIdent,
        solverFunction: ({worldMatrix}): SolverInstancePatchResult => {

            quatReused.setFromRotationMatrix(worldMatrix);
            if (typeIdent === TrackerTypeIdent) {
                vReused.copy(Vec3Y);
            } else {
                vReused.copy(Vec3X);
            }
            vReused.applyQuaternion(quatReused);

            projReused.copy(vReused);
            projReused.z = 0;

            let slopeDirection: string;
            const angleXY = projReused.angleTo(Vec3X);
            if (angleXY < 0.25 * Math.PI) {
                slopeDirection = vReused.z >= 0 ? 'west-facing' : 'east-facing';
            } else if (angleXY > 0.75 * Math.PI) {
                slopeDirection = vReused.z >= 0 ? 'east-facing' : 'west-facing';
            } else if (projReused.y >= 0) {
                slopeDirection = vReused.z >= 0 ? 'south-facing' : 'north-facing';
            } else {
                slopeDirection = vReused.z >= 0 ? 'north-facing' : 'south-facing';
            }

            const slopeAngle = vReused.angleTo(projReused);

            let angleValue: number;
            let angleUnit: string;
            if (slopeAngle <= Math.PI / 4) {
                angleValue = KrMath.roundTo(100 * Math.tan(slopeAngle), 0.001);
                angleUnit = '%';
            } else {
                angleValue = Math.round(KrMath.radToDeg(slopeAngle));
                angleUnit = 'deg';
            }

            return {
                legacyProps: [
                    { path: ["position", "slope_first_to_last"], value: angleValue, unit: angleUnit },
                    { path: ["position", "slope-direction"], value: angleValue !== 0 ? slopeDirection : 'north-facing' },
                    { path: ["position", "_local-slope-direction"], value: angleValue !== 0 ? -Math.sign(vReused.z) : 1 },
                ]
            }
        }
    })
}


export function segmentsAnglesFromString(segments_slopes: string): number[] {
    const segmentsSlopes = segments_slopes.split(", ");
    const rotationsAngles: number[] = [];
    for (let i = 0; i < segmentsSlopes.length; i++) {
        const slopeWithFacing = segmentsSlopes[i].split("%");
        const rotationAngle = (slopeWithFacing[1] === 'N' ? -1 : 1) * Math.atan(0.01 * (+slopeWithFacing[0]));
        rotationsAngles.push(rotationAngle);
    }
    return rotationsAngles;
}


export function segmentsStringFromSlopes(segmentsSlopes: number[]): string {
    let newValue = '';
    for (let i = 0; i < segmentsSlopes.length; ++i) {
        const direction = segmentsSlopes[i] > 0 ? 'S' : 'N';
        newValue = newValue.concat(KrMath.roundTo(Math.abs(100 * segmentsSlopes[i]), 0.001) + '%' + direction);
        if (i < segmentsSlopes.length - 1) {
            newValue = newValue.concat(", ");
        }
    }
    return newValue;
}


export function hasDifferentSegmentsSlopes(segments_slopes: string): boolean {
    const angles = segmentsAnglesFromString(segments_slopes);
    for (const angle of angles) {
        if (Math.abs(angle) > 0.0001) {
            return true;
        }
    }
    return false;
}


export function getLocalPilesCoords(
    trackerBuilderParams: TrackerBuilderInfo,
    segments_slopes: string
): Vector3[] {
    const pilesCoords: Vector3[] = [];

    const parts = trackersPartsCache.acquire(trackerBuilderParams).parts;

    const rotations: Quaternion[] = [];
    let rotationsAngles: number[] = [];
    if (segments_slopes !== "") {
        rotationsAngles = segmentsAnglesFromString(segments_slopes);

        for (const rotationAngle of rotationsAngles) {
            rotations.push(new Quaternion().setFromAxisAngle(Vec3X, rotationAngle));
        }
    }

    let allRotationsEqual = true;
    for (let i = 1; i < rotations.length; ++i) {
        if (!rotations[0].equals(rotations[i])) {
            allRotationsEqual = false;
            break;
        }
    }

    const trackerCenterOffset = 0.5 * (parts[0].centerOffset + parts.at(-1)!.centerOffset);
    if (allRotationsEqual) {
        for (const part of parts) {
            if (part.ty & TrackerPartType.Pile) {
                pilesCoords.push(new Vector3(0, part.centerOffset - trackerCenterOffset, 0));
            }
        }
    } else {
        const centerCoords = new Vector3();
        let centerReached = false;

        let pilesCount = 0;
        let lastPileCenterOffset = parts[0].centerOffset;
        for (const part of parts) {
            if (part.ty & TrackerPartType.Pile) {
                const offset = new Vector3(0, part.centerOffset - lastPileCenterOffset, 0);
                if (pilesCount > 0) {
                    offset.applyQuaternion(rotations[pilesCount - 1]);
                } else {
                    offset.applyQuaternion(rotations[pilesCount]);
                }
                pilesCoords.push(offset);

                if (part.centerOffset < trackerCenterOffset) {
                    centerCoords.add(offset);
                } else if (!centerReached) {
                    const centerOffset = new Vector3(0, trackerCenterOffset - lastPileCenterOffset, 0);
                    if (pilesCount > 0) {
                        centerOffset.applyQuaternion(rotations[pilesCount - 1]);
                    } else {
                        centerOffset.applyQuaternion(rotations[pilesCount]);
                    }
                    centerCoords.add(centerOffset);
                    centerReached = true;
                }

                lastPileCenterOffset = part.centerOffset;

                pilesCount++;
            }
        }
        for (let i = 1; i < pilesCoords.length; i++) {
            const prev = pilesCoords[i - 1];
            const p = pilesCoords[i];
            p.add(prev);
        }

        for (const point of pilesCoords) {
            point.sub(centerCoords);
        }
    }

    return pilesCoords;
}


export interface TrackerBuilderInfo {
    useModulesRow: boolean,
    modulesRow: string,
    modulesPerStringCountHorizontal: number,
    stringsPerTrackerCount: number,
    moduleBayCount: number,
    moduleSize: number,
    motorPlacementCoefficient: number,
    pileGap: number,
    motorGap: number,
    stringGap: number,
    modulesGap: number
}

export const trackersPartsCache = new CachedCalculations<TrackerBuilderInfo, TrackerSequenceBuilder>({
    identifier: `$tracker-mesh-group-gen-inner-cache`,
    maxCacheSize: 10,
    argsStringifer: (trackerInfo: TrackerBuilderInfo) => {
        let string = '';
        string += trackerInfo.moduleBayCount + ';';
        string += trackerInfo.moduleSize + ';';
        string += trackerInfo.modulesGap + ';';
        string += trackerInfo.modulesPerStringCountHorizontal + ';';
        string += trackerInfo.modulesRow + ';';
        string += trackerInfo.motorGap + ';';
        string += trackerInfo.motorPlacementCoefficient + ';';
        string += trackerInfo.pileGap + ';';
        string += trackerInfo.stringGap + ';';
        string += trackerInfo.stringsPerTrackerCount + ';';
        string += trackerInfo.useModulesRow + ';';
        return string;
    },
    lazyInvalidation: new LazyBasic('', ''),
    calculator: (trackerInfo: TrackerBuilderInfo) => {
        return TrackerSequenceBuilder.createBuilder({
            useModulesRow: trackerInfo.useModulesRow,
            modulesRow: trackerInfo.modulesRow,
            modulesPerStringCountHorizontal: trackerInfo.modulesPerStringCountHorizontal,
            stringsPerTrackerCount: trackerInfo.stringsPerTrackerCount,
            moduleBayCount: trackerInfo.moduleBayCount,
            moduleSize: trackerInfo.moduleSize,
            motorPlacementCoefficient: trackerInfo.motorPlacementCoefficient,
            pileGap: trackerInfo.pileGap,
            motorGap: trackerInfo.motorGap,
            stringGap: trackerInfo.stringGap,
            modulesGap: trackerInfo.modulesGap,
        });
    },
});


export function trackerMeshGenerator(bim: Bim): ReactiveSolverBase {

    const DefaultTrackerMeshGenInput = {
        legacyProps: {
            module_size_x: BimProperty.NewShared({ path: ["module", "width"], value: 0, unit: 'm' }),
            module_size_y: BimProperty.NewShared({ path: ["module", "length"], value: 0, unit: 'm' }),
            string_modules_count_x: BimProperty.NewShared({ path: ['tracker-frame', "string", "modules_count_x"], value: 0 }),
            string_modules_count_y: BimProperty.NewShared({ path: ['tracker-frame', "string", "modules_count_y"], value: 0 }),
            tracker_strings_count: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "strings_count"], value: 0 }),
            tracker_module_bay_size: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "module_bay_size"], value: 1 }),
            tracker_pile_reveal: BimProperty.NewShared({ path: ["tracker-frame", "piles", "max_reveal"], value: 0.5, unit: 'm' }),
            tracker_pile_embedment: BimProperty.NewShared({ path: ["tracker-frame", "piles", "min_embedment"], value: 0.5, unit: 'm' }),
            tracker_pile_bearings_gap: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "pile_bearings_gap"], value: 0, unit: 'm' }),
            tracker_strings_gap: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "strings_gap"], value: 0, unit: 'm' }),
            modules_gap: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "modules_gap"], value: 0, unit: 'm' }),
            tracker_motor_placement: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "motor_placement"], value: 0 }),
            tracker_motor_gap: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "motor_gap"], value: 0, unit: 'm' }),
            modules_row: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "modules_row"], value: "", readonly: false }),
            use_modules_row: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "use_modules_row"], value: false, readonly: false }),
            mounting_module_width: BimProperty.NewShared({ path: ["tracker-frame", "module_mounting", "module_width"], value: 0, unit: 'm' }),
            slope: BimProperty.NewShared({ path: ["position", "slope_first_to_last"], value: 0, unit: '%' }),
            slope_direction: BimProperty.NewShared({ path: ["position", "_local-slope-direction"], value: 1 }),
            segments_slopes: BimProperty.NewShared({ path: ["position", "_segments-slopes"], value: "", readonly: false }),
        },
    }


    interface ModuleInfo {
        geoId: IdBimGeo;
        matId: IdBimMaterial;
        transform: Transform | null;
    }

    const modulesCache = new LruCache<ModuleInfo, StdSubmeshRepresentation>({
        identifier: `$tracker-mesh-module-gen-inner-cache`,
        maxSize: 100,
        hashFn: (moduleInfo: ModuleInfo) => {
            let hash: number = moduleInfo.geoId;
            hash = combineHashCodes(moduleInfo.matId, hash);
            if (moduleInfo.transform) {
                const position = moduleInfo.transform.position;
                hash = combineHashCodes(ObjectUtils.primitiveHash(position.x), hash);
                hash = combineHashCodes(ObjectUtils.primitiveHash(position.y), hash);
                hash = combineHashCodes(ObjectUtils.primitiveHash(position.z), hash);
            }
            return hash;
        },
        eqFunction: (a: ModuleInfo, b: ModuleInfo) => {
            if (a.geoId !== b.geoId) {
                return false;
            }
            if (a.matId !== b.matId) {
                return false;
            }
            if (a.transform && b.transform) {
                return a.transform.equals(b.transform);
            }
            return a.transform === b.transform;
        },
        factoryFn: (moduleInfo: ModuleInfo) => {
            return new StdSubmeshRepresentation(
                moduleInfo.geoId,
                moduleInfo.matId,
                moduleInfo.transform
            );
        },
    });


    interface GroupInfo {
        offsets: number[];
        stringModulesCountX: number;
        moduleTotalX: number;
        moduleGeoId: IdBimGeo;
        moduleMatId: IdBimMaterial;
    }

    const modulesGroupsCache = new LruCache<GroupInfo, StdSubmeshRepresentation[]>({
        identifier: `$tracker-mesh-group-gen-inner-cache`,
        maxSize: 250,
        hashFn: (groupInfo: GroupInfo) => {
            let hash: number = ObjectUtils.primitiveHash(groupInfo.stringModulesCountX);
            for (const offset of groupInfo.offsets) {
                hash = combineHashCodes(ObjectUtils.primitiveHash(offset), hash);
            }
            hash = combineHashCodes(ObjectUtils.primitiveHash(groupInfo.moduleTotalX), hash);
            hash = combineHashCodes(ObjectUtils.primitiveHash(groupInfo.moduleGeoId), hash);
            hash = combineHashCodes(ObjectUtils.primitiveHash(groupInfo.moduleMatId), hash);
            return hash;
        },
        eqFunction: (a: GroupInfo, b: GroupInfo) => {
            if (a.stringModulesCountX !== b.stringModulesCountX) {
                return false;
            }
            if (a.moduleTotalX !== b.moduleTotalX) {
                return false;
            }
            if (a.moduleGeoId !== b.moduleGeoId) {
                return false;
            }
            if (a.moduleMatId !== b.moduleMatId) {
                return false;
            }
            if (a.offsets.length !== b.offsets.length) {
                return false;
            }
            for (let i = 0; i < a.offsets.length; ++i) {
                if (a.offsets[i] !== b.offsets[i]) {
                    return false;
                }
            }
            return true;
        },
        factoryFn: (groupInfo: GroupInfo) => {
            const modulesSubmeshes: StdSubmeshRepresentation[] = [];
            for (const offset of groupInfo.offsets) {
                for (let x = 0; x < groupInfo.stringModulesCountX; ++x) {
                    const tr = new Transform(
                        new Vector3(groupInfo.moduleTotalX * x, offset, 0)
                        .roundTo(1e-6)
                    );
                    modulesSubmeshes.push(modulesCache.get({
                        geoId: groupInfo.moduleGeoId,
                        matId: groupInfo.moduleMatId,
                        transform: tr
                    }));
                }
            }
            return modulesSubmeshes;
        },
    });


    interface TrackersProps {
        parts: TrackerPart[],
        props: {
            module_size_x: number,
            module_size_y: number,
            string_modules_count_x: number,
            string_modules_count_y: number,
            tracker_strings_count: number,
            tracker_module_bay_size: number,
            tracker_pile_reveal: number,
            tracker_pile_embedment: number,
            tracker_pile_bearings_gap: number,
            tracker_strings_gap: number,
            modules_gap: number,
            tracker_motor_placement: number,
            tracker_motor_gap: number,
            modules_row: string,
            use_modules_row: boolean,
            mounting_module_width: number,
            slope: number,
            slope_direction: number,
            segments_slopes: string,
        }
    };

    function calculateStartingPoint(parts: TrackerPart[], rotations: Quaternion[]) {
        const trackerCenterOffset = 0.5 * (parts[0].centerOffset + parts.at(-1)!.centerOffset);
        if (rotations.length === 0) {
            return new Vector3(0, -trackerCenterOffset, 0);
        }

        const currentCoords = new Vector3();

        let pilesCount = 0;
        let lastPileCenterOffset = parts[0].centerOffset;
        for (const part of parts) {
            if (part.ty & TrackerPartType.Pile) {
                if (part.centerOffset >= trackerCenterOffset) {
                    const offset = new Vector3(0, trackerCenterOffset - lastPileCenterOffset, 0);
                    if (pilesCount > 0) {
                        offset.applyQuaternion(rotations[pilesCount - 1]);
                    } else {
                        offset.applyQuaternion(rotations[pilesCount]);
                    }
                    currentCoords.add(offset);

                    break;
                } else {
                    const offset = new Vector3(0, part.centerOffset - lastPileCenterOffset, 0);
                    if (pilesCount > 0) {
                        offset.applyQuaternion(rotations[pilesCount - 1]);
                    } else {
                        offset.applyQuaternion(rotations[pilesCount]);
                    }
                    currentCoords.add(offset);

                    lastPileCenterOffset = part.centerOffset;

                    pilesCount++;
                }
            }
        }

        return currentCoords.negate();
    }

    const calcultionsCache = new LruCache<TrackersProps, SolverInstancePatchResult>({
        identifier: `$tracker-mesh-gen-inner-cache`,
        maxSize: 2500,
        hashFn: (args: TrackersProps) => {
            let hash: number = 0;
            for (const path in args.props) {
                //@ts-ignore
                const bimProp = args.props[path];
                hash = combineHashCodes(ObjectUtils.primitiveHash(bimProp), hash);
            }
            return hash + args.props.segments_slopes;
        },
        eqFunction: (a: TrackersProps, b: TrackersProps) => {
            if (a.props.segments_slopes !== b.props.segments_slopes) {
                return false;
            }
            for (const path in a.props) {
                //@ts-ignore
                if (a.props[path] !== b.props[path]) {
                    return false;
                }
            }
            if (a.parts.length !== b.parts.length) {
                return false;
            }
            for (let i = 0; i < a.parts.length; ++i) {
                if (!a.parts[i].equals(b.parts[i])) {
                    return false;
                }
            }
            return true;
        },
        factoryFn: (args: TrackersProps) => {
            const parts = args.parts;
            const props = args.props;

            let generalRotation = new Quaternion();
            const generalRotationAngle = props.slope_direction * props.slope;
            if (generalRotationAngle < 0.7854 && generalRotationAngle > -0.7854) {
                generalRotation.setFromAxisAngle(Vec3X, generalRotationAngle);
            }

            const rotations: Quaternion[] = [];
            let rotationsAngles: number[] = [];
            if (props.segments_slopes !== "") {
                rotationsAngles = segmentsAnglesFromString(props.segments_slopes);

                for (const rotationAngle of rotationsAngles) {
                    rotations.push(new Quaternion().setFromAxisAngle(Vec3X, rotationAngle));
                }
            }

            let allRotationsEqual = true;
            for (let i = 1; i < rotations.length; ++i) {
                if (!rotations[0].equals(rotations[i])) {
                    allRotationsEqual = false;
                    break;
                }
            }
            if (allRotationsEqual) {
                rotations.length = 0;
            }

            const mounting_module_width = props.mounting_module_width;

            let module_width = mounting_module_width || props.module_size_x;
            const module_height = props.module_size_y;

            let string_modules_count_y = props.string_modules_count_x;
            const string_modules_count_x = props.string_modules_count_y;
            const modules_gap = props.modules_gap;


            const tracker_strings_count = props.tracker_strings_count;
            const [modulesRow] = getModulesRows(props.modules_row);
            if(props.use_modules_row){
                let modules_count_x = 0;
                for (const module of modulesRow) {
                    modules_count_x += module;
                }
                string_modules_count_y = Math.ceil(modules_count_x/tracker_strings_count);
            }

            const moduleGeoId = bim.cubeGeometries.shared!.get({
                size: new Vector3(module_height, module_width, ModuleHeight),
            })!;
            const moduleMatId = bim.bimMaterials.shared!.get({
                name: "default",
                stdRenderParams: new BimMaterialStdRenderParams(
                    "#000077",
                    0,
                    0.85,
                    0.15
                ),
            })!;

            const submeshesResult: StdSubmeshRepresentation[] = [];
            const modulesRepr: SubmeshGroupRepresentation[] = [];
            const lowLodSubmeshes: StdSubmeshRepresentation[] = [];

            const poleRadius = 0.05;
            const pileHeight = props.tracker_pile_reveal + props.tracker_pile_embedment;

            const moduleTotalX = module_height + modules_gap;
            const stringTotalX = string_modules_count_x * moduleTotalX - modules_gap;

            const tracker_pipe_length = Math.abs(parts[parts.length-1].centerOffset - parts[0].centerOffset);
            let tracker_length = tracker_pipe_length;
            if (parts[0].ty & TrackerPartType.Motor) {
                tracker_length += 0.125;
            } else if (parts[0].ty & TrackerPartType.Pile) {
                tracker_length += poleRadius;
            }
            if (parts[parts.length-1].ty & TrackerPartType.Motor) {
                tracker_length += 0.125;
            } else if (parts[parts.length-1].ty & TrackerPartType.Pile) {
                tracker_length += poleRadius;
            }

            const tracker_width = module_height * string_modules_count_x + (string_modules_count_x - 1) * modules_gap;

            const pipePilesMatId = bim.bimMaterials.shared!.get({
                name: "default",
                stdRenderParams: new BimMaterialStdRenderParams(
                    "#FFFFFF",
                    0,
                    0.2,
                    0.9
                ),
            })!;

            const pileScale = new Vector3(1, 1, pileHeight);

            const segmentGeoId = bim.polylineGeometries.shared!.get(
                PolylineGeometry.newWithAutoIds(
                    [ Vec3Zero, Vec3ZNeg ],
                    poleRadius
                )
            )!;

            const motorMatId = bim.bimMaterials.shared!.get({
                name: "motor",
                stdRenderParams: new BimMaterialStdRenderParams(
                    "#555555",
                    0,
                    0.7,
                    0.4
                ),
            })!;
            const motorShell: Vector2[] = [
                new Vector2(-1, 0),
                new Vector2(-0.5, -1),
                new Vector2(0.5, -1),
                new Vector2(1, 0),
            ];
            if (props.tracker_motor_gap > 0) {
                motorShell.push(new Vector2(0.5, 1), new Vector2(-0.5, 1));
            }
            const motorGeoId = bim.extrudedPolygonGeometries.shared!.get(
                ExtrudedPolygonGeometry.newWithAutoIds(motorShell, [], -1, 1)
            )!;

            const lodBoxGeo = bim.cubeGeometries.shared!.get({
                size: Vec3One,
            })!;

            let pilesCount = 0;
            let lastPileCenterOffset = parts[0].centerOffset;
            const pipePoints: Vector3[] = [];
            const matReused = new Matrix4();
            const startingPoint = calculateStartingPoint(parts, rotations);
            const startingPointOffset = new Vector3(0, 0, props.tracker_pile_reveal).applyQuaternion(generalRotation);
            if (rotations.length !== 0) {
                lowLodSubmeshes.push(
					new StdSubmeshRepresentation(
						lodBoxGeo,
						moduleMatId,
						new Transform(
							startingPointOffset,
							Quaternion.identity(),
							new Vector3(tracker_width, -2 * startingPoint.y, ModuleHeight),
						),
					)
				);

                pipePoints.push(startingPoint.clone());

                startingPoint.add(startingPointOffset);

                matReused
                    .makeIdentity()
                    .makeRotationFromQuaternion(rotations[0])
                    .setPosition(startingPoint.x + 0.5 * (module_height - stringTotalX), startingPoint.y, startingPoint.z + poleRadius);

                let modulesOffsets: number[] = [];
                for (const part of parts) {
                    if (part.ty & TrackerPartType.Pile) {
                        const offset = new Vector3(0, part.centerOffset - lastPileCenterOffset, 0);
                        if (pilesCount > 0) {
                            offset.applyQuaternion(rotations[pilesCount - 1]);
                        } else {
                            offset.applyQuaternion(rotations[pilesCount]);
                        }
                        offset.add(startingPoint);

                        if (pilesCount > 0 && pilesCount < rotations.length) {
                            lastPileCenterOffset = part.centerOffset;
                            startingPoint.copy(offset);
                            pipePoints.push(startingPoint.clone().sub(startingPointOffset));

                            if (modulesOffsets.length > 0) {
                                matReused.addToPosition(new Vector3(0, modulesOffsets[0], 0).applyMatrix4Rotation(matReused));

                                for (let i = modulesOffsets.length - 1; i >= 0; --i) {
                                    modulesOffsets[i] = KrMath.roundTo(modulesOffsets[i] - modulesOffsets[0], 1e-6);
                                }

                                const modulesSubmeshes = modulesGroupsCache.get({
                                    offsets: modulesOffsets,
                                    stringModulesCountX: string_modules_count_x,
                                    moduleTotalX,
                                    moduleGeoId,
                                    moduleMatId
                                }) as StdSubmeshRepresentation[];

                                const tr = new Transform();
                                tr.setFromMatrix4(matReused)
                                modulesRepr.push(new SubmeshGroupRepresentation(modulesSubmeshes, tr));

                                modulesOffsets = [];
                            }

                            matReused
                                .makeIdentity()
                                .makeRotationFromQuaternion(rotations[pilesCount])
                                .setPosition((module_height - stringTotalX) * 0.5, startingPoint.y, startingPoint.z + poleRadius);
                        }

                        submeshesResult.push(
                            new StdSubmeshRepresentation(
                                segmentGeoId,
                                pipePilesMatId,
                                new Transform(offset, generalRotation, pileScale)
                            )
                        );

                        pilesCount++;
                    }
                    if (part.ty & TrackerPartType.ModulesColumn) {
                        modulesOffsets.push(part.centerOffset - lastPileCenterOffset);
                    }
                    if (part.ty & TrackerPartType.Motor) {
                        const position = new Vector3(0, part.centerOffset - lastPileCenterOffset, 0);
                        if (pilesCount > rotations.length) {
                            position.applyQuaternion(rotations[pilesCount - 2]);
                        } else if (pilesCount === 1) {
                            position.applyQuaternion(rotations[0]);
                        } else {
                            position.applyQuaternion(rotations[pilesCount - 2].clone().multiply(rotations[pilesCount - 1]));
                        }
                        position.add(startingPoint)

                        const mat = matReused.clone()
                            .makeRotationX(Math.PI / 2)
                            .setPositionV(position);
                        const tr = new Transform();
                        tr.setFromMatrix4(mat);
                        tr.scale.multiplyScalar(0.25);
                        tr.scale.z *= 0.5;
                        submeshesResult.push(
                            new StdSubmeshRepresentation(motorGeoId, motorMatId, tr)
                        );
                    }
                }
                if (modulesOffsets.length > 0) {
                    matReused.addToPosition(new Vector3(0, modulesOffsets[0], 0).applyMatrix4Rotation(matReused));

                    for (let i = modulesOffsets.length - 1; i >= 0; --i) {
                        modulesOffsets[i] = KrMath.roundTo(modulesOffsets[i] - modulesOffsets[0], 1e-6);
                    }

                    const modulesSubmeshes = modulesGroupsCache.get({
                        offsets: modulesOffsets,
                        stringModulesCountX: string_modules_count_x,
                        moduleTotalX,
                        moduleGeoId,
                        moduleMatId
                    }) as StdSubmeshRepresentation[];

                    const tr = new Transform();
                    tr.setFromMatrix4(matReused);
                    modulesRepr.push(new SubmeshGroupRepresentation(modulesSubmeshes, tr));

                    modulesOffsets = [];
                }

                if (parts.length >= 2) {
                    const offset = new Vector3(0, parts.at(-1)!.centerOffset - lastPileCenterOffset, 0);
                    offset.applyQuaternion(rotations[pilesCount - 2]);
                    offset.add(startingPoint);

                    pipePoints.push(offset.clone().sub(startingPointOffset));
                }
            } else {
                lowLodSubmeshes.push(
					new StdSubmeshRepresentation(
						lodBoxGeo,
						moduleMatId,
						new Transform(
							startingPointOffset,
							Quaternion.identity(),
							new Vector3(tracker_width, tracker_pipe_length, ModuleHeight),
						),
					)
				);

                if (parts.length >= 2) {
                    pipePoints.push(startingPoint.clone());
                    pipePoints.push(startingPoint.clone().add(new Vector3(0, tracker_pipe_length, 0)));
                }

                startingPoint.add(startingPointOffset);

                for (const part of parts) {
                    if (part.ty & TrackerPartType.Pile) {
                        submeshesResult.push(
                            new StdSubmeshRepresentation(
                                segmentGeoId,
                                pipePilesMatId,
                                new Transform(
                                    startingPoint.clone().add(new Vector3(0, part.centerOffset, 0)),
                                    generalRotation,
                                    pileScale
                                )
                            )
                        );
                        pilesCount++;
                    }
                    if (part.ty & TrackerPartType.ModulesColumn) {
                        for (let x = 0; x < string_modules_count_x; ++x) {
                            const offset = startingPoint.clone().add(new Vector3(
                                moduleTotalX * x + module_height * 0.5 - stringTotalX * 0.5,
                                part.centerOffset,
                                poleRadius
                            ));
                            matReused
                                .makeIdentity()
                                .setPosition(offset.x, offset.y, offset.z);

                            const tr = new Transform();
                            tr.setFromMatrix4(matReused);
                            submeshesResult.push(
                                new StdSubmeshRepresentation(
                                    moduleGeoId,
                                    moduleMatId,
                                    tr
                                )
                            );
                        }
                    }
                    if (part.ty & TrackerPartType.Motor) {
                        matReused
                            .makeRotationX(Math.PI / 2)
                            .setPosition(startingPoint.x, startingPoint.y + part.centerOffset, startingPoint.z);
                        const tr = new Transform();
                        tr.setFromMatrix4(matReused);
                        tr.scale.multiplyScalar(0.25);
                        tr.scale.z *= 0.5;
                        submeshesResult.push(
                            new StdSubmeshRepresentation(motorGeoId, motorMatId, tr)
                        );
                    }
                }
            }

			const lod0DetailSize: number = Math.max(
				2 * poleRadius,
				10 * props.modules_gap,
				props.tracker_motor_gap * 0.3,
				props.tracker_strings_gap,
				props.tracker_pile_bearings_gap
			);

            if (parts.length >= 2) {
                const pipeGeoId = bim.polylineGeometries.shared!.get(
                    PolylineGeometry.newWithAutoIds(pipePoints, poleRadius)
                )!;
                submeshesResult.push(
                    new StdSubmeshRepresentation(
                        pipeGeoId,
                        pipePilesMatId,
                        new Transform(startingPointOffset)
                    )
                );
            }

			const lodRepr = lowLodSubmeshes.length > 0 ? new StdSubmeshesLod(
				lowLodSubmeshes,
				lod0DetailSize
			): null;

            const totalModulesCountY = string_modules_count_y * tracker_strings_count;
            const totalModulesCountX = string_modules_count_x;

            const repr = submeshesResult.length ?
                modulesRepr.length ?
                    new StdGroupedMeshRepresentation(submeshesResult, modulesRepr, lodRepr, true) :
                    new StdMeshRepresentation(submeshesResult, lodRepr, true) :
                null;

			// lod representation
            return {
                repr: repr,
                legacyProps: [
                    { path: ["tracker-frame", "dimensions", "length"], value: tracker_length, unit: "m" },
                    { path: ["tracker-frame", "dimensions", "max_width"], value: tracker_width, unit: "m" },
                    { path: ["tracker-frame", "piles", "count"], value: pilesCount },
                    { path: ["tracker-frame", "dimensions", "module_bay_size"], value: props.tracker_module_bay_size, readonly: props.use_modules_row },
                    { path: ["tracker-frame", "dimensions", "modules_row"], value: props.modules_row ? props.modules_row: " ", readonly: !props.use_modules_row },
                    { path: ["tracker-frame", "dimensions", "motor_placement"], value: props.tracker_motor_placement, readonly: props.use_modules_row },
                    { path: ["tracker-frame", "string", "modules_count_x"], value: string_modules_count_y, readonly: props.use_modules_row },
                    { path: ["tracker-frame", "modules", "modules_count_x"], readonly: true, value: totalModulesCountY },
                    { path: ["tracker-frame", "modules", "modules_count_y"], readonly: true, value: totalModulesCountX },
                ]
            };
        }
    });

    return new SolverObjectInstance({
        solverIdentifier: 'tracker-mesh-gen',
        objectsDefaultArgs: DefaultTrackerMeshGenInput,
        objectsIdentifier: 'tracker',
        cache: false,
        solverFunction: (inputObj): SolverInstancePatchResult => {
            const props = {
                module_size_x: inputObj.legacyProps.module_size_x.as('m'),
                module_size_y: inputObj.legacyProps.module_size_y.as('m'),
                string_modules_count_x: inputObj.legacyProps.string_modules_count_x.asNumber(),
                string_modules_count_y: inputObj.legacyProps.string_modules_count_y.asNumber(),
                tracker_strings_count: inputObj.legacyProps.tracker_strings_count.asNumber(),
                tracker_module_bay_size: inputObj.legacyProps.tracker_module_bay_size.asNumber(),
                tracker_pile_reveal: inputObj.legacyProps.tracker_pile_reveal.as('m'),
                tracker_pile_embedment: inputObj.legacyProps.tracker_pile_embedment.as('m'),
                tracker_pile_bearings_gap: inputObj.legacyProps.tracker_pile_bearings_gap.as('m'),
                tracker_strings_gap: inputObj.legacyProps.tracker_strings_gap.as('m'),
                modules_gap: inputObj.legacyProps.modules_gap.as('m'),
                tracker_motor_placement: inputObj.legacyProps.tracker_motor_placement.asNumber(),
                tracker_motor_gap: inputObj.legacyProps.tracker_motor_gap.as('m'),
                modules_row: inputObj.legacyProps.modules_row.asText(),
                use_modules_row: inputObj.legacyProps.use_modules_row.asBoolean(),
                mounting_module_width: inputObj.legacyProps.mounting_module_width.as('m'),
                slope: inputObj.legacyProps.slope.as('rad'),
                slope_direction: inputObj.legacyProps.slope_direction.asNumber(),
                segments_slopes: inputObj.legacyProps.segments_slopes.asText(),
            }

            let string_modules_count_x = props.string_modules_count_x;
            const [modulesRow] = getModulesRows(props.modules_row);
            if(props.use_modules_row){
                let modules_count_x = 0;
                for (const module of modulesRow) {
                    modules_count_x += module;
                }
                string_modules_count_x = Math.ceil(modules_count_x / props.tracker_strings_count);
            }

            const parts = trackersPartsCache.acquire({
                useModulesRow: props.use_modules_row,
                modulesRow: props.modules_row,
                modulesPerStringCountHorizontal: string_modules_count_x,
                stringsPerTrackerCount: props.tracker_strings_count,
                moduleBayCount: props.tracker_module_bay_size,
                moduleSize: props.mounting_module_width || props.module_size_x,
                motorPlacementCoefficient: props.tracker_motor_placement,
                pileGap: props.tracker_pile_bearings_gap,
                motorGap: props.tracker_motor_gap,
                stringGap: props.tracker_strings_gap,
                modulesGap: props.modules_gap,
            }).parts;

            const pilesHeight = props.tracker_pile_reveal + props.tracker_pile_embedment;

            const generalRotationAngle = props.slope_direction * props.slope;
            let maxBayToBaySlopeChange = 0;
            let cumulativeSlopes = [0, 0];
            let wingIndex = 0;
            let maxSlopePerBay = Math.abs(generalRotationAngle);

            const rotations: Quaternion[] = [];
            let rotationsAngles: number[] = [];
            if (props.segments_slopes !== "") {
                rotationsAngles = segmentsAnglesFromString(props.segments_slopes);

                for (const rotationAngle of rotationsAngles) {
                    rotations.push(new Quaternion().setFromAxisAngle(Vec3X, rotationAngle));

                    const slopePerBay = Math.abs(generalRotationAngle + rotationAngle);
                    if (slopePerBay > maxSlopePerBay) {
                        maxSlopePerBay = slopePerBay;
                    }
                }

                let pilesCount = 0;
                for (const part of parts) {
                    if (part.ty & TrackerPartType.Pile) {
                        if (pilesCount > 0 && pilesCount < rotations.length) {
                            const bayToBaySlopeChange = Math.abs(rotationsAngles[pilesCount] - rotationsAngles[pilesCount - 1]);
                            if (bayToBaySlopeChange > maxBayToBaySlopeChange) {
                                maxBayToBaySlopeChange = bayToBaySlopeChange;
                            }
                            if (part.ty & TrackerPartType.Motor) {
                                cumulativeSlopes[wingIndex] += bayToBaySlopeChange / 2;
                                wingIndex++;
                                cumulativeSlopes[wingIndex] += bayToBaySlopeChange / 2;
                            } else {
                                cumulativeSlopes[wingIndex] += bayToBaySlopeChange;
                            }
                        }
                        pilesCount++;
                    }
                }
            }

            let angle = props.slope;
            angle = angle < 0.7854 ? KrMath.roundTo(angle, 0.02) : 0;
            props.slope = angle;

            if (props.segments_slopes && props.segments_slopes !== "") {
                const segments_slopes = props.segments_slopes.split(",");
                let newSlopes = "";
                for (let i = 0; i < segments_slopes.length; i++) {
                    const slopeWithFacing = segments_slopes[i].trim().split("%");
                    angle = +slopeWithFacing[0];
                    angle = angle < 100 ? KrMath.roundTo(angle, 0.1) : 0;
                    if (angle === 0) {
                        slopeWithFacing[1] = "N";
                    }
                    newSlopes = newSlopes.concat(angle.toString() + "%" + slopeWithFacing[1]);
                    if (i < segments_slopes.length - 1) {
                        newSlopes += ", ";
                    }
                }
                props.segments_slopes = newSlopes;
            }

            props.tracker_pile_embedment = KrMath.roundTo(
                props.tracker_pile_embedment,
                Math.max(0.005, 0.01 * Math.floor(2 * props.tracker_pile_embedment)));
            props.tracker_pile_reveal = KrMath.roundTo(
                props.tracker_pile_reveal,
                Math.max(0.005, 0.01 * Math.floor(2 * props.tracker_pile_reveal)));

            const result = calcultionsCache.get({ parts, props });

            const legacyProps: BimPropertyData[] = [
                { path: ["tracker-frame", "piles", "length"], value: KrMath.roundTo(pilesHeight, 0.001), unit: "m", numeric_step: 0.001 },
                { path: ["position", "max_bay-to-bay_slope_change"], readonly: true, value: KrMath.roundTo(100 * Math.tan(maxBayToBaySlopeChange), 0.01), unit: '%', numeric_step: 0.01 },
                { path: ["position", "cumulative_slope_change_wing1"], readonly: true, value: KrMath.roundTo(100 * Math.tan(cumulativeSlopes[0]), 0.01), unit: '%', numeric_step: 0.01 },
                { path: ["position", "cumulative_slope_change_wing2"], readonly: true, value: KrMath.roundTo(100 * Math.tan(cumulativeSlopes[1]), 0.01), unit: '%', numeric_step: 0.01 },
                { path: ["position", "max_slope_per_bay"], readonly: true, value: KrMath.roundTo(100 * Math.tan(maxSlopePerBay), 0.01), unit: '%', numeric_step: 0.01 },
            ];
            IterUtils.extendArray(legacyProps, result.legacyProps!);

            return {
                repr: result.repr,
                legacyProps: legacyProps
            };
        },
        invalidateInnerCache: (args: {
            geometriesIds: Set<IdBimGeo>,
            materialsIds: Set<IdBimMaterial>,
        }) => {
            const idsReused: EntityIdAny[] = [];
            function containsIdsFromSet(setToCheck: Set<EntityIdAny>) {
                for (const id of idsReused) {
                    if (setToCheck.has(id)) {
                        return true;
                    }
                }
                return false;
            }
            calcultionsCache.filter((output) => {
                if (output.repr) {
                    idsReused.length = 0;
                    output.repr.geometriesIdsReferences(idsReused);
                    if (containsIdsFromSet(args.geometriesIds)) {
                        return false;
                    }
                    idsReused.length = 0;
                    output.repr.materialIdsReferences(idsReused);
                    if (containsIdsFromSet(args.materialsIds)) {
                        return false;
                    }
                }
                if (output.reprAnalytical) {
                    idsReused.length = 0;
                    output.reprAnalytical.geometriesIdsReferences(idsReused);
                    if (containsIdsFromSet(args.geometriesIds)) {
                        return false;
                    }
                    idsReused.length = 0;
                    output.reprAnalytical.materialIdsReferences(idsReused);
                    if (containsIdsFromSet(args.materialsIds)) {
                        return false;
                    }
                }
                return true;
            });
            modulesGroupsCache.filter((output) => {
                idsReused.length = 0;
                IterUtils.extendArray(idsReused, output.map(r => r.geometryId));
                if (containsIdsFromSet(args.geometriesIds)) {
                    return false;
                }
                idsReused.length = 0;
                IterUtils.extendArray(idsReused, output.map(r => r.materialId));
                if (containsIdsFromSet(args.materialsIds)) {
                    return false;
                }
                return true;
            });
        }
    })
}

export enum ModulesGapContent {
    None,
    StringGap = 1,
    Pile = 2,
    Motor = 4,
    Edge = 8,
}

export enum TrackerPartType {
    ModulesColumn = 1,
    Pile = 2,
    Motor = 4,
    Edge = 8,
}

export class TrackerPart {
    constructor(
        readonly ty: TrackerPartType,
        readonly centerOffset: number
    ) {}

    equals(other: TrackerPart) {
        return this.ty === other.ty && this.centerOffset === other.centerOffset;
    }
}

// class TrackerParts {
//     constructor(
//         readonly parts: TrackerPart[],
//         readonly totalLength: number[],
//     )
// }


export class TrackerSequenceBuilder {
    _gaps: ModulesGapContent[];
    parts: TrackerPart[] = [];
    constructor(gaps: ModulesGapContent[]) {
        this._gaps = gaps;
    }

    static createBuilder(params: TrackerBuilderInfo) {
        let gaps: ModulesGapContent[] = [];
        if (params.useModulesRow) {
            gaps = new TrackerRowSequenceBuilder(params).createGaps();
        } else {
            gaps = new TrackerBaseSequenceBuilder(params).createGaps();
        }
        const builder = new TrackerSequenceBuilder(gaps);
        builder.finish(params);
        return builder;
    }

    finish(params: TrackerBuilderInfo): TrackerPart[] {
        const result: TrackerPart[] = [];

        let currentOffset = 0;

        for (let i = 0; i < this._gaps.length; ++i) {
            const inBetweenFlags = this._gaps[i];
            let trackerPartType = 0;
            let gapSize = 0;
            if (inBetweenFlags & ModulesGapContent.StringGap) {
                gapSize = Math.max(
                    gapSize,
                    params.stringGap,
                    params.modulesGap
                );
            }
            if (inBetweenFlags & ModulesGapContent.Motor) {
                trackerPartType |= TrackerPartType.Motor;
                gapSize = Math.max(gapSize, params.motorGap);
            }
            if (inBetweenFlags & ModulesGapContent.Pile) {
                trackerPartType |= TrackerPartType.Pile;
                gapSize = Math.max(gapSize, params.pileGap);
            }
            if (inBetweenFlags & ModulesGapContent.Edge) {
                trackerPartType |= TrackerPartType.Edge;
                gapSize = Math.max(gapSize, params.pileGap);
            }
            if (gapSize === 0) {
                gapSize = Math.max(gapSize, params.modulesGap);
            }

            if (trackerPartType != 0) {
                const offset = i === 0 ? 0 : gapSize * 0.5;
                result.push(
                    new TrackerPart(
                        trackerPartType,
                        currentOffset + offset
                    )
                );
            }
            currentOffset += (i === 0 ? gapSize * 0.5 : gapSize);

            if (i !== this._gaps.length - 1) {
                // if not last portion, add modules column
                result.push(
                    new TrackerPart(
                        TrackerPartType.ModulesColumn,
                        currentOffset + params.moduleSize * 0.5
                    )
                );

                currentOffset += params.moduleSize;
            }
        }

        this.parts = result;

        return this.parts;
    }
}

class TrackerBaseSequenceBuilder {
    initParams: TrackerBuilderInfo;
    motorAtPileN: number;

    _pilesAdded: number = 0;

    gaps: ModulesGapContent[] = [ModulesGapContent.None];

    constructor(params: TrackerBuilderInfo) {
        this.initParams = params;
        const trackerColumns =
            this.initParams.stringsPerTrackerCount *
            this.initParams.modulesPerStringCountHorizontal;
        const pilesCount =
            Math.ceil(trackerColumns / this.initParams.moduleBayCount) + 1;
        const motorAtPile =
            KrMath.clamp(
                Math.round(
                    pilesCount * this.initParams.motorPlacementCoefficient
                ),
                1,
                pilesCount
            ) - 1;

        this.motorAtPileN = motorAtPile;
    }

    createGaps(){
        for (let stringI = 0; stringI < this.initParams.stringsPerTrackerCount; ++stringI) {
            for (let x = 0; x < this.initParams.modulesPerStringCountHorizontal; ++x) {
                this.addModule();
            }
        }
        this._addPile();


        return this.gaps;
    }



    _addPile() {
        const lastIndex = this.gaps.length - 1;
        this.gaps[lastIndex] |= ModulesGapContent.Pile;
        if (this.motorAtPileN === this._pilesAdded) {
            this.gaps[lastIndex] |= ModulesGapContent.Motor;
        }
        this._pilesAdded += 1;
    }

    addModule() {
        const modulesCount = this.gaps.length - 1;
        if (
            modulesCount % this.initParams.modulesPerStringCountHorizontal == 0 &&
            modulesCount > 0
        ) {
            this.gaps[modulesCount] |= ModulesGapContent.StringGap;
        }
        if (modulesCount % this.initParams.moduleBayCount == 0) {
            this._addPile();
        }
        this.gaps.push(ModulesGapContent.None);
    }
}


class TrackerRowSequenceBuilder {
    _initParams: TrackerBuilderInfo;
    _motorsAtModuleN: Set<number>;
    _modulesByString: number[];

    _gaps: ModulesGapContent[] = [ModulesGapContent.None];
    _allModules:number;

    constructor(params: TrackerBuilderInfo) {
        this._initParams = params;
        const [modulesByString, motorAtModuleN] = getModulesRows(params.modulesRow);
        this._motorsAtModuleN = new Set(motorAtModuleN);
        this._modulesByString = modulesByString;
        let allModules = 0;
        for (const modules of this._modulesByString) {
            allModules+=modules;
        }
        this._allModules = allModules;

    }
    createGaps(){
        const modulesCountHor = Math.ceil(this._allModules/this._initParams.stringsPerTrackerCount);

        let pilesPosition = 0;
        for (let x = 0; x < this._modulesByString.length; ++x) {
            const modules = this._modulesByString[x];
            for (let m = 0; m < modules; m++) {
                this.addModule(pilesPosition, modulesCountHor);
            }
            pilesPosition+=modules;
        }
        this.addPile(pilesPosition);

        return this._gaps;
    }

    private addModule(pilesPosition: number, modulesStringCountHor: number) {
        const modulesCount = this._gaps.length - 1;

        if (
            modulesCount % modulesStringCountHor == 0 &&
            modulesCount > 0
        ) {
            this._gaps[modulesCount] |= ModulesGapContent.StringGap;
        }
        if (modulesCount == pilesPosition) {
            this.addPile(pilesPosition);
        }

        this._gaps.push(ModulesGapContent.None);
    }

    private addPile(pilesPosition: number) {
        const lastIndex = this._gaps.length - 1;
        if(pilesPosition === 0 && this._modulesByString[0]!==0 && !this._motorsAtModuleN.has(pilesPosition)){
            this._gaps[lastIndex] |= ModulesGapContent.Edge;
        } else if(pilesPosition === this._allModules && this._modulesByString[this._modulesByString.length-1]!==0 && !this._motorsAtModuleN.has(pilesPosition)){
            this._gaps[lastIndex] |= ModulesGapContent.Edge;
        } else {
            this._gaps[lastIndex] |= ModulesGapContent.Pile;
            if (this._motorsAtModuleN.has(pilesPosition)) {
                this._gaps[lastIndex] |= ModulesGapContent.Motor;
            }
        }
    }
}

export const TrackerKeyProps = {
    model: BimProperty.NewShared({
        path: ['tracker-frame', 'commercial', 'model'],
        value: 'unknown_model',
    }),
    moduleModel: BimProperty.NewShared({
        path: ['module', 'model'],
        value: 'unknown_model',
    }),
    stringModulesCount: BimProperty.NewShared({
        path: ['tracker-frame', 'string', 'modules_count'],
        value: 0,
    }),
    modulesCount: BimProperty.NewShared({
        path: ['circuit', 'equipment', 'modules_count'],
        value: 0,
    }),
    length: BimProperty.NewShared({
        path: ['tracker-frame', 'dimensions', 'length'],
        value: 0,
        unit: 'm',
    }),
    max_width: BimProperty.NewShared({
        path: ['tracker-frame', 'dimensions', 'max_width'],
        value: 0,
        unit: 'm',
    }),
    pilesCount: BimProperty.NewShared({
        path: ['tracker-frame', 'piles', 'count'],
        value: 0,
    })
};

export function registerTrackerKeyPropsGroupFormatter(group: PropertiesGroupFormatters) {
    group.register(
        TrackerTypeIdent,
        new PropertiesGroupFormatter(
            TrackerKeyProps,
            (props, unitsMapper) => {
                return [
                    [
                        props.model,
                        props.moduleModel,
                    ].map(x => x.asText()),
                    [
                        props.stringModulesCount,
                        props.modulesCount
                    ].map(x => x.valueUnitUiString(unitsMapper)).join('/') + 'MOD',
                ].flat().join(' ');
            }
        )
    )
}

export function registerTrackerAssetToCatalogItem(group: AssetBasedCatalogItemCreators) {
    registerEquipmentCommonAssetToCatalogItem(TrackerTypeIdent, group);
}

export function movePilesPropsToTrackerFrame_ShapeMigration(toVersion: number)
    : SceneInstanceShapeMigration
{
    const prefixesToMove = [
        ['piles'],
    ];
    return {
        toVersion,
        validation: {
            deletedProps: [],
            updatedProps: [],
        },
        patch: (inst) => {
            const migratePropsPatch: PropertiesPatch = [];
            for (const prefixPath of prefixesToMove) {
                const props = inst.properties.getPropStartingWith(BimProperty.MergedPath(prefixPath));
                for (const prop of props) {
                    const newPath = ['tracker-frame', ...prop.path];
                    migratePropsPatch.push(
                        // remove old prop
                        [
                            BimProperty.MergedPath(prop.path),
                            null
                        ],
                        // create new prop
                        [
                            BimProperty.MergedPath(newPath),
                            {
                                ...prop,
                                path: newPath,
                            }
                        ]
                    )
                }
            }
            inst.properties.applyPatch(migratePropsPatch);
        }
    }
}

sceneInstanceHierarchyPropsRegistry.set(
    TrackerTypeIdent,
    [
        new BimPropertiesFormatter(
            {
                trackerFrameModel: BimProperty.NewShared({
                    path: ['tracker-frame', 'commercial', 'model'],
                    value: '',
                }),
            },
            (props) => [props.trackerFrameModel.asText() || 'module model not specified']
        ),
        new BimPropertiesFormatter(
            {
                modules_count: BimProperty.NewShared({
                    path: ['circuit', 'equipment', 'modules_count'],
                    value: 0,
                }),
            },
            (props) => [props.modules_count.asNumber(), 'MODS']
        ),
    ]
);

export const expandTrackerLegacyPropsWithCostTableLinks: ExpandLegacyPropsWithCostTableLinks = (params) => {
    const sis = Array.from(params.bim.instances.peekByIds(params.ids).values());
    if (sis.some(x => x.type_identifier !== TrackerTypeIdent)) {
        return;
    }

    createTrackerModuleCostModelLink(params);

    frame: {
        const groupByFrame = Array.from(IterUtils.groupBy(
            sis,
            (o) => {
                const props = o.properties.extractPropertiesGroup(TrackerFrameUniqueProps)
                return [
                    props.manufacturer.asText(),
                    props.model.asText(),
                    props.loadWindPosition.asText(),
                    props.moduleCountX.asNumber(),
                ].join('/');
            },
        ))
        if (groupByFrame.length !== 1) {
            break frame;
        }
        const sample = groupByFrame[0][1][0];

        createFocusLinkOnSample({
            costModelFocusApi: params.costModelFocusApi,
            sample,
            targetPui: PUI_GroupNode.tryGetNestedChild(params.pui, ['cost', 'frame']),
            type_identifier: TrackerFrameTypeIdentifier,
            label: 'Setup frame costs'
        })
    }

    createFocusLinkOnSample({
        costModelFocusApi: params.costModelFocusApi,
        targetPui: PUI_GroupNode.tryGetNestedChild(params.pui, ['cost', 'piles']),
        type_identifier: `tracker-pile`,
        label: 'Setup piles costs'
    })
}


export const createTrackerModuleCostModelLink: ExpandLegacyPropsWithCostTableLinks = (params) => {
    const sis = Array.from(params.bim.instances.peekByIds(params.ids).values());
    const groupByModule = Array.from(IterUtils.groupBy(
        sis,
        (o) => {
            const props = extractIntoNamedPropsGroup(ModuleUniqueProps, o.properties, o.props)
            return [
                props.manufacturer.asText(),
                props.model.asText(),
                props.power.as('W'),
            ].join('/');
        },
    ))
    if (groupByModule.length !== 1) {
        return;
    }
    createFocusLinkOnSample({
        costModelFocusApi: params.costModelFocusApi,
        sample: groupByModule[0][1][0],
        targetPui: PUI_GroupNode.tryGetNestedChild(params.pui, ['cost', 'module']),
        type_identifier: PVModuleTypeIdent,
        label: 'Setup module costs'
    })
}


